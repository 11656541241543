import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useSelector } from "react-redux";
import { store } from "../../store";



const PublicRoute = ({ component: Component, ...rest }) => {
  const { token } = useSelector((state) => state.auth);

  const userdata = store.getState((state) => state);
// let authenticated = userdata.auth.loggedIn;
// let role = userdata.auth.user.role;
// let user = userdata.auth.user;



  return (
    <Route
      {...rest}
      render={(props) =>
        !token ? <Component {...props} /> : <Redirect to="/dashboard" />
      }
    />
  );
};

export default PublicRoute;
