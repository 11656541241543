import React, { useEffect, useState,useCallback, lazy, Suspense  } from 'react';
import { connect } from "react-redux";
import API from '../../utils/API';
import { Row, Col, Button, Typography, Layout, Tabs, Skeleton, Drawer, Mentions, Upload } from 'antd';
import { ScaleLoader, SyncLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled, { createGlobalStyle } from "styled-components";
import isMobile from '../../components/viewport/isMobile';
import { store } from "../../store";
import Swal from "sweetalert2";

const ClientDetails = lazy(() => import('../../components/LeadsDetails/ClientDetails'));
const Requirements = lazy(() => import('../../components/LeadsDetails/Requirements'));
const Enquiry = lazy(() => import('../../components/LeadsDetails/Enquiry'));
const Viewings = lazy(() => import('../../components/LeadsDetails/Viewings'));
const FollowUps = lazy(() => import('../../components/LeadsDetails/FollowUps'))
const Offers = lazy(() => import('../../components/LeadsDetails/Offers'));
const ActivityTimeline = lazy(() => import('../../components/LeadsDetails/ActivityTimeline'));


const { Sider } = Layout;
const { Title } = Typography;
const { Dragger } = Upload;
const { Text } = Typography;
const { TabPane } = Tabs;

const GlobalStyles = createGlobalStyle`
  .ant-mentions textarea { padding:0 }
  .ant-mentions-outlined:focus, .ant-mentions-outlined:hover{ outline:none; border:none }
  .listinginfowrap  .d-flex.flex-row.w-100:nth-child(2) { gap:0.5rem }
  a.whatsappwrap {background:#25d366; color:#FFF}
  a.whatsappwrap span, a.whatsappwrap i {color:#FFF !important}
  
  @media screen and (max-width:768px) {
    input, textarea {font-size:16px !important}
  }
`;

const LeadsDetails = ({ match }) => {
  const { id } = match.params;
  const userdata = store.getState((state) => state);
  let authenticated = userdata.auth.loggedIn;
  let role = userdata.auth.user.role;
  let role_name = userdata.auth.user.role_name;
  const user_id = userdata.auth.user.id

  const [isLoading, setIsLoading] = useState(true);
  const [leadDetails, setLeadDetails] = useState([]);
  const [leadListDetails, setLeadListDetails] = useState([]);
  const [otherProperties, setOtherProperties] = useState([]);
  const [leadsStageVal, setLeadsStageVal] = useState('');
  const [viewings, setViewings] = useState([]);
  const [followups, setFollowups] = useState([]);
  const [offers, setOffers] = useState([]);
  const [matches, setMatches] = useState([]);
  const [activity, setActivity] = useState([]);
  const [addNote, setAddNote] = useState(false);

  const [noteLog, setNoteLog] = useState('');
  const [selectedNoteUsers, setSelectedNoteUsers] = useState([]);
  const [userClients, setUserClients] = useState('');

  const [recordId, setRecordId] = useState(null);
  const [noteLoader, setNoteLoader] = useState(false);
  const [allNotes, setAllNotes] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [isAddingImage, setIsAddingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [showAddImage, setShowAddImage] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false)

  const stages = [
    { value: 1, label: "New Inquiry" },
    { value: 2, label: "Working" },
    { value: 4, label: "Follow Up" },
    { value: 5, label: "Dead" },
    { value: 6, label: "Offer" },
    { value: 7, label: "Deal signed" },
    { value: 8, label: "Viewings" },
    { value: 9, label: "Recruitment" },
    { value: 12, label: "Pond" },
    { value: 13, label: "Offer accepted" },
    { value: 14, label: "Offer declined" },
    { value: 15, label: "whatsapp reply" },
    { value: 16, label: "Second Viewing" },
    { value: 17, label: "Not Interested" },
    { value: 18, label: "Considering" },
    { value: 19, label: "Whatsapp automation" },
    { value: 20, label: "Spam" },
    { value: 22, label: "3rd party" },
    { value: 25, label: "Verified" },
    { value: 26, label: "Verification call" },
    { value: 27, label: "Whatsapp Verified" },
    { value: 28, label: "Past Client" },
  ];

  const getStatusLabel = (leadStage) => {
    const stage = stages.find((s) => Number(s.value) === Number(leadStage));
    return stage ? stage.label : "Unknown Stage";
  };

  useEffect(() => {
    getLeadsDetail(id);
    getClientUsers()
  }, [id]);
  

  const getLeadsDetail = (id) => {
    
    if (!id) return;
  
    setIsLoading(true);
    API.get(`/lead_details/${id}`)
      .then((response) => {        // console.log(response.data)
        if (response.data && response.data.success) {
          setLeadDetails(response.data.lead_details);
          setLeadListDetails(response.data.lead_list_details);
          setLeadsStageVal(getStatusLabel(response.data.lead_details.lead_stage));
          setOtherProperties(response.data.owner_other_properties);
          const filteredViewings = response.data.all.filter(item => item.note_type === 'viewing');
          setViewings(filteredViewings);
          setOffers(response.data.property_offers);
          setAllNotes(response.data.all)
          setFollowups(response.data.followup_notes)
        }
      })
      .catch((error) => {
        console.error("Error fetching lead details:", error.response || error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getClientUsers = () => {
    API.get("/all_client_users").then((response) => {
        if (response.data.success) {
            const data = response.data.users;
            for (let i = 0; i < data.length; i++) {
              const num = data[i].id;
              data[i].key = num.toString();
            }
            setUserClients( data );
        }
    });
  }

  const StageActionItems = [
    {
      key: '1',
      label: <span>Viewings ({viewings.length})</span>,
      children: <Viewings viewings={viewings} />,
    }
    // , {
    //   key: '2',
    //   label:<span>Offers ({offers.length})</span>,
    //   children: <Offers offers={offers} />,
    // }, {
    //   key: '3',
    //   label: <span>Match ({matches.length})</span>,
    //   children: <Viewings matches={matches} />,
    // },
  ];

  // STYLES
  const activityside = { background: '#F6F7F8', marginBottom:'2rem' };
  const cardStyle = { padding: '0.5rem 0' };

  const StyledLink = styled(Button)`
    border:none; color:#151515; text-decoration:none; 
    & i.bi { border:1px solid rgba(21,21,21,0.16); border-radius:50%; height:25px; width:25px; display:flex; align-items:center; justify-content:center; font-size:10px} 

    @media screen and (max-width:768px) {
        margin:0 0 0 1rem; padding:0;
        & i.bi { border:none; border-radius:50%; height:25px; width:20px; font-size:21px; font-weight:900 } 
        span {font-size:14px}
    }
  `;

  const StyledButton = styled(Button)`
    border:1px solid rgba(21,21,21,0.06)!important;background:#FFF; color:#151515; width:fit-content; border-radius:5px; margin-top:0; height:36px;
    text-decoration:none;
    & span {font-size:12px;} 
    & :hover span {color:#25d366}
  `;

  const StyleLoader = styled.div`
    background:#FFF; height:76vh; width:100vw; display:flex; align-items:center; justify-content:center;
  `;

  const StyleStatusRow = styled(Row)`
    display:flex; flex-direction:column; padding:2px 15px; text-align:right; justify-content:flex-end;
    span {color:#FFF}
    &.new_inquiry { background: #580aff}
    &.working { background: #25d366 }
    &.follow_up { background: #f433ab }
    &.dead { background: #151515 }
    &.offer { background: #ffba08 }
    &.deal_signed { background: #25d366 }
    &.viewings { background: #19c0cc }
    &.recruitment { background: #415A77 }
    &.pond { background: #ff0000 }
    &.spam {background: rgb(153, 80, 136)}
    &.offer_accepted {background: #25d366}
    &.past_client {background: #BAA898}
    &.offer_declined {background: #414361}
  `;

const StyleActionButtons = styled(Row)`
  position:fixed; width:100%; height:70px; bottom:0; background:#fefefe; display:flex; justify-content:space-between; border-top:1px solid #ccc; align-items:center;padding:0 1.5rem; gap:0.3rem;
  button, a { width:100%; height:46px; border:1px solid rgba(21,21,21,0.16); display:flex; flex-direction:row; gap:0.3rem; color:#151515;opacity:1; text-decoration:none; margin:0; }
  .notewrap { background:#151515; gap: 0.3rem; padding: 0;}
  button i, button span { color:#FFF; font-size:12px}
  a i, a span {color:#151515; font-size:12px;}
`
const DrawerTitleStyle = styled.div`display: flex; width: 100%; justify-content: space-between; align-items: center;`;
const ButtonClose = styled(Button)` border:none; box-shadow:none;margin-top:0; font-size:1rem; padding-right:0`
const InfoStyle = styled.span` font-size:12px; font-style:italic; margin-top:2px; opacity:0.6 `
const TabStyle = styled(Tabs)` 
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {background: rgba(21, 21, 21, 0.06);border-radius: 7px;height: 100%;}
  .ant-tabs-tab .ant-tabs-tab-btn span {font-size:12px; gap:0.3rem; }
  .ant-tabs-content-holder { min-height:5rem }
  .ant-tabs-nav-list .ant-tabs-tab {margin:0 !important}
 `
const SubmitBtnStyle = styled(Button)` 
    background: #151515 !important; border: 1px solid #151515; padding: 0 1.5rem; margin: 0; box-shadow: none; width: 100%; height: 46px; opacity: 1; height:46px !important;
    span {font-size:14px !important; color:#FFF}
`
const DrawerStyle = styled(Drawer)`
  .ant-drawer-footer {display:flex; justify-content:flex-end; padding: 8px 24px;}
`
const UploadStyle = styled(Upload)` height:100%`
const DrawerAddNote = styled(Drawer)` .ant-drawer-content-wrapper {height:auto !important} `
const ButtonNoteStyle = styled(Button)` &.active {background:#151515; color:#FFF}` 


useEffect(() => {
    if (!addNote) {
        setImageUrl(null);
    }
}, [addNote]);

  if (isLoading) { return <StyleLoader><ScaleLoader /></StyleLoader>; }

  if (!leadDetails) { return <div>No lead details found.</div>; }

    const showDrawer = () => { setAddNote(true); };

    // Close drawer
    const onCloseNoteDrawer = () => { setAddNote(false); };

    // Handle note change (only updates noteLog, not the drawer)
    const onChangeNoteNote = (value) => {
        setNoteLog(value);
    };

    // Handle mentions (only updates selectedNoteUsers, not the drawer)
    const onSelectNoteMentions = (option) => {
        setSelectedNoteUsers(prev => Array.isArray(prev) ? [...prev, option] : [option]);
    };


    const getLeadName = (lead) => {
        const leadStageFilter = [
            { value: 1, label: "New Inquiry" },
            { value: 2, label: "Working" },
            { value: 4, label: "Follow Up" },
            { value: 5, label: "Dead" },
            { value: 6, label: "Offer" },
            { value: 7, label: "Deal signed" },
            { value: 8, label: "Viewings" },
            { value: 9, label: "Recruitment" },
            { value: 12, label: "Pond" },
            { value: 13, label: "Offer accepted" },
            { value: 14, label: "Offer declined" },
            { value: 15, label: "whatsapp reply" },
            { value: 16, label: "Second Viewing" },
            { value: 17, label: "Not Interested" },
            { value: 18, label: "Considering" },
            { value: 19, label: "Whatsapp automation" },
            { value: 20, label: "Spam" },
            { value: 22, label: "3rd party" },
            { value: 25, label: "Verified" },
            { value: 26, label: "Verification call" },
            { value: 27, label: "Whatsapp Verified" },
            { value: 28, label: "Past Client" },
          ];
      
          const leadStage = leadStageFilter.find((stage) => Number(stage.value) === Number(lead));
          return leadStage ? leadStage.label : "";
    };

    const handleLeadStageChange = (newStage) => {
        setLeadsStageVal(getStatusLabel(newStage));

        const data = { leads_id: id, lead_stage: newStage };
        API.post("/update_lead_stage", data)
            .then((response) => {
                if (response.data.success) {
                    toast.success("Lead Stage has been updated to " +  getLeadName(newStage))
                }
            })
            .catch((err) => console.log(err));
        
    };

    const handleNoteSubmit = () => {
        if (noteLog === "") { toast.warning("Note log cannot be empty");  return;}

        setNoteLoader(true);
        const data = {
        leads_id: id,
        note: noteLog,
        note_type: "note",
        users: selectedNoteUsers,
        };

        API.post("/add_lead_note", data)
        .then((response) => {
            if (response.data.success) {
            setAllNotes(response.data.all);
            setNoteLoader(false);
            setNoteLog("");
            handleLeadStageChange(2)
            Swal.fire({
                icon: "success",
                title: "Note Added Successfully",
                text: "Lead stage changed to Working. You can see your note on the Activity section.",
            });
            setAddNote(false);
            
            if (selectedNoteUsers.length > 0) {
                const emailInfo = {
                leads_id: id,
                users: selectedNoteUsers,
                note: noteLog,
                };

                API.post("/send_lead_note_email", emailInfo).then((res) => {
                if (res.data.success) {
                    setSelectedNoteUsers([]);
                    setNoteLog("");
                }
                });
            }
            } else {
            setNoteLoader(false);
            }
        })
        .catch((err) => {
            setNoteLoader(false);
        });
    };

    const handleShowAddImage = () => { setShowAddImage(true) }
    const handleHideAddImage = () => { setShowAddImage(false) }
    const saveAgentInParent = (agentData) => {
        setLeadDetails(prevDetails => ({
          ...prevDetails,
          agent: agentData,
        }));
      };
      
    const saveStageInParent = (updatedStage) => {
        setLeadDetails(prevDetails => ({
          ...prevDetails,
          lead_stage: updatedStage,
        }));
        setLeadsStageVal(getStatusLabel(updatedStage))
    }; 
    const updateAllNotes = (data) => { setAllNotes(data) }
    
    const uploadButton = (
        <div style={{height:'100%', display:'flex', alignItems:'center', justifyContent:'center', gap:'0.5rem'}}>
            <i className="bi bi-cloud-arrow-up" style={{fontSize:'2rem'}}></i>
            <span>Upload</span>
        </div>
    );

    const handleChange = (info) => {
        const { file } = info;
        if (file.status === 'done') {
        toast.success(`${file.name} file uploaded successfully.`);
        } else if (file.status === 'error') {
        toast.error(`${file.name} file upload failed.`);
        }
        setFileList(info.fileList);
    };

    const beforeUpload = (file) => {
        const isImage = file.type.startsWith('image/');
        const isValidSize = file.size / 1024 / 1024 < 2;
        if (!isImage) {
            toast.error('You can only upload image files!');
            return false;
        }
        if (!isValidSize) {
            toast.error('File must be smaller than 2MB!');
            return false;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result;
            setImageUrl(reader.result);
            const filelist = [{ base64: base64String, name: file.name, type: file.type }]
            setFileList(filelist)
        };

        return false;  // Prevent automatic upload
    };
    const draggerProps = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        beforeUpload: beforeUpload,
        onChange: handleChange,
        showUploadList: false,
        action: ''
    };

    const handleImageUpload = async () => {
        setNoteLoader(true);
        if (fileList.length === 0) {
            toast.error('Please select an image before submitting.');  // Show error if no file selected
            return;
        }

        const fileData = fileList[0];
      
        const data = {
          leads_id: id,
          document_file: fileData.base64,
          document_title: fileData.name,
        };

        console.log(data)

        API.post("/add_lead_documents", data)
            .then((response) => {
            if (response.data.success) {
                setAllNotes(response.data.all);
                const noteData = { leads_id: id, note: response.data.url,note_type: "image",  users: selectedNoteUsers,};
                API.post("/add_lead_note", noteData)
                .then((response) => {
                    if (response.data.success) {
                        setAllNotes(response.data.all);
                        setNoteLoader(false);
                        setNoteLog("");
                        handleLeadStageChange(2)
                        Swal.fire({ icon: "success",
                            title: "Image Added Successfully",
                            text: "Your image has been added to the Activity Section.",
                        });
                        setAddNote(false);
                    }
                })
            }
        })

       
    };

  return (
    <>
      <ToastContainer />
      <GlobalStyles />
      <Row gutter={16} style={{margin:0}}>
        <Col xs={24} md={8} p={3} className="border-r" style={cardStyle}>
          <Row className="w-100 border-b pb-3" style={{ display: 'flex', justifyContent: 'space-between', padding: '0', alignItems: 'center',  }}>
            <StyledLink type="link" href="/leads"> <i className="bi bi-chevron-left"></i>{!isMobile && 'Back to Leads'}</StyledLink>
            <StyleStatusRow direction='column' className={leadsStageVal.toLowerCase().replace(/\s+/g, '_')}>
              <Col>
                <span className="font-10">Status</span>
              </Col>
              <Col>
                <span className='font-bold'>{leadsStageVal}</span>
              </Col>
            </StyleStatusRow>
          </Row>
          <Row><Col span={24}><Suspense fallback={<Skeleton active />}><ClientDetails lead_details={leadDetails} /></Suspense></Col></Row>
          <Row><Col span={24}><Suspense fallback={<Skeleton active />}><Requirements lead_details={leadDetails} list_lead_details={leadListDetails} saveAgent={saveAgentInParent} saveStage={saveStageInParent} updateAllNotes={updateAllNotes} /></Suspense></Col></Row>
          {otherProperties.length > 0 && <Row><Col span={24}><Suspense fallback={<Skeleton active />}><Enquiry properties={otherProperties} /></Suspense></Col></Row> }
          <Row><Col span={24}><Suspense fallback={<Skeleton active />}> <Viewings viewings={viewings} lead_details={leadDetails} saveStage={saveStageInParent} updateAllNotes={updateAllNotes} /></Suspense></Col></Row>
          {/* <Row><Col span={24}><Suspense fallback={<Skeleton active />}> <FollowUps followups={followups} lead_details={leadDetails} saveStage={saveStageInParent} updateAllNotes={updateAllNotes} /></Suspense></Col></Row> */}
        </Col>
        <Col xs={24} md={16} style={activityside}><Suspense fallback={<Skeleton active />}><ActivityTimeline activity={allNotes} /></Suspense></Col>
      </Row>
      <StyleActionButtons>
        <Col span={5}><Button className='callwrap' type="link" href={`tel:${leadDetails.mobile} `}><i className="bi bi-telephone"></i>Call</Button></Col>
        <Col span={8}><Button className='whatsappwrap' type="link" href={`https://api.whatsapp.com/send/?phone=${leadDetails.mobile.replace(/\+/g, '')}`} target="_blank" rel="noopener noreferrer"><i className="bi bi-whatsapp"></i>WhatsApp</Button></Col>
        <Col span={8}><Button className='notewrap' onClick={showDrawer}><i className="bi bi-plus font-21"></i>Add Note</Button></Col>
      </StyleActionButtons>
 
      <Drawer height={500}
    title={
        <DrawerTitleStyle>
            <span className="font-bold font-14">
                <i className="bi bi-pencil-fill mr-1"></i>Add New Note
            </span>
            <ButtonClose onClick={onCloseNoteDrawer}><i className="bi bi-x"></i></ButtonClose>
        </DrawerTitleStyle>
    } 
    placement={'bottom'} 
    closable={false}
    close={onCloseNoteDrawer}
    open={addNote}
    footer={
         !showAddImage ?  <SubmitBtnStyle id="logsbtn" onClick={handleNoteSubmit} type="primary">{noteLoader ? <SyncLoader /> : 'Submit'}</SubmitBtnStyle>
         :  <SubmitBtnStyle id="logsbtn" onClick={handleImageUpload} type="primary">{noteLoader ? <SyncLoader /> : 'Upload Image'}</SubmitBtnStyle>
    }
>
    <Row >
        <Col span={24} style={{display:'flex', gap:'0.6rem', marginBottom:10}}>
            <ButtonNoteStyle className={`mt-0 ${!showAddImage ? 'active' : ''}`} onClick={handleHideAddImage}><span><i className="bi bi-textarea-t mr-1"></i>Text</span></ButtonNoteStyle>
            <ButtonNoteStyle className={`mt-0 ${showAddImage ? 'active' : ''}`} onClick={handleShowAddImage}><span><i className="bi bi-image mr-1"></i>Image</span></ButtonNoteStyle>
        </Col>
        <Col span={24} style={{ background:'#fefefe', border:'1px solid rgba(21,21,21,0.10)', boxShadow:'0 0 11px rgba(21,21,21,0.03)', borderRadius:5, padding:10, minHeight:'17.2rem' }}>

            { !showAddImage ? 
                <Mentions 
                    style={{ width: "100%", fontSize:16, border:'none', padding:0, height:'100%' }} 
                    value={noteLog} 
                    onChange={onChangeNoteNote} 
                    placeholder="Type @ to tag someone in this note..." 
                    onSelect={onSelectNoteMentions} 
                    options={userClients} 
                    rows={3} 
                />
            : <>
                <UploadStyle
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                >
                    {imageUrl ? (
                        <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                        uploadButton
                    )}
                </UploadStyle>
                
            </>}

        </Col>
        
    </Row>
</Drawer>

    </>
  );
};

const mapDispatchToProps = (dispatch) => {
    return {
      setNoMatch: (data) => dispatch({ type: "SET_Not_Match", payload: data }),
      removeLeadReducer: () => dispatch({ type: "REMOVE_LEAD_STATISTICS", payload: false }),
    };
  };
  
  export default connect(null, mapDispatchToProps)(LeadsDetails);
  
