import React, { Component } from 'react';
import { Col, Row, Typography, Card, Layout, Space, Button, Table, Tooltip, Drawer, Input } from 'antd';
import { ReadOutlined, EditOutlined, FilePdfOutlined, WhatsAppOutlined, EyeFilled, UserOutlined, InfoCircleOutlined, CalendarOutlined, SearchOutlined, AppstoreAddOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Moment from 'react-moment';
import API from '../../../utils/API';
import { ToastContainer, toast } from "react-toastify";
import { itemRender, onShowSizeChange } from '../../paginationfunction';

import EditBlog from './EditBlog';
import ManageCategory from './ManageCategory';

const { Title } = Typography;

class AllBlogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            categories: [],
            selectionType: 'checkbox',
            blogs: [],
            currentPage: 1,
            searchTerm: '',
            openEditDrawer: false,
            openAddCategoryDrawer: false,
            selectedEditId:'',
        };
    }

    componentDidMount() {
        this.fetchBlogs(this.state.currentPage); // Call fetchBlogs with the initial page
    }

    fetchBlogs = (page) => {
        this.setState({ loading: true });
        API.get(`/get_all_blogs?page=${page}`)
            .then((res) => {
                if (res.data.success) {     console.log(res.data.blogs)
                    this.setState({ blogs: res.data.blogs, loading: false });
                }
            })
            .catch((error) => {
                console.error("Error fetching blog data:", error);
                this.setState({ loading: false });
            });
    };
    columns = [
        { title: 'Image', width:150,
            render: (text, record) => (
                record.featured_image ? (
                    <div style={{ display: 'flex', justifyContent: 'center', width:'fit-content' }}>
                        <img src={record.featured_image.includes('uploads/blog/') ? 'https://www1.luxuryproperty.com/' + record.featured_image : 'https://www1.luxuryproperty.com/files/large/' + record.featured_image} alt="Blog" style={{ width: '217px', height: '130px', overflow: 'hidden', borderRadius:5, objectFit:'cover' }} />
                    </div>
                ) : (
                    <div className="noimageyet" style={{ textAlign: 'center' }}>
                        <img src={"../../images/noimageyet.png"} alt="No Image" className="defimg" width="80px" style={{ opacity: 0.6 }} />
                        <span className="font-12">Image pending</span>
                    </div>
                )
            ),
        }, { title: 'Title', width:800,
            render: (text, record) => (
                <Row style={titleStyleWrap}>
                    <Col span={24} style={{flex:'unset'}}>
                        <p style={topcatStyle} className='mb-2'>{record.category_name}</p>
                        <MainActionLinkBtn variant="link" onClick={(e) => this.handleOpenEditDrawer(e, record.id)} style={titleStyle} className='font-bold font-16 mb-0'>{record.title}</MainActionLinkBtn>
                        <p style={metaDescStyle}>{record.meta_description}</p>
                    </Col>
                    <Col span={24} className='gap-4 d-flex flex-row align-items-center' style={{flex:'unset'}}>
                        <p style={record.status=="live"? toplivestyle : topdraftstyle}>{record.status}</p>
                        <p style={metaDescStyle} ><UserOutlined className='mr-1' />{record.author_name}</p>
                        <p style={metaDescStyle} ><CalendarOutlined className='mr-1' /><Moment format={'DD MMMM YYYY | hh:mm'}>{record.published_date}</Moment></p>
                    </Col>
                </Row>
                
            )
        }, {title: 'Preview', width: 80, className: "actionwrap", style: {previewStyle} ,
            render: (text, record) => (
              <>
                <div className="info-group d-flex flex-column align-items-start justify-content-between " style={{height:'10rem'}}>
                  <p className='info-title px-4 pt-1'><i class="bi bi-three-dots"></i></p>
                  <Tooltip placement="topRight" title={"Edit this blog"}><ActionLinkBtn className='btn btn-text w-100 px-4 align-items-center d-flex font-14' onClick={(e) => this.handleOpenEditDrawer(e, record.id)}><EditOutlined className='mr-1' /><span>Edit blog</span></ActionLinkBtn></Tooltip>
                  <Tooltip placement="topRight" title={"Send to WhatsApp"}><ActionLinkBtn onClick={() => this.handleShareViaWhatsApp(record.title, record.slug)} className='btn w-100 btn-text px-4 d-flex align-items-center font-14'><WhatsAppOutlined className='mr-1' /><span>Share via WhatsApp</span></ActionLinkBtn></Tooltip>
                  <Tooltip placement="topRight" title={"Preview page"}><a href={"https://www.luxuryproperty.com/blog/" + record.slug} target="_blank" style={previewBtn} className='btn btn-text px-4 d-flex align-items-center font-14 w-100'><EyeFilled className='mr-1' /><span>Preview</span></a></Tooltip>
                </div>
              </>
            ),
        }
    ];
    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
    };

    handlePageChange = (page) => {
        this.setState({ currentPage: page });
        this.fetchBlogs(page); // Fetch blogs for the new page
        window.scrollTo({ top: 0, behavior: 'smooth' })
    };

    handleSearchChange = (event) => {
        const searchTerm = event.target.value;
        this.setState({ searchTerm }, () => {
            this.fetchBlogSearch(); // Fetch blogs based on the updated search term
        });
    };
    // Fetch blogs based on the search term only
    fetchBlogSearch = () => {
        this.setState({ loading: true });
        const { searchTerm } = this.state;
        const searchQuery = searchTerm ? `?search=${searchTerm}` : '';

        API.get(`/get_all_blogs${searchQuery}`)
            .then((res) => {
                if (res.data.success) {
                    this.setState({ blogs: res.data.blogs, loading: false });
                }
            })
            .catch((error) => {
                console.error("Error fetching blog data:", error);
                this.setState({ loading: false });
            });
    };
    
    handleOpenEditDrawer = (e, id) => {
        e.preventDefault(); // Prevent any default browser behavior
        this.setState({ openEditDrawer: true, selectedEditId: id });
    };
    
    handleCloseEditDrawer = () => { this.setState({ openEditDrawer: false,selectedEditId:'' }) }

    handleOpenAddCategoryDrawer = () => { this.setState({ openAddCategoryDrawer: true }) }
    handleCloseAddCategoryDrawer = () => { this.setState({ openAddCategoryDrawer: false }) }

    handleShareViaWhatsApp = (title, slug) => { 
        const message = `*${title}*%0Ahttps://www.luxuryproperty.com/blog/${slug}`; // %0A represents a newline
        window.open(`https://api.whatsapp.com/send/?text=${message}`, "_blank");
    }


    render() {
        return (<>
            <Card
                className="allblogswrap"
                title={
                    <Space className="d-flex flex-row justify-content-between align-items-center">
                        <Space className="d-flex align-items-center">
                            <ReadOutlined style={{ fontSize: 21, mr:1 }}/>
                            <Title style={{ fontSize: 21 }} className="font-bold mb-0">All Blogs ({Number(this.state?.blogs?.total).toLocaleString()})</Title>
                        </Space>
                        <Space className='gap-2'>
                            <StyledBtn onClick={() => window.location.href = "/addblog"} className="mt-0 addnew font-bold">Add new blog</StyledBtn>
                            <StyledBtn className="mt-0 addnew font-bold" onClick={this.handleOpenAddCategoryDrawer}>Manage Categories</StyledBtn>
                        </Space>
                    </Space>
                }
            >
                <Layout className="d-flex flex-column w-100 justify-content-between" style={{ background: 'transparent' }}>
                <ToastContainer />
                    <StyledTableWrapper>
                        <Row>
                            <Col span={12}>
                                <label className='font-bold'>Search:</label>
                                <StyledInput type="text" name="search" className="form-control" placeholder="Search title or url"  allowClear  onChange={this.handleSearchChange} prefix={<i class="bi bi-search"></i>}   />
                            </Col>
                        </Row>
                    </StyledTableWrapper>
                    <StyledTableWrapper>
                        <StyledTable columns={this.columns} 
                            dataSource={this.state.blogs?.data} showHeader={false} 
                            rowKey="id"
                            rowSelection={{ type: this.state.selectionType, ...this.rowSelection, }}
                            scroll={{ x: 'max-content' }}
                            tableLayout="fixed"
                            pagination={{
                                total: this.state?.blogs?.total || 0,
                                pageSize: this.state?.blogs?.per_page || 10,
                                current: this.state.currentPage,    
                                showTotal: (total, range) =>
                                  window.isTabView ? `` : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                showSizeChanger: true,
                                itemRender: itemRender,
                                onChange: this.handlePageChange,
                            }}
                        />
                    </StyledTableWrapper>
                </Layout>
            </Card>

            <Drawer onClose={this.handleCloseEditDrawer} open={this.state.openEditDrawer || false} width={'95vw'} closable={false}
            title={
                <Space className='headercard d-flex flex-row flex-m-column justify-content-between align-items-center'>
                    <Space className='d-flex align-items-center'><ReadOutlined style={{fontSize:16}} /><Title style={{fontSize:16}} className='font-bold mb-0'>Edit Post</Title></Space>
                    <Button onClick={this.handleCloseEditDrawer}><CloseCircleOutlined /></Button>
                </Space>}
            >
                {this.state.openEditDrawer && this.state.selectedEditId && ( <EditBlog id={this.state.selectedEditId} handleCloseEditDrawer={this.handleCloseEditDrawer} /> )}
            </Drawer>

            <Drawer onClose={this.handleCloseAddCategoryDrawer} open={this.state.openAddCategoryDrawer} width={'95vw'} closable={false}
                title={
                <Space className='headercard d-flex flex-row flex-m-column justify-content-between align-items-center'>
                    <Space className='d-flex align-items-center'><AppstoreAddOutlined style={{fontSize:16}} /><Title style={{fontSize:16}} className='font-bold mb-0'>Manage categories</Title></Space>
                    <Button onClick={this.handleCloseEditDrawer}><CloseCircleOutlined /></Button>
                </Space>}
            >
                {this.state.openAddCategoryDrawer && <ManageCategory />}
            </Drawer>
        </>);
    }
}

export default AllBlogs;

const addnewlink = { color:'#151515', textDecoration:'none', border:'1px solid rgba(21,21,21,0.16)', borderRadius:5, height:'36px', alignItems:'center', display:'flex', justifyContent:'center', padding:'5px 1rem',
    'hover': { background:'#151515', color:'#FFF' }

}
const topcatStyle = {textTransform:'uppercase', marginBottom:0}
const topdraftstyle = {textTransform:'uppercase', marginBottom:0, background:'#ccc', color:'#FFF', width:'fit-content', padding:'2px 10px', justifyContent:'center', borderRadius:5, height:26}
const toplivestyle = {textTransform:'uppercase', marginBottom:0, background:'#25d366', color:'#FFF', width:'fit-content', padding:'2px 10px', justifyContent:'center', borderRadius:5, height:26}
const titleStyleWrap = {display: 'flex', justifyContent: 'space-between', flexDirection:'column',height:'8rem', flexFlow:'column'  }
const titleStyle = {whiteSpace:'normal'}
const metaDescStyle = {whiteSpace:'normal', marginBottom:0, lineHeight:1, display:'block'}
const previewStyle = {borderLeft:'1px solid rgba(21,21,21,0.16)',}
const StyledTable = styled(Table)`
  width: 100%;
  .ant-table-content {border-radius: 8px; overflow: hidden;}
  .actionwrap { border-left: 1px solid rgba(21, 21, 21, 0.16) !important; border-right: 1px solid rgba(21, 21, 21, 0.16) !important;}
`;
const StyledTableWrapper = styled.div`
    width:100%;
  .ant-table-content table {border: none !important;border-spacing: 0 1.2rem !important;}
  .ant-table-tbody > tr > td {border-bottom: 1px solid rgba(21, 21, 21, 0.16) !important;border-top: 1px solid rgba(21, 21, 21, 0.16) !important;}
  .ant-table-tbody > tr > td:first-child {border-left: 1px solid rgba(21, 21, 21, 0.16) !important; border-top-left-radius:5px;border-bottom-left-radius:5px;}
  .ant-table-tbody > tr > td:last-child { border-top-right-radius:5px;border-bottom-right-radius:5px; padding:0 !important}
  .ant-table-cell > div { height: 100%;flex:1}
`;
const previewBtn = {borderTop:'1px solid rgba(21,21,21,0.16)', width:'100%', borderRadius:0, justifyContent:'flex-start', height:46,
    'hover': {background:'#151515 ', color:'#FFF'}
}
const StyledBtn = styled(Button)`
    '.ant-btn span':{ fontSize:14  },
    'hover': {background:'#151515'}
`;
const StyledInput = styled(Input)` height:46px; border:1px solid rgba(21,21,21,0.16); border-radius:5px; font-family:'Poppins-Regular'; position: relative; display:flex;
`;
const ActionLinkBtn = styled(Button)` height:46px; justify-content: flex-start; box-shadow:none;
    span{ font-size:14px !important }
    &:hover {opacity:0.7}
`;
const MainActionLinkBtn = styled(Button)` box-shadow:none; border:none; padding:0; 
    span {font-size:18px; font-family:'Poppins-Bold',sans-serif; text-align:left; }
    &:hover {opacity:0.7}
`;