import React, { Component, createRef } from 'react';
import { Typography, Row, Col, Button, Layout, Tabs } from 'antd';
import BrochurePdfOne from '../BrochureOne';
import BrochurePdfMulti from '../Brochure';
import styled from 'styled-components';
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { saveAs } from 'file-saver';
import { BarLoader } from 'react-spinners'
import ReactToPrint from 'react-to-print';

const { Title } = Typography;
const { Content } = Layout;
const { TabPane } = Tabs;

class BrochurePdf extends Component {   
  constructor(props) {
    super(props);
    this.state = {
        loading:false,
        multiloading:false,
        agentPicBase64:'',
        multiloading2:false,
        activeTabKey: '1',
    };
    this.singlePageRef = createRef();
    this.multiPageRef = createRef();
    this.multiPageRef2 = createRef();
    this.convertToBase64 = this.convertToBase64.bind(this);
  }

  componentDidMount() {
    this.updateAgentPic();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.listing_agent_picture !== this.props.data.listing_agent_picture) {
      this.updateAgentPic();
    }
  }

  updateAgentPic() {
    const listing_agent_picture = this.props.data.listing_agent_picture;
    if (listing_agent_picture) {
      this.convertToBase64(listing_agent_picture);
    }
  }

  convertToBase64(url) {
    this.setState({ agentPicBase64: '' }); // Clear existing Base64 value
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch image');
        }
        return response.blob();
      })
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.setState({ agentPicBase64: reader.result });
        };
        reader.onerror = (error) => {
          console.error('Error reading blob as Base64:', error);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        console.error('Error converting image to Base64:', error);
      });
  }


  getData = () => {
    const { data } = this.props;
    return data ? data : "No data available";
  };

  getHtmlContent = (ref) => {
    return ref.current ? ref.current.innerHTML : '';
  };

  downloadPDF = () => {
    // Set loading state to true while generating the PDF
    this.setState({ multiloading: true });

    const doc = new jsPDF('landscape', 'mm', 'a4');
    const fileName = `${this.props.data.refno || 'document'}.pdf`;

    // Capture the HTML content from the reference
    const htmlContent = this.multiPageRef.current;

    // Add the content with proper scaling and save the PDF
    doc.html(htmlContent, {
        callback: (pdf) => {
            pdf.save(fileName); // Save the PDF
            this.setState({ multiloading: false });
        },
        x: 10, // Start position x
        y: 10, // Start position y
        html2canvas: {
            scale: 0.25, // Adjust the scaling factor for better clarity
        },
    });
};

generatePdf = async () => {
    this.setState({ activeTabKey: '2' });
    const element = this.multiPageRef.current;
    if (!element) return;

    try {
        this.setState({ multiloading: true });

        // Wait for all images to load
        const images = element.querySelectorAll('img');
        const imagePromises = Array.from(images).map(
            (img) =>
                new Promise((resolve, reject) => {
                    if (img.complete) {
                        resolve();
                    } else {
                        img.onload = resolve;
                        img.onerror = reject;
                    }
                })
        );
        await Promise.all(imagePromises);

        // Apply inline styles directly to the original element
        const applyInlineStyles = (el) => {
            const elements = el.querySelectorAll('*');
            Array.from(elements).forEach((node) => {
                const computedStyle = window.getComputedStyle(node);
                let inlineStyle = '';
                for (let i = 0; i < computedStyle.length; i++) {
                    const property = computedStyle[i];
                    const value = computedStyle.getPropertyValue(property);
                    inlineStyle += `${property}: ${value};`;
                }
                node.setAttribute('style', inlineStyle);
            });
        };

        applyInlineStyles(element);

        // Render HTML to Canvas
        const isMobile = window.innerWidth <= 768;
        const scale = isMobile ? 2 : 3; // Adjust scale for smaller file size
        const canvas = await html2canvas(element, {
            scale,
            useCORS: true,
        });

        const imageData = canvas.toDataURL("image/jpeg", 0.7); // Compress image

        // Set up jsPDF
        const pdf = new jsPDF("l", "pt", "a4"); // Portrait mode
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        let imgWidth = pageWidth;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;

        // If content height exceeds one page, split it into multiple pages
        let currentHeight = 0;
        while (currentHeight < canvas.height) {
            const canvasPage = document.createElement("canvas");
            canvasPage.width = canvas.width;
            canvasPage.height = Math.min(canvas.height - currentHeight, pageHeight * (canvas.width / pageWidth));

            const ctx = canvasPage.getContext("2d");
            ctx.drawImage(
                canvas,
                0,
                currentHeight,
                canvas.width,
                canvasPage.height,
                0,
                0,
                canvas.width,
                canvasPage.height
            );

            const pageData = canvasPage.toDataURL("image/jpeg", 0.7); // Compress page image

            if (currentHeight > 0) {
                pdf.addPage();
            }

            pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, (canvasPage.height * imgWidth) / canvasPage.width);
            currentHeight += canvasPage.height;
        }

        const fileName = `${this.props.data.refno || 'document'}.pdf`;
        pdf.save(fileName);

        this.setState({ multiloading: false });
    } catch (error) {
        console.error("Error generating PDF:", error);
    }
};


  handleDownloadJPG = () => {
    if (this.singlePageRef.current === null) {
      return;
    }
    this.setState({ activeTabKey: '1' });
    this.setState({ loading: true });
    
    const a4Width = 2480; // A4 width in pixels at 300 DPI
    const a4Height = 3508; // A4 height in pixels at 300 DPI
  
    html2canvas(this.singlePageRef.current, { scale: 5, useCORS: true,font: 'Poppins, Cormorant Garamond, sans-serif' })
      .then((canvas) => {
        // Resize the canvas to A4 dimensions
        const resizedCanvas = document.createElement('canvas');
        resizedCanvas.width = a4Width;
        resizedCanvas.height = a4Height;
  
        const context = resizedCanvas.getContext('2d');
        context.fillStyle = '#ffffff'; // Set background color to white
        context.fillRect(0, 0, a4Width, a4Height);
        context.drawImage(canvas, 0, 0, a4Width, a4Height);
  
        resizedCanvas.toBlob((blob) => {
          const fileName = `${this.props.data.refno}.jpg`;
          saveAs(blob, fileName);
          this.setState({ loading: false });
        });
      })
      .catch((err) => {
        console.error('Failed to capture HTML as image:', err);
        this.setState({ loading: false });
      });
  };

  handleBeforePrint = () => { this.setState({ multiloading2: true }); };
  handleAfterPrint = () => { this.setState({ multiloading2: false }); };
  
  render() {
    const { pageNumber, numPages } = this.state;
    const data = this.getData();
    
    return (
      <BrochureContainer>
      <Row className="w-100" offset={16}>
        <Col span={19} className="previewTab">
          <Row>
            <Col style={colStyle}>
                <span className="font-bold" style={previewBtn}>Preview</span>
                <StyledTabs type="card" tabPosition="left" style={tabsListStyle} activeKey={this.state.activeTabKey} onChange={(key) => this.setState({ activeTabKey: key })}>
                    <TabPane style={tabbrochureStyle} tab={<span>One Page</span>} key="1">
                        <Content style={brochureStyle} ref={this.singlePageRef}><BrochurePdfOne data={data} picBase={this.state.agentPicBase64} /></Content>
                    </TabPane>
                    <TabPane style={tabbrochureStyle2} tab={<span>Multi Page</span>} key="2">
                        <Content ref={this.multiPageRef} style={brochureStyleMulti}><BrochurePdfMulti data={data}  /></Content>
                    </TabPane>
                    {/* <TabPane tab={<span style={tabbrochureStyleHide}>Multi Page</span>} key="3" style={{ display: 'none'}} hidden={true} >
                        <div ref={(response) => (this.multiPageRef2 = response)}>
                            <BrochurePdfMulti data={data} />
                        </div>
                    </TabPane> */}
                </StyledTabs>
            </Col>
          </Row>
        </Col>
        <Col md={5} xs={24} className="border-l d-flex flex-column px-3 pt-3">
            <Row className='border-b previewAction'>
                <Col span={24} className='font-bold'>Download</Col>
                <Col span={24}>
                    <StyledButton onClick={this.handleDownloadJPG}><i className="bi bi-filetype-jpg mr-1"></i>One Page JPG</StyledButton>
                    {this.state.loading? <BarLoader className='w-100'/> : ''}
                </Col>
                <Col span={24}><StyledButton onClick={this.generatePdf}><i className="bi bi-file-earmark-arrow-down mr-1"></i>Multi-Page PDF</StyledButton>
                {this.state.multiloading? <BarLoader className='w-100'/> : ''}</Col>
                {/* <ReactToPrint content={() => this.multiPageRef2.current} 
                    trigger={() => ( <StyledButton disabled={this.state.multiloading2}> <i className="bi bi-printer mr-1"></i> Print to PDF (Multi-Page) </StyledButton> )}
                    onBeforePrint={this.handleBeforePrint}
                    onAfterPrint={this.handleAfterPrint}
                    />
                    {this.state.multiloading2 && <BarLoader className="w-100" />} */}
            </Row>
            {/*<Row>
                <Col span={24} className='font-bold mt-3'>Share</Col>
                <Col span={24}><StyledButton><i className="bi bi-envelope-at mr-1"></i>via Email</StyledButton></Col>
                 <Col span={24}><StyledButton><i className="bi bi-whatsapp mr-1"></i>thru WhatsApp</StyledButton></Col> 
            </Row>*/}
        </Col>
      </Row>
      </BrochureContainer>
    );
  }
}

export default BrochurePdf;

const colStyle = {height:'41rem', overflow:'hidden', }
const tabbrochureStyle = { background: '#FFF', boxShadow: '0 0 7px rgba(21, 21, 21, 0.16)'}
const tabbrochureStyle2 = { background:' rgb(255, 255, 255)', boxShadow: 'rgba(21, 21, 21, 0.16) 0px 0px 7px',  position: 'relative',  width: '59vw', height: '100%', overflowY: 'scroll' }
const tabbrochureStyleHide = {display:'none !important'}

const tabsListStyle = {
    '& .ant-tabs-nav': { width: '12rem', gap:'1.5rem'}
}
const brochureStyle = {
    transform: 'scale(0.55) translate(-10%, -38%)', boxShadow: 'rgba(21, 21, 21, 0.6) 0 0 6px', width: '210mm', display: 'block', overflow: 'hidden', height: '297mm'    
};
const brochureStyleMulti = {transform: 'scale(0.65) translate(-26.5%, -26.5%)', top: 0, position: 'absolute'};

const StyledTabs = styled(Tabs)`
    .ant-tabs-nav {width: 12rem; margin:3rem 0 0 2rem; z-index:99 },
    .ant-tabs-nav .ant-tabs-tab {padding:1rem 2rem !important; background:#ECEDF2; border-radius:8px !important; border:1px solid #ECEDF2; width:92% }
    .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {background:#FFF; border-radius:5px 0 0 5px !important; border:1px solid #ECEDF2; border-left:6px inset #25d366; border-right:1px solid #FFF; width:100%; boxShadow: 0 0 7px rgba(21, 21, 21, 0.16)}
    .ant-tabs .ant-tabs-nav-wrap .ant-tabs-tab span {font-size:14px}
    .ant-tabs-content-holder {border-left:0; display: flex; height: 80vh; overflow-y:scroll; border-left:1px solid rgba(21,21,21,0.06)}
    #rc-tabs-0-panel-2 {height:270rem; margin-bottom:1rem}
    .ant-tabs-nav-list div[data-node-key="3"] { display:none !important }
`;

const StyledButton = styled(Button)`
    border:none; width:100%; height:46px; margin:0; box-shadow:none; padding:0; justify-content:left;
    &:hover { background-color: #f0f0f0;}
    @media screen and (max-width: 768px) {
        border: 1px solid rgba(21, 21, 21, 0.16); height: 46px; margin-bottom: 1rem; width: 100%; justify-content: center; display: flex; overflow: hidden; margin-top: 1rem;
        span {font-size:14px}
    }

`
const previewBtn = { position:'absolute', left:'2rem', top:'2px', fontSize:16, letterSpacing:2, textTransform:'uppercase'}

const BrochureContainer = styled.div`
  width: 100%;

  @media screen and (max-width: 768px) {
    .previewTab {transform: translateY(-999%);
    position: absolute;}
    
  }
`;