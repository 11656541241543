import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  Table,
  Modal,
} from "antd";
import { ConsoleSqlOutlined, SaveOutlined } from "@ant-design/icons";
import API from "../../utils/API";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditOutlined } from "@ant-design/icons";
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction";
import Swal from "sweetalert2";

class ListSubCommunity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sub_community_name: "",
      add_community_modal: false,
      edit_sub_community_id: "",
      propertytypes: [],
      property_type_details: "",
      filter_community_id: "",
      communities: [],
    };
  }

  componentDidMount() {
    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage);
    this.fetchCommunity();

  }

  fetchData = (page, perPage) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      API.get(`/subcommunity?page=${page}&perPage=${perPage}`).then(
        async (response) => {
          var data = await response.data.data;
          await response.data.data;
          this.setState({
            communitysubcommunity: response.data.data,
            isLoading: false,
          });
        }
      );
    }, 300);
  };


  fetchCommunity = () => {
    API.get("/community").then((response) => {
      if (response.data.success){
        this.setState({ communities: response.data.communities });
      }
    });
  }


  handleCommunityFilter = (e) => {
    if(e != undefined){
        this.setState({ filter_community_id: e });
    }else{
      alert('please select atleast commmunity');
    }

  }



  OpenAddCommunityModal = () => {
    this.setState({
        sub_community_name: "",
        add_community_modal: true,
        filter_community_id: "",
    });
  };

  handleOKModal = () => {
    this.setState({ add_community_modal: false });
  };
  handleCancel = () => {
    this.setState({ add_community_modal: false });
  };

  HandelCommunityName = (e) => {
    this.setState({ sub_community_name: e.target.value });
  };


   
  SaveSubCommunity = () => {
    const { sub_community_name, edit_sub_community_id , filter_community_id } = this.state;
    const data = {
      sub_community_name  : sub_community_name,
      filter_community_id : filter_community_id,
    };

    if (edit_sub_community_id) {
      API.put(`/update_sub_community/${edit_sub_community_id}`, data).then(
        (response) => {
          if (response.data.status === "success") {
            toast.success("SubCommunity Updated Successfully");
            this.setState({
              sub_community_name: "",
              edit_sub_community_id: "",
              add_community_modal: false,
            });
            this.fetchData(1, 10);
          }
        }
      );
    } else {
      API.post("/add_sub_community", data).then((response) => {
        if (response.data.status === "success") {
          this.setState({ sub_community_name: "" });
          toast.success("SubComminity Add SuccessFully");
          this.setState({ add_community_modal: false });
          const page = 1;
          const perPage = 10;
          this.fetchData(page, perPage);
        }
      });
    }
  };

  handleDelete = (id, e) => {
    const page = 1;
    const perPage = 10;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete("/delete_sub_community/" + id).then((response) => {
          if (response.data.status === "success") {
            toast.success("Successfully deleted");
            this.fetchData(page, perPage);
          }
        });
      }
    });
  };

  handleEdit = (id, e) => {
    const page = 1;
    const perPage = 10;
    API.get("/editSubCommunity/" + id).then((response) => {

    console.log(response);
    if (response.data.status === "success") {

      const community_id = response.data.subcommunities.getcommunity?.id;
      const sub_community_name = response.data.subcommunities.title;

      this.setState({
        filter_community_id: community_id,
        sub_community_name: sub_community_name,
        add_community_modal: true, // Open the modal for editing
        edit_sub_community_id: id,
      }, () => {
            const data = {
              edit_sub_community_id: this.state.id,
              sub_community_name: this.state.sub_community_name,
            };
          });
      }      

    });
  };

  // Render Html & Functions
  render() {
    
    const columns = [
      {
        title: "Community", 
        dataIndex: "getcommunity",
        key: "title",
        sorter: (a, b) => a.getcommunity?.title?.localeCompare(b.getcommunity?.title || ""),
        render: (getcommunity) => (getcommunity && getcommunity.title ? getcommunity.title : "No Community"),
      },
      {
        title: "Sub-Community",
        dataIndex: "title", 
        key: "title",
        sorter: (a, b) => a.label.localeCompare(b.label),
      },
      {
        title: "Action",
        render: (text, record) => (
          <div className="ms-auto">
            <a
              href="#"
              className="btn btn-success btn-sm m-2"
              onClick={this.handleEdit.bind(this, record.id)}
              style={{ color: "white" }}
            >
              {" "}
              <i className="fa fa-edit"></i>{" "}
            </a>
            <a
              className="btn btn-danger btn-sm m-2"
              onClick={this.handleDelete.bind(this, record.id)}
              style={{ color: "white" }}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </a>
          </div>
        ),
      },
    ];
    const handleTableChange = (pagination) => {
      this.fetchData(pagination.current, pagination.pageSize);
    };
console.log(this.state.communities)
    return (
      <div>
        <ToastContainer />
         <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              {" "}
              <Link to="/dashboard">Dashboard</Link>{" "}
            </Breadcrumb.Item>
             <Breadcrumb.Item>Locations</Breadcrumb.Item>
             <Breadcrumb.Item>Communities</Breadcrumb.Item>
             <Breadcrumb.Item>Sub communities List</Breadcrumb.Item>
           </Breadcrumb>
          <Button type="primary" shape="round" size="large" onClick={this.OpenAddCommunityModal}>
            {" "}
            <PlusCircleOutlined />
            Add Sub Community
          </Button>
        </div>
        <Modal
          width={600}
          title="Offers"
          open={this.state.add_community_modal}
          onOk={this.handleOKModal}
          onCancel={this.handleCancel}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="form-group mt-2">
                  <label className="control-labels">Communities</label>
                  <div className="form-group w-100 px-0 mb-0">
                    <Select
                      allowClear
                      showSearch
                      style={{ width: "100%" }}
                      value={this.state.filter_community_id}
                      placeholder="Search"
                      onChange={this.handleCommunityFilter}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={this.state.communities.map((community) => ({
                        label: community.label, // Assuming 'title' is the display name
                        value: community.value, // Assuming 'id' is the unique identifier
                      }))}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-group mt-2">
                  <input
                    type="text"
                    name="subcommunity"
                    placeholder="Sub-Community"
                    className="form-control input-sm"
                    id="subcommunity"
                    onChange={this.HandelCommunityName}
                    value={this.state.sub_community_name}
                    disabled={!this.state.filter_community_id}
                    style={{ cursor: !this.state.filter_community_id ? "not-allowed" : "auto" }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <button
                  onClick={this.SaveSubCommunity}
                  className="btn btn-success"
                  type="submit"
                  href="javascript:void(0)"
                  data-href
                >
                  {" "}
                  <i className="bi bi-check" />{" "}
                </button>
              </div>
            </div>
          </div>
        </Modal>
        ;
        <div style={{ padding: 24, minHeight: 380, background: "#fff" }}>
          <div class="table-responsive">
            <Table
              onChange={handleTableChange}
              loading={this.state.isLoading}
              className="table-striped"
              pagination={{
                pageSizeOptions: ["5", "10", "20", "50", "100"],

                total: this.state.communities
                  ? this.state.communities.length
                  : 0,
                // total: this.state?.title?.total > 0 && this.state.title.total,
                // pageSize : this.state.communities.per_page,
                // current  : this.state.communities.current_page,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                onShowSizeChange: onShowSizeChange,
                itemRender: itemRender,
              }}
              style={{ overflowX: "auto" }}
              columns={columns}
              // bordered
              dataSource={
                this.state.communitysubcommunity
                  ? this.state.communitysubcommunity
                  : []
              }
              rowKey={(value) => value}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ListSubCommunity;
