import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "antd";
import API from "../../utils/API";
import { useDispatch } from 'react-redux';

const GoogleLoginButton = () => {
  const [showError, setShowError] = useState(false);
    const dispatch = useDispatch();
  const login = useGoogleLogin({
    redirectUri: 'https://crm.luxuryproperty.com',
    accessType: 'offline',
    isSignedIn: true,
    scope: 'https://mail.google.com/ https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/calendar openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
    responseType: 'code',
    prompt: 'consent',
    flow: 'auth-code',
    onSuccess: (tokenResponse) => {
        const data = { 
            oauth_response: tokenResponse
        };
        API.post("/oauth_login", data).then((response) => {
          if (response.data.success) {
            dispatch({ type: "SET_LOGIN", payload: response.data }); 
          }else{
            setShowError(true);
          }
        }).catch((error) => {
          setShowError(true)
        });
    },
  });

  return (
    <div className="container-login100-form-btn">
         <Button type="primary" onClick={login} style={{height: "45px", borderRadius: "0.3rem", width: "100%", display: "flex", justifyContent: "center", color:'#151515'}}>
            <img src="images/GoogleLogo.svg" height="20px" width="20px" style={{margin:'0 5px 0 0'}} />
            Login with Google</Button>
         {showError && <p style={{fontSize: "14px", color: "#ff0000", marginTop:'5px'}}>This email does not exist, please choose another email</p>}
    </div>
  );
};

export default GoogleLoginButton;