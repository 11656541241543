
export const baseurl ="https://dev_backend.luxuryproperty.com/api"
// export const baseurl ="https://opportunity.luxuryproperty.com"

// export const baseurl ="http://127.0.0.1:8000/api"


export const liveUrl ="https://crm.luxuryproperty.com"
export const oldBaseurl ="https://crm.luxuryproperty.com"
export const baseurlImg  ="https://www1.luxuryproperty.com/"
export const oldBaseurlImg  ="https://crm.luxuryproperty.com"

//"https://api.luxuryproperty.com/api"
//http://localhost:8000/api
// https://devapi.luxuryproperty.com/api
// export const baseurlImg  ="https://api.luxuryproperty.com/public"

export const baseLpUrlImg       = "https://www1.luxuryproperty.com/lpcrm/public/uploads/clients/2036/users"
export const oauth_client_key   = "434882917002-7n09ftsvg8sgojlk1uo48k70t8kpi7bd.apps.googleusercontent.com"
export const oauth_redirect_url = "https://crm.luxuryproperty.com"
         

