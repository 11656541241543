import React, { Component, createRef } from 'react';
import { Col, Row, Card, Layout, Space, Select, Typography, Button, DatePicker, TimePicker, Upload, Input, message, Tabs, Drawer } from 'antd';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppstoreAddOutlined, LoadingOutlined, PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import BlogBuilder from '../../../components/cms/blog/BlogBuilder';
import { Content } from 'antd/es/layout/layout';
import API from '../../../utils/API';
import dayjs from 'dayjs';
import { store } from '../../../store';
import ManageCategory from './ManageCategory'

const { Title } = Typography;
const {Sider } = Layout;
const { TextArea } = Input;
const { TabPane } = Tabs;

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};
const imageUrl='https://www1.luxuryproperty.com/files/large/';
const userdata = store.getState((state) => state);
class EditBlog extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
          loading: false, blog_data:[], status:'',
          title: '',
          slug: '',
          blogContent: [],
          featuredimage:'', featuredimage_temp:'', featuredimage_change:false,
          categories:[],
          currentPage:1, openAddCategoryDrawer: false,
          selectedDate: dayjs(),
          authors:[],
          category:'', author: '',
          author_linkedin: '',
          author_description:'',
          added_date: null,
          seo_title: '',
          seo_description: '',seo_keywords:'', missingKeys: []
        };
        this.editorRef = createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        const { id } = this.props;
        this.fetchBlogData(id);
        this.fetchBlogCategories();
        this.fetchAuthors();        
    }

    fetchBlogData = (id) => {
        if (id) {
            this.setState({ loading: true }); // Start loading
    
            API.get(`/blog/${id}`)
                .then((response) => {
                    const isSuccess = response.status === 200 && response.data && response.data.title && response.data.html;
                    if (isSuccess) {
                        this.setState({blog_data:response.data, blogContent:response.data.html,
                            title: response.data.title,
                            slug:response.data.slug,
                            category:response.data.category,
                            author:response.data.author,
                            added_date: response.data.added_date,
                            featuredimage: response.data.featured_image,
                            seo_title: response.data.seo_title,
                            seo_description: response.data.seo_description,
                            status: response.data.status,
                            author_description: response.data.author_description,
                            author_linkedin: response.data.author_linkedin
                         });
                    } else {
                        console.error("Failed to fetch blog data:", response.data.message);
                        this.setState({ loading: false }); // End loading on failure
                    }
                })
                .catch((error) => {
                    console.error("Error fetching blog data:", error);
                    this.setState({ loading: false }); // End loading on error
                });
        }
    };

    fetchBlogCategories = (page = 1, allCategories = []) => {
        this.setState({ loading: true });
        API.get(`/get_all_categories?page=${page}`)
        .then((res) => {
            if (res.data.success) {
                // Extract current page categories
                const currentCategories = res.data.blogs.data.map((category) => ({
                    label: category.title, // Adjust based on your data structure
                    value: category.slug,   // Adjust based on your data structure
                }));

                // Combine with previously fetched categories
                const combinedCategories = [...allCategories, ...currentCategories];

                // Check if there are more pages
                if (page < res.data.blogs.last_page) {
                    // Fetch next page
                    this.fetchBlogCategories(page + 1, combinedCategories);
                } else {
                    // All pages fetched, update state
                    this.setState({
                        categories: combinedCategories,
                        loading: false,
                    });
                }
            }
        })
        .catch((error) => {
            console.error("Error fetching blog categories data:", error);
            this.setState({ loading: false });
        });
    };

    fetchAuthors = async () => {
        let allUsers = [];
        let hasMore = true;
        let nextPageUrl = `/all_users`;
    
        try {
            while (hasMore) {
                // Fetch the current page of users
                const response = await API.post(nextPageUrl);
    
                // Extract users and pagination details from the response
                const users = response.data.users || [];
                const nextPage = response.data.next_page_url;
    
                const filteredUsers = users;
                filteredUsers.sort((a, b) => {
                    if (a.display_name < b.display_name) {
                        return -1;
                    }
                    if (a.display_name > b.display_name) {
                        return 1;
                    }
                    return 0;
                });
                allUsers = [...allUsers, ...filteredUsers];
                
                // Check if there's another page to fetch
                if (nextPage) {
                    nextPageUrl = nextPage;  // Update the URL to fetch the next page
                } else {
                    hasMore = false;  // Stop the loop when no more pages
                }
            }
    
            // Update the state with the fetched authors
            this.setState({ authors: allUsers });
        } catch (error) {
            console.error('Error fetching authors:', error);
        }
    }
    processHtmlToArray = (content) => {
        const html = [];
        const div = document.createElement('div');
        div.innerHTML = content;
    
        // Loop through child nodes and classify each as text or image
        Array.from(div.childNodes).forEach(node => {
            if (node.nodeName === 'IMG') {
                // If it's an image, push it as an object
                html.push({ type: "img1",
                    value: {
                        image1: {
                        src: node.src,
                        alt: node.alt || '',
                        is_image: true,
                        client_name: node.src.split('/').pop(),
                        file_ext: ".jpg",
                        file_name: node.src.split('/').pop().split('.').shift(),
                        file_path: "/var/www/html/uploads/blog/",
                        file_type: "image/jpeg",
                        full_path: node.src,
                        image_type: "jpeg",
                        orig_name: node.src.split('/').pop(),
                        raw_name: node.src.split('/').pop(),
                        image_height:1080,
                        image_width:1920,
                        image_size_str:"width=\"1920\" height=\"1080\""
                    }}
                });
            } else  {
                // If it's a paragraph or heading, push it as a text block
                html.push({ type: 'text' , value: node.outerHTML });
            }
        });
        return html;
    };
   
    handleFeaturedImage = ({ file, fileList }) => {
        if (file.status === 'done' || file.status === 'uploading') {
            const fileObj = fileList[0].originFileObj;
            const previewUrl = URL.createObjectURL(fileObj);
            this.setState({ featuredimage: fileObj, featuredimage_change: true, featuredimage_temp: previewUrl});
        }
    };
    handleRemove = () => {
        
    };
    handleCategoryChange = (e) => {
        this.setState({ category: e });
    }

    handletitleUpdateInput = (e) => {
        const value = e.target.value.toLowerCase();
        const slug = value.replace(/[^a-z0-9\s]/g, '').replace(/\s+/g, '-');
        this.setState({ slug:slug, title:e.target.value, seo_title:e.target.value  });
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;    
        this.setState({ [name]: value,
        });
    };
    
    
    handleSeoTitleInputChange = (e) => {
        const newSeoTitle = e.target.value || this.state.blog_data.seo_title;
        
        // Only update if the new value is different to prevent re-rendering
        if (newSeoTitle !== this.state.seo_title) {
            this.setState({ seo_title: newSeoTitle});
        }
    };
    
    handleSeoDescInputChange = (e) => {
        const newSeoDesc = e.target.value || this.state.blog_data.seo_description;
        
        // Only update if the new value is different to prevent re-rendering
        if (newSeoDesc !== this.state.seo_description) {
            this.setState({ seo_description: newSeoDesc });
        }
    };
    
    handleSeoKeywordsInputChange = (e) => {
        const newKeywords = e.target.value || this.state.blog_data.keywords;
        
        // Only update if the new value is different to prevent re-rendering
        if (newKeywords !== this.state.seo_keywords) {
            this.setState({ seo_keywords: newKeywords });
        }
    };
    
    handleCategoryChange = (e) => {
        this.setState({ category: e });
    }
    handleDateChange = (date, dateString) => {
        if (date) {
            this.setState({ 
                added_date: date,
                selectedDate: date
            });
        } else {
            console.log("Invalid date selected");
            this.setState({
                added_date: null,
                selectedDate: null
            });
        }
    }

    handleAuthorChange = (e) => {       
        this.setState({ author: e})
    }

    handleSave = (html, css) => { this.setState({ blogContent: { html, css } });};

    // Function to convert the array data into an HTML string
    generateHTMLFromData = (dataArray) => {
        let htmlContent = '';
        let cssContent = ''; // Initialize CSS content
    
        // Check if dataArray is an array before calling forEach
        if (Array.isArray(dataArray)) {
            dataArray.forEach((item) => {
                if (item.text) {
                    htmlContent += item.text; // Append text content
                }
                if (item.img1 && item.img1.image1) {
                    const image = item.img1.image1;
                    const imgsrc = image.src && image.src.includes("https") ? image.src : "https://www1.luxuryproperty.com/uploads/blog/" + image.file_name
                    htmlContent += `<img 
                        src="${imgsrc}" 
                        alt="${image.raw_name || ''}" 
                        width="100%" 
                        height="${image.image_height || 'auto'}" 
                        style="object-fit: cover;" 
                    />`;
                }
            });
        } else {
            console.error('Expected an array for dataArray, but got:', dataArray);
        }
    
        return {
            html: htmlContent,
            css: cssContent
        };
    };
    
    handleFeaturedImageUpload = (info) => {
        const data = new FormData();
        data.append("image", this.state.featuredimage); // Assuming the file is accessible here

        return API.post("/upload_image", data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
            if (response.data.success) {
                toast.success(response.data.message);
                this.setState({ featuredimage: response.data.image_url }); // Update state with the image URL
                return response.data.image_url; // Return the image URL
            } else {
                toast.warning("Error uploading the image");
                throw new Error("Image upload failed");
            }
        })
        .catch((error) => {
            toast.error("Error uploading the image");
            console.error("Error uploading the image:", error);
            throw error; // Throw error to stop further processing in handleSubmit
        });
    };

    handleSubmit = async () => {
        const { id } = this.props;
        if (!this.editorRef || !this.editorRef.current) return;

        
    
        const editor = this.editorRef.current.editor;
        const htmlcontent = editor.getHtml().replace(/<\/?body[^>]*>/g, '');
        const css = editor.getCss();
        const categoryname = this.state.category.replace(/(^\w|-\w)/g, (match) => match.replace('-', ' ').toUpperCase());
        const foundAuthor = this.state.authors.find((author) => author.id === this.state.author);    
        const displayName = foundAuthor?.display_name || 'Jason Hayes';
        const author_id = foundAuthor?.id || 34;
        const assets = editor.AssetManager.getAll().map(asset => asset.get('src'));
        const widgets = this.processHtmlToArray(htmlcontent);

        const requiredFields = {
            title: this.state.title,
            slug: this.state.slug,
            category: this.state.category,
            seo_title: this.state.seo_title,
            seo_description: this.state.seo_description,
            featuredimage: this.state.featuredimage,
            author: this.state.author,
            blogContent: this.state.blogContent,
        };
    
        const missingKeys = Object.entries(requiredFields)
            .filter(([key, value]) => !value)
            .map(([key]) => key);
        this.setState({ missingKeys });
    
        if (missingKeys.length > 0) {
            console.log("Missing required fields:", missingKeys);
            return;
        }
    
        this.setState({ saving: true });
    
        try {
            let featuredImageUrl = ""; // Declare with 'let' to allow reassignment

            if (this.state.featuredimage_change !== false) { featuredImageUrl = await this.handleFeaturedImageUpload();
            } else {featuredImageUrl = this.state.featuredimage;}
    
            // Only proceed if featuredImageUrl is successfully retrieved
            this.setState({ featuredimage: featuredImageUrl, blogContent: { widgets, css } }, () => {
                const { widgets, css } = this.state.blogContent;
                
                const payload = {
                    widgets,
                    title: this.state.title,
                    slug: this.state.slug,
                    category: 1,
                    category_name: categoryname,
                    created_by: +author_id,
                    created_by_name: displayName,
                    author: +author_id,
                    author_name:displayName,
                    country: 1,
                    country_name: 'United States',
                    country_isocode: 'US',
                    meta_title: this.state.seo_title,
                    meta_desc: this.state.seo_description,
                    keywords: this.state.seo_keywords,
                    home_page: 1,
                    rent_page: 0,
                    sorting: 1,
                    status: this.state.status,
                    image: featuredImageUrl,
                    author_linkedin: this.state.author_linkedin,
                    author_description: this.state.author_description
                };
                console.log("Updated: ", payload);

                this.props.handleCloseEditDrawer();
                this.saveBlog(id, payload)
            });
        } catch (error) {
            console.error("Image upload failed, blog was not saved:", error);
            this.setState({ saving: false });
        }
    };

    
    handlePublish = async () => {
        this.setState({ status: 'live' })
        this.handleSubmit()
    }
    handleDraft = async () => {
        this.setState({ status: 'draft' })
        this.handleSubmit()
    }


    saveBlog = async (id, payload) => {
        API.put(`/edit_blog/${id}`, payload, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
            if (response.data.success) {
                console.log("Updated: ", response.data);
                toast.success("Blog Updated: ", this.state.title);
            } else {
                toast.warning("Something went wrong");
            }
        })
        .catch((error) => {
            toast.error("Error saving blog");
            console.error("Save blog error:", error);
        })
        .finally(() => {
            this.setState({ saving: false });
        });
    };
    
    handleOpenAddCategoryDrawer = () => { this.setState({ openAddCategoryDrawer: true }) }
    handleCloseAddCategoryDrawer = () => { this.setState({ openAddCategoryDrawer: false }) }
    render() {
        const uploadButton = ( <div> {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />} <div style={{ marginTop: 8, }} > Upload </div> </div>);
        
        return (
            <Card className='addblogwrap' title={
                <Space className='headercard d-flex flex-row flex-m-column justify-content-between align-items-center'>
                    <div className="d-flex flex-row gap-2 mb-2 align-items-center">
                        <p style={this.state.status=="live"? toplivestyle : topdraftstyle} className='mb-0'>{this.state.status}</p>
                        
                    </div>
                    <div className='d-flex flex-row gap-2 mb-2'>
                    {this.state.status=="draft" && <StyledButton onClick={this.handlePublish}>Publish</StyledButton>}
                    <StyledButton onClick={this.handleSubmit}>Save changes</StyledButton></div>
                </Space>}>
                <Layout className="d-flex flex-row justify-content-between relative" style={{background:'transparent'}}>
                    <ToastContainer />
                    <Content style={{background:'none'}}>

                        <StyledTabs tabPosition="left" defaultActiveKey="1">
                            <TabPane tab="Details" key="1">
                                <Row gutter={16}>
                                    <Col span={16}>
                                        <Row gutter={16} className="mb-3">
                                            <Col span={24}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Title:</label>
                                                    <input placeholder="Add title" name='title' value={this.state.title??''} onChange={this.handletitleUpdateInput} style={titleStyle} />
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Slug:</label>
                                                    <input placeholder="Add title" name='slug' value={this.state.slug??''} onChange={this.handleInputChange} style={slugStyle}/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-1" gutter={16}>
                                            <Col span={12}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Category:</label>
                                                    <StyledSelect placeholder="Choose Category" showSearch allowClear name="category" onChange={this.handleCategoryChange} options={this.state.categories} value={this.state.category} />
                                                    <LinkButton  onClick={this.handleOpenAddCategoryDrawer}>Manage Category</LinkButton>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Added Date:</label>
                                                    <StyledDatePicker showTime now className="w-100"  value={this.state.blog_data.added_date ? dayjs(this.state.blog_data.added_date) : null}  />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8}><Card title={<p className='font-bold mb-0'>Featured Image</p>}>
                                        <div className='form-group'>
                                            <Upload name="footer-logo" listType="picture-card" className="desktop-uploader" showUploadList={false} beforeUpload={beforeUpload} onChange={this.handleFeaturedImage} onRemove={this.handleRemove}>
                                            {this.state.featuredimage_change ?
                                            this.state.featuredimage_temp ? (<img src={this.state.featuredimage_temp} alt="change banner" style={{ width: '100%',borderRadius:7}} />) : (uploadButton)
                                            :this.state.featuredimage ? (<img src={this.state.featuredimage.includes('uploads/blog/') ? 'https://www1.luxuryproperty.com/' + this.state.featuredimage : imageUrl + this.state.featuredimage} alt="avatar" style={{ width: '100%',borderRadius:7}} />) : (uploadButton)}
                                            </Upload>
                                        </div>
                                    </Card></Col>
                                </Row>
                                <Row gutter={16} className='mt-4'>
                                    <Col span={24}>
                                        <Card title={<p className='font-bold mb-0'>Content</p>}>
                                            {this.state.blogContent && this.editorRef ? (
                                                <BlogBuilder ref={this.editorRef} onSave={this.handleSave} blogContent={this.generateHTMLFromData(this.state.blogContent)}
                                                widgets={this.state.blogContent.html}
                                                css={this.state.blogContent.css}
                                                />
                                            ) : (
                                                <LoadingOutlined />
                                            )}
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="SEO Details" key="2">
                                <Row gutter={16} className="mb-3 pt-2">
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label className='font-bold'>Meta Title:</label>
                                            <StyledInput name="meta_title" placeholder="Meta Title" allowClear showCount value={this.state.seo_title} onChange={this.handleSeoTitleInputChange} />
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label className='font-bold'>Meta Description:</label>
                                            <StyledTextArea name="meta_description" placeholder="Meta Description" allowClear showCount value={this.state.seo_description}  onChange={this.handleSeoDescInputChange} />
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Author Details" key="3">
                                <Row gutter={16} className="mb-3">
                                    <Col span={12}>
                                        <div className='form-group'>
                                            <label className='font-bold'>Name:</label>
                                            <StyledSelect name="author" placeholder="Choose Author" onChange={this.handleAuthorChange} value={this.state.author} showSearch allowClear
                                                options={this.state.authors.map(author => ({
                                                    label: author.display_name,value: author.id,
                                                }))}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                className={this.state.missingKeys.includes("author") ? "error" : ""}
                                            />
                                        </div>
                                        <div className='form-group mt-3'>
                                            <label className='font-bold'>Linkedin:</label>
                                            <StyledInput name="author_linkedin" placeholder="Author Linkedin" allowClear showCount onChange={this.handleInputChange} value={this.state.author_linkedin}/>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className='form-group'>
                                            <label className='font-bold'>Description:</label>
                                            <StyledTextArea name="author_description" placeholder="Author Description" allowClear showCount onChange={this.handleInputChange} value={this.state.author_description}/>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                        </StyledTabs>

                        <Row>
                            <Col span={24} className='d-flex flex-row gap-2 mb-2 justify-content-start pt-4'>
                                <StyledButton onClick={this.handleDraft}><i className="bi bi-trash3 mr-1"></i>Change to draft</StyledButton>
                            </Col>
                        </Row>


                    </Content>
                    <Drawer onClose={this.handleCloseAddCategoryDrawer} open={this.state.openAddCategoryDrawer} width={'95vw'} closable={false}
                        title={
                        <Space className='headercard d-flex flex-row flex-m-column justify-content-between align-items-center'>
                            <Space className='d-flex align-items-center'><AppstoreAddOutlined style={{fontSize:16}} /><Title style={{fontSize:16}} className='font-bold mb-0'>Manage categories</Title></Space>
                            <Button onClick={this.handleCloseAddCategoryDrawer} style={{border:'none', boxShadow:'none'}}><CloseCircleOutlined /></Button>
                        </Space>}
                    >
                        {this.state.openAddCategoryDrawer && <ManageCategory />}
                    </Drawer>
                </Layout>
            </Card>
        );
    }
  
};

export default EditBlog;

const StyledButton = styled(Button)`
    height:36px; width:fit-content; margin-top:7px;
`;
const StyledSelect = styled(Select)`
    height:36px;
`;
const StyledDatePicker = styled(DatePicker)`
    height:36px; overflow:hidden; width:100%
`;
const LinkButton = styled(Button)`
    border:none; padding:0; width:fit-content; font-size:12px; box-shadow:none
`;
const StyledInput = styled(Input)`
    height:36px; overflow:hidden;
`;
const StyledTextArea = styled(TextArea)`
    height:7rem;
`;
const titleStyle = { fontSize:14, fontFamily: '"Poppins-Regular", sans-serif !important'}
const slugStyle = {}

const StyledTabs = styled(Tabs)`
  .ant-tabs-left-bar {width: 180px;}
  .ant-tabs-tab {font-size: 14px; padding: 8px 16px;}
  .ant-tabs-tab-active {font-weight: bold; color: #1890ff; background-color: #f0f5ff; border-left: 3px solid #1890ff;}
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {color:#151515; font-family: "Poppins-Bold", sans-serif;}
    .ant-tabs-tab {width:150px;}
`;
const topdraftstyle = {textTransform:'uppercase', marginTop:7, marginBottom:0, background:'#ccc', color:'#FFF', width:'fit-content', padding:'2px 10px', justifyContent:'center', borderRadius:5, height:36, display:'flex', alignItems:'center'}
const toplivestyle = {textTransform:'uppercase', marginTop:7,marginBottom:0, background:'#25d366', color:'#FFF', width:'fit-content', padding:'2px 10px', justifyContent:'center', borderRadius:5, height:36, display:'flex', alignItems:'center'}