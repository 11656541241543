import React, { Component, createRef } from 'react';
import { Col, Row, Card, Layout, Space, Select, Typography, Button, DatePicker, TimePicker, Upload, Input, message, Tabs, Drawer, } from 'antd';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReadOutlined, LoadingOutlined, PlusOutlined, CloseCircleOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import BlogBuilder from '../../../components/cms/blog/BlogBuilder'
import { Content } from 'antd/es/layout/layout';
import API from '../../../utils/API';
import dayjs from 'dayjs';
import Moment from 'react-moment';
import ManageCategory from './ManageCategory'
import { store } from '../../../store';
import { withRouter } from "react-router-dom";

const { Title } = Typography;
const {Sider } = Layout;
const { TextArea } = Input;
const { TabPane } = Tabs;

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};
const imageUrl='https://www1.luxuryproperty.com/';
const userdata = store.getState((state) => state);

class AddBlog extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          title: '',
          slug: '',
          categories:[],
          blogContent: { html: '', css: '' },
          featuredimage:'', featuredimage_temp:'', featuredimage_change:false,
          currentPage:1, openAddCategoryDrawer: false,
          selectedDate: dayjs(),
          authors:[],
          category:'', author: '',
          added_date: null,
          seo_title: '',
          seo_description: '',seo_keywords:'', missingKeys: [],
          author_description: '',
          author_linkedin: ''
        };
        this.editorRef = createRef(); // Create a ref to access the PageBuilder component
    }

    fetchBlogCategories = (page = 1, allCategories = []) => {
        this.setState({ loading: true });
        API.get(`/get_all_categories?page=${page}`)
            .then((res) => {
                if (res.data.success) {
                    // Extract current page categories
                    const currentCategories = res.data.blogs.data.map((category) => ({
                        label: category.title, // Adjust based on your data structure
                        value: category.slug,   // Adjust based on your data structure
                    }));
    
                    // Combine with previously fetched categories
                    const combinedCategories = [...allCategories, ...currentCategories];
    
                    // Check if there are more pages
                    if (page < res.data.blogs.last_page) {
                        // Fetch next page
                        this.fetchBlogCategories(page + 1, combinedCategories);
                    } else {
                        // All pages fetched, update state
                        this.setState({
                            categories: combinedCategories,
                            loading: false,
                        });
                    }
                }
            })
            .catch((error) => {
                console.error("Error fetching blog categories data:", error);
                this.setState({ loading: false });
            });
    };

    fetchAuthors = async () => {
        let allUsers = [];
        let hasMore = true;
        let nextPageUrl = `/all_users`;
    
        try {
            while (hasMore) {
                // Fetch the current page of users
                const response = await API.post(nextPageUrl);
    
                // Extract users and pagination details from the response
                const users = response.data.users || [];
                const nextPage = response.data.next_page_url;
    
                const filteredUsers = users //.filter(user => user.role_id === 1);
                filteredUsers.sort((a, b) => {
                    if (a.display_name < b.display_name) {
                        return -1;
                    }
                    if (a.display_name > b.display_name) {
                        return 1;
                    }
                    return 0;
                });
                allUsers = [...allUsers, ...filteredUsers];
                
                // Check if there's another page to fetch
                if (nextPage) {
                    nextPageUrl = nextPage;  // Update the URL to fetch the next page
                } else {
                    hasMore = false;  // Stop the loop when no more pages
                }
            }
    
            // Update the state with the fetched authors
            this.setState({ authors: allUsers });
        } catch (error) {
            console.error('Error fetching authors:', error);
        }
    }
    
    // In componentDidMount
    componentDidMount() {
        this.fetchBlogCategories(); // Call without initial page
        this.fetchAuthors()
    }

    handleFeaturedImage = ({ file, fileList }) => {
        if (file.status === 'done' || file.status === 'uploading') {
            const fileObj = fileList[0].originFileObj;
            const previewUrl = URL.createObjectURL(fileObj);
            this.setState({ featuredimage: fileObj, featuredimage_change: true, featuredimage_temp: previewUrl});
        }
    };
    handleRemove = () => {
        
    };

    handletitleUpdateInput = (e) => {
        const value = e.target.value.toLowerCase();
        const slug = value.replace(/[^a-z0-9\s]/g, '').replace(/\s+/g, '-');
        this.setState({ slug:slug, title:e.target.value, seo_title:e.target.value  });
    };

    handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Update the field value only if it has changed
        if (this.state[name] !== value) {
            this.setState((prevState) => {
                const updatedState = { [name]: value };
    
                // Remove the `name` key from `missingKeys` if it's currently there
                if (prevState.missingKeys.includes(name)) {
                    updatedState.missingKeys = prevState.missingKeys.filter((key) => key !== name);
                }
    
                return updatedState;
            });
        }
    };
    
    handleSeoTitleInputChange = (e) => {
        const { value } = e.target;
        this.setState((prevState) => ({
            seo_title: value,
            missingKeys: prevState.missingKeys.includes("seo_title")
                ? prevState.missingKeys.filter((key) => key !== "seo_title")
                : prevState.missingKeys,
        }));
    };
    
    handleSeoDescInputChange = (e) => {
        const { value } = e.target;
        this.setState((prevState) => ({
            seo_description: value,
            missingKeys: prevState.missingKeys.includes("seo_description")
                ? prevState.missingKeys.filter((key) => key !== "seo_description")
                : prevState.missingKeys,
        }));
    };
    
    handleSeoKeywordsInputChange = (e) => {
        const { value } = e.target;
        this.setState((prevState) => ({
            seo_keywords: value,
            missingKeys: prevState.missingKeys.includes("seo_keywords")
                ? prevState.missingKeys.filter((key) => key !== "seo_keywords")
                : prevState.missingKeys,
        }));
    };
    
    handleCategoryChange = (e) => {
        this.setState({ category: e });
    }
    handleDateChange = (date, dateString) => {
        if (date) {
            this.setState({ 
                added_date: date,
                selectedDate: date
            });
        } else {
            console.log("Invalid date selected");
            this.setState({
                added_date: null,
                selectedDate: null
            });
        }
    }

    handleAuthorChange = (e) => {
        this.setState({ author: e})
    }

    handleSave = (content) => {
        console.log('Saved content:', content);
        const editorContent = this.editorRef.current.getContent();
    };

    processHtmlToArray = (content) => {
        const html = [];
        const div = document.createElement('div');
        div.innerHTML = content;
    
        // Loop through child nodes and classify each as text or image
        Array.from(div.childNodes).forEach(node => {
            if (node.nodeName === 'IMG') {
                // If it's an image, push it as an object
                html.push({ type: "img1",
                    value: {
                        image1: {
                        src: node.src,
                        alt: node.alt || '',
                        is_image: true,
                        client_name: node.src.split('/').pop(),
                        file_ext: ".jpg",
                        file_name: node.src.split('/').pop().split('.').shift(),
                        file_path: "/var/www/html/uploads/blog/",
                        file_type: "image/jpeg",
                        full_path: node.src,
                        image_type: "jpeg",
                        orig_name: node.src.split('/').pop(),
                        raw_name: node.src.split('/').pop()
                    }}
                });
            } else  {
                // If it's a paragraph or heading, push it as a text block
                html.push({ type: 'text' , value: node.outerHTML });
            }
        });
        return html;
    };
      

    handleFeaturedImageUpload = (info) => {
        const data = new FormData();
        data.append("image", this.state.featuredimage); // Assuming the file is accessible here

        return API.post("/upload_image", data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
            if (response.data.success) {
                toast.success(response.data.message);
                this.setState({ featuredimage: response.data.image_url }); // Update state with the image URL
                return response.data.image_url; // Return the image URL
            } else {
                toast.warning("Error uploading the image");
                throw new Error("Image upload failed");
            }
        })
        .catch((error) => {
            toast.error("Error uploading the image");
            console.error("Error uploading the image:", error);
            throw error; // Throw error to stop further processing in handleSubmit
        });
    };
    
    handleSubmit = async () => {
        if (!this.editorRef || !this.editorRef.current) return;
    
        const editor = this.editorRef.current.editor;
        const htmlcontent = editor.getHtml().replace(/<\/?body[^>]*>/g, '');
        const css = editor.getCss();
        const categoryname = this.state.category.replace(/(^\w|-\w)/g, (match) => match.replace('-', ' ').toUpperCase());
        const userId = this.state.author ?? userdata.auth.user.id;
        const foundAuthor = this.state.authors.find((author) => author.id === userId);
        const displayName = foundAuthor?.display_name || 'Unknown';
    
        const assets = editor.AssetManager.getAll().map(asset => asset.get('src'));
        const widgets = this.processHtmlToArray(htmlcontent);
    
        const requiredFields = {
            title: this.state.title,
            slug: this.state.slug,
            category: this.state.category,
            seo_title: this.state.seo_title,
            seo_description: this.state.seo_description,
            featuredimage: this.state.featuredimage,
            author: this.state.author,
            blogContent: this.state.blogContent,
        };
    
        const missingKeys = Object.entries(requiredFields)
            .filter(([key, value]) => !value)
            .map(([key]) => key);
        this.setState({ missingKeys });
    
        if (missingKeys.length > 0) {
            console.log("Missing required fields:", missingKeys);
            return;
        }
    
        this.setState({ saving: true });
    
        try {
            const featuredImageUrl = await this.handleFeaturedImageUpload();
    
            // Only proceed if featuredImageUrl is successfully retrieved
            this.setState({ featuredimage: featuredImageUrl, blogContent: { widgets, css } }, () => {
                const { widgets, css } = this.state.blogContent;
                
                const payload = {
                    widgets,
                    title: this.state.title,
                    slug: this.state.slug,
                    category: 1,
                    category_name: categoryname,
                    created_by: this.state.author,
                    created_by_name: displayName,
                    author: +this.state.author,
                    author_name:displayName,
                    country: 1,
                    country_name: 'United States',
                    country_isocode: 'US',
                    meta_title: this.state.seo_title,
                    meta_desc: this.state.seo_description,
                    keywords: this.state.seo_keywords,
                    home_page: 1,
                    rent_page: 0,
                    sorting: 1,
                    status: 'draft',
                    image: featuredImageUrl,
                    author_linkedin : this.state.author_linkedin,
                    author_description: this.state.author_description
                };
    
                console.log("Payload to be saved:", payload);
    
                API.post("/add_new_blog", payload, { headers: { 'Content-Type': 'application/json' } })
                    .then((response) => {
                        if (response.data.success) {
                            toast.success("New blog added");
                            this.props.history.push("/blogs");
                        } else {
                            toast.warning("Something went wrong");
                        }
                    })
                    .catch((error) => {
                        toast.error("Error saving blog");
                        console.error("Save blog error:", error);
                    })
                    .finally(() => {
                        this.setState({ saving: false });
                    });
            });
        } catch (error) {
            console.error("Image upload failed, blog was not saved:", error);
            this.setState({ saving: false });
        }
    };
    
    handleOpenAddCategoryDrawer = () => { this.setState({ openAddCategoryDrawer: true }) }
    handleCloseAddCategoryDrawer = () => { this.setState({ openAddCategoryDrawer: false }) }

    render() {
        const uploadButton = ( <div style={{minHeight:'10rem', alignContent:'center'}}> {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />} <div style={{ marginTop: 8, }} > Upload </div> </div>);

        return (
            <Card className='addblogwrap' title={
                <Space className='headercard d-flex flex-row flex-m-column justify-content-between align-items-center mt-3'><Space className='d-flex align-items-center'><ReadOutlined style={{fontSize:21}} /><Title style={{fontSize:21}} className='font-bold mb-0'>Add new post</Title></Space>
                    <div className='d-flex flex-row gap-2 mb-2'>
                        <StyledButton onClick={this.handleSubmit} >Save as draft</StyledButton>
                    </div>
                </Space>}
                actions={<div className='d-flex flex-row gap-2 mb-2'>
                    <StyledButton >Save draft changes</StyledButton>
                    </div>}>
                <Layout className="d-flex flex-row justify-content-between relative" style={{background:'transparent'}}>
                    <ToastContainer />
                    <Content style={{background:'none',}}>

                        <StyledTabs tabPosition="left" defaultActiveKey="1">
                            <TabPane tab="Details" key="1">
                                <Row gutter={16}>
                                    <Col span={16}>
                                        <Row gutter={16} className="mb-3">
                                            <Col span={24}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Title:</label>
                                                    <input placeholder="Add title" name='title' onChange={this.handletitleUpdateInput} className={this.state.missingKeys.includes("title") ? "error" : ""} />
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Slug:</label>
                                                    <input placeholder="Add title" name='slug' value={this.state.slug??''} onChange={this.handleInputChange} className={this.state.missingKeys.length >0 ?this.state.missingKeys.includes("slug") ? "error" : "":""}/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mb-1" gutter={16}>
                                            <Col span={12}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Category:</label>
                                                    <StyledSelect placeholder="Choose Category" showSearch allowClear name="category" onChange={this.handleCategoryChange} 
                                                        options={this.state.categories} className={this.state.missingKeys.includes("category") ? "error" : ""}/>
                                                    <LinkButton  onClick={this.handleOpenAddCategoryDrawer}>Manage Category</LinkButton>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Published Date:</label>
                                                    <StyledDatePicker showTime className="w-100" value={this.state.selectedDate} name="added_date" onChange={this.handleDateChange} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8}><Card title={<p className='font-bold mb-0'>Featured Image</p>}>
                                        <div className='form-group'>
                                            <Upload name="featured_image" listType="picture-card"showUploadList={false} className={`desktop-uploader ${this.state.missingKeys.includes("featuredimage") ? "error" : ""}`}
                                            beforeUpload={beforeUpload} onChange={this.handleFeaturedImage} onRemove={this.handleRemove}>
                                            {this.state.featuredimage_change ?
                                            this.state.featuredimage_temp ? (<img src={this.state.featuredimage_temp} alt="change banner" style={{ width: '100%',borderRadius:7}} />) : (uploadButton)
                                            :this.state.featuredimage ? (<img src={imageUrl + this.state.featuredimage} alt="avatar" style={{ width: '100%',borderRadius:7}} />) : (uploadButton)}
                                            </Upload>
                                        </div>
                                    </Card></Col> 
                                    <Col span={24} className="mt-4">
                                    <StyledCardEditor title={"Content"}><BlogBuilder ref={this.editorRef} onSave={this.handleSave} page={"addpage"} /></StyledCardEditor></Col>  
                                </Row>                                
                            </TabPane>
                            <TabPane tab="SEO Details" key="2">
                                <Row gutter={16} className="mb-3 pt-2">
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label className='font-bold'>Meta Title:</label>
                                            <TextArea name="seo_title" placeholder="Meta Title" allowClear showCount onChange={this.handleSeoTitleInputChange} value={this.state.seo_title??''} className={this.state.missingKeys.includes("seo_title") ? "error" : ""} />
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label className='font-bold'>Meta Description:</label>
                                            <TextArea name="seo_description" placeholder="Meta Description" allowClear showCount onChange={this.handleSeoDescInputChange} className={this.state.missingKeys.includes("seo_description") ? "error" : ""}/>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label className='font-bold'>Keywords:</label>
                                            <label className='font-10'>Separate each keyword with a comma(,).</label>
                                            <TextArea name="keywords" placeholder="Keywords" allowClear showCount onChange={this.handleSeoKeywordsInputChange} value={this.state.seo_keywords??''} className={this.state.missingKeys.includes("keywords") ? "error" : ""} />
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Author Details" key="3">
                                <Row gutter={16} className="mb-3">
                                    <Col span={12}>
                                        <div className='form-group'>
                                            <label className='font-bold'>Name:</label>
                                            <StyledSelect name="author" placeholder="Choose Author" onChange={this.handleAuthorChange} value={userdata.auth.user.id} showSearch allowClear
                                                options={this.state.authors.map(author => ({
                                                    label: author.display_name,  // Display name or another field from the author object
                                                    value: author.id,  // ID or another unique identifier
                                                }))}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                className={this.state.missingKeys.includes("author") ? "error" : ""}
                                            />
                                        </div>
                                        <div className='form-group mt-3'>
                                            <label className='font-bold'>Linkedin:</label>
                                            <StyledInput name="author_linkedin" onChange={this.handleInputChange} />
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className='form-group'>
                                            <label className='font-bold'>Description:</label>
                                            <StyledTextArea name="author_description" placeholder="Meta Description" allowClear showCount onChange={this.handleInputChange} />
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                        </StyledTabs>
                    </Content>

                    <Drawer onClose={this.handleCloseAddCategoryDrawer} open={this.state.openAddCategoryDrawer} width={'95vw'} closable={false}
                        title={
                        <Space className='headercard d-flex flex-row flex-m-column justify-content-between align-items-center'>
                            <Space className='d-flex align-items-center'><AppstoreAddOutlined style={{fontSize:16}} /><Title style={{fontSize:16}} className='font-bold mb-0'>Manage categories</Title></Space>
                            <Button onClick={this.handleCloseAddCategoryDrawer} style={{border:'none', boxShadow:'none'}}><CloseCircleOutlined /></Button>
                        </Space>}
                    >
                        {this.state.openAddCategoryDrawer && <ManageCategory />}
                    </Drawer>

                </Layout>
            </Card>
        );
    }
  
};

export default AddBlog;

const StyledButton = styled(Button)`
    height:36px; width:fit-content;
`;
const StyledSelect = styled(Select)`
    height:38px;
`;
const StyledDatePicker = styled(DatePicker)`
    height:36px; overflow:hidden; width:100%
`;
const LinkButton = styled(Button)`
    border:none; padding:0; width:fit-content; font-size:12px; box-shadow:none
`;
const StyledInput = styled(Input)`
    height:36px;
`;
const StyledTextArea = styled(TextArea)`
    height:7rem;
`;

const titleStyle = { fontSize:14, fontFamily: '"Poppins-Regular", sans-serif !important'}
const slugStyle = {}

const StyledTabs = styled(Tabs)`
  .ant-tabs-left-bar {width: 180px;}
  .ant-tabs-tab {font-size: 14px; padding: 8px 16px;}
  .ant-tabs-tab-active {font-weight: bold; color: #1890ff; background-color: #f0f5ff; border-left: 3px solid #1890ff;}
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {color:#151515; font-family: "Poppins-Bold", sans-serif;}
    .ant-tabs-tab {width:150px;}
`;

const StyledCardEditor = styled(Card)`
    .ant-card-body {background: url(/dot-grid.webp);box-shadow: 0 0 15px inset rgba(21, 21, 21, 0.06);}
    .ant-card-head {border-bottom:1px solid rgba(21,21,21,0.16); } 
.blogcontent {border: 1px solid rgba(21, 21, 21, 0.06);}
`;