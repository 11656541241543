import React, { Component } from 'react'
import { store } from "../../../store";
import { Col, Row, Divider, Typography, Card, Avatar, Upload, Button, message, Input, Space, Layout, Menu, FloatButton, AutoComplete ,Select, Collapse, Switch, Checkbox } from 'antd';
import cmsstyle from "./cms.css"
import { AlignLeftOutlined, LoadingOutlined, PlusOutlined,DesktopOutlined, InsertRowBelowOutlined,SearchOutlined, HomeOutlined, MenuOutlined, MobileOutlined,TrophyOutlined, RubyOutlined, YoutubeOutlined, SketchOutlined,ReadOutlined, SignatureOutlined, WhatsAppOutlined, ShareAltOutlined, CloudUploadOutlined, CaretUpOutlined, InfoCircleOutlined,DeleteOutlined, TagOutlined, CaretRightOutlined  } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { Content } from 'antd/es/layout/layout';
import API from '../../../utils/API';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImgCrop from 'antd-img-crop';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const {Sider } = Layout;
const { Panel } = Collapse;

const { Title } = Typography;
const { Meta } = Card;

const imageUrl='https://www1.luxuryproperty.com/';


const CustomBackTop = styled(FloatButton.BackTop)`
  left: 23px; /* Adjust this value as needed */
  right: auto; /* Override the default right alignment */
`;
  
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const beforeUploadLogo = (file) => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG/PNG/SVG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must be smaller than 2MB!');
      return false;
    }
    return true;
};

const beforeUploadFavicon = (file) => {
    const isIco = file.type === 'image/x-icon'; // MIME type for ICO files
    if (!isIco) {
        message.error('You can only upload ICO files!');
        return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2; // Check if file size is less than 2MB
    if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
        return false;
    }
    return true;
};



  function getItem(label, key,id, icon, children) {
    return { key, icon,  children, label,id };
  }
  const items = [
    getItem('Homepage', '1',"home", <HomeOutlined />, [
        getItem('Banner', '2', "banner"),
        getItem('Search content', '3', "search"),
        getItem('Featured Content', '4', "featcontent"),
        getItem('Featured Homes For Sale', '5', 'homesale'),
        getItem('Featured Video Spotlight', '6', 'video'),
        getItem('Featured Homes For Rent', '7', 'homesrent'),
        getItem('Featured Off Plan', '8', 'offplan'),
        getItem('Featured Blog', '9', 'blog'),
        getItem('SEO', '10','seo', <SignatureOutlined />),
    ]),
    getItem('Header', '11','header', <MenuOutlined />),
    getItem('Footer', '12','footer', <InsertRowBelowOutlined />),
    getItem('WhatsApp & Email', '13','seo', <WhatsAppOutlined />),
    getItem('Social Media', '14','social', <ShareAltOutlined />),
  ];



class Homepage extends Component {
    // update this
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            collapsed:false,
            homepage:true,
            header:false,
            footer:false,
            seo:false,
            whatsappview: false,
            social: false,
            sticky:false,
            saving:false,

            // homepage
            desktop_banner: null, desktoptemp_banner:null, desktop_banner_change:false,
            desktop_banner_alt:'',
            mobile_banner:null, mobiletemp_banner:null,mobile_banner_change:false,
            mobile_banner_alt:'',
            search_title:'',
            search_text:'',
            title_1:'',
            title_2:'',
            title_3:'',
            title_4:'',
            title_5:'',
            search_title_mobile:'',
            search_text_mobile:'',
            featured_title:'',
            featured_description:'',
            featured_title_mobile:'',
            featured_desc_mobile:'',
            featured_sale_title:'',
            featured_sale_title_mobile:'',
            featured_sale_desc:'',
            featured_sale_desc_mobile:'',
            featured_listing_sale_1:'',
            featured_listing_sale_2:'',
            featured_listing_sale_3:'',
            featured_listing_sale_4:'',
            featured_listing_sale_5:'',
            featured_rent_title:'',
            featured_rent_desc:'',
            featured_listing_rent_1:'',
            featured_listing_rent_2:'',
            featured_listing_rent_3:'',
            featured_listing_rent_4:'',
            featured_listing_rent_5:'',
            featured_video:'',
            video_title:'',
            video_title_mobile:'',
            video_desc:'',
            video_desc_mobile:'',
            video_button_text:'',
            video_button_link:'',
            offplan_title:'',
            offplan_desc:'',
            offplan_listing_1:'',
            offplan_listing_2:'',
            offplan_listing_3:'',
            offplan_listing_4:'',
            offplan_listing_5:'',
            blog_title:'',
            blog_description:'',
            blog_1:'', blog_1_image:null, blog_1_title:null,
            blog_2:'', blog_2_image:null, blog_2_title:null,
            blog_3:'', blog_3_image:null, blog_3_title:null,
            blog_4:'', blog_4_image:null, blog_4_title:null,
            blog_5:'', blog_5_image:null, blog_5_title:null,

            ref_nums:[],
            sale_ref_nums:[],
            sale_ref_nums_first:[],
            sale_ref_nums_two:[],
            sale_ref_nums_three:[],
            sale_ref_nums_four:[],
            sale_ref_nums_five:[],

            rent_ref_nums:[],
            rent_ref_nums_first:[],
            rent_ref_nums_two:[],
            rent_ref_nums_three:[],
            rent_ref_nums_four:[],
            rent_ref_nums_five:[],

            offplan_ref_nums:[],
            offplan_ref_nums_first:[],
            offplan_ref_nums_two:[],
            offplan_ref_nums_three:[],
            offplan_ref_nums_four:[],
            offplan_ref_nums_five:[],       
            
            blog_search:[],

            // SEO
             meta_title:"", 
             meta_description:"",
             meta_image:"",

            // Header
            logo:'', logo_change:false,
            logo_temp:null,
            favicon : '', favicon_change:false,
            favicon_temp:null,
            hamburgermenu: [],
            newMenuItem: { addicon: '',addtype:'', addlabel: '', addlink: '', addmobile: 'no'},
            topmenu:[],
            newTopMenuItem: { icon: '', type:'', label: '', link: '', mobile: 'no'},

            //   Footers
            link_type_options:[
                {id:'1', value:'customlink', label:'Custom Link'},
                {id:'2', value:'property', label:'Property'},
                {id:'3', value:'page', label:'Page'},
            ],
            rowLinkTypes: Array(5).fill('customlink'),
            customlink:false,
            propertylink:false,
            pagelink:false,
            footer_logo:'', footer_logo_temp:null, footer_logo_change:false,
            footer_logo_alt:'',
            copyright:'',
            first_col_title:'',
            first_col:[],
            second_col_title:'',
            second_col:[],
            third_col_title:'',
            third_col:[],
            fourth_col_title:'',
            fourth_col:[],
            fifth_col_title:'',
            fifth_col:[],
            footer_menu:[],
            bottom_footer:[],
            social_media:[],
            app_store:[],
            google_play:[],
            all_ref_nos:[],

            // WhatsApp & Email
            email:'',
            whatsapp: [{
                general_number: '',
                general_text: '',
                property_number: '',
                property_message: ''
            }],
            cursorPosition: 0,

            // Social Media
            socialMediaEntries: [],
            facebook:'/svg/facebook.svg',
            instagram:'/svg/instagram.svg',
            youtube:'/svg/youtube.svg',
            linkedin:'/svg/linkedin.svg',
            twitter:'/svg/twitter.svg',
            podcast:'/svg/podcast.svg',
        }; this.onDragEnd = this.onDragEnd.bind(this);
        this.textAreaRef = React.createRef();
    }

    setFieldsToState = (data, fields) => {
        const stateData = {};
        fields.forEach((field) => {
          stateData[field] = data[field] ?? '';
        });
        this.setState(stateData);
      };
    componentDidMount() {
        // Helper function to set state from API response
        
      
        // Fetch homepage data
        API.get("/homepage")
          .then((res) => {
            if (res.data.success) {
              const homepageData = res.data.homepage;
              const homepageFields = [
                "desktop_banner", "desktop_banner_alt", "mobile_banner", "mobile_banner_alt", 
                "search_title", "search_title_mobile", "search_text", "search_text_mobile", 
                "title_1", "title_2", "title_3", "title_4", "title_5", 
                "featured_title","featured_title_mobile", "featured_desc", "featured_desc_mobile",
                "featured_sale_title", "featured_sale_title_mobile", "featured_sale_desc", "featured_sale_desc_mobile",
                "featured_listing_sale_1", "featured_listing_sale_2", "featured_listing_sale_3", "featured_listing_sale_4", "featured_listing_sale_5", 
                "featured_video", "video_title", "video_title_mobile", "video_desc", "video_desc_mobile", "button_text", "button_url", 
                "featured_rent_title", "featured_rent_desc", "featured_rent_desc_mobile", 
                "featured_listing_rent_1", "featured_listing_rent_2", "featured_listing_rent_3", "featured_listing_rent_4", "featured_listing_rent_5",
                "offplan_title", "offplan_title_mobile", "offplan_desc", "offplan_desc_mobile",
                "offplan_listing_1", "offplan_listing_2", "offplan_listing_3", "offplan_listing_4", "offplan_listing_5", 
                "blog_title", "blog_title_mobile", "blog_description", "blog_description_mobile", 
                "blog_1", "blog_2", "blog_3", "blog_4", "blog_5", "meta_title", "meta_description", "meta_image"
              ];
              this.setFieldsToState(homepageData, homepageFields);
            }
          })
          .catch((error) => {
            console.error("Error fetching homepage data:", error);
          });
      
        // Fetch header data
        API.get("/header")
          .then((res) => {
            if (res.data.success) {
              const headerData = res.data.header;
              const headerFields = ["email", "favicon", "logo", "logo_alt", "hamburgermenu", "topmenu", "whatsapp"];
              this.setFieldsToState(headerData, headerFields);
            }
          })
          .catch((error) => {
            console.error("Error fetching header data:", error);
          });

        //   Fetch Footer Data
        API.get("/footer")
          .then((res) => {
            if (res.data.success) {
              const footerData = res.data.footer;
              footerData.footer_logo = footerData.logo;
              footerData.footer_logo_alt = footerData.logo_alt;
              const footerFields = ["app_store","google_play", "bottom_footer", "copyright", "first_col", "first_col_title", "second_col", "second_col_title","third_col", "third_col_title","fourth_col", "fourth_col_title","fifth_col", "fifth_col_title","footer_menu","social_media", "footer_logo", "footer_logo_alt", ];
              this.setFieldsToState(footerData, footerFields);
            }
          })
          .catch((error) => {
            console.error("Error fetching header data:", error);
          });
      }
      

    callToRef = (typed_data, currentPage, refNumsKey) => {
        this.setState({isListingLoading: true});
        this.setState({unit_nums: []})
        if (!typed_data || typed_data == "" || typed_data == null) {
          this.setState({ [refNumsKey]: [] });
          this.setState({ref_def_nums: typed_data})
          return;
        }
        this.setState({ref_def_nums: typed_data})
        const data = { id: typed_data, page: currentPage, perPage: 5}  
        API.post("/get_ref_no", data).then((response) => {
          this.setState({isListingLoading: false});
          if (response.data.success) { 
            this.setState({ [refNumsKey]: response.data.ref_nums.data });
          }
        });
      }

    handleSearchRefNo = (e, type, stateKey) => {
        if (!e) {
            this.setState({ [stateKey]: [] });
            return;
        }
        const optionslist = `${type}_ref_nums`;
        const apiPath = type === 'rent' ? "/get_rent_ref_no/" : "/get_sales_ref_no/";
        API.get(apiPath + e).then((response) => {
            if (response.data.success) {
                let refNums = response.data.ref_nums;    
                if (type === 'Offplan' || type==='offplan_secondary' || type=== 'offplan_primary') {
                    refNums = refNums.filter(refnum => refnum.completed === 'Offplan' || refnum.completed === 'offplan_secondary' || refnum.completed === 'offplan_primary')
                }
                
                this.setState({ [optionslist]: refNums });
            }
        });
        
    };

    handleRefSelection = (e, listingType, index) => {
        const stateKey = listingType=="offplan" ?  `${listingType}_listing_${index}` : `featured_listing_${listingType}_${index}`;
        const refNumsKey = `${listingType}_ref_nums_${this.convertIndexToText(index)}`;
        this.callToRef(e, 1, refNumsKey);
        this.setState({ [stateKey]: e });
    };
    
    convertIndexToText = (index) => {
        const numText = ['first', 'two', 'three', 'four', 'five'];
        return numText[index - 1];
    };
    
    scrollToRow = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    handleDesktopChange = ({ file, fileList }) => {
        if (file.status === 'done' || file.status === 'uploading') {
            const fileObj = fileList[0].originFileObj;
            const previewUrl = URL.createObjectURL(fileObj);
            this.setState({ desktop_banner: fileObj, desktop_banner_change:true });
            this.setState({desktoptemp_banner: previewUrl})
        }
    };
    handleMobileChange = ({ file, fileList }) => {
        if (file.status === 'done' || file.status === 'uploading') {
            const fileObj = fileList[0].originFileObj;
            const previewUrl = URL.createObjectURL(fileObj);
            this.setState({ mobile_banner: fileObj, mobile_banner_change:true });
            this.setState({ mobiletemp_banner: previewUrl})
        }
    };
    
    
    
    
    handleRemove = () => {
    this.setState({ desktop_banner: null });
    };

      handleFeaturedVideo = (value) => {
        if(value) {
        const regex = /v=([^&]+)/;     
        const yt_id = value.target.value.match(regex)
        const videoId = yt_id ? yt_id[1] : null;
        this.setState({ featured_yt_img: videoId })       
        } 
      }

     
      handleMenuClick = ({ key }) => {
        const newState = {
            homepage: false,
            header: false,
            footer: false,
            seo: false,
            whatsappview: false,
            social: false
        };
    
        switch (key) {
            case '10':
                newState.seo = true;
                break;
            case '11':
                newState.header = true;
                break;
            case '12':
                newState.footer = true;
                break;
            case '13':
                newState.whatsappview = true;
                break;
            case '14':
                newState.social = true;
                break;
            default:
                newState.homepage = true;
                this.scrollToRow(key);
                break;
        }
    
        this.setState(newState);
    };
    
    renderRefNums = (ref_nums) => {
        return ref_nums.map(ref => (
            <div key={ref.refno}>
                <p className='font-bold mt-3 mb-0'>{ref.refno}</p>
                <p className='mb-0'>{ref.unitno ? ref.unitno+", ":''} {ref.sub_community?ref.sub_community+'':''} {ref.community?ref.community:''}</p>
                <p className='mb-0 font-12'><CaretRightOutlined style={{margin:'0 5px 0 -2px'}} />{ref.display_name}</p>
            </div>
        ));
    }

    // BLOG
    onSearchBlog = (e) => {
        if (!e || e == '' || e == null) {
            this.setState({ 'blog_search': [] })
            return
        }
        const formData = new FormData();
        formData.append("blog", e);
        API.post("/search_blogs", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            if (response.data.success) {            
                const options = response.data.blogs.map(blog => ({
                    value: blog.slug,
                    label: blog.title,
                    img: blog.image
                })); 
                this.setState({ blog_search: options });
            } else {
                toast.warning("Something went wrong");
            }
        })
    }
    handleSearchBlog =  (value, option, index) => {
        const selectedBlog = option ? option : {};
        const blogLabel = selectedBlog.label;
        const blogImg = selectedBlog.img;
        this.setState({ [`blog_${index}`]: value,  [`blog_${index}_image`]: blogImg, [`blog_${index}_title`]: blogLabel });
    }
    renderBlog = (title, index) => {    
        const blogValue = title
        const blogImage = this.state[`blog_${index}_image`];
        const blogTitle = this.state[`blog_${index}_title`];
        if (blogValue) {
          return (
            <div key={index}>
              {blogImage && (
                <img
                  src={`https://www1.luxuryproperty.com/files/large/${blogImage}`}
                  alt="avatar"
                  style={{ width: '100%' }}
                />
              )}
              {blogTitle && (
                <a
                  href={`https://www.luxuryproperty.com/blog/${blogValue}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#151515' }}
                >
                  {blogTitle}
                </a>
              )}
            </div>
          );
        }
      
        return null;
      };
      
//==================== HEADER ==================== HEADER ==================== HEADER ==================== HEADER

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: `0`,
        margin: `0 0 8px 0`,
        border:`1px solid rgba(21,21,21,0.16)`,
        // change background colour if dragging
        background: isDragging ? "#25d366" : "rgba(21,21,21,0.02)",
        // styles we need to apply on draggables
        ...draggableStyle
    });

    getListStyle = isDraggingOver => ({ });
    onDragEnd(result) {
        if (!result.destination) { return;}
        const items = this.reorder(
          this.state.hamburgermenu,
          result.source.index,
          result.destination.index
        );
    
        this.setState({hamburgermenu:items});
    }
    handleLogoChange = ({ file, fileList }) => {
        if (file.status === 'done' || file.status === 'uploading') {
            const fileObj = fileList[0].originFileObj;
            const previewUrl = URL.createObjectURL(fileObj);
            this.setState({ logo: fileObj, logo_change: true, logo_temp: previewUrl});
        }
    };
    handleFaviconChange = ({ file, fileList }) => {
        if (file.status === 'done' || file.status === 'uploading') {
            const fileObj = fileList[0].originFileObj;
            const previewUrl = URL.createObjectURL(fileObj);
            this.setState({ favicon: fileObj, favicon_change: true, favicon_temp: previewUrl});
        }
    };
    // Add the new menu item to the hamburgermenu list
    addMenuItem = () => {
        const { newMenuItem, hamburgermenu } = this.state;
    
        // Ensure 'mobile' has a proper value
        // const mobileValue = newMenuItem.addmobile === 'yes' ? 'yes' : 'no';
  
        const updatedMenu = [
            ...hamburgermenu,
            {
                id: `${hamburgermenu.length + 1}`, // Generate a unique ID
                label: newMenuItem.addlabel, // Use the new label from state
                type: newMenuItem.addtype, // Use the new type from state
                link: newMenuItem.addlink, // Use the new link from state
                mobile: newMenuItem.addmobile, // Ensure mobile value is set
            },
        ];
        this.setState({
            hamburgermenu: updatedMenu,
            newMenuItem: { icon: '',type:'', label: '', link: '', mobile: newMenuItem.addmobile},
        });
    };
    
    handleNewMenuChange = (e) => {
        const { name, type, checked, value } = e.target;
    
        this.setState(prevState => ({
            newMenuItem: {
                ...prevState.newMenuItem,
                [name]: type === 'checkbox' ? (checked ? 'yes' : 'no') : value
            }
        }));
    };
    
    addTopMenuItem = () => {
        const { newMenuItem, topmenu } = this.state;
    
        // Ensure 'mobile' has a proper value
        const mobileValue = newMenuItem.mobile === 'yes' ? 'yes' : 'no';
    
        const updatedMenu = [
            ...topmenu,
            { ...newMenuItem, mobile: mobileValue, id: `${topmenu.length + 1}` } // Add menu item with correct mobile value
        ];
    
        this.setState({
            topmenu: updatedMenu,
            newMenuItem: { icon: '', label: '', link: '', mobile: 'yes' } // Reset form after adding
        });
    
    };
    handleNewTopMenuChange = (e) => {
        const { name, type, checked, value } = e.target;
    
        if (type === 'checkbox') {
            // For checkbox, handle it based on whether it's checked
            this.setState(prevState => ({
                newMenuItem: {
                    ...prevState.newMenuItem,
                    [name]: checked ? 'yes' : 'no' // Set 'yes' if checked, 'no' otherwise
                }
            }));
        } else {
            // For other inputs (like text inputs)
            this.setState(prevState => ({
                newMenuItem: {
                    ...prevState.newMenuItem,
                    [name]: value
                }
            }));
        }
    };

    handleMenuInputChange = (e, index, field) => {
        const newMenu = [...this.state.hamburgermenu];
        newMenu[index][field] = e.target.value;
        this.setState({ hamburgermenu: newMenu });
    }


// ==================== FOOTER ==================== FOOTER ==================== FOOTER ==================== FOOTER

    handleLinkOptions = (e, index) => {
        const rowLinkTypes = [...this.state.rowLinkTypes];
        rowLinkTypes[index - 1] = e;
        this.setState({ rowLinkTypes });
    }

    callToAllRef = (typed_data) => {
        this.setState({isListingLoading: true});
        const data = { id: typed_data, page: 1, perPage: 15}  
        API.post("/get_ref_no", data).then((response) => {
          this.setState({isListingLoading: false});
          if (response.data.success) { 
            this.setState({ all_ref_nos: response.data.ref_nums.data });
          }
        });
      }


    handleSearchAllRefNo =(e) => {
        this.callToAllRef(e)
    }
    handleAllRef =(e)=> {
        console.log(e)
    }

    renderFooterRows = (title, index) => {
        const footerRows = [];
        const data = this.state[`${title.toLowerCase()}_col`] || [];

        for (let i = 0; i <5 ; i++) {
            const item = data[i] || {};
            footerRows.push(
                <Row gutter={16} key={i}>
                    <Col span={1}><label className="font-bold">{i+1}</label></Col>
                    <Col span={8}>
                        <div className='form-group'>
                            <label className='font-bold'>Label:</label>
                            <input placeholder="" name={`footer_${title.toLowerCase()}_label_${i}`} defaultValue={item.label || ''} onChange={(e) => this.handleFooterInputChange(title, e)} />
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className='form-group'>
                            <label className='font-bold'>Type:</label>
                            <StyledSelect 
                                options={this.state.link_type_options} onChange={(e) => this.handleLinkOptions(e, i + 1)}
                                defaultValue={this.state.link_type_options.find(option => option.value === (item?.type ?? 'customlink')) || { value: 'customlink', label: 'Custom Link' }}
                            />
                        </div>
                    </Col>
                    {this.state.rowLinkTypes[i] === 'customlink' && <Col span={9}>
                        <div className='form-group'>
                            <label className='font-bold'>Custom Link:</label>
                            <input placeholder="" name={`footer_${title.toLowerCase()}_customlink_${i}`} defaultValue={item.link || ''} onChange={(e) => this.handleFooterInputChange(title, e)} />
                        </div>
                    </Col>}
                    {this.state.rowLinkTypes[i] === 'property' && <Col span={9}>
                        <div className='form-group'>
                            <label className='font-bold'>Property Link:</label>
                            <StyledSelect style={{ width: "100%",}} name={`footer_${title.toLowerCase()}_propertylink_${i}`} defaultValue={item.link || ''} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={this.handleAllRef} onSearch={this.handleSearchAllRefNo} filterOption={(input, option) => option?.value.toLowerCase().includes(input.toLowerCase())} options={this.state.all_ref_nos.map(ref => ({ value: ref.refno, label: `${ref.refno} - ${ref.property ?? ''} (${ref.community})`}))}  />
                        </div>
                    </Col>}
                    {this.state.rowLinkTypes[i] === 'page' && <Col span={9}>
                        <div className='form-group'>
                            <label className='font-bold'>Page Link:</label>
                            <input placeholder="" name={`footer_${title.toLowerCase()}_pagelink_${i}`} defaultValue={item.link || ''} onChange={(e) => this.handleFooterInputChange(title, e)} />
                        </div>
                    </Col>}
                </Row>
            );
        }
        return footerRows;
    }

    handleFooterInputChange = (title, e) => {
        const { name, value } = e.target;  // Extract name and value from the input event
        const colName = `${title.toLowerCase()}_col`;  // Determine which column (city or property)
    
        const updatedCol = [...this.state[colName]];  // Copy the current column data (e.g., cityLinks or propertyLinks)
    
        // Extract index from input name, assuming the format is like 'footer_city_label_0'
        const index = parseInt(name.split('_').pop());  // Get the index from the name, last part of name
    
        // Update the specific field based on name (label or link)
        if (name.includes('label')) {
            updatedCol[index].label = value;
        } else if (name.includes('link')) {
            updatedCol[index].link = value;
        }
    
        // Update the state with the modified column
        this.setState({ [colName]: updatedCol });
    };

    handleFooterTitleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value, });
    }
    

    handleFooterLogoChange = ({ file, fileList }) => {
        if (file.status === 'done' || file.status === 'uploading') {
            const fileObj = fileList[0].originFileObj;
            const previewUrl = URL.createObjectURL(fileObj);
            this.setState({ footer_logo: fileObj, footer_logo_change: true, footer_logo_temp: previewUrl});
        }
    };

/* =================== BOTTOM LINKS ====================*/
handleAddBottomLink = () => {
    const newIndex = this.state.bottom_footer.length + 1;
    this.setState(prevState => ({
        bottom_footer: [...prevState.bottom_footer, { id: newIndex.toString(), label: '', link: '', icon: null }]
    }));
};

handleRemoveBottomLink = (index) => {
    this.setState(prevState => ({
        bottom_footer: prevState.bottom_footer.filter(entry => entry.id !== index.toString())
    }));
};

renderBottomLinksRows = (entry, index) => (
    <Row key={index} className="wrap container pt-3 pb-2 justify-content-between" gutter={16}>
        <Col span={1}><label className="font-bold">{index + 1}</label></Col>
        <Col span={11}>
            <div className='form-group'>
                <label className='font-bold'>Label:</label>
                <input name={`footer-label-${index}`} value={entry.label} onChange={(e) => this.handleBottomLinksInputChange(index, 'label', e)} />
            </div>
        </Col>
        <Col span={11}>
            <div className='form-group'>
                <label className='font-bold'>Link:</label>
                <input name={`footer-link-${index}`} value={entry.link} onChange={(e) => this.handleBottomLinksInputChange(index, 'link', e)} />
            </div>
        </Col>
        <Col span={1}>
            <div className='form-group justify-content-center'>
                <label className='font-bold'>Delete:</label>
                <div className='d-flex flex-row gap-1'>
                    <Button onClick={() => this.handleRemoveBottomLink(index)} type="danger" className='mt-0'><DeleteOutlined /></Button>
                </div>
            </div>
        </Col>
    </Row>
);

handleBottomLinksInputChange = (index, field, e) => {
    if (!e) { console.error('Event is undefined'); return; }
    const value = e.target.value;
    this.setState(prevState => {
        const updatedEntries = [...prevState.bottom_footer];
        updatedEntries[index][field] = value;
        return { bottom_footer: updatedEntries };
    });
    console.log(value);
};




// ==================== WHATSAPP & EMAIL ==================== WHATSAPP & EMAIL ==================== WHATSAPP & EMAIL ====================
    handleEmailInputChange = (e) => {
        this.setState({email: e.target.value})
    }

    handleWhatsAppInputChange = (key, value) => {
        this.setState(prevState => ({
            whatsapp: Array.isArray(prevState.whatsapp) ? prevState.whatsapp.map(item => ({
                ...item,
                [key]: value
            })) : [{
                general_number: key === 'general_number' ? value : prevState.whatsapp[0]?.general_number || '',
                general_text: key === 'general_text' ? value : prevState.whatsapp[0]?.general_text || '',
                property_number: key === 'property_number' ? value : prevState.whatsapp[0]?.property_number || '',
                property_message: key === 'property_message' ? value : prevState.whatsapp[0]?.property_message || ''
            }]
        }));
    };
      handleCursorChange = () => {
        const cursorPosition = this.textAreaRef.current?.resizableTextArea?.textArea.selectionStart;
        this.setState({ cursorPosition });
      };
    
      insertTextAtCursor = (text) => {
        const message = this.state.whatsapp[0].property_message;
        const { cursorPosition } = this.state;
    
        // Insert the text at the current cursor position
        const newMessage =
          message.slice(0, cursorPosition) +
          text +
          message.slice(cursorPosition);
    
        this.handleWhatsAppInputChange('property_message', newMessage);
    
        // Update cursor position after text insertion
        this.setState({
          cursorPosition: cursorPosition + text.length,
        }, () => {
          // Set the cursor back to the position after inserting the text
          if (this.textAreaRef.current) {
            this.textAreaRef.current?.resizableTextArea?.textArea.setSelectionRange(
              this.state.cursorPosition,
              this.state.cursorPosition
            );
          }
        });
      };

// ==================== SOCIAL MEDIA ==================== SOCIAL MEDIA ==================== SOCIAL MEDIA ==================== SOCIAL MEDIA

    handleAddSocialMedia = () => {
        const newIndex = this.state.social_media.length + 1;
        this.setState(prevState => ({
            social_media: [...prevState.social_media, { index: newIndex, label: '', link: '', icon: null }]
        }));
    };

    handleRemoveSocialMedia = (index) => {
        this.setState(prevState => ({
            social_media: prevState.social_media.filter(entry => entry.index !== index)
        }));
    };

    handleIconChange = (index, file) => {
        if (file) {
            const { status, originFileObj } = file;
            if (status === 'done' || status === 'uploading') {
                const previewUrl = URL.createObjectURL(originFileObj);
                this.setState((prevState) => ({
                    social_media: prevState.social_media.map((item, i) =>
                        i === index
                            ? { ...item, icon: originFileObj, icon_preview: previewUrl }
                            : item
                    )
                }));
            }
        } else {
            // Handle file removal
            this.setState((prevState) => ({
                social_media: prevState.social_media.map((item, i) =>
                    i === index
                        ? { ...item, icon: null, icon_preview: null }
                        : item
                )
            }));
        }
    };

    renderSocialRows = (entry, index) => (
        <Row key={index} className="wrap container pt-3 pb-2 justify-content-between" gutter={16}>
            <Col span={1}><label className="font-bold">{index+1}</label></Col>
            <Col span={8}>
                <div className='form-group'>
                    <label className='font-bold'>Label:</label>
                    <input name={`sm-label-${index}`} value={entry.label} onChange={(e) => this.handleSocialInputChange(index, 'label', e)}
                    />
                </div>
            </Col>
            <Col span={10}>
                <div className='form-group'>
                    <label className='font-bold'>Link:</label>
                    <input name={`sm-link-${index}`} value={entry.link}
                        onChange={(e) => this.handleSocialInputChange(index, 'link', e)}
                    />
                </div>
            </Col>
            <Col span={3}>
                <div className='form-group'>
                    <label className='font-bold'>Icon:</label>
                    <Upload
                        name={`sm-image-${index}`}
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={this.beforeUpload}
                        onChange={(info) => this.handleIconChange(index, info.file)}
                        onRemove={() => this.handleIconChange(index, null)}
                    >
                        {entry.icon ? (
                            <img src={entry.icon.url} alt="avatar" style={{ width: '100%' }} />
                        ) : (
                            <div className='d-flex flex-row gap-2'>
                                <PlusOutlined />
                                <div>Upload</div>
                            </div>
                        )}
                    </Upload>
                </div>
            </Col>
            <Col span={1}>
                <div className='form-group justify-content-center'>
                    <label className='font-bold'>Delete:</label>
                    <div className='d-flex flex-row gap-1'>
                        <Button onClick={() => this.handleRemoveSocialMedia(index)} type="danger" className='mt-0'><DeleteOutlined /></Button>
                    </div>
                </div>
            </Col>
        </Row>
    );

    handleSocialInputChange = (index, field, e) => {
        if (!e) { console.error('Event is undefined'); return;}
        const value = e.target.value; // Safe to access now
        this.setState(prevState => {
            const updatedEntries = [...prevState.social_media];
            updatedEntries[index][field] = value;
            return { social_media: updatedEntries };
        });
        console.log(value);
    }

// ==================== SOCIAL MEDIA ==================== SOCIAL MEDIA ==================== SOCIAL MEDIA ==================== SOCIAL MEDIA

    handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'featuredvideo') { this.handleFeaturedVideo(e);}
        this.setState({
          [name]: value,
        });
      };

    removeMenuItem = (index) => {
        this.setState((prevState) => {
            const updatedMenu = [...prevState.hamburgermenu];
            updatedMenu.splice(index, 1); // Remove the item at the specified index
            return { hamburgermenu: updatedMenu };
        });
    };

    removeTopMenuItem = (index) => {
        this.setState((prevState) => {
            const updatedMenu = [...prevState.topmenu];
            updatedMenu.splice(index, 1); // Remove the item at the specified index
            return { topmenu: updatedMenu };
        });
    };
    

    // *********************************//
    // ********SAVE FOOTER ONLY*********//
    // *********************************//
    
    handleSaveFooter = () => {
        this.setState({ saving: true });
        const data = new FormData();
        const footerFields = ["copyright", "first_col_title", "second_col_title","third_col_title", "fourth_col_title", "fifth_col_title","footer_logo_alt"];

        footerFields.forEach((field) => {
            const value = this.state[field] !== undefined ? this.state[field] : null;
            data.append(field, value);
        });

        if (this.state.footer_logo) { data.append("logo", this.state.footer_logo); } else { data.append("logo", null); }

        const arrayFields = {
            app_store:Array.isArray(this.state.app_store) ? this.state.app_store : [],
            google_play:Array.isArray(this.state.google_play) ? this.state.google_play : [],
            bottom_footer:Array.isArray(this.state.bottom_footer) ? this.state.bottom_footer : [],
            first_col:Array.isArray(this.state.first_col) ? this.state.first_col : [],
            second_col:Array.isArray(this.state.second_col) ? this.state.second_col : [],
            third_col:Array.isArray(this.state.third_col) ? this.state.third_col : [],
            fourth_col:Array.isArray(this.state.fourth_col) ? this.state.fourth_col : [],
            fifth_col:Array.isArray(this.state.fifth_col) ? this.state.fifth_col : [],
            footer_menu:Array.isArray(this.state.footer_menu) ? this.state.footer_menu : [],
            social_media:Array.isArray(this.state.social_media) ? this.state.social_media : [],
        };

        console.log(arrayFields);
        Object.keys(arrayFields).forEach((field) => {
            arrayFields[field].forEach((item, index) => {
                if (item && typeof item === 'object') {
                    Object.keys(item).forEach((key) => {
                        data.append(`${field}[${index}][${key}]`, item[key]);
                    });
                }
            });
        });

        API.post("/save_footer", data, {headers: { 'Content-Type': 'multipart/form-data' }})
        .then((response) => {
            if (response.data.success) {        console.log(response.data)
                toast.success("Footer Successfully Updated");
                // window.location.reload();
            } else {
                toast.warning("Something went wrong");
            }
        })
        .catch((error) => {
            toast.error("Error saving footer data");
            console.error('Save footer error:', error);
        })
        .finally(() => {
            this.setState({ saving: false }); // End loading
        });
    }


    // *********************************//
    // ********SAVE HEADER ONLY*********//
    // *********************************//
    
    handleSaveHeader = () => {
        this.setState({ saving: true });
    
        // Create FormData to handle both files and other fields
        const data = new FormData();
        
        data.append("email", this.state.email);
        data.append("logo_alt", this.state.logo_alt);

        // Append array fields with indexed names
        const arrayFields = {
            hamburgermenu: Array.isArray(this.state.hamburgermenu) ? this.state.hamburgermenu : [],
            topmenu: Array.isArray(this.state.topmenu) ? this.state.topmenu : [],
            whatsapp: Array.isArray(this.state.whatsapp) ? this.state.whatsapp : []
        };

        Object.keys(arrayFields).forEach((field) => {
            arrayFields[field].forEach((item, index) => {
                if (item && typeof item === 'object') {
                    Object.keys(item).forEach((key) => {
                        data.append(`${field}[${index}][${key}]`, item[key]);
                    });
                }
            });
        });

        // Append file fields (logo, favicon)
        if (this.state.logo) {
            data.append("logo", this.state.logo);
        } else {
            data.append("logo", null);
        }
    
        if (this.state.favicon) {
            data.append("favicon", this.state.favicon);
        } else {
            data.append("favicon", null);
        }

        // for (let [key, value] of data.entries()) {
        //         console.log(`${key}:`, value);
        // }
    
        API.post("/save_header", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            if (response.data.success) {
                toast.success("Header Successfully Updated");
                // window.location.reload();
            } else {
                toast.warning("Something went wrong");
            }
        })
        .catch((error) => {
            toast.error("Error saving header data");
            console.error('Save header error:', error);
        })
        .finally(() => {
            this.setState({ saving: false }); // End loading
        });
    }
    

    // ******************************//
    // ********SAVE ALL DATA*********//
    // ******************************//

    handleSaveAll = () => {
        this.setState({ saving: true });
        const fieldsToSave = [
            "desktop_banner_alt","mobile_banner_alt",
            "search_title", "search_title_mobile", "search_text", "search_text_mobile", 
            "title_1", "title_2", "title_3", "title_4", "title_5", 
            "featured_title","featured_title_mobile" , "featured_desc", "featured_desc_mobile",
            "featured_sale_title", "featured_sale_title_mobile", "featured_sale_desc", "featured_sale_desc_mobile",
            "featured_listing_sale_1", "featured_listing_sale_2", "featured_listing_sale_3", "featured_listing_sale_4", "featured_listing_sale_5", 
            "featured_video", "video_title","video_title_mobile", "video_desc", "video_desc_mobile", "button_text", "button_url", 
            "featured_rent_title", "featured_rent_desc","featured_rent_desc_mobile", 
            "featured_listing_rent_1", "featured_listing_rent_2", "featured_listing_rent_3", "featured_listing_rent_4", "featured_listing_rent_5",
            "offplan_title", "offplan_title_mobile", "offplan_desc", "offplan_desc_mobile",
            "offplan_listing_1", "offplan_listing_2", "offplan_listing_3", "offplan_listing_4", "offplan_listing_5", 
            "blog_title","blog_title_mobile", "blog_description","blog_description_mobile", 
            "blog_1", "blog_2", "blog_3", "blog_4", "blog_5",  "meta_title", "meta_description",
        ];
    
        const data = new FormData();
    
        fieldsToSave.forEach((field) => {
            const value = this.state[field] !== undefined ? this.state[field] : null;
            data.append(field, value);
        });
    
        if (this.state.desktop_banner !== undefined) {
            data.append("desktop_banner", this.state.desktop_banner);
        } else {
            data.append("desktop_banner", null);
        }
    
        if (this.state.mobile_banner !== undefined) {
            data.append("mobile_banner", this.state.mobile_banner);
        } else {
            data.append("mobile_banner", null);
        }
        if (this.state.mobile_banner !== undefined) {
            data.append("meta_image", this.state.meta_image);
        } else {
            data.append("meta_image", null);
        }
    
        // Save data (implement the saving logic here)
        // Log all FormData entries
        // for (let [key, value] of data.entries()) {
        //     console.log(`${key}:`, value);
        // }
        API.post("/save_homepage", data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            if (response.data.success) {
                toast.success("Homepage Successfully Updated");
                window.location.reload();
            } else {
                toast.warning("Something went wrong");
            }
        })
        .catch((error) => {
            toast.error("Error saving homepage data");
            console.error('Save homepage error:', error);
        })
        .finally(() => {
            this.setState({ saving: false }); // End loading
        });
    }
    
    render() {
        const userdata = store.getState((state) => state);
        let authenticated = userdata.auth.loggedIn;
        let role = userdata.auth.user.role;
        let user = userdata.auth.user;
        let role_name = userdata.auth.user.role_name;
        let user_id = user.id

        const { loading, desktop_banner, mobile_banner } = this.state;
        const uploadButton = ( <div> {loading ? <LoadingOutlined /> : <PlusOutlined />} <div style={{ marginTop: 8, }} > Upload </div> </div>);
        const titles = ['First', 'Second', 'Third', 'Fourth', 'Fifth'];

        const getVideoId = (value) => {
            // Handle cases where value might be undefined or not an event object
            const url = value?.target?.value || (typeof value === 'string' ? value : null);
        
            if (url) {
                const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^"&?\/\s]{11})/;
                const yt_id = url.match(regex);
                return yt_id?.[1] ?? null;
            }
        
            return null;
        };
        

          const textareaStyle = {'..ant-input-textarea-affix-wrapper' : {minHeight:160} }
          
        return (
        <Card className='websettingwrap' title={
            <Space className='headercard d-flex flex-row flex-m-column justify-content-between align-items-center'><Space className='d-flex align-items-center'><DesktopOutlined style={{fontSize:21}} /><Title style={{fontSize:21}} className='font-bold mb-0'>Website Settings</Title></Space>
             {this.state.header || this.state.whatsappview ? 
                <Button type="primary" className='mt-0 savebtn' onClick={this.handleSaveHeader}>{this.state.saving? <><LoadingOutlined style={{marginRight:'5px'}}/>Saving...</> : <>Save header changes</>}</Button>
             : this.state.footer || this.state.social ? 
                <Button type="primary" className='mt-0 savebtn' onClick={this.handleSaveFooter}>{this.state.saving? <><LoadingOutlined style={{marginRight:'5px'}}/>Saving...</> : <>Save footer changes</>}</Button>
             :
                <Button type="primary" className='mt-0 savebtn' onClick={this.handleSaveAll}>{this.state.saving? <><LoadingOutlined style={{marginRight:'5px'}}/>Saving...</> : <>Save all changes</>}</Button>
             }
            </Space>} 
            actions={[this.state.header || this.state.whatsappview ? 
                <Button type="primary" className='mt-0 savebtn' onClick={this.handleSaveHeader}>{this.state.saving? <><LoadingOutlined style={{marginRight:'5px'}}/>Saving...</> : <>Save header changes</>}</Button>
             : this.state.footer || this.state.social ? 
                <Button type="primary" className='mt-0 savebtn' onClick={this.handleSaveFooter}>{this.state.saving? <><LoadingOutlined style={{marginRight:'5px'}}/>Saving...</> : <>Save footer changes</>}</Button>
             :
                <Button type="primary" className='mt-0 savebtn' onClick={this.handleSaveAll}>{this.state.saving? <><LoadingOutlined style={{marginRight:'5px'}}/>Saving...</> : <>Save all changes</>}</Button>
             ]}
            >
            <Layout className="d-flex flex-row gap-3 justify-content-between relative" style={{background:'transparent'}}>
            <ToastContainer />
                <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.state.collapsed} style={{background:'transparent'}} trigger={null} width={300} className='websider mt-3'>
                        <Menu defaultSelectedKeys={['1']} mode="inline" items={items} defaultOpenKeys={['1']} onClick={this.handleMenuClick}/>
                </Sider>
                {this.state.homepage && <Content style={{background:'none'}}>
                    <Row><Col span={24}><Title level={1} className='title mb-0 mt-3 pl-3'>Homepage</Title></Col></Row>
                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={21} id="2">
                        <Col span={14} mt={3}  className="gutter-row">
                            <Card title={<Space className='d-flex align-items-center'><DesktopOutlined /><Title level={5} className='font-bold mb-0'>Main Banner</Title></Space>} style={{height:'100%'}}>
                                <ImgCrop aspect={1864 / 1079}  quality={7}  modalTitle="Crop your image" modalOk="Crop" modalCancel="Cancel" style={{minHeight:'17.5rem'}}>
                                    <Upload name="desktop_banner" listType="picture-card" className="desktop-uploader" showUploadList={false} beforeUpload={beforeUpload} onChange={this.handleDesktopChange} onRemove={this.handleRemove}>
                                    {this.state.desktop_banner_change ?
                                        this.state.desktoptemp_banner ? (<img src={this.state.desktoptemp_banner} alt="change banner" style={{ width: '100%',}} />) : (uploadButton)
                                        :desktop_banner ? (<img src={imageUrl + this.state.desktop_banner} alt="avatar" style={{ width: '100%',}} />) : (uploadButton)}
                                    </Upload>
                                </ImgCrop>
                                <div className='form-group mt-2 mb-0'>
                                    <label className='font-bold'>Image Alt Tag:</label>
                                    <input name='desktop_banner_alt' onChange={this.handleInputChange} value={this.state.desktop_banner_alt}/>
                                </div>
                            </Card>
                        </Col>
                        <Col span={10} mt={3}  className="gutter-row">
                            <Card title={<Space className='d-flex align-items-center'><MobileOutlined /><Title level={5} className='font-bold mb-0'>Mobile Main Banner</Title></Space>} style={{height:'100%'}}>
                                <ImgCrop aspect={430 / 500}  quality={7}  modalTitle="Crop your image" modalOk="Crop" modalCancel="Cancel">
                                <Upload name="mobile_banner" listType="picture-card" className="mobile-uploader" showUploadList={false} beforeUpload={beforeUpload} onChange={this.handleMobileChange} onRemove={this.handleRemove}>
                                {this.state.mobile_banner_change ?
                                        this.state.mobiletemp_banner ? (<img src={this.state.mobiletemp_banner} alt="change banner" style={{ width: 'auto',height:'278px',objectFit:'cover'}} />) : (uploadButton)
                                    : mobile_banner ? (<img src={imageUrl + this.state.mobile_banner} alt="avatar" style={{ width: 'auto', height:'278px', objectFit:'cover'}} />) : (uploadButton)}
                                </Upload>
                                </ImgCrop>
                                <div className='form-group mt-2 mb-0'>
                                    <label className='font-bold'>Image Alt Tag:</label>
                                    <input name='mobile_banner_alt' value={this.state.mobile_banner_alt} onChange={this.handleInputChange} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={21} id="2">
                        <Col span={24} mt={3}>
                            <Card title={<Space className='d-flex align-items-center'><TagOutlined /><Title level={5} className='font-bold mb-0'>Banner Label</Title></Space>}>
                                <Row gutter={21}>
                                    <Col span={12}>
                                        <div className='form-group mt-2 mb-0'>
                                            <label className='font-bold'>Link:</label>
                                            <input placeholder="" name='banner_link' value={this.state.banner_link??""} onChange={this.handleInputChange} />
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className='form-group mt-2 mb-0'>
                                            <label className='font-bold'>Label:</label>
                                            <input placeholder="" name='banner_label' value={this.state.banner_label??""} onChange={this.handleInputChange} />
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={21} id="3">
                        <Col span={24} mt={3}>
                            <Card title={<Space className='d-flex align-items-center'><SearchOutlined /><Title level={5} className='font-bold mb-0'>Search Section</Title></Space>}>
                                <Row gutter={21}>
                                    <Col span={24} className="gutter-row">
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Search Title:</label>
                                                    <input placeholder="" name='search_title' value={this.state.search_title??""} onChange={this.handleInputChange} />
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div className='form-group mb-0'>
                                                    <label className='font-bold align-items-center'><SearchOutlined style={{fontSize:14}} className='mr-2' />Searchbox text:</label>
                                                    <input placeholder="" name='search_text' value={this.state.search_text??''} onChange={this.handleInputChange} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={24} className="gutter-row">
                                        <div className='form-group animatedtitle'>
                                            <label className='font-bold'>Animated Titles (5):</label>
                                            <div className='d-flex flex-row gap-2 justify-content-betwen' style={{flexWrap:'wrap'}}>
                                                <input placeholder="" name='title_1' value={this.state.title_1??''} onChange={this.handleInputChange} />
                                                <input placeholder="" name='title_2' value={this.state.title_2??''} onChange={this.handleInputChange} />
                                                <input placeholder="" name='title_3' value={this.state.title_3??''} onChange={this.handleInputChange}/>
                                                <input placeholder="" name='title_4' value={this.state.title_4??''} onChange={this.handleInputChange}/>
                                                <input placeholder="" name='title_5' value={this.state.title_5??''} onChange={this.handleInputChange}/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                
                                <Row className='border-t pt-4 mt-3'>
                                    <Col span={24} className='d-flex flex-col '>
                                        <Collapse ghost className='mobwrap'>
                                            <Panel header={<Col span={24} className='d-flex flex-col p-0'>
                                                    <label className='font-bold '>Mobile</label>
                                                    <label className='font-13'>If empty, we'll use the above details.</label>
                                                </Col>} key="1">
                                                <Row gutter={21}>
                                                    <Col span={12}>
                                                        <div className='form-group'>
                                                            <label className='font-bold'>Search Title:</label>
                                                            <input placeholder="" name='search_title_mobile' value={this.state.search_title_mobile??''} onChange={this.handleInputChange} />
                                                        </div>
                                                    </Col>
                                                    <Col span={12}>
                                                        <div className='form-group mb-0'>
                                                            <label className='font-bold align-items-center'><SearchOutlined style={{fontSize:14}} className='mr-2' />Searchbox text:</label>
                                                            <input placeholder="" name='search_text_mobile' value={this.state.search_text_mobile??''} onChange={this.handleInputChange} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16} id="4" >
                        <Col span={24} mt={3} className="gutter-row">
                            <Card title={<Space className='d-flex align-items-center'><AlignLeftOutlined /><Title level={5} className='font-bold mb-0'>Featured Content Section</Title></Space>}>
                                <div className='form-group'>
                                    <label className='font-bold'>Featured Title:</label>
                                    <input placeholder="" name='featured_title' value={this.state.featured_title??''} onChange={this.handleInputChange}/>
                                </div>
                                <div className='form-group'>
                                    <label className='font-bold'>Featured Description:</label>
                                    <TextArea placeholder="" row={10} style={{minHeight:'7rem'}} name='featured_desc' value={this.state.featured_desc??''} onChange={this.handleInputChange}/>
                                </div>
                                <Row className='border-t pt-4 mt-3'>
                                    <Col span={24} className='d-flex flex-col '>
                                        <Collapse ghost className='mobwrap'>
                                            <Panel header={<Col span={24} className='d-flex flex-col p-0'>
                                                    <label className='font-bold '>Mobile</label>
                                                    <label className='font-13'>If empty, we'll use the above details.</label>
                                                </Col>} key="1">
                                                <Row gutter={21}>
                                                    <Col span={24}>
                                                        <div className='form-group'>
                                                            <label className='font-bold'>Featured Title:</label>
                                                            <input placeholder="" name='featured_title_mobile' value={this.state.featured_title_mobile || ''} onChange={this.handleInputChange} />
                                                        </div>
                                                    </Col>
                                                    <Col span={24}>
                                                        <div className='form-group mb-0'>
                                                            <label className='font-bold align-items-center'><SearchOutlined style={{fontSize:14}} className='mr-2' />Featured Description:</label>
                                                            <TextArea placeholder="" row={10} style={{minHeight:'7rem'}} name='featured_desc_mobile' value={this.state.featured_desc_mobile ?? ''} onChange={this.handleInputChange} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16} id="5">
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title={<Space className='d-flex align-items-center'><TrophyOutlined /><Title level={5} className='font-bold mb-0'>Featured Homes For Sale Section</Title></Space>}>
                                <div className='form-group'>
                                    <label className='font-bold'>Title:</label>
                                    <input placeholder="" name='featured_sale_title' value={this.state.featured_sale_title??''} onChange={this.handleInputChange} />
                                </div>
                                <div className='form-group'>
                                    <label className='font-bold'>Description:</label>
                                    <TextArea placeholder="" row={10} name='featured_sale_desc' value={this.state.featured_sale_desc??''} onChange={this.handleInputChange} />
                                </div>
                                <Row className='justify-content-between' gutter={16}>
                                    <Col span={12}>
                                        <Card title={'Featured One'} style={{height:'100%'}}>
                                            <Select style={{ width: "100%",}} name="featured_listing_sale_1" value={this.state.featured_listing_sale_1} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={(e) =>this.handleRefSelection(e, 'sale', 1)} onSearch={(e) =>this.handleSearchRefNo(e, 'sale', 'sale_ref_nums')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.sale_ref_nums} />
                                            {/* <img src={this.state.imageUrl} alt="avatar" className='mt-4' style={{ width: '100%',}} /> */}
                                            {this.state.sale_ref_nums_first && this.renderRefNums(this.state.sale_ref_nums_first)}
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Card title={'Featured Two'} style={{height:'100%'}}>
                                                    <Select style={{ width: "100%",}} name="featured_listing_sale_2" value={this.state.featured_listing_sale_2} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={(e) =>this.handleRefSelection(e, 'sale', 2)} onSearch={(e) =>this.handleSearchRefNo(e, 'sale', 'sale_ref_nums_two')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.sale_ref_nums} />
                                                    {this.state.sale_ref_nums_two && this.renderRefNums(this.state.sale_ref_nums_two)}
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card title={'Featured Three'} style={{height:'100%'}}>
                                                    <Select style={{ width: "100%",}} name="featured_listing_sale_3" value={this.state.featured_listing_sale_3} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" 
                                                    onChange={(e) =>this.handleRefSelection(e, 'sale', 3)} onSearch={(e) =>this.handleSearchRefNo(e, 'sale', 'sale_ref_nums_three')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.sale_ref_nums} />
                                                    {this.state.sale_ref_nums_three && this.renderRefNums(this.state.sale_ref_nums_three)}
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} style={{marginTop:'1rem'}}>
                                            <Col span={12}>
                                                <Card title={'Featured Four'} style={{height:'100%'}}>
                                                    <Select style={{ width: "100%",}} name="featured_listing_sale_4" value={this.state.featured_listing_sale_4} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children"onChange={(e) =>this.handleRefSelection(e, 'sale', 4)} onSearch={(e) =>this.handleSearchRefNo(e, 'sale', 'sale_ref_nums_four')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.sale_ref_nums} />
                                                    {this.renderRefNums(this.state.sale_ref_nums_four)}
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card title={'Featured Five'} style={{height:'100%'}}>
                                                    <Select style={{ width: "100%",}} name="featured_listing_sale_5"  value={this.state.featured_listing_sale_5} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={(e) =>this.handleRefSelection(e, 'sale', 5)} onSearch={(e) =>this.handleSearchRefNo(e, 'sale', 'sale_ref_nums_five')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.sale_ref_nums} />
                                                    {this.renderRefNums(this.state.sale_ref_nums_five)}
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='border-t pt-4 mt-3'>
                                    <Col span={24} className='d-flex flex-col '>
                                        <Collapse ghost className='mobwrap'>
                                            <Panel header={<Col span={24} className='d-flex flex-col p-0'>
                                                    <label className='font-bold '>Mobile</label>
                                                    <label className='font-13'>If empty, we'll use the above details.</label>
                                                </Col>} key="1">
                                                <Row gutter={21}>
                                                    <Col span={24}>
                                                        <div className='form-group'>
                                                            <label className='font-bold'>Title:</label>
                                                            <input placeholder="" name='featured_sale_title_mobile' value={this.state.featured_sale_title_mobile} onChange={this.handleInputChange} />
                                                        </div>
                                                    </Col>
                                                    <Col span={24}>
                                                        <div className='form-group mb-0'>
                                                            <label className='font-bold align-items-center'>Description:</label>
                                                            <TextArea placeholder="" row={10} name='featured_sale_desc_mobile' value={this.state.featured_sale_desc_mobile} onChange={this.handleInputChange} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="wrap container pt-3 pb-2 justify-content-between" id="6">
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title={<Space className='d-flex align-items-center'><YoutubeOutlined /><Title level={5} className='font-bold mb-0'>Featured Video Spotlight</Title></Space>}>
                                
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <img src={'https://i.ytimg.com/vi/' + (this.state.featured_yt_img ?? getVideoId(this.state.featured_video) ) + '/hqdefault.jpg' }  style={{width:'100%', borderRadius:4}}/>
                                    </Col>
                                    <Col span={16}>
                                        <div className='form-group'>
                                            <label className='font-bold'>Youtube Link:</label>
                                            <input name='featured_video' value={this.state.featured_video??''} onChange={this.handleInputChange}/>
                                        </div>
                                        <Divider />
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Title:</label>
                                                    <input placeholder="" name='video_title' value={this.state.video_title??''} onChange={this.handleInputChange} />
                                                </div>
                                            </Col>
                                            <Col span={24} >
                                                <div className='form-group'>
                                                    <label className='font-bold'>Description:</label>
                                                    <TextArea placeholder="" row={10} style={{minHeight:'7rem'}} name='video_desc' value={this.state.video_desc??''} onChange={this.handleInputChange} />
                                                </div>
                                            </Col>
                                            <Col span={24} >
                                                <Row gutter={16} >
                                                    <Col span={8} style={{marginTop:'1rem'}}>
                                                        <div className='form-group'>
                                                            <label className='font-bold'>Button Text:</label>
                                                            <input placeholder="" name='button_text' value={this.state.button_text??''} onChange={this.handleInputChange} />
                                                        </div>
                                                    </Col>
                                                    <Col span={16} style={{marginTop:'1rem'}}>
                                                        <div className='form-group'>
                                                            <label className='font-bold'>Button Link:</label>
                                                            <input placeholder="" name='button_url' value={this.state.button_url??''} onChange={this.handleInputChange}/>
                                                            <label style={{color:'#151515', fontSize:12}}>You can enter the Ref No</label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='border-t pt-4 mt-3'>
                                    <Col span={24} className='d-flex flex-col '>
                                        <Collapse ghost className='mobwrap'>
                                            <Panel header={<Col span={24} className='d-flex flex-col p-0'>
                                                    <label className='font-bold '>Mobile</label>
                                                    <label className='font-13'>If empty, we'll use the above details.</label>
                                                </Col>} key="1">
                                                <Row gutter={21}>
                                                    <Col span={24}>
                                                        <div className='form-group'>
                                                            <label className='font-bold'>Title:</label>
                                                            <input placeholder="" name='video_title_mobile' value={this.state.mobile_video_title} onChange={this.handleInputChange}/>
                                                        </div>
                                                    </Col>
                                                    <Col span={24}>
                                                        <div className='form-group mb-0'>
                                                            <label className='font-bold align-items-center'>Description:</label>
                                                            <TextArea placeholder="" row={10} style={{minHeight:'7rem'}} name='video_desc_mobile' value={this.state.video_desc_mobile??null} onChange={this.handleInputChange} />
                                                        </div>
                                                    </Col>
                                                    {/* <Col span={8}>
                                                        <div className='form-group'>
                                                            <label className='font-bold'>Button Text:</label>
                                                            <input placeholder="" name='mobile_video_button_text' value={this.state.mobile_video_button_text??''} onChange={this.handleInputChange} />
                                                        </div>
                                                    </Col>
                                                    <Col span={16}>
                                                        <div className='form-group'>
                                                            <label className='font-bold'>Button Link:</label>
                                                            <input placeholder="" name='mobile_video_button_link' value={this.state.mobile_video_button_link??''} onChange={this.handleInputChange} />
                                                        </div>
                                                    </Col> */}
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                </Row>
                                
                            </Card>
                        </Col>
                    </Row>

                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16} id="7">
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title={<Space className='d-flex align-items-center'><SketchOutlined /><Title level={5} className='font-bold mb-0'>Featured Homes For Rent Section</Title></Space>}>
                                <div className='form-group'>
                                    <label className='font-bold'>Title:</label>
                                    <input placeholder="" name='featured_rent_title' value={this.state.featured_rent_title??''} onChange={this.handleInputChange}/>
                                </div>
                                <div className='form-group'>
                                    <label className='font-bold'>Description:</label>
                                    <TextArea placeholder="" row={10} name='featured_rent_desc' value={this.state.featured_rent_desc??''} onChange={this.handleInputChange} />
                                </div>
                                <Row className='justify-content-between' gutter={16}>
                                    <Col span={12}>
                                        <Card title={'Featured One'} style={{height:'100%'}}>
                                            <Select style={{ width: "100%",}} name="featured_listing_rent_1" value={this.state.featured_listing_rent_1} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={(e) =>this.handleRefSelection(e, 'rent', 1)} onSearch={(e) =>this.handleSearchRefNo(e, 'rent', 'rent_ref_nums_first')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.rent_ref_nums} />
                                            {/* <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%',}}  className='mt-4' /> */}
                                            {this.renderRefNums(this.state.rent_ref_nums_first)}
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Card title={'Featured Two'} style={{height:'100%'}}>
                                                    <Select style={{ width: "100%",}} name="featured_listing_rent_2" value={this.state.featured_listing_rent_2} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={(e) =>this.handleRefSelection(e, 'rent', 2)} onSearch={(e) =>this.handleSearchRefNo(e, 'rent', 'rent_ref_nums_two')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.rent_ref_nums} />
                                                    {this.renderRefNums(this.state.rent_ref_nums_two)}
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card title={'Featured Three'} style={{height:'100%'}}>
                                                    <Select style={{ width: "100%",}} name="featured_listing_rent_3" value={this.state.featured_listing_rent_3} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={(e) =>this.handleRefSelection(e, 'rent', 3)} onSearch={(e) =>this.handleSearchRefNo(e, 'rent', 'rent_ref_nums_three')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.rent_ref_nums} />
                                                    {this.renderRefNums(this.state.rent_ref_nums_three)}
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} style={{marginTop:'1rem'}}>
                                            <Col span={12}>
                                                <Card title={'Featured Four'} style={{height:'100%'}}>
                                                    <Select style={{ width: "100%",}} name="featured_listing_rent_4" value={this.state.featured_listing_rent_4} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={(e) =>this.handleRefSelection(e, 'rent', 4)} onSearch={(e) =>this.handleSearchRefNo(e, 'rent', 'rent_ref_nums_four')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.rent_ref_nums} />
                                                    {this.renderRefNums(this.state.rent_ref_nums_four)}
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card title={'Featured Five'} style={{height:'100%'}}>
                                                    <Select style={{ width: "100%",}} name="featured_listing_rent_5" value={this.state.featured_listing_rent_5} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={(e) =>this.handleRefSelection(e, 'rent', 5)} onSearch={(e) =>this.handleSearchRefNo(e, 'rent', 'rent_ref_nums_five')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.rent_ref_nums} />
                                                    {this.renderRefNums(this.state.rent_ref_nums_five)}
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='border-t pt-4 mt-3'>
                                    <Col span={24} className='d-flex flex-col '>
                                        <Collapse ghost className='mobwrap'>
                                            <Panel header={<Col span={24} className='d-flex flex-col p-0'>
                                                    <label className='font-bold '>Mobile</label>
                                                    <label className='font-13'>If empty, we'll use the above details.</label>
                                                </Col>} key="1">
                                                <Row gutter={21}>
                                                    <Col span={24}>
                                                        <div className='form-group'>
                                                            <label className='font-bold'>Title:</label>
                                                            <input placeholder="" name='featured_rent_title_mobile' onChange={this.handleInputChange} value={this.state.featured_rent_title_mobile} />
                                                        </div>
                                                    </Col>
                                                    <Col span={24}>
                                                        <div className='form-group mb-0'>
                                                            <label className='font-bold align-items-center'>Description:</label>
                                                            <TextArea placeholder="" row={10} name='featured_rent_desc_mobile' onChange={this.handleInputChange} value={this.state.featured_rent_desc_mobile} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16} id="8">
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title={<Space className='d-flex align-items-center'><RubyOutlined /><Title level={5} className='font-bold mb-0'>OffPlan Projects Section</Title></Space>}>
                                <div className='form-group'>
                                    <label className='font-bold'>Title:</label>
                                    <input placeholder="" name='offplan_title' value={this.state.offplan_title} onChange={this.handleInputChange} />
                                </div>
                                <div className='form-group'>
                                    <label className='font-bold'>Description:</label>
                                    <TextArea placeholder="" row={10} name='offplan_desc' value={this.state.offplan_desc} onChange={this.handleInputChange}/>
                                </div>
                                <Row className='justify-content-between' gutter={16}>
                                    <Col span={12}>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Card title={'Featured One'} style={{height:'100%'}}>
                                                    <Select style={{ width: "100%",}} name="offplan_listing_1" value={this.state.offplan_listing_1} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={(e) =>this.handleRefSelection(e, 'offplan', 1)} onSearch={(e) =>this.handleSearchRefNo(e, 'offplan', 'offplan_ref_nums_first')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.offplan_ref_nums} />
                                                    {this.state.offplan_ref_nums_first && this.renderRefNums(this.state.offplan_ref_nums_first)}
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card title={'Featured Two'} style={{height:'100%'}}>
                                                    <Select style={{ width: "100%",}} name="offplan_listing_2" value={this.state.offplan_listing_2} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={(e) =>this.handleRefSelection(e, 'offplan', 2)} onSearch={(e) =>this.handleSearchRefNo(e, 'offplan', 'offplan_ref_nums_two')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.offplan_ref_nums} />
                                                    {this.state.offplan_ref_nums_two && this.renderRefNums(this.state.offplan_ref_nums_two)}
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} style={{marginTop:'1rem'}}>
                                            <Col span={12}>
                                                <Card title={'Featured Four'} style={{height:'100%'}}>
                                                    <Select style={{ width: "100%",}} name="offplan_listing_4" value={this.state.offplan_listing_4} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={(e) =>this.handleRefSelection(e, 'offplan', 4)} onSearch={(e) =>this.handleSearchRefNo(e, 'offplan', 'offplan_ref_nums_four')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.offplan_ref_nums} />
                                                    {this.state.offplan_ref_nums_four && this.renderRefNums(this.state.offplan_ref_nums_four)}
                                                </Card>
                                            </Col>
                                            <Col span={12}>
                                                <Card title={'Featured Five'} style={{height:'100%'}}>
                                                    <Select style={{ width: "100%",}} name="offplan_listing_5" value={this.state.offplan_listing_5} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={(e) =>this.handleRefSelection(e, 'offplan', 5)} onSearch={(e) =>this.handleSearchRefNo(e, 'offplan', 'offplan_ref_nums_five')} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.offplan_ref_nums} />
                                                    {this.state.offplan_ref_nums_five && this.renderRefNums(this.state.offplan_ref_nums_five)}
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Card title={'Featured Three'} style={{height:'100%'}}>
                                            <Select style={{ width: "100%",}} name="offplan_listing_3" value={this.state.offplan_listing_3} showSearch allowClear  placeholder="Start typing LP" maxTagCount='responsive' optionFilterProp="children" onChange={(e) => this.handleRefSelection(e, 'offplan', 3)} onSearch={(e) =>this.handleSearchRefNo(e, 'offplan', 'offplan_ref_nums_three')}filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.offplan_ref_nums} />
                                            {this.state.offplan_ref_nums_three && this.renderRefNums(this.state.offplan_ref_nums_three)}
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='border-t pt-4 mt-3'>
                                    <Col span={24} className='d-flex flex-col '>
                                        <Collapse ghost className='mobwrap'>
                                            <Panel header={<Col span={24} className='d-flex flex-col p-0'>
                                                    <label className='font-bold '>Mobile</label>
                                                    <label className='font-13'>If empty, we'll use the above details.</label>
                                                </Col>} key="1">
                                                <Row gutter={21}>
                                                    <Col span={24}>
                                                        <div className='form-group'>
                                                            <label className='font-bold'>Title:</label>
                                                            <input placeholder="" name='offplan_title_mobile' value={this.state.offplan_title_mobile} onChange={this.handleInputChange} />
                                                        </div>
                                                    </Col>
                                                    <Col span={24}>
                                                        <div className='form-group mb-0'>
                                                            <label className='font-bold align-items-center'>Description:</label>
                                                            <TextArea placeholder="" row={10} name='offplan_desc_mobile' value={this.state.offplan_desc_mobile} onChange={this.handleInputChange}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="wrap container pt-3 pb-2 justify-content-between blogsection" gutter={16} id="9">
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title={<Space className='d-flex align-items-center'><ReadOutlined /><Title level={5} className='font-bold mb-0'>Blog Section</Title></Space>}>
                                <div className='form-group'>
                                    <label className='font-bold'>Title:</label>
                                    <input placeholder="" name='blog_title' value={this.state.blog_title??''} onChange={this.handleInputChange} />
                                </div>
                                <div className='form-group'>
                                    <label className='font-bold'>Description:</label>
                                    <TextArea placeholder="" row={10} name='blog_description' value={this.state.blog_description??''} onChange={this.handleInputChange} />
                                </div>
                                <div className='form-group border-b'>
                                    <label className='font-14 italic' ><InfoCircleOutlined style={{marginRight:4}}/>Search blogs by title or url</label>
                                </div>
                                <Row className='justify-content-between' gutter={16}>
                                    <Col span={12}>
                                        <Card title={'Blog One'} style={{height:'100%'}}>
                                            <Select style={{ width: "100%",}} name="blog_1" className='mb-2' value={this.state.blog_1} showSearch allowClear  placeholder="Start typing the name of the blog" maxTagCount='responsive' optionFilterProp="children" onChange={(value, option) => this.handleSearchBlog(value, option, '1')} onSearch={(e) => this.onSearchBlog(e)} 
                                            filterOption={(input, option) => { const label = option?.label?.toLowerCase() ?? ''; const value = option?.value?.toLowerCase() ?? ''; const searchTerm = input.toLowerCase(); return label.includes(searchTerm) || value.includes(searchTerm);}} options={this.state.blog_search} />
                                            {this.renderBlog(this.state.blog_1, "1")}
                                        </Card> 
                                    </Col>
                                    <Col span={12}>
                                        <Card title={'Blog Two'} style={{height:'100%'}}>
                                            <Select style={{ width: "100%",}} name="blog_2" className='mb-2' value={this.state.blog_2} showSearch allowClear  placeholder="Start typing the name of the blog" maxTagCount='responsive' optionFilterProp="children" onChange={(value, option) => this.handleSearchBlog(value, option, '2')} onSearch={this.onSearchBlog} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.blog_search} />
                                                {this.state.blog_2_image && <img src={'https://www1.luxuryproperty.com/files/large/' + this.state.blog_2_image} alt="avatar" style={{ width: '100%',}} /> }
                                                <a href={'https://www.luxuryproperty.com/blog/' + this.state.blog_2_url} target='_blank' style={{color:'#151515'}}>{this.state.blog_2_title}</a>
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card title={'Blog Three'} style={{height:'100%'}}>
                                            <Select style={{ width: "100%",}} name="blog_3" className='mb-2' value={this.state.blog_3} showSearch allowClear  placeholder="Start typing the name of the blog" maxTagCount='responsive' optionFilterProp="children" onChange={(value, option) => this.handleSearchBlog(value, option, '3')} onSearch={this.onSearchBlog} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.blog_search} />
                                            {this.state.blog_3_image && <img src={'https://www1.luxuryproperty.com/files/large/' + this.state.blog_3_image} alt="avatar" style={{ width: '100%',}} /> }
                                            <a href={'https://www.luxuryproperty.com/blog/' + this.state.blog_3_url} target='_blank' style={{color:'#151515'}}>{this.state.blog_3_title}</a>
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card title={'Blog Four'} style={{height:'100%'}}>
                                            <Select style={{ width: "100%",}} name="blog_4" className='mb-2' value={this.state.blog_4} showSearch allowClear  placeholder="Start typing the name of the blog" maxTagCount='responsive' optionFilterProp="children" onChange={(value, option) => this.handleSearchBlog(value, option, '4')} onSearch={this.onSearchBlog} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) } options={this.state.blog_search} />
                                                {this.state.blog_4_image && <img src={'https://www1.luxuryproperty.com/files/large/' + this.state.blog_4_image} alt="avatar" style={{ width: '100%',}} /> }
                                                <a href={'https://www.luxuryproperty.com/blog/' + this.state.blog_4_url} target='_blank' style={{color:'#151515'}}>{this.state.blog_4_title}</a>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='border-t pt-4 mt-3'>
                                    <Col span={24} className='d-flex flex-col '>
                                        <Collapse ghost className='mobwrap'>
                                            <Panel header={<Col span={24} className='d-flex flex-col p-0'>
                                                    <label className='font-bold '>Mobile</label>
                                                    <label className='font-13'>If empty, we'll use the above details.</label>
                                                </Col>} key="1">
                                                <Row gutter={21}>
                                                    <Col span={24}>
                                                        <div className='form-group'>
                                                            <label className='font-bold'>Title:</label>
                                                            <input placeholder="" name='blog_title_mobile' onChange={this.handleInputChange} value={this.state.blog_title_mobile??null} allowClear />
                                                        </div>
                                                    </Col>
                                                    <Col span={24}>
                                                        <div className='form-group mb-0'>
                                                            <label className='font-bold align-items-center'>Description:</label>
                                                            <TextArea placeholder="" row={10} name='blog_description_mobile' onChange={this.handleInputChange} value={this.state.blog_description_mobile} allowClear/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Content> }
                {this.state.seo && <Content style={{background:'none'}}>
                    <Row><Col span={24}><Title level={1} className='title mb-0 mt-3 pl-3'>SEO</Title></Col></Row>
                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16}>
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title="Meta Data">
                                <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16}>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={24} mt={3}  className="gutter-row">
                                                <div className='form-group'>
                                                    <label className='font-bold'>Title:</label>
                                                    <TextArea row={5} placeholder="" name='meta_title' onChange={this.handleInputChange} count={{show: true,max:60}} multiline/>
                                                </div>
                                            </Col>
                                            <Col span={24} mt={3}  className="gutter-row">
                                                <div className='form-group'>
                                                    <label className='font-bold'>Description:</label>
                                                    <TextArea placeholder="" name='meta_description' onChange={this.handleInputChange} count={{show: true,max:160}} multiline style={textareaStyle}/>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} mt={3}  className="gutter-row">
                                        <div className='form-group'>
                                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                                <label className='font-bold'>Image:</label><label style={{color:'rgba(21,21,21,0.6)'}}>Recommend 1200x628</label></div>
                                            <Card style={{height:'100%'}}>
                                                <ImgCrop aspect={1864 / 1079}  quality={7}  modalTitle="Crop your image" modalOk="Crop" modalCancel="Cancel" style={{minHeight:'17.5rem'}}>
                                                    <Upload name="meta_image" listType="picture-card" className="desktop-uploader" showUploadList={false} beforeUpload={beforeUpload} /*onChange={this.handleDesktopChange} */onRemove={this.handleRemove}>
                                                    {
                                                        this.state.meta_image ? (<img src={this.state.desktoptemp_banner} alt="change banner" style={{ width: '100%',}} />) : (uploadButton)
                                                    }
                                                    </Upload>
                                                </ImgCrop>
                                            </Card>
                                        </div>
                                    </Col>
                                    
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Content>}

                {this.state.header && <Content style={{background:'none'}}>
                <Row><Col span={24}><Title level={1} className='title mb-0 mt-3 pl-3'>Header</Title></Col></Row>
                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16}>
                        <Col span={16}>
                            <Card title={<Space className='d-flex align-items-center'><CloudUploadOutlined /><Title level={5} className='font-bold mb-0'>Logo</Title></Space>} style={{height:'100%'}} className='d-flex flex-col'>
                                <div className='d-flex gap-2 align-items-center mb-2'>
                                    <label className='font-12 align-items-center'><InfoCircleOutlined style={{marginRight:3}}/>For the best outcome, please use <span className="font-bold">SVG format</span>.</label>
                                </div>
                                    <Upload name="logo" listType="picture-card" className="desktop-uploader" showUploadList={false} beforeUpload={beforeUploadLogo} onChange={this.handleLogoChange} onRemove={this.handleRemove}>
                                    {this.state.logo_change ?
                                        this.state.logo_temp ? (<img src={this.state.logo_temp} alt="Temp Logo" style={{ width: '100%',}} />) : (uploadButton)
                                    : this.state.logo ? (<img src={imageUrl +this.state.logo} alt="Logo" style={{ width: '100%',}} />)
                                    : (uploadButton)
                                    }
                                    </Upload>
                                
                                <div className='form-group mt-2 mb-0'>
                                    <label className='font-bold'>Logo Alt Tag:</label>
                                    <input placeholder="" name='logo_alt' value={this.state.logo_alt??""} onChange={this.handleInputChange} />
                                </div>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={<Space className='d-flex align-items-center'><CloudUploadOutlined /><Title level={5} className='font-bold mb-0'>Favicon</Title></Space>} style={{height:'100%'}} className='d-flex flex-col'>
                                <div className='d-flex gap-2 align-items-center mb-2'>
                                    <label className='font-12 align-items-center font-bold'><InfoCircleOutlined style={{marginRight:3}}/>.ico format only</label>
                                </div>
                                <ImgCrop aspect={32 / 32}  quality={7}  modalTitle="Crop your image" modalOk="Crop" modalCancel="Cancel" style={{minHeight:'17.5rem'}}>
                                    <Upload name="favicon" listType="picture-card" className="desktop-uploader" showUploadList={false} beforeUpload={beforeUploadFavicon} onChange={this.handleFaviconChange} onRemove={this.handleRemove}>
                                    {this.state.favicon_change ?
                                        this.state.favicon_temp ? (<img src={this.state.logo_temp} alt="Temp Favicon" style={{ width: '100%',}} />) : (uploadButton)
                                    : this.state.favicon ? (<img src={imageUrl +this.state.favicon} alt="Favicon" style={{ width: '32px',}} />)
                                    : (uploadButton)
                                    }
                                    </Upload>
                                </ImgCrop>
                            </Card>
                        </Col>
                        
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title="Hamburger Section">
                                <Row gutter={24} style={{justifyContent:'space-between'}}> 
                                    <Col span={24} className='d-flex gap-2 align-items-center mb-3'>
                                        <label className='font-13 align-items-center'><InfoCircleOutlined style={{marginRight:3}}/>Drag each item into the order you prefer. Click arrow on the right to reveal additional configuration options.</label>
                                    </Col>
                                    <Col span={12}>
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={this.getListStyle(snapshot.isDraggingOver)}
                                                >
                                                {Array.isArray(this.state.hamburgermenu) && this.state.hamburgermenu.length > 0 ? (
                                                this.state.hamburgermenu.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={this.getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                        >
                                                        <Collapse expandIconPosition="end" ghost>
                                                            <Panel header={<><MenuOutlined style={{fontSize:12, marginRight:8}} /> {item.label}</>} key="1">
                                                                <Row gutter={16}>
                                                                    <Col span={14}>
                                                                        <div className='form-group'>
                                                                            <label className='font-bold'>Label:</label>
                                                                            <input name="label" placeholder="" value={item.label}  onChange={(e) => this.handleNewMenuChange(e, index)}  />
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <div className='form-group'>
                                                                            <label className='font-bold'>Type:</label>
                                                                            <Select name="type"
                                                                                options={this.state.link_type_options.map(option => ({ label: option.label, value: option.value }))}
                                                                                onChange={(value) => this.handleNewMenuChange({ target: { name: 'type', value }, index })} 
                                                                                defaultValue={this.state.link_type_options.find(option => option.value === (item?.type ?? 'customlink')) || { value: 'customlink', label: 'Custom Link' }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <div className='form-group'>
                                                                            <label className='font-bold'>Custom Link:</label>
                                                                            <input placeholder="" name="link" value={item.link} onChange={(e) => this.handleNewMenuChange(e, index)}  />
                                                                            <label className='font-12 align-items-center mt-1'><InfoCircleOutlined style={{marginRight:3}}/>Start with /. Remove the root base url</label>
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <Row className='d-flex align-items-center'>
                                                                            <Col span={12}>
                                                                                <div className='form-group d-flex flex-row gap-2 mb-0'>
                                                                                <Checkbox name="mobile" checked={item.mobile === 'yes'} onChange={(e) => this.handleNewMenuChange({ target: { name: 'mobile', checked: e.target.checked }, index })} />
                                                                                <label htmlFor="mobile" className='font-bold'>Show on mobile:</label>
                                                                                </div>
                                                                            </Col>
                                                                            <Col span={12}>
                                                                                <Button className='form-group d-flex flex-row gap-2 ml-auto my-0 mr-0 p-0' type='text' onClick={() => this.removeMenuItem(index)} >
                                                                                    <DeleteOutlined /> Remove
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Panel>
                                                        </Collapse>
                                                        </div>
                                                    )}
                                                    </Draggable>
                                                ))):''}
                                                {provided.placeholder}
                                                </div>
                                            )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Col>
                                    <Col span={11}>
                                        <Row gutter={16} className='border p-3'>
                                            <Col span={24}><label className='font-bold mb-2'>Add New Menu Item:</label></Col>
                                            <Col span={24}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Label:</label>
                                                    <input placeholder="Enter label" name='addlabel' onChange={this.handleNewMenuChange} />
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Type:</label>
                                                    <Select name='addtype'
                                                        options={this.state.link_type_options}
                                                        onChange={(value) => this.handleNewMenuChange({ target: { name: 'addtype', value } })}
                                                        value={'customlink'}
                                                    />
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Custom Link:</label>
                                                    <input placeholder="" name="addlink" onChange={this.handleNewMenuChange}  />
                                                    <label className='font-12 align-items-center mt-1'><InfoCircleOutlined style={{marginRight:3}}/>Start with /. Remove the root base url</label>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={12}>
                                                        <div className='form-group mb-0 d-flex flex-row gap-2'>
                                                        <Checkbox name="addmobile"
                                                        onChange={this.handleNewMenuChange} />
                                                        <label htmlFor="addmobile" className='font-bold'>Show on mobile:</label>
                                                        </div>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Button type="primary" className='ml-auto pt-0' onClick={this.addMenuItem}><PlusOutlined /> Add to Menu</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        
                                    </Col>
                                    
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title="Top Menu Section">
                            <Row gutter={24} style={{justifyContent:'space-between'}}> 
                                    <Col span={24} className='d-flex gap-2 align-items-center mb-3'>
                                        <label className='font-13 align-items-center'><InfoCircleOutlined style={{marginRight:3}}/>Drag each item into the order you prefer. Click arrow on the right to reveal additional configuration options.</label>
                                    </Col>
                                    <Col span={12}>
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={this.getListStyle(snapshot.isDraggingOver)}
                                                >
                                                {Array.isArray(this.state.topmenu) && this.state.topmenu.length > 0 ? (
                                                this.state.topmenu.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={this.getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                        >
                                                        <Collapse expandIconPosition="end" ghost>
                                                            <Panel header={<><MenuOutlined style={{fontSize:12, marginRight:8}} /> {item.label}</>} key="1">
                                                                <Row gutter={16}>
                                                                    <Col span={14}>
                                                                        <div className='form-group'>
                                                                            <label className='font-bold'>Label:</label>
                                                                            <input placeholder="" value={item.label} onChange={this.handleNewTopMenuChange}  />
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <div className='form-group'>
                                                                            <label className='font-bold'>Type:</label>
                                                                            <Select 
                                                                                options={this.state.link_type_options} 
                                                                                onChange={(e) => this.handleLinkOptions(e)} 
                                                                                defaultValue={this.state.link_type_options.find(option => option.value === (item?.type ?? 'customlink')) || { value: 'customlink', label: 'Custom Link' }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <div className='form-group'>
                                                                            <label className='font-bold'>Custom Link:</label>
                                                                            <input placeholder="" name="link" value={item.link} onChange={this.handleNewTopMenuChange} />
                                                                            <label className='font-12 align-items-center mt-1'><InfoCircleOutlined style={{marginRight:3}}/>Start with /. Remove the root base url</label>
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={24}>
                                                                        <Button className='form-group d-flex flex-row gap-2 ml-auto my-0 mr-0 p-0' type='text' onClick={() => this.removeTopMenuItem(index)} >
                                                                            <DeleteOutlined /> Remove
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Panel>
                                                        </Collapse>
                                                        </div>
                                                    )}
                                                    </Draggable>
                                                ))):''}
                                                {provided.placeholder}
                                                </div>
                                            )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Col>
                                    <Col span={11}>
                                        <Row gutter={16} className='border p-3'>
                                            <Col span={24}><label className='font-bold mb-2'>Add New Menu Item:</label></Col>
                                            <Col span={24}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Label:</label>
                                                    <input placeholder="" name="label" onChange={this.handleNewTopMenuChange}/>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Type:</label>
                                                    <Select 
                                                        options={this.state.link_type_options} 
                                                        onChange={(e) => this.handleLinkOptions(e)} 
                                                        value={'customlink'}
                                                    />
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div className='form-group'>
                                                    <label className='font-bold'>Custom Link:</label>
                                                    <input placeholder="" name="link" onChange={this.handleNewTopMenuChange} />
                                                    <label className='font-12 align-items-center mt-1'><InfoCircleOutlined style={{marginRight:3}}/>Start with /. Remove the root base url</label>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Button type="primary" className='ml-auto pt-0' onClick={this.addTopMenuItem}><PlusOutlined /> Add to Menu</Button>
                                            </Col>
                                        </Row>
                                        
                                    </Col>
                                    
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Content>}
                
                {this.state.footer && <Content style={{background:'none'}}>
                    <Row><Col span={24}><Title level={1} className='title mb-0 mt-3 pl-3'>Footer</Title></Col></Row>
                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16}>
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title={<Space className='d-flex align-items-center'><CloudUploadOutlined /><Title level={5} className='font-bold mb-0'>Logo</Title></Space>} style={{height:'100%'}} className='d-flex flex-col'>
                                <Row className="wrap container justify-content-between pl-0" gutter={16}>
                                    <Col span={12}>
                                        <div className='d-flex gap-2 align-items-center mb-2'>
                                            <label className='font-12 align-items-center'><InfoCircleOutlined style={{marginRight:3}}/>For the best outcome, please use <span className="font-bold">SVG format</span>.</label>
                                        </div>
                                        <Upload name="footer-logo" listType="picture-card" className="desktop-uploader" showUploadList={false} beforeUpload={beforeUploadLogo} onChange={this.handleFooterLogoChange} onRemove={this.handleRemove}>
                                            {this.state.footer_logo_change ?
                                                this.state.footer_logo_temp ? (<img src={this.state.footer_logo_temp} alt="Temp Logo" style={{ width: '100%',}} />) : (uploadButton)
                                            : this.state.footer_logo ? (<img src={imageUrl +this.state.footer_logo} alt="Logo" style={{ width: '100%',}} />)
                                            : (uploadButton)
                                            }
                                        </Upload>
                                    </Col>
                                    <Col span={12}>
                                        <div className='form-group mb-0'>
                                            <label className='font-bold'>Logo Alt Tag:</label>
                                            <input placeholder="" name='footer_logo_alt' value={this.state.footer_logo_alt??''} onChange={this.handleInputChange} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16}><Col span={24} className='d-flex flex-row gap-2 align-items-center mt-3'><InfoCircleOutlined /><label className='font-12'>If empty, the header logo will be used.</label></Col></Row>
                            </Card>
                        </Col>
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title="">
                                <div className='form-group'>
                                    <label className='font-bold'>Copyright:</label>
                                    <input placeholder="Enter copyright" name='copyright' value={this.state.copyright??''} onChange={this.handleInputChange} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    {/* 
                        Custom link
                        Has options to add from the pages
                    */}
                    {titles.map((title, index) => (
                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16}>
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>{title + ' Column'}</Title></Space>} style={{height:'100%'}} className='d-flex flex-col'>
                                <div className='form-group mb-3'>
                                    <label className='font-bold'>{title + ' Title'}:</label>
                                    <input placeholder="" name={`${title.toLowerCase()}_col_title`} value={this.state[`${title.toLowerCase()}_col_title`] || ''} onChange={this.handleFooterTitleInputChange}  />
                                </div>
                                {this.renderFooterRows(title, index)}
                            </Card>
                        </Col>
                    </Row>))}

                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16}>
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>{'Bottom Links'}</Title></Space>} style={{height:'100%'}} className='d-flex flex-col'>
                                {this.state.bottom_footer.map(this.renderBottomLinksRows)}
                            </Card>
                        </Col>
                    </Row>
                </Content>}
                
                {this.state.whatsappview && <Content style={{background:'none'}}>
                    <Row><Col span={24}><Title level={1} className='title mb-0 mt-3 pl-3'>Email</Title></Col></Row>
                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16}>
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title="General">
                                <div className='form-group'>
                                    <label className='font-bold'>Email address:</label>
                                    <input placeholder="Add a default email" name='email' value={this.state.email} onChange={this.handleEmailInputChange} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row><Col span={24}><Title level={1} className='title mb-0 mt-3 pl-3'>WhatsApp</Title></Col></Row>
                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16}>
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title="General">
                                <div className='form-group'>
                                    <label className='font-bold'>Number:</label>
                                    <input placeholder="" name='general_number' value={this.state.whatsapp[0].general_number} onChange={(e) => this.handleWhatsAppInputChange('general_number', e.target.value)} />
                                </div>
                                <div className='form-group'>
                                    <label className='font-bold'>General Message text:</label>
                                    <TextArea placeholder="" name='general_text'
                                    value={this.state.whatsapp[0].general_text}
                                    onChange={(e) => this.handleWhatsAppInputChange('general_text', e.target.value)} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16}>
                        <Col span={24} mt={3}  className="gutter-row">
                            <Card title="Property">
                                <div className='form-group'>
                                    <label className='font-bold'>Number:</label>
                                    <input placeholder="" name='property_number'
                                    value={this.state.whatsapp[0].property_number}
                                    onChange={(e) => this.handleWhatsAppInputChange('property_number', e.target.value)}/>
                                </div>
                                <div className='form-group'>
                                    <label className='font-bold'>Property Message text:</label>
                                    <TextArea placeholder="" name='property_message' ref={this.textAreaRef}
                                    value={this.state.whatsapp[0].property_message}
                                    onChange={(e) => this.handleWhatsAppInputChange('property_message', e.target.value)}
                                    onClick={this.handleCursorChange} // Handle cursor change when clicking in the textarea
                                    onKeyUp={this.handleCursorChange} // Handle cursor change when typing                                    
                                    />
                                </div>
                                <div style={{ display:'flex', gap:'0.6rem' }}>
                                    <Button onClick={() => this.insertTextAtCursor('[link]')} style={{ marginRight: '5px' }}>[link]</Button>
                                    <Button onClick={() => this.insertTextAtCursor('[title]')}>[title]</Button>
                                    <Button onClick={() => this.insertTextAtCursor('[price]')}>[price]</Button>
                                    <Button onClick={() => this.insertTextAtCursor('[refno]')}>[refno]</Button>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Content>}
                {this.state.social && <Content style={{background:'none'}}>
                    <Row className="wrap container pt-3 pb-2 justify-content-between" gutter={16}>
                        <Col span={24} mt={3} className="gutter-row">
                            <Card title="Social Badge Section">
                                <Row gutter={16} >
                                    <Col span={24}>
                                        <Row className="wrap container justify-content-between px-0 align-items-end" style={{rowGap:0}} gutter={16}>
                                            <Col span={24} className='d-flex flex-column gap-2 align-items-start'>
                                                <label className='font-bold'>App Store:</label>
                                                <label className='font-12 align-items-center'><InfoCircleOutlined style={{marginRight:3}}/>For the best outcome, please use <span className="font-bold">SVG format</span>.</label>
                                            </Col>
                                            <Col span={8}>
                                                <Upload name="footer-logo" listType="picture-card" className="desktop-uploader " showUploadList={false} beforeUpload={beforeUploadLogo} onChange={this.handleAppStoreUpload} onRemove={this.handleRemove}>
                                                    {this.state.app_store_change ?
                                                        this.state.app_store_temp ? (<img src={this.state.app_store_temp} alt="Temp Logo" style={{ width: '100%',}} />) : (uploadButton)
                                                    : this.state.app_store.image ? (<img src={imageUrl +this.state.app_store.image} alt="Logo" style={{ width: '100%',}} />)
                                                    : (uploadButton)
                                                    }
                                                </Upload>
                                            </Col>
                                            <Col span={8}>
                                                <div className='form-group mb-0'>
                                                    <label className='font-bold'>Alt Tag:</label>
                                                    <input placeholder="" name='app_store_alt_tag' value={this.state.app_store.label??''} onChange={this.handleInputChange} />
                                                </div>
                                            </Col>
                                            <Col span={8}>
                                                <div className='form-group mb-0'>
                                                    <label className='font-bold'>Link</label>
                                                    <input placeholder="" name='app_store_link' value={this.state.app_store.link??''} onChange={this.handleInputChange} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={24} className='mt-5'>
                                        <Row className="wrap container justify-content-between px-0 align-items-end" gutter={16} style={{rowGap:0}} >
                                            <Col span={24} className='d-flex flex-column gap-2 align-items-start'>
                                                <label className='font-bold'>Google Play:</label>
                                                <label className='font-12 align-items-center'><InfoCircleOutlined style={{marginRight:3}}/>For the best outcome, please use <span className="font-bold">SVG format</span>.</label>
                                            </Col>
                                            <Col span={8}>
                                                <Upload name="footer-logo" listType="picture-card" className="desktop-uploader" showUploadList={false} beforeUpload={beforeUploadLogo} onChange={this.handleGooglePlayUpload} onRemove={this.handleRemove}>
                                                    {this.state.google_play_change ?
                                                        this.state.google_play_temp ? (<img src={this.state.google_play_temp} alt="Temp Logo" style={{ width: '100%',}} />) : (uploadButton)
                                                    : this.state.google_play ? (<img src={imageUrl +this.state.google_play.image} alt="Logo" style={{ width: '100%',}} />)
                                                    : (uploadButton)
                                                    }
                                                </Upload>
                                            </Col>
                                            <Col span={8}>
                                                <div className='form-group mb-0'>
                                                    <label className='font-bold'>Alt Tag:</label>
                                                    <input placeholder="" name='google_play_alt_tag' value={this.state.google_play.label??''} onChange={this.handleInputChange} />
                                                </div>
                                            </Col>
                                            <Col span={8}>
                                                <div className='form-group mb-0'>
                                                    <label className='font-bold'>Link</label>
                                                    <input placeholder="" name='google_play_link' value={this.state.google_play.link??''} onChange={this.handleInputChange} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24} mt={3} className="gutter-row">
                            <Card title="Social Media Section">
                                <Row>
                                    <Col span={24}>
                                        {this.state.social_media.map(this.renderSocialRows)}
                                    </Col>
                                    <Col span={24}>
                                        <Row className='d-flex flex-row justify-content-between'>
                                            <Col><Button onClick={this.handleAddSocialMedia} type="primary"><PlusOutlined /> Add More</Button></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    
                </Content>}
            </Layout>
            <CustomBackTop icon={<CaretUpOutlined style={{fontSize:20}}/>} className='floatbtn' />
        </Card>)

    }


}

export default Homepage

const StyledSelect = styled(Select)`
    & .ant-select .ant-select-selector .ant-select-selection-item {font-size:12px;}
`;
