import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { MultiSelect } from "react-multi-select-component";
import { Route, withRouter } from 'react-router-dom';
import MapPicker from 'react-google-map-picker'
import 'antd/dist/reset.css';
import './../Requests/request.css'
import BootStrapCarousel from 'react-bootstrap/Carousel';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FileUploader } from "react-drag-drop-files";
import AutocompleteReact from 'react-autocomplete';
import { Table, DatePicker, Space, Button, Modal, Drawer, Select, Input, Upload, Progress, Divider, Mentions, Radio, Spin, Carousel, Avatar, Row, Col, TimePicker, Checkbox, AutoComplete, Popover, Switch, Tooltip, Collapse, Layout, Timeline, Empty, Tag, Image } from 'antd'
import ReactToPrint from 'react-to-print';
import parse from 'html-react-parser';
import jsPDF from "jspdf";
import html2pdf from 'html2pdf.js';
import html2canvas from "html2canvas";
import { BarLoader } from 'react-spinners'
import {
  CloseOutlined, ReloadOutlined, RiseOutlined,
  PrinterOutlined,
  PlusCircleOutlined,
  UploadOutlined,
  PlusOutlined,
  ClearOutlined, ClockCircleOutlined,
  WhatsAppOutlined,
  DeleteOutlined,
  EyeFilled,
  FilePdfOutlined,
  PaperClipOutlined,
  FilterOutlined,
  FileSearchOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  ArrowRightOutlined, ArrowLeftOutlined,
  CaretRightOutlined, CaretLeftOutlined,
  DoubleRightOutlined,
  SaveOutlined, EditOutlined, DownloadOutlined
} from '@ant-design/icons';
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EdiText from "react-editext";
import Swal from "sweetalert2";

import { Chrono } from "react-chrono";

import CurrencyInput from 'react-currency-input-field';
import SortableList, { SortableItem } from "react-easy-sort";

// import {
//     GridContextProvider,
//     GridDropZone,
//     GridItem,
//     swap,
//     move
//   } from "react-grid-dnd";


// import './index.css'
import './listing.css'
import { baseLpUrlImg, baseurlImg, liveUrl } from '../../components/BaseUrl';
import { store } from "../../store";
import dayjs from "dayjs";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import 'react-dropzone-uploader/dist/styles.css';
import ReactDropzoneUploader from 'react-dropzone-uploader';
import SortableContainer from '../../components/SortableContainer';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';
import { end } from '@popperjs/core'
const styles = {
  dropzoneActive: {
    borderColor: 'green',
  },
  submitButton: {
    display: 'none',
  },
};
const fileTypes = ["JPEG", "JPG", "PNG", "GIF", "TIFF", "HEIC"];

const DefaultLocation = { lat: 10, lng: 106 };
const DefaultZoom = 10;
const CheckboxGroup = Checkbox.Group;
const { RangePicker } = DatePicker
const { Dragger } = Upload;
const { TextArea } = Input
const { TabPane } = Tabs;
const RadioGroup = Radio.Group;
let imagesArray = []
const Option = Select.Option;
let columns = []
let columnsTable = []
let property_type_array = ["Residential", "Commercial"]
let property_for_array = ["sale", "rent", "tenanted", "short-term", "pond"]
const { Panel } = Collapse;
const { Sider } = Layout;

let alarr = []
class ArchiveListings extends Component {
  // update this
  constructor(props) {
    super(props);
    this.state = {
      showAvailableDateModel: false,
      price_application: false,
      FilterDrawerOpen: false,
      pf_property: "",
      pf_project: "",
      property_category: "",
      isDownloading: false,
      previewDoc: false,
      all_docs: [],
      selected_portals_id: [],
      selected_portals: [],
      lat: "",
      lng: "",
      rera_permit: "",
      listing: {},
      openPDF: false,
      listings_id: [],
      isLoading: false,
      mapOpen: false,
      listing_note: "",
      description_loader: false,
      map_location: { lat: 25.197197, lng: 55.2718015 },
      websitevalidationerrors: {},
      isOptionSelected: true,
      progress: 0,
      fileList: [],
      selectedValue: "",
      zoom: 15,
      photography_check: false,
      videography_walk_check: false,
      videography_agent_check: false,
      haveNoc: false,
      referred_by: "",
      referred_to: "",
      is_referred: false,
      global_search: "",
      property_contact: {},
      selected_facilities: [],
      property_contact_name: "",
      smartSearchValue: "",
      smartSearchOptions: [],
      role: "",
      agent_id: "",
      furnished_types_value: [],
      property_contact_email: "",
      property_contact_mobile: "",
      selected_agent: "",
      aiSearch: false,
      followup_for: "",
      marketing_agent: "",
      reminder: "",
      bedrooms: [],
      title_details: "",
      curation_details: [],
      lifestyle_details: "",
      bathrooms: [],
      curation: [],
      life_style: [],
      curations: "",
      lifestyle: "",
      avail_d: "",
      photography_note: "",
      present_photo: "",
      photography_date: "",
      photography_time: [],
      followup_note: "",
      followup_date: "",
      type: 1,
      followups: [],
      followups_details: [],
      whatsappcols: [],
      seller: [],
      buyers: [],
      whatsappClients: [],
      whatsappSelectedClients: [],
      SelectedRows: [],
      iswhatsappOpen: false,
      isviewingOpen: false,
      isfollowupOpen: false,
      iseditviewingOpen: false,
      dateRange: [],
      leads_count: "",
      ismourequestOpen: false,
      selectedFacilities: [],
      lead_gen: false,
      just_listed: false,
      communities: [],
      allUsers: [],
      selectedCallUsers: [],
      isInternationDisable: false,
      default_status_value: [],
      validationerrors: {},
      selectedNoteUsers: [],
      selectedWhatsappUsers: [],
      selectedEmailUsers: [],

      selectedViewingUsers: [],
      selectedOfferUsers: [],
      selectedFollowupUsers: [],
      cities_details: [],
      countries_details: [],
      purchaseprice: 0,
      servicecharge: 0,
      rentalamount: 0,
      rentalyieldtotal: 0,
      // call
      isCallLogOpen: false,
      isCallEnable: false,

      // note
      noteUsers: [],

      // whatsapp
      whatsappUsers: [],
      //email
      emailUsers: [],
      //offer
      offerUsers: [],
      // viewing
      viewingUsers: [],
      //fllowup
      followupUsers: [],
      files: [],
      sub_communities: [],
      properties: [],
      facilities: [],
      agents: [],
      propertytypes: [],
      property_images: [],
      community_match: "",
      sub_community_match: "",
      min_budget: 0,
      max_budget: 0,
      bedroom_match: "",
      property_for_match: "",
      property_type_match: "",
      added_date_match: "",
      date_morethan_option_match: false,
      date_lessthan_option_match: false,
      date_value_match: 0,
      date_value_id_match: 1,

      matched_leads_and_viewings_morethan_option: false,
      matched_leads_and_no_viewings_option: false,
      matched_leads_viewing_value_show: false,
      matched_leads_viewing_value_id: 1,
      matched_leads_viewing_value: 0,

      matchs: [],
      not_matchs: [],
      property_viewings: [],
      listing_offers: [],
      offer: {},
      showOffer: false,
      viewings: {},
      document_notes: [],
      documents: [],
      document_title: "",
      document_file: null,
      documentImageShow: "",
      property_note: "",
      value: "This is a sample text",

      mylistings: [],
      filters: [],
      rows: [],
      ref_nums: [],
      userClients: [],
      selectedClients: [],
      viewing_ref_no: "",
      viewing_agent: "",
      viewing_date: "",
      viewing_note: "",
      viewing_status: "",
      viewing_price: "",
      showPrice: false,
      showDate: false,
      listing_details: {},
      owner_id: "",
      owner_name: "",
      owner_email: "",
      owner_mobile: "",
      property_type: "",
      stage: "UC",
      avail_date: "",
      bedroom: "",
      price: "",
      property_for: "",
      community_value_id: "",
      sub_community_value_id: "",
      property_value_id: "",
      is_loading: false,
      owner_other_properties: [],
      all: [],
      notes: [],
      call_notes: [],
      whatsapp_notes: [],
      email_notes: [],
      record_id: 0,
      property_for_is: false,
      greaterthanprice: false,
      leassthanprice: false,
      inbetweenprice: false,
      lessValue: 0,
      greaterValue: 0,


      call_log_discuss: "",
      isPropertyAvailable: false,
      confirm_price: "",
      note_comment: "",

      // details
      agent_name_details: "",
      property_for_details: "",
      completiondetails: "",
      style_property_details: "",
      property_type_details: "",
      unit_no_details: "",
      unit_no: "",
      community_details: "",
      subcommunity_details: "",
      property_details: "",
      bedroom_details: "",
      bathroom_details: "",
      price_details: "",
      view_details: "",
      tags_detail: "",
      bua: "",
      plot_area: "",
      rera_permit_details: "",
      description_details: "",
      listing_status_details: "",
      furnished_details: "",
      maids_room: "",
      vacant_details: "",
      completion_status: "Yes",
      emirate_id: "",
      form_a: "",
      title_deed: "",
      exclusive: "",
      deed_expire: "",

      // for website

      video_link: "",
      livetour_link: "",
      brochure: "",
      meta_title: "",
      meta_description: "",
      meta_keywords: "",

      // international
      international: "",
      country: "",
      city: "",

      // filters option
      property_for_first_value: 0,
      property_for_second_value: 0,
      property_for_second_option: false,
      community_filter: "",
      agent_filter: "",
      sub_community_filter: "",
      property_filter: "",
      property_type_filter: [],
      bedroom_filter: "",
      lead_gen_filter: false,
      pledge_filter: false,
      pond_filter: false,
      referred_to_filter: false,
      referred_filter: false,
      // last call
      last_call_morethan_option: false,
      last_call_lessthan_option: false,
      last_call_value: "",
      last_call_value_id: 1,
      last_call_option_value: 1,
      // ref no
      refno_first_value_id: 1,
      refno_second_value_id: 1,
      refno_active_second_option: false,
      refno_and_or_option: true,
      refno_first_value: "",
      refno_second_value: "",

      // property for
      sale: true,
      rent: true,
      tenant: true,
      short_term: true,

      // property type

      residential: true,
      commercial: true,
      // unit no
      unitno_first_value_id: 1,
      unitno_second_value_id: 1,
      unitno_active_second_option: false,
      unitno_and_or_option: true,
      unitno_first_value: "",
      unitno_second_value: "",

      // price

      price_first_value_id: 1,
      price_second_value_id: 1,
      price_active_second_option: false,
      price_and_or_option: true,
      price_first_value: "",
      price_second_value: "",
      inrange: false,
      price_min: 0,
      price_max: 0,

      leads_count_first_value_id: 1,
      leads_count_second_value_id: 1,
      leads_count_active_second_option: false,
      leads_count_and_or_option: true,
      leads_count_first_value: "",
      leads_count_second_value: "",
      leads_count_inrange: false,
      leads_count_min: 0,
      leads_count_price_max: 0,

      viewings_count_first_value_id: 1,
      viewings_count_second_value_id: 1,
      viewings_count_active_second_option: false,
      viewings_count_and_or_option: true,
      viewings_count_first_value: "",
      viewings_count_second_value: "",
      viewings_count_inrange: false,
      viewings_count_min: 0,
      viewings_count_price_max: 0,

      offers_count_first_value_id: 1,
      offers_count_second_value_id: 1,
      offers_count_active_second_option: false,
      offers_count_and_or_option: true,
      offers_count_first_value: "",
      offers_count_second_value: "",
      offers_count_inrange: false,
      offers_count_min: 0,
      offers_count_price_max: 0,

      // date

      date_first_value_id: 1,
      date_second_value_id: 1,
      date_active_second_option: false,
      date_and_or_option: true,
      date_first_value: "",
      date_second_value: "",
      date_morethan_option: false,
      date_lessthan_option: false,
      publish_date_morethan_option: false,
      publish_date_lessthan_option: false,
      date_value: 0,
      date_value_id: 1,
      publish_date_value: 0,
      publish_date_value_id: 1,
      status_filter: [],

      // add new filter
      isFilterOpen: false,
      isFilterEditOpen: false,
      filter_title: "",
      open: false,
      openToEdit: false,
      openToAmenities: false,

      property_type_option: [
        {
          value: 'Residential',
          label: 'Residential'
        },
        {
          value: 'Commercial',
          label: 'Commercial'
        }
      ],
      property_for_option: [
        {
          value: 'sale',
          label: 'Sales'
        },
        {
          value: 'rent',
          label: 'Rent'
        },
        {
          value: 'pond',
          label: 'Pond'
        },
        // {
        //   value:'tenanted',
        //   label:'Tenanted'
        // },
        // {
        //   value:'short-term',
        //   label:'Short-term'
        // }
      ],
      propert_type_value: [],
      propert_for_value: [],


      filter_options: [
        {
          value: '1',
          label: 'Status',
        },
        {
          value: '2',
          label: 'Property for',
        },
        {
          value: '3',
          label: 'Available Date',
        },
        {
          value: '4',
          label: 'Community / Sub Community',
        },
        {
          value: '5',
          label: 'Property',
        },
        {
          value: '6',
          label: 'Property Type',
        },
        {
          value: '7',
          label: 'Bedrooms',
        },
        {
          value: '8',
          label: 'Furnished / Unfurnished',
        },
        {
          value: '9',
          label: 'Added Date',
        },
        {
          value: '10',
          label: 'Price',
        },
        // {
        //     value: '11',
        //     label: 'No of Leads',
        // },
        // {
        //     value: '12',
        //     label: 'No of Viewings',
        // },
        // {
        //     value: '13',
        //     label: 'No of Offers',
        // }
      ],

      status_types: [
        {
          value: 'D',
          label: 'Draft'
        },
        {
          value: 'UC',
          label: 'Confirmed Owner'
        },
        {
          value: 'N',
          label: 'Unpublished'
        },
        {
          value: 'OM',
          label: 'Off-Market'
        },
        {
          value: 'CTM',
          label: 'Coming to market'
        },
        {
          value: 'Y',
          label: 'Published'
        },
        {
          value: 'U',
          label: 'Action'
        },
        {
          value: 'SEO',
          label: 'SEO'
        },
        {
          value: 'L',
          label: 'Unlisted'
        },
        {
          value: 'S',
          label: 'Sold'
        },
        {
          value: 'P',
          label: 'Review'
        },
        {
          value: 'F',
          label: 'Feeds'
        }
      ],

      furnished_types: [
        {
          value: "1",
          label: 'Furnished'
        },
        {
          value: '0',
          label: 'Unfurnished'
        }
      ],


      // add notes
      call_log: "",
      note_log: "",
      whatsapp_log: "",
      email_log: "",
      viewing_log: "",
      offer_log: "",
      followup_log: "",

      // Loaders
      call_loader: false,
      note_loader: false,
      whatsapp_loader: false,
      email_loader: false,
      offer_loader: false,
      viewing_loader: false,
      followUp_loader: false,

      // Farooq
      // IDs
      nextId: '',
      prevId: '',
      currId: '',

      // states for disabled
      disabledNext: false,
      disabledPrev: false,

      currUrl: "",
      loader: false,

      // Photo Request
      showPhotoRequest: false,

      // filters popovers
      isLeadsfilterOpen: false,
      isViewingsfilterOpen: false,
      isOfferfilterOpen: false,
      isMatchLeadsOpen: false,
      isLastCallOpen: false,

      // Toggle views
      isViewAsGallery: true,

      isCollapsed: false,
      isReferralOpen: false,

    }

    this.textInput = React.createRef();
    this.handleChangeLocation = this.handleChangeLocation.bind(this);

    this.handleChangeZoom = this.handleChangeZoom.bind(this)
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.carousel = React.createRef();

  }

  componentDidMount() {



    // ///// chnages By Farooq ////
    if (this.props.match.params.id) {
      // this.setState({open: true})
      this.handleOpenDrawerToEdit(this.props.match.params.id, false);
    }
    // ///////////////////////////////////////////

    var loadScript = function (src) {
      var tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    }
    loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.min.js')
    //loadScript('https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0-alpha1/js/bootstrap.bundle.min.js"')

    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id
    const stock_property_for = all_data.stock_reducer.property_for
    const stock_agent_value = all_data.stock_reducer.agent_value

    const added_date_morethan_option = all_data.stock_reducer.added_date_morethan_option
    const added_date_lessthan_option = all_data.stock_reducer.added_date_lessthan_option
    const added_date_value_id = all_data.stock_reducer.added_date_value_id
    const added_date_value = all_data.stock_reducer.added_date_value


    const publish_date_morethan_option = all_data.stock_reducer.publish_date_morethan_option
    const publish_date_lessthan_option = all_data.stock_reducer.publish_date_lessthan_option
    const publish_date_value_id = all_data.stock_reducer.publish_date_value_id
    const publish_date_value = all_data.stock_reducer.publish_date_value

    const listings_id = all_data.stock_reducer.listings_id


    this.setState({
      agent_filter: stock_agent_value ? stock_agent_value : this.state.agent_filter,
      propert_for_value: stock_property_for ? stock_property_for : this.state.propert_for_value,
      status_filter: stock_status ? [...this.state.status_filter, stock_status] : this.state.status_filter,
      community_filter: stock_community_id, sub_community_filter: stock_subcommunity_id, property_filter: stock_property_id,
      date_morethan_option: added_date_morethan_option ? added_date_morethan_option : this.state.date_morethan_option,
      date_lessthan_option: added_date_lessthan_option ? added_date_lessthan_option : this.state.date_lessthan_option,
      publish_date_morethan_option: publish_date_morethan_option ? publish_date_morethan_option : this.state.publish_date_morethan_option,
      publish_date_lessthan_option: publish_date_lessthan_option ? publish_date_lessthan_option : this.state.publish_date_lessthan_option,
      date_value: added_date_value ? added_date_value : this.state.date_value,
      date_value_id: added_date_value_id ? added_date_value_id : this.state.date_value_id,
      publish_date_value_id: publish_date_value_id ? publish_date_value_id : this.state.publish_date_value_id,
      publish_date_value: publish_date_value ? publish_date_value : this.state.publish_date_value,
      listings_id: listings_id ? listings_id : this.state.listings_id
    })



    const page = 1;
    const perPage = 10;

    const userdata = store.getState((state) => state);
    let authenticated = userdata.auth.loggedIn;
    let role = userdata.auth.user.role;
    let user = userdata.auth.user;
    let role_name = userdata.auth.user.role_name;
    let user_id = user.id
    if (role_name != "Admin" && role_name != "Super Admin") {
      this.state.agent_filter = user_id;
    }

    const data = {
      listings_id: listings_id ? listings_id : this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      status_filter: stock_status ? [...this.state.status_filter, stock_status] : this.state.status_filter,
      community_filter: stock_community_id ? stock_community_id : this.state.community_filter,
      agent_filter: stock_agent_value ? stock_agent_value : this.state.agent_filter,
      sub_community_filter: stock_subcommunity_id ? stock_subcommunity_id : this.state.sub_community_filter,
      property_filter: stock_property_id ? stock_property_id : this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: added_date_morethan_option ? added_date_morethan_option : this.state.date_morethan_option,
      date_lessthan_option: added_date_lessthan_option ? added_date_lessthan_option : this.state.date_lessthan_option,
      publish_date_morethan_option: publish_date_morethan_option ? publish_date_morethan_option : this.state.publish_date_morethan_option,
      publish_date_lessthan_option: publish_date_lessthan_option ? publish_date_lessthan_option : this.state.publish_date_lessthan_option,
      date_value: added_date_value ? added_date_value : this.state.date_value,
      date_value_id: added_date_value_id ? added_date_value_id : this.state.date_value_id,
      publish_date_value_id: publish_date_value_id ? publish_date_value_id : this.state.publish_date_value_id,
      publish_date_value: publish_date_value ? publish_date_value : this.state.publish_date_value,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: stock_property_for ? stock_property_for : this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);
    this.fetchFilter();

    API.get("/community")
      .then((response) => {
        if (response.data.success) {
          this.setState({ communities: response.data.communities })
        }
      })
    API.get("/all_sub_community")
      .then((response) => {

        if (response.data.success) {
          this.setState({ sub_communities: response.data.sub_communities })
        }
      })

    this.fetchFilter();

    API.get("/get_curation")
      .then((response) => {
        if (response.data.success) {
          this.setState({ curation: response.data.curation })
        }
      })

    API.get("/get_lifestyle")
      .then((response) => {
        if (response.data.success) {
          this.setState({ life_style: response.data.life_style })
        }
      })

    API.get("/all_properties")
      .then((response) => {

        if (response.data.success) {
          this.setState({ properties: response.data.properties })
        }
      })

    API.get("/propertytypes")
      .then((response) => {
        if (response.data.success) {
          this.setState({ propertytypes: response.data.propertytypes })
        }
      })

    API.get("/bedrooms")
      .then((response) => {
        if (response.data.success) {
          this.setState({ bedrooms: response.data.bedrooms })
        }
      })

    API.get("/bathrooms")
      .then((response) => {
        if (response.data.success) {
          this.setState({ bathrooms: response.data.bathroom })
        }
      })

    API.get("/countries")
      .then((response) => {
        if (response.data.success) {
          this.setState({ countries_details: response.data.countries })
        }
      })

    API.get("/all_cities")
      .then((response) => {
        if (response.data.success) {
          this.setState({ cities_details: response.data.cities })
        }
      })

    API.get("/all_agents")
      .then((response) => {
        if (response.data.success) {
          this.setState({ agents: response.data.agents })
        }
      })
    this.fetchClientUsers();
  }

  fetchClientUsers = () => {
    API.get('/all_client_users').then((response) => {
      if (response.data.success) {
        this.setState({ userClients: response.data.users })
      }
    })
  }

  fetchFilter = () => {
    API.get('/listings_filters')
      .then((response) => {
        if (response.data.success) {
          this.setState({ filters: response.data.filters })

        }
      })
  }

  handleCommunity = (value) => {
    this.setState({ community_value_id: value })
    API.get("/sub_community/" + value)
      .then((response) => {
        if (response.data.success)
          this.setState({ sub_communities: response.data.sub_communities })
      })
  }

  handleNewFilter = () => {
    this.setState({ isFilterOpen: true })
  }

  handleEditFilter = (id, e) => {



    let filter_option = []

    const newrows = [
      {
        id: 1
      },
      { id: 2 },
      {
        id: 3,
      },
      { id: 4 },
      { id: 5 },
      {
        id: 6
      }
    ]


    //this.setState({filter_options:[]})
    this.setState({ rows: newrows })

    this.setState({ isFilterEditOpen: true, filter_id: id })





    API.get("/get_listing_saved_filter/" + id)
      .then((response) => {



        if (response.data.success) {
          const response_filter = response.data.filter

          const property_for = response.data.filter_property_for
          const property_type = response.data.filters_property_types


          this.setState({
            listings_id: this.state.listings_id,
            propert_type_value: property_type,
            propert_for_value: property_for,
            price_first_value_id: response_filter.price_first_value_id,
            price_first_value: response_filter.price_first_value,
            price_min: response_filter.price_min,
            price_max: response_filter.price_max,
            inrange: response_filter.price_first_value_id == 7 ? true : false,

            leads_count_first_value_id: response_filter.leads_count_first_value_id,
            leads_count_first_value: response_filter.leads_count_first_value,
            leads_count_min: response_filter.leads_count_min,
            leads_count_max: response_filter.leads_count_max,
            leads_count_inrange: response_filter.leads_count_first_value_id == 7 ? true : false,

            viewings_count_first_value_id: response_filter.viewings_count_first_value_id,
            viewings_count_first_value: response_filter.viewings_count_first_value,
            viewings_count_min: response_filter.viewings_count_min,
            viewings_count_max: response_filter.viewings_count_max,
            viewings_count_inrange: response_filter.viewings_count_first_value_id == 7 ? true : false,

            offers_count_first_value_id: response_filter.offers_count_first_value_id,
            offers_count_first_value: response_filter.offers_count_first_value,
            offers_count_min: response_filter.offers_count_min,
            offers_count_max: response_filter.offers_count_max,
            offers_count_inrange: response_filter.offers_count_first_value_id == 7 ? true : false,

            date_morethan_option: response_filter.date_morethan_option,
            date_lessthan_option: response_filter.date_lessthan_option,
            date_value_id: response_filter.date_value_id,
            date_value: response_filter.date_value,

            publish_date_morethan_option: response_filter.publish_date_morethan_option,
            publish_date_lessthan_option: response_filter.publish_date_lessthan_option,
            publish_date_value_id: response_filter.publish_date_value_id,
            publish_date_value: response_filter.publish_date_value,

            available_date: this.state.dateRange,
            leads_count: this.state.leads_count,

            bed_rooms_value_id: response_filter.bed_rooms_value_id,
            bed_rooms_value: response_filter.bed_rooms_value,


            default_stage_value: response.data.filter_stages,

            default_contact_types_value: response.data.filters_contact_types,
            default_status_value: response.data.filters_status,
            default_furnished_types_value: response.data.filters_furnished_unfurnisheds

          })
        }
      })
  }



  handleOk = () => {

    const data = {
      filter_title: this.state.filter_title,
      refno_value_id: this.state.refno_first_value_id,
      refno_value: this.state.refno_first_value,

      unitno_value_id: this.state.unitno_first_value_id,
      unitno_value: this.state.unitno_first_value,

      price_first_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      propert_for_value: this.state.propert_for_value,

      furnished_types_value: this.state.furnished_types_value,
      status_types: this.state.status_filter,
      property_for_option: this.state.propert_for_value,
      sub_communities: this.state.sub_community_filter,
      communities: this.state.community_filter,
      property_filter: this.state.property_filter,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter

    }
    API.post("/add_listing_filter", data)
      .then((response) => {

        if (response.data.success) {
          this.setState({ isFilterOpen: false, filter_title: "" })
          this.fetchFilter();

        }
      })


    //   const data = {
    //     filter_title:this.state.filter_title
    //   }
    // API.post("/add_filter",data)
    // .then((response) =>{
    //   if(response.data.success){
    //     this.setState({isFilterOpen:false,filter_title:""})
    //     this.fetchFilter();

    //   }
    // })
  };
  handleCancel = () => {
    this.setState({ isFilterOpen: false, filter_title: '' })
  };

  handleCancelEit = () => {
    this.setState({ isFilterEditOpen: false, filter_title: '' })
  }

  handleFilterTitle = (e) => {
    this.setState({ filter_title: e.target.value })
  }

  handleFollowupCancel = () => {
    this.setState({ isfollowupOpen: false })
  }

  handleViewFollowup = (reminder_id, e) => {
    API.get("/followup_by_reminder_id/" + reminder_id)
      .then((response) => {
        if (response.data.success) {

          this.setState({ isfollowupViewOpen: true, followup_date: response.data.followup.remind_date, followup_note: response.data.followup.remind_note })

        }
      })

  }

  onTypeChange = (e) => {
    this.setState({ type: e.target.value })
  }

  onChangeListingNote = (e) => {
    this.setState({ listing_note: e.target.value })
  }

  getUploadParams = ({ meta }) => {
    const url = 'https://httpbin.org/post';
    const fileUrl = `${url}/${encodeURIComponent(meta.name)}`;
    return { url, meta: { fileUrl } };
  };

  handleUploadAllFiles = (file) => {

    const data_length = file.length
    let i
    for (i = 0; i < file.length; i++) {
      const formData = new FormData();
      formData.append("listing_id", this.state.record_id);
      formData.append("files", file[i]);
      API.post("/upload_listing_files", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then((response) => {

        if (response.data.success) {

          this.setState({ property_images: response.data.images })
          // remove();
          imagesArray = []
        }
      })

    }
    // if(i == data_length){
    //   toast.success(data_length+" Files uploaded")
    //  }
  }

  handleChangeStatus = ({ meta, file, remove }, status) => {

    if (status == 'done') {
      const formData = new FormData();
      formData.append("listing_id", this.state.record_id);
      formData.append("files[]", file);
      API.post("/upload_listing_files", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then((response) => {
        if (response.data.success) {
          this.setState({ property_images: response.data.images })
          remove();
          imagesArray = []
        }
      })
    }
  };


  handleFollowupOk = () => {
    if (this.validateFollowup()) {
      const data = {
        listing_id: this.state.record_id,
        leads_id: 0,
        contacts_id: this.state.owner_id,
        followup_date: this.state.followup_date,
        followup_note: this.state.followup_note,
        type: this.state.type,
        selected_agent: this.state.selected_agent,
        followup_for: this.state.followup_for,
        reminder_time: this.state.reminder
      }

      API.post("/add_followup_details", data)
        .then((response) => {
          if (response.data.success) {
            this.setState({ isfollowupOpen: false })
            API.get("/listing_details/" + this.state.record_id)
              .then((response) => {



                if (response.data.success) {
                  this.setState({
                    listing_details: response.data.listing_details, property_images: response.data.images, notes: response.data.notes,
                    call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
                    email_notes: response.data.email_notes, agent_name_details: response.data.listing_details.agent,
                    agent_name_details: response.data.listing_details.agent_id, property_for_details: response.data.listing_details.property_for,
                    international: response.data.listing_details.international, country: (response.data.listing_details.country) ? response.data.listing_details.country : 198,
                    city: (response.data.listing_details.emirate) ? response.data.listing_details.emirate : 1,
                    role: response.data.role.role,
                    referred_by: (response.data.listing_details.referred_by != "" && response.data.listing_details.referred_by != null && response.data.listing_details.referred_by != undefined) ? response.data.listing_details.referred_by : response.data.listing_details.agent_id,
                    referred_to: response.data.listing_details.referred_to,
                    is_referred: (response.data.listing_details.referred_to == null || response.data.listing_details.referred_to == "") ? false : true,
                    marketing_agent: (response.data.listing_details.marketing_agent == "" || response.data.listing_details.marketing_agent == "") ? "" : response.data.listing_details.agent_id,
                    agent_id: response.data.role.id,
                    title_details: response.data.listing_details.title,
                    selected_facilities: response.data.selected_amenities,
                    lifestyle_details: response.data.life_style_detail,
                    curation_details: response.data.curation_details,
                    selected_portals_id: response.data.selected_portals_id,
                    selected_portals: response.data.selected_portals,
                    //isSameValueCheck: this.isCheckSameValue(response.data.selected_portals),
                    rera_permit_details: response.data.listing_details.rera_permit,
                    bua: response.data.listing_details.bua,
                    plot_area: response.data.listing_details.plot_area,
                    avail_d: response.data.listing_details.avail_date,
                    rera_permit: response.data.listing_details.rera_permit,
                    lat: response.data.listing_details.latitude, lng: response.data.listing_details.longitude,
                    property_type_details: response.data.listing_details.property_category_id, completion_status: (response.data.listing_details.completed == null) ? 'Yes' : response.data.listing_details.completed,
                    // style_property_details:this.state.style_property_details,
                    style_property_details: response.data.listing_details.style_property_details,
                    video_link: response.data.listing_details.video_link, livetour_link: response.data.listing_details.livetour_link,
                    meta_title: response.data.listing_details.meta_title,
                    meta_description: response.data.listing_details.meta_desc,
                    meta_keywords: response.data.listing_details.meta_keywords,
                    unit_no_details: response.data.listing_details.unitno, lead_gen: response.data.listing_details.leadgen == "Yes" ? true : false,
                    just_listed: response.data.listing_details.just_listed == 1 ? true : false,
                    community_details: response.data.listing_details.development, subcommunity_details: response.data.listing_details.project,
                    property_details: response.data.listing_details.property, bedroom_details: response.data.listing_details.bedrooms, bathroom_details: response.data.listing_details.bathrooms,
                    price_details: response.data.listing_details.price, view_details: response.data.listing_details.view,
                    vacant_details: response.data.listing_details.vacant_details,
                    upgraded_details: response.data.listing_details.upgraded_details,
                    furnished_details: response.data.listing_details.furnished,
                    maids_room: response.data.listing_details.maids_room,
                    // tags_detail:this.state.tags_detail,description_details:this.state.description_details,
                    listing_status_details: response.data.listing_details.status,
                    emirate_id: response.data.listing_details.emirate_id, form_a: response.data.listing_details.form_a,
                    title_deed: response.data.listing_details.title_deed, exclusive: response.data.listing_details.exclusive,
                    deed_expire: response.data.listing_details.deed_expire, owner_other_properties: response.data.owner_other_properties,
                    document_notes: response.data.document_notes, documents: response.data.documents,
                    description_details: response.data.listing_details.description,
                    owner_id: response.data.listing_details.owners_id,
                    owner_name: response.data.listing_details.owner,
                    owner_email: response.data.listing_details.owner_email,
                    owner_mobile: response.data.listing_details.owner_mobile,
                    // Add By Farooq
                    all: response.data.all,
                    // Farooq
                    loader: false
                  })

                  // this.setState({openToEdit:true})
                  this.fetchFollowUpDetails(this.state.record_id)
                  this.fetchViewings(this.state.record_id)
                  this.fetchOffers(this.state.record_id);

                  ///// chnages By Farooq ////
                  this.setState({ currUrl: window.location.href })

                  window.history.replaceState(null, "all_listings Id", window.location.href + '/' + this.state.record_id)
                  /////////////////////////


                  API.get("/all_agents")
                    .then((response) => {
                      if (response.data.success) {
                        this.setState({ agents: response.data.agents })
                      }
                    })

                  API.get("/facilities")
                    .then((response) => {
                      if (response.data.success) {
                        this.setState({ facilities: response.data.facilities })
                      }
                    })

                  API.get("/portals")
                    .then((response) => {
                      if (response.data.success) {
                        this.setState({ portals: response.data.portals })
                      }
                    })



                  API.get("/all_client_users")
                    .then((response) => {
                      // console.log(response.data.users)


                      if (response.data.success) {
                        this.setState({ allUsers: response.data.users })
                      }
                    })
                }
                // Farooq
                else {
                  this.setState({
                    loader: false
                  })
                }
              })
          }


        })
    }
  }



  handleDeleteFilter = (targetKey, action) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete("/delete_listing_filter/" + targetKey)
          .then((response) => {
            if (response.data.success) {
              toast.success("Successfully deleted")
              this.fetchFilter()
            }
          })
      }
    })

    // if (action === 'add') {
    //   this.setState({isFilterOpen:true})
    //  // add();
    // } else {
    //   //remove(targetKey);
    // }
  };

  // filter options
  handlePropertyForFirstValue = (e) => {

    const property_for_first_value = e.target.value;
    if (property_for_first_value != '') {
      this.setState({ property_for_second_option: true })
    } else {
      this.setState({ property_for_second_option: false })
    }




  }

  handleRefnoFirst = (e) => {

    const refno_first_value = e.target.value;
    this.setState({ refno_first_value: refno_first_value })

    // if(refno_first_value !=''){
    //   this.setState({refno_active_second_option:true})
    // }else{
    //   this.setState({refno_active_second_option:false})
    // }
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      property_type_filter: this.state.property_type_filter,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: refno_first_value,
      refno_second_value: this.state.refno_second_value,
      furnished_types_value: this.state.furnished_types_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);


  }

  handleRefnoSecond = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ refno_second_value: e.target.value })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: e.target.value,
      furnished_types_value: this.state.furnished_types_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handleRefnoFirstValue_id = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents
    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ refno_first_value_id: e.target.value })
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: e.target.value,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      furnished_types_value: this.state.furnished_types_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,


      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.refno_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handleRefRadio = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    // alert(!this.state.refno_and_or_option)
    this.setState({ refno_and_or_option: !this.state.refno_and_or_option })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: !this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      furnished_types_value: this.state.furnished_types_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.refno_second_value != '')

      this.fetchData(page, perPage, data);

  }

  handleRefnoSecondValue_id = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ refno_second_value_id: e.target.value })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: e.target.value,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      furnished_types_value: this.state.furnished_types_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.refno_second_value != '')
      this.fetchData(page, perPage, data);

  }

  // property for
  handlePropertyFor = (e) => {

    //   if(this.state.sale){
    //     property_for_array = property_for_array.filter(e => e !== 'sale');
    //   }else{

    //       property_for_array.push('sale')
    //   }

    // this.setState({sale:!this.state.sale})
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ propert_for_value: e })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      furnished_types_value: this.state.furnished_types_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_for: e,

      property_type: this.state.propert_type_value,
      filter_key: this.state.global_search
    }


    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }
  handleRent = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    if (this.state.rent) {
      property_for_array = property_for_array.filter(e => e !== 'rent');
    } else {

      property_for_array.push('rent')
    }
    this.setState({ rent: !this.state.rent })
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      furnished_types_value: this.state.furnished_types_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }
  handleTenant = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents


    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    if (this.state.tenant) {
      property_for_array = property_for_array.filter(e => e !== 'tenanted');
    } else {

      property_for_array.push('tenanted')
    }
    this.setState({ tenant: !this.state.tenant })
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,
      furnished_types_value: this.state.furnished_types_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }
  handleShortterm = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id





    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    if (this.state.short_term) {
      property_for_array = property_for_array.filter(e => e !== 'short-term');
    } else {

      property_for_array.push('short-term')
    }
    this.setState({ short_term: !this.state.short_term })
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }

  // property type
  handlePropertyType = (e) => {

    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ propert_type_value: e })
    // if(this.state.residential){

    //   property_type_array = property_type_array.filter(e => e !== 'Residential');


    // }else{

    //   property_type_array.push('Residential')


    // }

    // this.setState({residential:!this.state.residential})
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_for: this.state.propert_for_value,

      property_type: e,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }
  handleCommercial = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    if (this.state.commercial) {
      property_type_array = property_type_array.filter(e => e !== 'Commercial');


    } else {
      property_type_array.push('Commercial')

    }
    this.setState({ commercial: !this.state.commercial })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_for: property_for_array,
      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }

  // unit no options
  handleUnitnoFirst = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})




    const unitno_first_value = e.target.value;
    this.setState({ unitno_first_value: unitno_first_value })

    // if(unitno_first_value !=''){
    //   this.setState({unitno_active_second_option:true})
    // }else{
    //   this.setState({unitno_active_second_option:false})
    // }

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);


  }

  handleUnitnoSecond = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ unitno_second_value: e.target.value })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,

      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: e.target.value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handleUnitnoFirstValue_id = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ unitno_first_value_id: e.target.value })
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: e.target.value,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.unitno_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handleUnitRadio = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    // alert(!this.state.refno_and_or_option)
    this.setState({ unitno_and_or_option: !this.state.unitno_and_or_option })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.unitno_second_value != '')

      this.fetchData(page, perPage, data);

  }

  handleUnitnoSecondValue_id = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ unitno_second_value_id: e.target.value })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: e.target.value,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.unitno_second_value != '')
      this.fetchData(page, perPage, data);

  }


  // price options

  handlePriceFirst = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const price_first_value = e.target.value;

    this.setState((prevState) => (
      e.target.validity.valid ? { price_first_value: e.target.value } : prevState)
    )



    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (e.target.value > 0) {
      this.fetchData(page, perPage, data);
    } else {
      return
    }




  }

  handleLeadsCountFirst = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const leads_count_first_value = e.target.value;

    this.setState((prevState) => (
      e.target.validity.valid ? { leads_count_first_value: e.target.value } : prevState)
    )



    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }

  handleViewingsCountFirst = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const viewings_count_first_value = e.target.value;

    this.setState((prevState) => (
      e.target.validity.valid ? { viewings_count_first_value: e.target.value } : prevState)
    )



    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }

  handleOffersCountFirst = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const offers_count_first_value = e.target.value;

    this.setState((prevState) => (
      e.target.validity.valid ? { offers_count_first_value: e.target.value } : prevState)
    )



    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }

  // price in range
  handlePriceMin = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ price_min: e.target.value })


    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: e.target.value,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: this.state.propert_for_value,

      property_type: this.state.propert_type_value,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_max > 0)
      this.fetchData(page, perPage, data);

  }

  handleLeadsCountMin = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ leads_count_min: e.target.value })


    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: e.target.value,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: this.state.propert_for_value,

      property_type: this.state.propert_type_value,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }

  handleViewingsCountMin = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ viewings_count_min: e.target.value })


    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: e.target.value,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: this.state.propert_for_value,

      property_type: this.state.propert_type_value,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }

  handleOffersCountMin = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ offers_count_min: e.target.value })


    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: e.target.value,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: this.state.propert_for_value,

      property_type: this.state.propert_type_value,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }

  handlePriceMax = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ price_max: e.target.value })
    const max = e.target.value
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: e.target.value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: this.state.propert_for_value,

      property_type: this.state.propert_type_value,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_min > 0 && max > this.state.price_min)
      this.fetchData(page, perPage, data);
  }

  handleLeadsCountMax = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ leads_count_max: e.target.value })
    const max = e.target.value
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: e.target.value,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: this.state.propert_for_value,

      property_type: this.state.propert_type_value,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }

  handleViewingsCountMax = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ viewings_count_max: e.target.value })
    const max = e.target.value
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: e.target.value,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: this.state.propert_for_value,

      property_type: this.state.propert_type_value,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }

  handleOffersCountMax = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ offers_count_max: e.target.value })
    const max = e.target.value
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      furnished_types_value: this.state.furnished_types_value,

      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: e.target.value,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: this.state.propert_for_value,

      property_type: this.state.propert_type_value,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }

  handlePriceSecond = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // not here
    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    this.setState({ price_second_value: e.target.value })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: e.target.value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handleLeadsCountSecond = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ leads_count_second_value: e.target.value })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: e.target.value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handleViewingsCountSecond = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id
    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})




    this.setState({ viewings_count_second_value: e.target.value })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: e.target.value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handleOffersCountSecond = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ offers_count_second_value: e.target.value })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: e.target.value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handlePriceFirstValue_id = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ inrange: false })
    if (e.target.value == 7) {
      this.setState({ inrange: true, price_first_value: 0 })
    } else {
      this.setState({ price_min: 0, price_max: 0 })
    }

    this.setState({ price_first_value_id: e.target.value })
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: e.target.value,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handleLeadsCountFirstValue_id = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    this.setState({ leads_count_inrange: false })
    if (e.target.value == 7) {
      this.setState({ leads_count_inrange: true, leads_count_first_value: 0 })
    } else {
      this.setState({ leads_count_min: 0, leads_count_max: 0 })
    }

    this.setState({ leads_count_first_value_id: e.target.value })
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: e.target.value,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }

  handleViewingsCountFirstValue_id = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id
    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})




    this.setState({ viewings_count_inrange: false })
    if (e.target.value == 7) {
      this.setState({ viewings_count_inrange: true, viewings_count_first_value: 0 })
    } else {
      this.setState({ viewings_count_min: 0, viewings_count_max: 0 })
    }

    this.setState({ viewings_count_first_value_id: e.target.value })
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: e.target.value,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }

  handleOffersCountFirstValue_id = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ offers_count_inrange: false })
    if (e.target.value == 7) {
      this.setState({ offers_count_inrange: true, offers_count_first_value: 0 })
    } else {
      this.setState({ offers_count_min: 0, offers_count_max: 0 })
    }

    this.setState({ offers_count_first_value_id: e.target.value })
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: e.target.value,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);
  }

  handlePriceRadio = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    // alert(!this.state.refno_and_or_option)
    this.setState({ price_and_or_option: !this.state.price_and_or_option })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_second_value != '')

      this.fetchData(page, perPage, data);

  }

  handleLeadsCountRadio = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ leads_count_and_or_option: !this.state.leads_count_and_or_option })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }

  handleViewingsCountRadio = (e) => {
    this.setState({ viewings_count_and_or_option: !this.state.viewings_count_and_or_option })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }

  handleOffersCountRadio = (e) => {
    this.setState({ offers_count_and_or_option: !this.state.offers_count_and_or_option })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }

  handlePriceSecondValue_id = (e) => {
    this.setState({ price_second_value_id: e.target.value })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})




    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: e.target.value,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.price_second_value != '')
      this.fetchData(page, perPage, data);

  }

  handleLeadsCountSecondValue_id = (e) => {
    this.setState({ leads_count_second_value_id: e.target.value })

    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: e.target.value,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }

  handleViewingsCountSecondValue_id = (e) => {
    this.setState({ viewings_count_second_value_id: e.target.value })

    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: e.target.value,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }

  handleOffersCountSecondValue_id = (e) => {
    this.setState({ offers_count_second_value_id: e.target.value })

    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: e.target.value,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage, data);

  }

  handlePublishDateMorethan = (e) => {
    this.setState({ publish_date_morethan_option: true, publish_date_lessthan_option: false })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      publish_date_morethan_option: true,
      publish_date_lessthan_option: false,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.publish_date_value != '')
      this.fetchData(page, perPage, data);

  }

  handlePublishDateLessthan = (e) => {
    this.setState({ publish_date_morethan_option: false, publish_date_lessthan_option: true })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      publish_date_morethan_option: false,
      publish_date_lessthan_option: true,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.publish_date_value != '')
      this.fetchData(page, perPage, data);

  }


  // date option

  handleDateMorethan = (e) => {
    this.setState({ date_morethan_option: true, date_lessthan_option: false })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: true,
      date_lessthan_option: false,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_value != '')
      this.fetchData(page, perPage, data);

  }

  handleDateLessthan = (e) => {
    this.setState({ date_morethan_option: false, date_lessthan_option: true })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: false,
      date_lessthan_option: true,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_value != '')
      this.fetchData(page, perPage, data);

  }

  handleDateValue_id = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ date_value_id: e.target.value })
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,


      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: e.target.value,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_value != '')
      this.fetchData(page, perPage, data);
  }

  handleDateValue = (e) => {
    this.setState({ date_value: e.target.value })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: e.target.value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (e.target.value != '')
      this.fetchData(page, perPage, data);
  }

  handleDateFirst = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    const date_first_value = e.target.value;
    this.setState({ date_first_value: date_first_value })

    if (date_first_value != '') {
      this.setState({ date_active_second_option: true })
    } else {
      this.setState({ date_active_second_option: false })
    }

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: date_first_value,
      date_second_value: this.state.date_second_value,


      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);


  }

  handleDateSecond = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id
    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})




    this.setState({ date_second_value: e.target.value })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: e.target.value,


      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search

    }

    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage, data);

  }

  handleDateFirstValue_id = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    this.setState({ date_first_value_id: e.target.value })
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: e.target.value,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,


      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_first_value != '')
      this.fetchData(page, perPage, data);
  }

  handleDateRadio = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    // alert(!this.state.refno_and_or_option)
    this.setState({ date_and_or_option: !this.state.date_and_or_option })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,


      property_for: property_for_array,

      property_type: property_type_array,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_second_value != '')

      this.fetchData(page, perPage, data);

  }

  handleDateSecondValue_id = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ date_second_value_id: e.target.value })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: e.target.value,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,


      property_for: this.state.propert_for_value,

      property_type: this.state.propert_type_value,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.date_second_value != '')
      this.fetchData(page, perPage, data);

  }





  onInputChange = (e) => {
    console.log("onchange", e.target.value)
  }

  fetchFilter = () => {
    API.get('/listings_filters')
      .then((response) => {
        if (response.data.success) {
          this.setState({ filters: response.data.filters })
        }
      })
  }

  handlePriceRange = (e) => {
    const radio = e.target.value
    if (radio == 1) {
      this.setState({ greaterthanprice: true, leassthanprice: false, inbetweenprice: false })
    } else
      if (radio == 2) {
        this.setState({ greaterthanprice: false, leassthanprice: true, inbetweenprice: false })
      } else
        if (radio == 3) {
          this.setState({ greaterthanprice: false, leassthanprice: false, inbetweenprice: true })
        }
  }

  handleGreaterValue = (e) => {
    this.setState({ greaterValue: e.target.value })
  }

  onSelectSmartSearch = (selectedValue) => {
    this.setState({ aiSearch: true })
    this.setState({ selectedValue: selectedValue })
    if (this.state.isOptionSelected) {
      this.setState({ smartSearchValue: selectedValue })
    }
    this.setState({ isLoading: true })
    const selectedOption = this.state.smartSearchOptions.find(
      (option) => option.value === selectedValue
    );
    if (selectedOption && selectedOption.complete_data) {
      const { complete_data } = selectedOption;
      const page = 1;
      const perPage = 10;
      const data = {
        search_key: selectedValue,
        selected_key: complete_data
      }
      API.post(`/get_smart_search_result?page=${page}&perPage=${perPage}`, data)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              mylistings: response.data.listings,
              isLoading: false,
              all_property_images: response.data.listings.data.property_images ? response.data.listings.data.property_images.split(",") : []
            })
          }
          return response.data;
        })
        .catch((e) => {
          console.log("error", e)
        }).finally(() => {
          this.setState({ isLoading: false })
        })

    }
  }

  handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.setState({ isOptionSelected: false })
      let selectedValue = "";
      if (this.state.smartSearchOptions && this.state.smartSearchOptions.length > 0) {
        selectedValue = this.state.smartSearchOptions[0].value;
      }
      this.setState({ aiSearch: true })
      this.setState({ selectedValue: selectedValue })
      this.setState({ smartSearchValue: e.target.value })
      this.setState({ isLoading: true })
      const selectedOption = this.state.smartSearchOptions.find(
        (option) => option.value === selectedValue
      );
      let complete_data = {};
      if (selectedOption && selectedOption.complete_data) {
        complete_data = selectedOption.complete_data;
      }
      const page = 1;
      const perPage = 10;
      const data = {
        search_key: selectedValue,
        selected_key: complete_data
      }
      API.post(`/get_smart_search_result?page=${page}&perPage=${perPage}`, data)
        .then((response) => {
          this.setState({ isOptionSelected: true })
          if (response.data.success) {
            this.setState({
              mylistings: response.data.listings,
              isLoading: false,
              all_property_images: response.data.listings.data.property_images ? response.data.listings.data.property_images.split(",") : []
            })
          }
          return response.data;
        })
        .catch((e) => {
          console.log("error", e)
        }).finally(() => {
          this.setState({ isLoading: false })
        })

    }
  };

  onChangeSmartSearch = (e) => {
    this.setState({ smartSearchValue: e.target.value });
    const data = {
      search_key: e.target.value
    }
    API.post("/get_smart_search_options_listing", data)
      .then((response) => {
        if (response.data && response.data.search_result && response.data.search_result.hits && response.data.search_result.hits.hits) {
          const search_r = [];
          for (const hit of response.data.search_result.hits.hits) {
            search_r.push({ value: hit._source.title, complete_data: hit._source });
          }
          this.setState({ smartSearchOptions: search_r })
        } else {
          this.setState({ smartSearchOptions: [] })
        }
      })
      .catch((e) => {
        console.log("error", e)
      })
  }

  handleLessValue = (e) => {
    this.setState({ lessValue: e.target.value })
  }

  handleMinKeyDown = (event) => {
    const maxValue = this.state.greaterValue
    const data = {
      greaterValue: this.state.greaterValue,
      lessValue: this.state.lessValue
    }
    if (event.key === 'Enter') {
      if (maxValue > 0) {
      } else {
        this.textInput.current.focus();
      }
    }
  }

  // New Code By Farooq
  fetchData = async (page, perPage, data) => {

    this.setState({ isLoading: true })
    const result = await API.post(`/archive_listings?page=${page}&perPage=${perPage}`, data)
      .then(async (response) => {
        if (response.data.success) {
          this.setState({
            mylistings: response.data.listings,
            isLoading: false,
            all_property_images: response.data.listings.data.property_images ? response.data.listings.data.property_images.split(",") : []
          })
        }
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      })
    // console.log("********* FETCH RESULT ****************", result.success)

    const xCheck = await result;
    if (xCheck.success) {
      this.handleDrawerDetail();
    }
  }

  handleDrawerDetail = () => {
    // console.log("************** Data State ************* ",this.state.mylistings)
    if (this.props.match.params.id) {
      this.handleOpenDrawerToEdit(this.props.match.params.id, false);
    }
  }

  /////////////////////////////////////////////////////////////////////

  // OLD CODE
  // fetchData = (page, perPage,data) => {
  // this.setState({isLoading:true})
  // //  setTimeout(() => {
  // API.post(`/listings?page=${page}&perPage=${perPage}`,data)
  //      .then(async (response)  =>{

  //         var data = await response.data.listings.data;

  //         await response.data.listings;
  //         this.setState({
  //             mylistings: response.data.listings,
  //             isLoading: false
  //         })
  //      })
  //     // }, 200);
  // }

  onDragEnd = (fromIndex, toIndex) => {

    // const columns = columns
    const item = columns.splice(fromIndex, 1)[0];
    //columns.splice(toIndex, 0, item);
    columns.push(item)

  };

  handleSortUpdate = (oldIndex, newIndex, totalItems) => {
    let new_position = this.arrayMove(this.state.property_images, oldIndex, newIndex);
    this.setState({ property_images: new_position })
    const data = {
      listing_id: this.state.record_id,
      old_sorting: oldIndex,
      new_sorting: newIndex,
      property_images: this.arrayMove(this.state.property_images, oldIndex, newIndex)
    }
    API.post("/sort_listing_images", data)
      .then((response) => {
        console.log("test", response)
      })
      .catch((e) => {
        console.log("error", e)
      })
  };


  handleTabs = (e) => {


    this.setState({ isLoading: true })


    API.get("/get_listing_saved_filter/" + e)
      .then((response) => {



        // return
        const response_filter = response.data.filter

        // const property_type = response.data.filters_property_types
        // const property_for = response.data.filter_property_for

        const available_date_value = response.data.filter_available_date_value
        const furnished_unfurnished = response.data.filters_furnished_unfurnished
        const type_value_id = response.data.filters_type_value_id
        const status_filter = response.data.filters_status_filter
        const property_for_filter = response.data.filters_property_for_filter

        const available_date_value_data = []
        const furnished_unfurnished_data = []
        const type_value_id_data = []
        const status_filter_data = []
        const property_for_filter_data = []

        for (let i = 0; i < available_date_value.length; i++) {
          available_date_value_data.push(available_date_value[i]);

        }

        for (let i = 0; i < furnished_unfurnished.length; i++) {
          furnished_unfurnished_data.push(furnished_unfurnished[i]);

        }

        for (let i = 0; i < type_value_id.length; i++) {
          type_value_id_data.push(type_value_id[i]);

        }

        for (let i = 0; i < status_filter.length; i++) {
          status_filter_data.push(status_filter[i]);

        }

        for (let i = 0; i < property_for_filter.length; i++) {
          property_for_filter_data.push(property_for_filter[i]);

        }

        this.setState({

          bedroom_filter: response_filter.bedrooms_filter,
          property_type_filter: type_value_id_data,
          status_filter: status_filter_data,
          furnished_types_value: furnished_unfurnished_data,
          community_filter: response_filter.community_value_id,
          sub_community_filter: response_filter.sub_community_value_id,
          property_filter: response_filter.property_value_id,

          refno_first_value_id: this.state.refno_first_value_id,
          refno_first_value: this.state.refno_first_value,
          unitno_first_value_id: this.state.unitno_first_value_id,
          unitno_first_value: this.state.unitno_first_value,

          property_type: this.state.propert_type_value,
          property_for: property_for_filter_data,

          price_first_value_id: response_filter.price_first_value_id,
          price_first_value: response_filter.price_first_value,
          price_min: response_filter.price_min,
          price_max: response_filter.price_max,

          date_morethan_option: this.state.date_morethan_option,
          date_lessthan_option: this.state.date_lessthan_option,

          publishdate_morethan_option: this.state.publishdate_morethan_option,
          publishdate_lessthan_option: this.state.publishdate_lessthan_option,

          date_value: response_filter.added_date_value,
          date_value_id: response_filter.added_date_value_id,
          available_date: available_date_value_data,
        });
        const all_data = store.getState((state) => state);
        const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
        const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
        const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
        const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

        const data = {
          listings_id: this.state.listings_id,
          pond_filter: this.state.pond_filter == true ? "Yes" : "No",
          selectedAgents: selectedAgents,
          isMatchedListing: isMatchedListing == true ? 1 : 0,
          isExpiringListing: isExpiringListing == true ? 1 : 0,
          isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
          last_call_value: this.state.last_call_value,
          last_call_value_id: this.state.last_call_value_id,
          last_call_option_value: this.state.last_call_option_value,
          pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
          matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
          matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
          matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
          matched_leads_viewing_value: this.state.matched_leads_viewing_value,
          lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
          bedroom_filter: response_filter.bedrooms_filter,
          property_type_filter: type_value_id_data,
          status_filter: status_filter_data,
          furnished_types_value: furnished_unfurnished_data,
          community_filter: response_filter.community_value_id,
          sub_community_filter: response_filter.sub_community_value_id,
          property_filter: response_filter.property_value_id,
          refno_first_value_id: this.state.refno_first_value_id,
          refno_first_value: this.state.refno_first_value,
          unitno_first_value_id: this.state.unitno_first_value_id,
          unitno_first_value: this.state.unitno_first_value,

          property_type: this.state.propert_type_value,
          property_for: property_for_filter_data,

          price_first_value_id: response_filter.price_first_value_id,
          price_first_value: response_filter.price_first_value,
          price_min: response_filter.price_min,
          price_max: response_filter.price_max,

          date_morethan_option: this.state.date_morethan_option,
          date_lessthan_option: this.state.date_lessthan_option,

          publish_date_morethan_option: this.state.publish_date_morethan_option,
          publish_date_lessthan_option: this.state.publish_date_lessthan_option,

          date_value: response_filter.added_date_value,
          date_value_id: response_filter.added_date_value_id,
          available_date: available_date_value_data,
          filter_key: this.state.global_search
        }

        const page = 1;
        const perPage = 10;
        this.fetchData(page, perPage, data);
      })




    // const page = 1;
    //   const perPage = 10;
    //
    //   const data  = {
    //     bedroom_filter:this.state.bedroom_filter,
    //     property_type_filter:this.state.property_type_filter,
    //     status_filter:this.state.status_filter,
    //       furnished_types_value: this.state.furnished_types_value,
    //     community_filter:this.state.community_filter,
    //       agent_filter:this.state.agent_filter,
    //     sub_community_filter:this.state.sub_community_filter,
    //     property_filter:this.state.property_filter,
    //     refno_first_value_id:this.state.refno_first_value_id,
    //     refno_and_or_option:this.state.refno_and_or_option,
    //     refno_second_value_id:this.state.refno_second_value_id,
    //     refno_first_value:this.state.refno_first_value,
    //     refno_second_value:this.state.refno_second_value,
    //
    //     unitno_first_value_id:this.state.unitno_first_value_id,
    //     unitno_and_or_option:this.state.unitno_and_or_option,
    //     unitno_second_value_id:this.state.unitno_second_value_id,
    //     unitno_first_value:this.state.unitno_first_value,
    //     unitno_second_value:this.state.unitno_second_value,
    //
    //     price_first_value_id:this.state.price_first_value_id,
    //     price_and_or_option:this.state.price_and_or_option,
    //     price_second_value_id:this.state.price_second_value_id,
    //     price_first_value:this.state.price_first_value,
    //     price_second_value:this.state.price_second_value,
    //     price_min:this.state.price_min,
    //     price_max:this.state.price_max,
    //
    //       leads_count_first_value_id:this.state.leads_count_first_value_id,
    //       leads_count_and_or_option:this.state.leads_count_and_or_option,
    //       leads_count_second_value_id:this.state.leads_count_second_value_id,
    //       leads_count_first_value:this.state.leads_count_first_value,
    //       leads_count_second_value:this.state.leads_count_second_value,
    //       leads_count_min:this.state.leads_count_min,
    //       leads_count_max:this.state.leads_count_max,
    //
    //       viewings_count_first_value_id:this.state.viewings_count_first_value_id,
    //       viewings_count_and_or_option:this.state.viewings_count_and_or_option,
    //       viewings_count_second_value_id:this.state.viewings_count_second_value_id,
    //       viewings_count_first_value:this.state.viewings_count_first_value,
    //       viewings_count_second_value:this.state.viewings_count_second_value,
    //       viewings_count_min:this.state.viewings_count_min,
    //       viewings_count_max:this.state.viewings_count_max,
    //
    //       offers_count_first_value_id:this.state.offers_count_first_value_id,
    //       offers_count_and_or_option:this.state.offers_count_and_or_option,
    //       offers_count_second_value_id:this.state.offers_count_second_value_id,
    //       offers_count_first_value:this.state.offers_count_first_value,
    //       offers_count_second_value:this.state.offers_count_second_value,
    //       offers_count_min:this.state.offers_count_min,
    //       offers_count_max:this.state.offers_count_max,
    //
    //     date_first_value_id:this.state.date_first_value_id,
    //     date_and_or_option:this.state.date_and_or_option,
    //     date_second_value_id:this.state.date_second_value_id,
    //     date_first_value:this.state.date_first_value,
    //     date_second_value:this.state.date_second_value,
    //     date_morethan_option:this.state.date_morethan_option,
    //     date_lessthan_option:this.state.date_lessthan_option,
    //     date_value:this.state.date_value,
    //     date_value_id:this.state.date_value_id,
    //       available_date: this.state.dateRange,
    //       leads_count: this.state.leads_count,
    //     property_type:property_type_array,
    //     property_for:property_for_array
    //   }
    //
    //
    //
    //   this.fetchData(page, perPage,data);

  }

  handleOpenDrawer = () => {
    this.setState({ open: true })
  }

  handleCloseDrawer = () => {
    this.setState({ open: false })
  }
  handleSaveData = () => {
    if (this.validate()) {
      const data = {
        "unit_no": this.state.unit_no,
        "community": this.state.community_value_id,
        "sub_community": this.state.sub_community_value_id,
        "property": this.state.property_value_id,
        "owner_name": this.state.owner_name,
        "owner_email": this.state.owner_email,
        "owner_mobile": this.state.owner_mobile,
        "price": this.state.price,
        "property_type": this.state.property_type,
        "stage": this.state.stage,
        "bedrooms": this.state.bedroom,
        "property_for": this.state.property_for,
        "listing_note": this.state.listing_note,
        "avail_date": (this.state.property_for == 'rent' || this.state.property_for == 'short-term') ? this.state.avail_date : ""
      }
      this.setState({ "is_loading": true });
      API.post("/add_new_all_listing", data)
        .then((response) => {
          if (response.data.success) {
            this.setState({ "is_loading": false });
            toast.success("Successfully added")
            this.setState({ open: false })
          } else {
            toast.error('something went wrong');
            this.setState({ "is_loading": false });
          }
        })
        .catch((err) => {
          console.log(err)
          toast.error('something went wrong');
          this.setState({ "is_loading": false });
        })
    }
  }

  // Farooq
  findNextOrder = (xId) => {
    let index = this.state.mylistings.data.findIndex(({ id }) => id == xId)
    // return index > -1 && index < this.state.mylistings.data.length - 1 ? this.state.mylistings.data[index + 1] : undefined
    return index > -1 && index < this.state.mylistings.data.length - 1 ? this.state.mylistings.data[index + 1] : undefined
  }

  findPrevOrder = (xId) => {
    let index = this.state.mylistings.data.findIndex(({ id }) => id == xId)
    return index > -1 && index < this.state.mylistings.data.length ? this.state.mylistings.data[index - 1] : undefined
  }
  ///////////////

  emptyState() {
    this.setState({
      selected_portals_id: [],
      selected_portals: [],
      isSameValueCheck: false,
      avail_date: "",
      lifestyle_details: "",
      curation_details: [],
      selected_facilities: [],
      listing_details: {},
      property_images: [],
      notes: [],
      call_notes: [],
      whatsapp_notes: [],
      email_notes: [],
      agent_name_details: "",
      agent_name_details: "",
      property_for_details: "",
      international: 0,
      country: "",
      city: "",
      property_type_details: 0,
      completion_status: "Yes",
      video_link: "",
      livetour_link: "",
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      unit_no_details: 0,
      lead_gen: false,
      just_listed: false,
      community_details: 0,
      subcommunity_details: 0,
      property_details: 0,
      bedroom_details: 0,
      bathroom_details: "",
      price_details: "",
      bua: "",
      plot_area: "",
      rera_permit_details: "",
      view_details: "",
      furnished_details: "",
      listing_status_details: "",
      emirate_id: "",
      form_a: 0,
      description_details: "",
      title_details: "",
      title_deed: "",
      exclusive: "",
      deed_expire: "",
      owner_other_properties: [],
      document_notes: [],
      documents: [],
      owner_id: "",
      available_date: "",
      owner_name: '',
      owner_email: '',
      owner_mobile: "",
      all: [],
    })
  }

  handleOpenDrawerToEdit = (id, xPrev_Next) => {
    if (id == undefined || id == "" || id == null) {
      return;
    }
    this.emptyState();
    if (xPrev_Next) {
      this.setState({
        loader: true
      });
    }

    this.setState({ matchs: [], not_matchs: [] })
    this.setState({ matchs: [], not_matchs: [] })
    const nextId = this.state.mylistings.data !== undefined && this.findNextOrder(id);
    const prevId = this.state.mylistings.data !== undefined && this.findPrevOrder(id);
    // const nextId = this.findNextOrder(id);
    // const prevId = this.findPrevOrder(id);

    // console.log("**Now Id***", id);
    // console.log("**Prev Id***", prevId);
    // console.log("**Next Id***", nextId);

    this.setState({
      // record_id: xPrev_Next ? this.state.record_id : id,
      nextId: nextId == undefined ? this.state.nextId : nextId.id,
      prevId: prevId == undefined ? this.state.prevId : prevId.id,
      currId: id
    })
    ///////////////////

    this.setState({ record_id: id })

    API.get("/listing_details/" + id)
      .then((response) => {

        if (response.data.success) {
          this.setState({
            listing_details: response.data.listing_details, property_images: response.data.images, notes: response.data.notes,
            call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
            email_notes: response.data.email_notes, agent_name_details: response.data.listing_details.agent,
            agent_name_details: response.data.listing_details.agent_id, property_for_details: response.data.listing_details.property_for,
            international: response.data.listing_details.international, country: (response.data.listing_details.country) ? response.data.listing_details.country : 198,
            city: (response.data.listing_details.emirate) ? response.data.listing_details.emirate : 1,
            role: response.data.role.role,
            agent_id: response.data.role.id,
            pf_property: parseInt(response.data.listing_details.pf_property),
            pf_project: parseInt(response.data.listing_details.pf_project),
            marketing_agent: (response.data.listing_details.marketing_agent == "" || response.data.listing_details.marketing_agent == "") ? "" : response.data.listing_details.agent_id,
            title_details: response.data.listing_details.title,
            selected_facilities: response.data.selected_amenities,
            lifestyle_details: response.data.life_style_detail,
            curation_details: response.data.curation_details,
            referred_by: (response.data.listing_details.referred_by != "" && response.data.listing_details.referred_by != null && response.data.listing_details.referred_by != undefined) ? response.data.listing_details.referred_by : response.data.listing_details.agent_id,
            referred_to: response.data.listing_details.referred_to,
            is_referred: (response.data.listing_details.referred_to == null || response.data.listing_details.referred_to == "") ? false : true,
            selected_portals_id: response.data.selected_portals_id,
            selected_portals: response.data.selected_portals,
            // isSameValueCheck: this.isCheckSameValue(response.data.selected_portals),
            rera_permit_details: response.data.listing_details.rera_permit,
            rera_permit: response.data.listing_details.rera_permit,
            lat: response.data.listing_details.latitude, lng: response.data.listing_details.longitude,
            bua: response.data.listing_details.bua,
            plot_area: response.data.listing_details.plot_area,
            avail_d: response.data.listing_details.avail_date, property_category: response.data.listing_details.property_category,
            property_type_details: response.data.listing_details.property_category_id, completion_status: (response.data.listing_details.completed == null) ? 'Yes' : response.data.listing_details.completed,
            style_property_details: response.data.listing_details.style_property_details,
            video_link: response.data.listing_details.video_link, livetour_link: response.data.listing_details.livetour_link,
            meta_title: response.data.listing_details.meta_title,
            meta_description: response.data.listing_details.meta_desc,
            meta_keywords: response.data.listing_details.meta_keywords,
            unit_no_details: response.data.listing_details.unitno, lead_gen: response.data.listing_details.leadgen == "Yes" ? true : false,
            just_listed: response.data.listing_details.just_listed == 1 ? true : false,
            community_details: response.data.listing_details.development, subcommunity_details: response.data.listing_details.project,
            property_details: response.data.listing_details.property, bedroom_details: response.data.listing_details.bedrooms, bathroom_details: response.data.listing_details.bathrooms,
            price_details: response.data.listing_details.price, view_details: response.data.listing_details.view,
            vacant_details: response.data.listing_details.vacant_details,
            upgraded_details: response.data.listing_details.upgraded_details,
            furnished_details: response.data.listing_details.furnished,
            maids_room: response.data.listing_details.maids_room,
            // tags_detail:this.state.tags_detail,description_details:this.state.description_details,
            listing_status_details: response.data.listing_details.status,
            emirate_id: response.data.listing_details.emirate_id, form_a: response.data.listing_details.form_a,
            title_deed: response.data.listing_details.title_deed, exclusive: response.data.listing_details.exclusive,
            deed_expire: response.data.listing_details.deed_expire, owner_other_properties: response.data.owner_other_properties,
            document_notes: response.data.document_notes, documents: response.data.documents,
            description_details: response.data.listing_details.description,
            owner_id: response.data.listing_details.owners_id,
            owner_name: response.data.listing_details.owner,
            owner_email: response.data.listing_details.owner_email,
            owner_mobile: response.data.listing_details.owner_mobile, price_application: response.data.listing_details.poa == "Y" ? true : false,
            // Add By Farooq
            all: response.data.all,
            // Farooq
            loader: false
          })

          // this.setState({openToEdit:true})
          this.fetchFollowUpDetails(this.state.record_id)
          this.fetchViewings(id)
          this.fetchOffers(id);

          // By Farooq
          if (!xPrev_Next) {
            this.setState({
              openToEdit: true,
              disabledNext: false,
              disabledPrev: false
            })
          }

          ///// chnages By Farooq ////
          this.setState({ currUrl: window.location.href })
          window.history.replaceState(null, "all_listings Id", window.location.href + '/' + this.state.record_id)
          /////////////////////////


          API.get("/all_agents")
            .then((response) => {
              if (response.data.success) {
                this.setState({ agents: response.data.agents })
              }
            })

          API.get("/facilities")
            .then((response) => {
              if (response.data.success) {
                this.setState({ facilities: response.data.facilities })
              }
            })

          API.get("/portals")
            .then((response) => {
              if (response.data.success) {
                this.setState({ portals: response.data.portals })
              }
            })

          API.get("/all_sub_community")
            .then((response) => {

              if (response.data.success) {
                this.setState({ sub_communities: response.data.sub_communities })
              }
            })

          API.get("/all_client_users")
            .then((response) => {
              // console.log(response.data.users)


              if (response.data.success) {
                this.setState({ allUsers: response.data.users })
              }
            })
        }
        // Farooq
        else {
          this.setState({
            loader: false
          })
        }
      })
  }

  fetchOffers(listing_id) {
    API.get("/listing_offers/" + listing_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({ listing_offers: response.data.listing_offers })
        }

      })
  }

  fetchViewings(listing_id) {


    API.get("/listing_viewings/" + listing_id)
      .then((response) => {



        if (response.data.success) {
          this.setState({ property_viewings: response.data.property_viewings })
        }
      })
  }

  handleCloseEditDrawer = () => {
    this.setState({ openToEdit: false })

    // Farooq
    const newUrl = window.location.href.replace(/all_listings.*/, 'all_listings');
    // window.history.replaceState(null, "Leads Id", this.state.currUrl)
    window.history.replaceState(null, "all_listings Id", newUrl);
    this.setState({ currUrl: "" })
    //////////////////
  }

  handleSaveEditDrawer = () => {
    toast.success("Successfully updated")
    this.setState({ openToEdit: false })
  }

  handleNextRecord = (e) => {
    // New Code By Farooq
    let newUrl;
    const arr = this.state.mylistings.data.slice(-1);
    if (this.state.currId == arr[0].id) {
      this.setState({ disabledNext: true });

    }
    else {
      this.handleOpenDrawerToEdit(this.state.nextId, true);
      this.setState({ disabledPrev: false });

      if (this.props.match.params.id) {
        newUrl = window.location.href.replace(/all_listings.*/, `all_listings`);
      }
      else {
        newUrl = window.location.href.replace(/all_listings.*/, `all_listings`);
      }
      this.setState({ currUrl: newUrl })
      window.history.replaceState(null, "all_listings Id", newUrl);
    }
    //////////////////////////////////////////


    // OLD CODE
    // const id = this.state.record_id;

    // API.get("/next_listing/"+id)
    // .then((response)=>{
    //  if(response.data.success){
    //   this.setState({listing_details:response.data.listing_details,property_images:response.data.images,notes:response.data.notes,
    //     call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
    //     email_notes:response.data.email_notes,
    //     agent_name_details:response.data.listing_details.agent_id,property_for_details:response.data.listing_details.property_for,
    //     international:response.data.listing_details.international,country:response.data.listing_details.country,
    //     city:response.data.listing_details.emirate,
    //     property_type_details:response.data.listing_details.property_category_id,completion_status:response.data.listing_details.completed,
    //     youtube_link:response.data.listing_details.video_link,livetour_link:response.data.listing_details.livetour_link,
    //      meta_title:response.data.listing_details.meta_title,meta_description:response.data.listing_details.meta_desc,
    //      meta_keywords:response.data.listing_details.meta_keywords,
    //     unit_no_details:response.data.listing_details.unitno,lead_gen:response.data.listing_details.leadgen =="Yes"?true:false,
    //     just_listed:response.data.listing_details.just_listed ==1?true:false,
    //     community_details:response.data.listing_details.development,subcommunity_details:response.data.listing_details.project,
    //     property_details:response.data.listing_details.property,bedroom_details:response.data.listing_details.bedrooms,bathroom_details:response.data.listing_details.bathrooms,
    //     price_details:response.data.listing_details.price,view_details:response.data.listing_details.view,
    //     //vacant_details:this.state.vacant_details,upgraded_details:this.state.upgraded_details,
    //     furnished_details:response.data.listing_details.furnished_details,
    //     //maids_room:this.state.maids_room,tags_detail:this.state.tags_detail,description_details:this.state.description_details,
    //     listing_status_details:response.data.listing_details.status,
    //     emirate_id:response.data.listing_details.emirate_id,form_a:response.data.listing_details.form_a,
    //     title_deed:response.data.listing_details.title_deed,exclusive:response.data.listing_details.exclusive,
    //     deed_expire:response.data.listing_details.deed_expire,owner_other_properties:response.data.owner_other_properties,record_id:response.data.listing_details.id,
    //    document_notes:response.data.document_notes,documents:response.data.documents
    //   })
    //   this.fetchViewings(id)
    //   this.fetchOffers(id);
    //     // this.setState({listing_details:response.data.next_record,record_id:response.data.next_record.id})
    //  }else{
    //   toast.warning("last record")
    //  }
    // })
  }

  handlePreviousRecord = (e) => {
    // New CODE ****
    const arr = this.state.mylistings.data.slice(0, 1);
    if (this.state.currId == arr[0].id) {
      this.setState({ disabledPrev: true })
    }
    else {
      this.handleOpenDrawerToEdit(this.state.prevId, true)
      this.setState({ disabledNext: false })

      // Farooq
      const newUrl = window.location.href.replace(/all_listings.*/, `all_listings`);
      this.setState({ currUrl: newUrl })
      window.history.replaceState(null, "all_listings Id", newUrl);
      /////////////////////////
    }



    // OLD CODE ****
    //  const id = this.state.record_id;
    //  API.get("/previous_listing/"+id)
    //  .then((response)=>{
    //   if(response.data.success){
    //     this.setState({listing_details:response.data.listing_details,property_images:response.data.images,notes:response.data.notes,
    //       call_notes:response.data.call_notes,whatsapp_notes:response.data.whatsapp_notes,
    //       email_notes:response.data.email_notes,
    //       agent_name_details:response.data.listing_details.agent_id,property_for_details:response.data.listing_details.property_for,
    //       international:response.data.listing_details.international,country:response.data.listing_details.country,
    //       city:response.data.listing_details.emirate,
    //       property_type_details:response.data.listing_details.property_category_id,completion_status:response.data.listing_details.completed,
    //       youtube_link:response.data.listing_details.video_link,livetour_link:response.data.listing_details.livetour_link,
    //      meta_title:response.data.listing_details.meta_title,meta_description:response.data.listing_details.meta_desc,
    //      meta_keywords:response.data.listing_details.meta_keywords,
    //       unit_no_details:response.data.listing_details.unitno,lead_gen:response.data.listing_details.leadgen =="Yes"?true:false,
    //       just_listed:response.data.listing_details.just_listed ==1?true:false,
    //       community_details:response.data.listing_details.development,subcommunity_details:response.data.listing_details.project,
    //       property_details:response.data.listing_details.property,bedroom_details:response.data.listing_details.bedrooms,bathroom_details:response.data.listing_details.bathrooms,
    //       price_details:response.data.listing_details.price,view_details:response.data.listing_details.view,
    //       //vacant_details:this.state.vacant_details,upgraded_details:this.state.upgraded_details,
    //       furnished_details:response.data.listing_details.furnished_details,
    //       //maids_room:this.state.maids_room,tags_detail:this.state.tags_detail,description_details:this.state.description_details,
    //       listing_status_details:response.data.listing_details.status,
    //       emirate_id:response.data.listing_details.emirate_id,form_a:response.data.listing_details.form_a,
    //       title_deed:response.data.listing_details.title_deed,exclusive:response.data.listing_details.exclusive,
    //       deed_expire:response.data.listing_details.deed_expire,owner_other_properties:response.data.owner_other_properties,record_id:response.data.listing_details.id,
    //      document_notes:response.data.document_notes,documents:response.data.documents
    //     })
    //     this.fetchViewings(id)
    //     this.fetchOffers(id);
    //   }else{
    //     toast.warning("last record")
    //   }
    //  })
  }


  handlePropertFor = (e) => {
    this.setState({ property_for: e.target.value })
  }

  validate() {
    let property_type = this.state.property_type;
    let unit_no = this.state.unit_no;
    let community = this.state.community_value_id;
    let sub_community = this.state.sub_community_value_id;
    let owner_name = this.state.owner_name;
    let owner_mobile = this.state.owner_mobile;
    let price = this.state.price;
    let stage = this.state.stage;
    let bedroom = this.state.bedroom;
    let property_for = this.state.property_for;
    let avail_date = this.state.avail_date;
    let phone = this.state.owner_mobile;
    let validationerrors = {};
    let isValid = true;


    if (property_type == null || property_type == "" || property_type == 0) {
      isValid = false;
      validationerrors["property_type"] = "Property Type is required.";
    }
    if (unit_no == null || unit_no == "" || unit_no == 0) {
      isValid = false;
      validationerrors["unit_no"] = "Unit no is required.";
    }
    if (community == null || community == "" || community == 0) {
      isValid = false;
      validationerrors["community"] = "Community is required.";
    }
    if (sub_community == null || sub_community == "" || sub_community == 0) {
      isValid = false;
      validationerrors["sub_community"] = "Sub community is required.";
    }
    if (owner_name == null || owner_name == "" || owner_name == 0) {
      isValid = false;
      validationerrors["owner_name"] = "Owner name is required.";
    }
    if (owner_mobile == null || owner_mobile == "" || owner_mobile == 0) {
      isValid = false;
      validationerrors["owner_mobile"] = "Owner mobile is required.";
    }
    if (price == null || price == "" || price == 0) {
      isValid = false;
      validationerrors["price"] = "Price is required.";
    }
    if (stage == null || stage == "" || stage == 0) {
      isValid = false;
      validationerrors["stage"] = "Stage is required.";
    }
    if ((bedroom == null || bedroom == "" || bedroom == 0) && (property_type != 9)) {
      isValid = false;
      validationerrors["bedroom"] = "Bedroom is required.";
    }
    if (property_for == null || property_for == "" || property_for == 0) {
      isValid = false;
      validationerrors["property_for"] = "Property for is required.";
    }
    if ((property_for == "short-term" || property_for == "rent") && (avail_date == null || avail_date == "" || avail_date == 0)) {
      isValid = false;
      validationerrors["avail_date"] = "Available date is required.";
    }
    if ((phone == null || phone == "" || phone == 0)) {
      isValid = false;
      validationerrors["phone"] = "Owner phone number is required.";
    }
    if (owner_mobile != "") {

      var phoneNumber = owner_mobile;
      var pattern = /^\+\d{12}$/; // This pattern matches exactly + followed by 12 digits


      if (pattern.test(owner_mobile)) {
        console.log("Phone number is in the correct format patern.");
      } else {
        validationerrors["owner_mobile"] = "Mobile number format should be ex +971xxxxxxx";
      }


    }

    this.setState({
      validationerrors: validationerrors
    });

    return isValid;
  }


  websiteValidate() {
    let meta_title = this.state.meta_title;
    let meta_description = this.state.meta_description;
    let meta_keywords = this.state.meta_keywords;
    let validationerrors = {};
    let isValid = true;


    if (meta_title == null || meta_title == "" || meta_title == 0) {
      isValid = false;
      validationerrors["meta_title"] = "Meta Title is required.";
    }
    if (meta_description == null || meta_description == "" || meta_description == 0) {
      isValid = false;
      validationerrors["meta_description"] = "Meta Description is required.";
    }
    if (meta_keywords == null || meta_keywords == "" || meta_keywords == 0) {
      isValid = false;
      validationerrors["meta_keywords"] = "Meta Keywords is required.";
    }

    this.setState({
      websitevalidationerrors: validationerrors
    });

    return isValid;
  }


  validateFollowup() {
    let followup_for = this.state.followup_for;
    let selected_agent = this.state.selected_agent;
    let followup_date = this.state.followup_date;
    let followup_note = this.state.followup_note;
    let validationerrors = {};
    let isValid = true;


    if (followup_for == null || followup_for == "" || followup_for == 0) {
      isValid = false;
      validationerrors["followup_for"] = "Follow up for is required.";
    }
    if (selected_agent == null || selected_agent == "" || selected_agent == 0) {
      isValid = false;
      validationerrors["selected_agent"] = "Agent is required.";
    }
    if (followup_date == null || followup_date == "" || followup_date == 0) {
      isValid = false;
      validationerrors["followup_date"] = "Date is required.";
    }
    if (followup_note == null || followup_note == "" || followup_note == 0) {
      isValid = false;
      validationerrors["followup_note"] = "Note is required.";
    }

    this.setState({
      validationerrors: validationerrors
    });

    return isValid;
  }

  handleAvailableDate = (state, date, dateString) => {
    state.avail_date = dateString
  }

  handleAvailableDateDetails = (date, dateString) => {
    this.setState({ avail_d: dateString })
  }

  handleAddRow = (value) => {
    const item = {
      name: "",
      mobile: "",
      id: value

    };

    let filter_options = this.state.filter_options.filter(item => item.value !== value)

    this.setState({
      rows: [...this.state.rows, item],
      filter_options: filter_options
    });
  };

  handleRemoveSpecificRow = (idx, id) => () => {
    const rows = [...this.state.rows]


    if (id == 1) {
      const item = {
        value: '1',
        label: 'Status'
      }

      this.setState({
        filter_options: [...this.state.filter_options, item]
      })
    } else
      if (id == 2) {
        const item = {
          value: '2',
          label: 'Property For'
        }

        this.setState({
          filter_options: [...this.state.filter_options, item]
        })
      } else
        if (id == 3) {
          const item = {
            value: '3',
            label: 'Available Date'
          }

          this.setState({
            filter_options: [...this.state.filter_options, item],
            // price_first_value_id:0,
            // price_first_value:0,
            // price_min:0,
            // price_max:0
          })
        } else
          if (id == 4) {
            const item = {
              value: '4',
              label: 'Community / Sub Community'
            }

            this.setState({
              filter_options: [...this.state.filter_options, item]
            })
          } else
            if (id == 5) {
              const item = {
                value: '5',
                label: 'Property'
              }

              this.setState({
                filter_options: [...this.state.filter_options, item]
              })
            } else
              if (id == 6) {
                const item = {
                  value: '6',
                  label: 'Property Type'
                }

                this.setState({
                  filter_options: [...this.state.filter_options, item]
                })
              } else
                if (id == 7) {
                  const item = {
                    value: '7',
                    label: 'Bedrooms'
                  }

                  this.setState({
                    filter_options: [...this.state.filter_options, item]
                  })
                }
    if (id == 8) {
      const item = {
        value: '8',
        label: 'Furnished / Unfurnished'
      }

      this.setState({
        filter_options: [...this.state.filter_options, item]
      })
    }
    if (id == 9) {
      const item = {
        value: '9',
        label: 'Added Date'
      }

      this.setState({
        filter_options: [...this.state.filter_options, item]
      })
    }
    if (id == 10) {
      const item = {
        value: '10',
        label: 'Price'
      }

      this.setState({
        filter_options: [...this.state.filter_options, item]
      })
    }
    if (id == 11) {
      const item = {
        value: '11',
        label: 'No of Leads'
      }

      this.setState({
        filter_options: [...this.state.filter_options, item]
      })
    }
    if (id == 12) {
      const item = {
        value: '12',
        label: 'No of Viewings'
      }

      this.setState({
        filter_options: [...this.state.filter_options, item]
      })
    }
    if (id == 13) {
      const item = {
        value: '13',
        label: 'No of Offers'
      }

      this.setState({
        filter_options: [...this.state.filter_options, item]
      })
    }

    rows.splice(idx, 1)


    this.setState({ rows })
  }

  handleFilterDrawerOpen = () => {
    this.setState({ FilterDrawerOpen: true })
  }
  handleFilterDrawerClose = () => {
    this.setState({ FilterDrawerOpen: false })
  }

  handleMinBudget = (e) => {
    const value = e.target.value
    const new_value = value.replace(/,/g, "")
    this.setState({ min_budget: new_value })
  }

  handleMaxBudget = (e) => {
    const value = e.target.value
    const new_value = value.replace(/,/g, "")
    this.setState({ max_budget: new_value })
  }

  handleBeds = (e) => {
    this.setState({ bedroom_match: e })
  }

  handleCommunityMatch = (e) => {
    this.setState({ community_match: e })
    API.get("/sub_community/" + e)
      .then((response) => {
        if (response.data.success)
          this.setState({ sub_communities: response.data.sub_communities })
      })
  }

  handleSubCommunityMatch = (e) => {
    this.setState({ sub_community_match: e })

  }


  handleSubCommunity = (value) => {
    this.setState({ sub_community_value_id: value })
    API.get("/property/" + value)
      .then((response) => {
        if (response.data.success)
          if (response.data.success)
            this.setState({ properties: response.data.properties })
      })
  }

  handleProperty = (value) => {
    this.setState({ property_value_id: value })
  }

  handleOwnerName = (e) => {
    this.setState({ owner_name: e.target.value })
  }

  handleUnitNo = (e) => {
    this.setState({ unit_no: e.target.value })
  }

  handlePrice = (e) => {
    this.setState({ price: e.target.value })
  }

  handleOwnerPhone = (e) => {
    this.setState({ owner_mobile: e.target.value })
  }

  handleOwnerEmail = (e) => {
    this.setState({ owner_email: e.target.value })
  }

  handlePropertyTypeValue = (e) => {
    this.setState({ property_type: e })
  }

  handleStage = (e) => {
    this.setState({ stage: e.target.value })
  }

  handleBedroom = (e) => {
    this.setState({ bedroom: e.target.value })
  }

  handlePropertForMatch = (e) => {
    this.setState({ property_for_match: e })
  }

  handlePropertTypeMatch = (e) => {
    this.setState({ property_type_match: e })
  }

  handleAddedDateMatch = (e) => {
    this.setState({ added_date_match: e })
  }

  handleMatch = () => {
    const data = {
      status_filter: this.state.status_filter,
      // listing_id:this.state.record_id,
      community_match: this.state.community_match,
      sub_community_match: this.state.sub_community_match,
      min_budget: this.state.min_budget, max_budget: this.state.max_budget,
      beds: this.state.bedroom_match, property_for_match: this.state.property_for_match,
      property_type_match: this.state.property_type_match,
      added_date_match: this.state.added_date_match
    }

    API.post("/match_listings", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ matchs: response.data.match_listings, not_matchs: response.data.not_match_listings })
        }
      })
  }

  handleCallLog = (e) => {
    this.setState({ call_log: e.target.value })
  }

  handleCallLogModal = () => {
    this.setState({ isCallLogOpen: true })
  }
  handleCallLogModalCancel = () => {
    this.setState({ isCallLogOpen: false })
  }

  handleCallNoteButtons = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      this.setState({ isCallEnable: true })
    } else { this.setState({ isCallEnable: false }) }
  }

  handleCallLogDiscuss = (e) => {
    this.setState({ call_log_discuss: e.target.value })
  }

  handlePropertyStillAvailable = (e) => {
    this.setState({ isPropertyAvailable: !this.state.isPropertyAvailable })
  }

  handleConfirmPrice = (e) => {
    this.setState({ confirm_price: e.target.value })
  }

  handleNoteComment = (e) => {
    this.setState({ note_comment: e.target.value })
  }

  handleCallLogSubmit = () => {
    const userdata = store.getState((state) => state);
    let user = userdata.auth.user;
    let user_id = user.id

    const data = {
      listing_id: this.state.record_id,
      call_log_discuss: this.state.call_log_discuss, isPropertyAvailable: this.state.isPropertyAvailable == true ? "Yes" : "No", confirm_price: this.state.confirm_price, note_comment: this.state.note_comment
    }
    API.post("/add_call_log", data)
      .then((res) => {

        if (res.data.success) {
          this.setState({
            notes: res.data.notes,
            call_notes: res.data.call_notes, whatsapp_notes: res.data.whatsapp_notes,
            email_notes: res.data.email_notes,
            agent_name_details: this.state.listing_details.agent_id == 430 ? user_id : this.state.listing_details.agent_id,
            all: res.data.all, isCallLogOpen: false, call_log_discuss: "", isPropertyAvailable: false, confirm_price: "", note_comment: ""
          })
        }
      })
  }

  handleCallSubmit = () => {
    if (this.state.call_log == '') {
      toast.warning('Call log cannot be empty');
    }
    else {
      this.setState({ call_loader: true })
      const data = {
        listing_id: this.state.record_id,
        note: this.state.call_log,
        note_type: "phone",
        users: this.state.selectedCallUsers
      }


      API.post("/add_note", data)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              notes: response.data.notes,
              call_notes: response.data.call_notes, whatsapp_notes: response.data.whatsapp_notes,
              email_notes: response.data.email_notes,
              call_log: "",
              selectedCallUsers: [],
              call_loader: false,
              all: response.data.all
            })
            Swal.fire({
              title: 'Do you want to add follow up?',
              icon: 'questions',
              showCancelButton: true,
              confirmButtonColor: '#151515',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
              cancelButtonText: 'No'
            }).then((result) => {
              if (result.isConfirmed) {
                this.setState({ isfollowupOpen: true })
              }
            })
          } else {
            this.setState({
              call_loader: false
            })
          }
        })
        .catch((err) => {
          console.log(err)
          this.setState({
            call_loader: false
          })
          toast.error('something went wrong');
        })
    }
  }


  handleNoteLog = (e) => {
    this.setState({ note_log: e.target.value })
  }
  handleNoteSubmit = () => {
    if (this.state.note_log == '') {
      toast.warning('Note log cannot be empty');
    }
    else {
      this.setState({ note_loader: true })
      const data = {
        listing_id: this.state.record_id,
        note: this.state.note_log,
        note_type: "note",
        users: this.state.selectedNoteUsers
      }

      API.post("/add_note", data)
        .then((response) => {

          if (response.data.success) {
            this.setState({
              notes: response.data.notes,
              call_notes: response.data.call_notes,
              whatsapp_notes: response.data.whatsapp_notes,
              email_notes: response.data.email_notes,
              selectedNoteUsers: [],
              note_log: "",
              note_loader: false,
              all: response.data.all
            })
          }
          else {
            this.setState({ note_loader: false })
          }
        })
        .catch((err) => {
          this.setState({ note_loader: false });
          toast.error('something went wrong');
        })
    }
  }
  handleWhatsappLog = (e) => {
    this.setState({ whatsapp_log: e.target.value })
  }
  handleWhatsappSubmit = () => {
    if (this.state.whatsapp_log == '') {
      toast.warning('whatsApp log cannot be empty')
    }
    else {
      this.setState({ whatsapp_loader: true })
      const data = {
        listing_id: this.state.record_id,
        note: this.state.whatsapp_log,
        note_type: "whatsapp",
        users: this.state.selectedWhatsappUsers
      }

      API.post("/add_note", data)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              notes: response.data.notes,
              call_notes: response.data.call_notes,
              whatsapp_notes: response.data.whatsapp_notes,
              email_notes: response.data.email_notes,
              selectedWhatsappUsers: [],
              whatsapp_log: '',
              whatsapp_loader: false,
              all: response.data.all
            })

          } else {
            this.setState({ whatsapp_loader: false })
          }
        })
        .catch((err) => {
          this.setState({ whatsapp_loader: false });
          toast.error('something went wrong');
        })
    }
  }
  handleEmailLog = (e) => {
    this.setState({ email_log: e.target.value })
  }

  handleEmailSubmit = () => {
    if (this.state.email_log == '') {
      toast.warning('Email log cannot be empty')
    }
    else {
      this.setState({ email_loader: true })
      const data = {
        listing_id: this.state.record_id,
        note: this.state.email_log,
        note_type: "email",
        users: this.state.selectedEmailUsers,

      }
      API.post("/add_note", data)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              notes: response.data.notes,
              call_notes: response.data.call_notes,
              whatsapp_notes: response.data.whatsapp_notes,
              email_notes: response.data.email_notes,
              selectedEmailUsers: [],
              email_log: '',
              email_loader: false,
              all: response.data.all
            })

          }
          else {
            this.setState({ email_loader: false })
          }
        })
        .catch((err) => {
          this.setState({ email_loader: false });
          toast.error('something went wrong');
        })
    }
  }

  handleViewingSubmit = () => {
    if (this.state.viewing_log == '') {
      toast.warning('Viewing log cannot be empty')
    }
    else {
      this.setState({ viewing_loader: true })
      const data = {
        listing_id: this.state.record_id,
        note: this.state.viewing_log,
        note_type: "viewing",
        users: this.state.selectedViewingUsers
      }

      API.post("/add_note", data)
        .then((response) => {
          console.log("res", response.data)
          if (response.data.success) {
            this.setState({
              notes: response.data.notes,
              call_notes: response.data.call_notes,
              whatsapp_notes: response.data.whatsapp_notes,
              email_notes: response.data.email_notes,
              selectedViewingUsers: [],
              viewing_log: '',
              viewing_loader: false,
              all: response.data.all
            })

          }
          else {
            this.setState({ viewing_loader: false })
          }
        })
        .catch(() => [
          this.setState({ viewing_loader: false })
        ])
    }
  }

  handleOfferSubmit = () => {
    if (this.state.offer_log == '') {
      toast.warning('toast log icannot be empty')
    }
    else {
      this.setState({ offer_loader: true })
      const data = {
        listing_id: this.state.record_id,
        note: this.state.offer_log,
        note_type: "offer",
        users: this.state.selectedOfferUsers
      }

      API.post("/add_note", data)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              notes: response.data.notes,
              call_notes: response.data.call_notes,
              whatsapp_notes: response.data.whatsapp_notes,
              email_notes: response.data.email_notes,
              selectedOfferUsers: [],
              offer_log: '',
              offer_loader: false,
              all: response.data.all
            })

          }
          else {
            this.setState({ offer_loader: false })
          }
        })
        .catch((err) => {
          this.setState({ offer_loader: false });
          toast.error('something went wrong');
        })
    }
  }

  handleFollowupDate = (data, dateString) => {
    this.setState({ followup_date: dateString })
  }

  onChangeAgent = (e) => {
    this.setState({ selected_agent: e.target.value })
  }

  onChangeFollowupFor = (e) => {
    this.setState({ followup_for: e.target.value })
  }

  onChangeReminder = (e) => {
    this.setState({ reminder: e.target.value })
  }

  handleAgentDetailsFollowup = (e) => {
    this.setState({ selected_agent: e.target.value })
  }

  handleFollowupNote = (e) => {
    this.setState({ followup_note: e.target.value })
  }

  handleFollowupSubmit = () => {
    if (this.state.followup_log == '') {
      toast.warning('Followup cannot be empty')
    }
    else {
      this.setState({ followUp_loader: true })
      const data = {
        listing_id: this.state.record_id,
        note: this.state.followup_log,
        note_type: "followup",
        users: this.state.selectedFollowupUsers
      }

      API.post("/add_note", data)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              notes: response.data.notes,
              call_notes: response.data.call_notes,
              whatsapp_notes: response.data.whatsapp_notes,
              email_notes: response.data.email_notes,
              selectedFollowupUsers: [],
              followup_log: '',
              followUp_loader: false,
              all: response.data.all
            })
          } else {
            this.setState({ followUp_loader: false })
          }
        })
        .catch((err) => {
          this.setState({ followUp_loader: false });
          toast.error('something went wrong');
        })
    }
  }

  onDrop = (files) => {
    this.setState({ files })
  };

  arrayMove = (array, sourceIndex, destinationIndex) => {
    const clonedArray = [...array]; // Clone the original array

    const [removedElement] = clonedArray.splice(sourceIndex, 1); // Remove the element at the source index

    clonedArray.splice(destinationIndex, 0, removedElement); // Insert the removed element at the destination index

    return clonedArray; // Return the modified array
  }

  onSortEnd = (position) => {
    let oldIndex = position.source.index;
    let newIndex = position.destination.index;
    let new_position = this.arrayMove(this.state.property_images, oldIndex, newIndex);
    this.setState({ property_images: new_position })
    const data = {
      old_sorting: oldIndex,
      new_sorting: newIndex,
      property_images: this.arrayMove(this.state.property_images, oldIndex, newIndex)
    }
    API.post("/sort_listing_images", data)
      .then((response) => {
        console.log("test", response)
      })
      .catch((e) => {
        console.log("error", e)
      })
  }

  handleUploadImages = (fileWithMeta) => {
    console.log("testing", fileWithMeta);
    return;
    for (let i = 0; i < fileWithMeta.length; i++) {
      const formData = new FormData();
      formData.append("listing_id", this.state.record_id);
      formData.append("files[]", fileWithMeta[i].file);
      API.post("/upload_listing_files", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then((response) => {
        if (response.data.success) {
          this.setState({ property_images: response.data.images })
          fileWithMeta[i].remove();
          imagesArray = []
        }
      })
    }
  }

  updateImages = (images) => {
    this.setState({ property_images: images })
  }


  // save details

  handleAgentDetails = (e) => {

    this.setState({ agent_name_details: e })
  }

  onLeadGen = (e) => {
    this.setState({ lead_gen: e })
  }

  isValidHttpUrl = (string) => {
    try {
      const url = new URL(string);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
      return false;
    }
  }

  onJustListed = (e) => {
    this.setState({ just_listed: e.target.checked })
  }

  getCurrentTime = (date) => {
    if (date != null && date != "" && date != undefined) {
      let newDate = new Date(date);
      newDate.setTime(newDate.getTime() + (4 * 60 * 60 * 1000));
      return newDate.toISOString();
    }
  }
  handlePropertyForDetails = (e) => {
    this.setState({ property_for_details: e })
  }
  handlePropertyCategory = (e) => {
    this.setState({ property_category: e })
  }
  handleCompletionDetails = (e) => {
    this.setState({ completion_status: e })
  }
  handlePropertyTypeDetails = (e) => {
    this.setState({ property_type_details: e })
  }
  handlePropertyStyleDetails = (e) => {
    this.setState({ style_property_details: e })
  }
  handleUnitNoDetails = (e) => {
    this.setState({ unit_no_details: e.target.value })
  }
  handleCommunityDetails = (e) => {
    this.setState({ community_details: e })
    this.getSubCommunity(e);
  }

  handleCurationDetails = (value) => {
    this.setState({ curation_details: value })
  }

  handleLifeStyleDetails = (e) => {
    this.setState({ lifestyle_details: e.target.value })
  }
  handleSubCommunityDetails = (e) => {
    this.setState({ subcommunity_details: e })
    this.getProperty(e)
  }
  handlePropertyDetails = (e) => {
    this.setState({ property_details: e })
  }

  changeReferred_by = (e) => {
    this.setState({ referred_by: e })
  }
  changeReferred_to = (e) => {
    this.setState({ referred_to: e })
  }
  onSaveRef = () => {
    if ((this.state.referred_by == "" || this.state.referred_by == null || this.state.referred_by == undefined)) {
      toast.error("Referred by is required")
    } else if ((this.state.referred_to == "" || this.state.referred_to == null || this.state.referred_to == undefined)) {
      toast.error("Referred to is required")
    } else {
      const data = {
        referred_to: this.state.referred_to,
        referred_by: this.state.referred_by,
        listing_id: this.state.record_id,
      }
      API.post("/listing_referred", data)
        .then((response) => {
          if (response.data.success) {
            toast.success("Listing is successfully referred")
          }
        }).catch((e) => {
          toast.error("Something Went Wrong")
        })
    }
  }

  handleMarketingAgentChange = (e) => {
    this.setState({ marketing_agent: e })
    const data = {
      "new_agent": e,
      "listing_id": this.state.record_id,
      "old_agent": this.state.agent_name_details
    }
    API.post("/assign_listing", data)
      .then((response) => {
        if (response.data.success) {
          if (e == '' || e == null) {
            toast.success("Listing is Successfully Reassign to original Agent")
          } else {
            toast.success("Listing is Successfully Assign")
          }
        } else {
          toast.warning("Something went wrong")
        }
      })
  }
  handleBedDetails = (e) => {
    this.setState({ bedroom_details: e })
  }
  handleaBathroomDetails = (e) => {
    this.setState({ bathroom_details: e })
  }
  handlePriceDetails = (e) => {
    this.setState({ price_details: e.target.value })
  }
  handleBua = (e) => {
    this.setState({ bua: e.target.value })
  }

  handlePlotArea = (e) => {
    this.setState({ plot_area: e.target.value })
  }

  // handleReraPermit = (e) => {
  //   this.setState({ rera_permit_details: e.target.value })
  // }

  handleViewDetails = (e) => {
    this.setState({ view_details: e.target.value })
  }
  handleVacantDetails = (e) => {
    this.setState({ vacant_details: e })
  }
  handleUpgradedDetails = (e) => {
    this.setState({ upgraded_details: e })
  }
  handleFurnishedDetails = (e) => {
    this.setState({ furnished_details: e })
  }
  handleMaidsDetails = (e) => {
    this.setState({ maids_room: e })
  }
  handleTagsDetails = (e) => {
    this.setState({ tags_detail: e.target.value })
  }
  handleTitleDetails = (e) => {
    this.setState({ title_details: e.target.value })
  }
  handleListingStatusDetails = (e) => {

    if (e == "RLP") {
      API.get("/check_contract/" + this.state.record_id)
        .then((res) => {
          if (!res.success) {
            toast.warning("No contract available")
            this.setState({ listing_status_details: "R" })
            return
          } else {
            this.setState({ listing_status_details: e })
          }
        })
    } else {
      this.setState({ listing_status_details: e })
    }




  }
  handleSetailsSubmit = () => {
    const listing_id = this.state.record_id
    const listing_status = this.state.listing_status_details
    const avail_d = this.state.avail_d
    if (listing_status == "R" && !avail_d) {
      this.setState({ showAvailableDateModel: true })
      return
    }
    const data = {
      pf_project: this.state.pf_project, pf_property: this.state.pf_property,
      property_category: this.state.property_category, poa: this.state.price_application ? "Y" : "N",
      agent_name_details: this.state.agent_name_details, property_for_details: this.state.property_for_details,
      completiondetails: this.state.completiondetails, property_type_details: this.state.property_type_details,
      style_property_details: this.state.style_property_details, unit_no_details: this.state.unit_no_details,
      community_details: this.state.community_details, subcommunity_details: this.state.subcommunity_details, completion_status: this.state.completion_status,
      property_details: this.state.property_details, bedroom_details: this.state.bedroom_details, bathroom_details: this.state.bathroom_details,
      form_a: this.state.form_a,
      rera_permit_details: this.state.rera_permit,
      plot_area: this.state.plot_area,
      bua_details: this.state.bua,
      avail_date: this.state.avail_d,
      title_details: this.state.title_details,
      price_details: this.state.price_details, view_details: this.state.view_details, vacant_details: this.state.vacant_details, upgraded_details: this.state.upgraded_details,
      furnished_details: this.state.furnished_details, maids_room: this.state.maids_room, tags_detail: this.state.tags_detail, description_details: this.state.description_details,
      listing_status_details: this.state.listing_status_details, leadgen: this.state.lead_gen == true ? "Yes" : "No", just_listed: this.state.just_listed == true ? 1 : 0
    }
    API.put("/update_listing/" + listing_id, data)
      .then((response) => {
        if (response.data.success) {
          toast.success("Listing Successfully Updated")
        } else {
          toast.warning("Something went wrong")
        }
      })
  }

  handleFormA = (e) => {
    this.setState({ form_a: e.target.value })
  }

  handleEmirateID = (e) => {
    this.setState({ emirate_id: e.target.value })
  }

  handleExclusive = (e) => {
    this.setState({ exclusive: e.target.value })
  }
  handleTitleDeed = (e) => {
    this.setState({ title_deed: e.target.value })
  }
  handleDeedExpire = (e) => {
    this.setState({ deed_expire: e.target.value })
  }

  handleFormSubmit = () => {
    const listing_id = this.state.record_id
    const data = {
      form_a: this.state.form_a, emirate_id: this.state.emirate_id,
      title_deed: this.state.title_deed, exclusive: this.state.exclusive,
      deed_expire: this.state.deed_expire
    }
    API.put("/Update_form/" + listing_id, data)
      .then((response) => {
        console.log("res")
      })
  }

  handlePropertyNotes = (e) => {
    this.setState({ property_note: e.target.value })
  }

  handleVideoLink = (e) => {
    this.setState({ video_link: e.target.value })
  }

  generateDescription = (e) => {
    this.setState({ description_loader: true })
    const description_data = {
      community_details: this.state.community_details,
      subcommunity_details: this.state.subcommunity_details,
      furnished_details: this.state.furnished_details,
      maids_room: this.state.maids_room,
      bedroom_details: this.state.bedroom_details,
      price_details: this.state.price_details,
      bua: this.state.bua,
      plot_area: this.state.plot_area,
      view_details: this.state.view_details,
      title_details: this.state.title_details,
      style_property_details: this.state.style_property_details,
      listing_refno: this.state.listing_details.refno,
      property_type_details: this.state.property_type_details,
      vacant_details: this.state.vacant_details,
      upgraded_details: this.state.upgraded_details,
      property_for_details: this.state.property_for_details
    }
    API.post("/generate_description", description_data)
      .then((response) => {
        this.setState({ description_loader: false })
        if (response.data.success) {
          this.setState({
            description_details: response.data.description
          })
        } else {
          this.setState({ description_loader: false })
          toast.error('Something went wrong');
        }
      }).catch((err) => {
        this.setState({ description_loader: false })
        console.log(err)
        toast.error('Something went wrong');
      }).finally(() => {
        this.setState({ description_loader: false })
      })
  }


  handleDocumentDelete = (document) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          added_by: document.added_by,
          added_date: document.added_date,
          docs_id: document.docs_id,
          document: document.document,
          title: document.title,
          listing_id: this.state.record_id
        }
        API.post("/delete_document", data)
          .then((response) => {
            if (response.data.success) {
              toast.success("Document Successfully deleted")
              this.setState({ documents: response.data.documents })
            } else {
              toast.error('Something went wrong');
            }
          }).catch((error) => {
            console.log(error);
            toast.error('something went wrong');
          });
      }
    })
  }



  generateDescription = (e) => {
    this.setState({ description_loader: true })
    const description_data = {
      community_details: this.state.community_details,
      subcommunity_details: this.state.subcommunity_details,
      furnished_details: this.state.furnished_details,
      maids_room: this.state.maids_room,
      bedroom_details: this.state.bedroom_details,
      price_details: this.state.price_details,
      bua: this.state.bua,
      plot_area: this.state.plot_area,
      view_details: this.state.view_details,
      title_details: this.state.title_details,
      style_property_details: this.state.style_property_details,
      listing_refno: this.state.listing_details.refno,
      property_type_details: this.state.property_type_details,
      vacant_details: this.state.vacant_details,
      upgraded_details: this.state.upgraded_details,
      property_for_details: this.state.property_for_details
    }
    API.post("/generate_description", description_data)
      .then((response) => {
        this.setState({ description_loader: false })
        if (response.data.success) {
          this.setState({
            description_details: response.data.description
          })
        } else {
          this.setState({ description_loader: false })
          toast.error('Something went wrong');
        }
      }).catch((err) => {
        this.setState({ description_loader: false })
        console.log(err)
        toast.error('Something went wrong');
      }).finally(() => {
        this.setState({ description_loader: false })
      })
  }


  handleLiveTourLink = (e) => {
    this.setState({ livetour_link: e.target.value })
  }
  handlePropertyNotesSubmit = () => {
    const data = {
      listing_id: this.state.record_id,
      property_note: this.state.property_note
    }
    API.post("/add_document_notes", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ document_notes: response.data.document_notes, property_note: "" })
        }
      })
  }
  handleDocumentTitle = (e) => {
    this.setState({ document_title: e.target.value })
  }

  handleDocumentFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;
    this.createImage(files[0]);
    this.setState({ documentImageShow: URL.createObjectURL(e.target.files[0]) });
  };
  createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        document_file: e.target.result,
      });
    };
    reader.readAsDataURL(file);

  }

  handleDocumentsSubmit = () => {
    const data = {
      listing_id: this.state.record_id, listing_refno: this.state.listing_details.refno,
      document_file: this.state.document_file, document_title: this.state.document_title

    }
    API.post("/add_documents", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ documents: response.data.documents, document_title: "" })
          toast.success("Document has been sucessfully added")
        }
      })
  }

  handleOpenAmenities = () => {
    this.setState({ openToAmenities: true })
  }

  onChangeFacilities = (facility, e) => {
    let selected_amenities = this.state.selected_facilities;
    let element_exist = selected_amenities.includes(facility.value);
    if (element_exist) {
      var index = selected_amenities.indexOf(facility.value);
      if (index !== -1) {
        selected_amenities.splice(index, 1);
      }
    } else {
      selected_amenities.push(facility.value);
    }
    this.setState({ selected_facilities: selected_amenities });
  }

  handleSaveAmenities = () => {
    const listing_id = this.state.record_id
    const data = {
      "selected_facilities": this.state.selected_facilities,
      "id": listing_id
    }
    API.put("/update_amenities/", data)
      .then((response) => {
        if (response.data.success) {
          toast.success("Amenities are successfully updated")
          this.setState({ openToAmenities: false })
        } else {
          toast.success("Something went wrong")
        }
      })
  }
  handleCancelAmenities = () => {
    this.setState({ openToAmenities: false })
  }

  handleAllNotMatch = () => {

    const data = {
      listing_id: this.state.record_id,
      community_match: this.state.community_match,
      sub_community_match: this.state.sub_community_match,
      min_budget: this.state.min_budget, max_budget: this.state.max_budget,
      beds: this.state.bedroom_match, property_for_match: this.state.property_for_match,
      property_type_match: this.state.property_type_match,
      added_date_match: this.state.added_date_match
    }
    this.props.setNoMatch(data)
    window.open("/restricted_leads", "_blank");

    //this.props.history.push("/restricted_leads")
  }

  handleAllMatch = () => {
    const data = {
      listing_id: this.state.record_id,
      community_match: this.state.community_match,
      sub_community_match: this.state.sub_community_match,
      min_budget: this.state.min_budget, max_budget: this.state.max_budget,
      beds: this.state.bedroom_match, property_for_match: this.state.property_for_match,
      property_type_match: this.state.property_type_match,
      added_date_match: this.state.added_date_match
    }
    this.props.setMatch(data)
    window.open("/access_leads", "_blank");
    //this.props.history.push("/access_leads")
  }

  handleSaveOwnerName = (value) => {

    const data = {
      owner_id: this.state.listing_details.owners_id,
      owner_name: value
    }

    API.post("/update_owner_name", data)
      .then((response) => {
        console.log("owner name")
      })
  }
  handleSaveOwnerEmail = (value) => {
    const data = {
      owner_id: this.state.listing_details.owners_id,
      owner_email: value
    }
    API.post("/update_owner_email", data)
      .then((response) => {
        console.log("owner email")
      })
  }
  handleSaveOwnerMobile = (value) => {
    const data = {
      owner_id: this.state.listing_details.owners_id,
      owner_mobile: value
    }
    API.post("/update_owner_mobile", data)
      .then((response) => {
        console.log("owner mobile")
      })
  }

  handleLeadGen = (e) => {
    this.setState({ lead_gen: !this.state.lead_gen })

  }

  handleJustListed = (e) => {
    this.setState({ just_listed: !this.state.just_listed })

  }

  handleNewViewing = () => {
    this.setState({ isviewingOpen: true })
  }
  handleNewFollowUp = () => {
    this.setState({
      followup_date: "",
      followup_note: "",
      selected_agent: "",
      followup_for: "",
      reminder: "",
      isfollowupOpen: true
    })
    if (this.state.role != 1 && this.state.role != 2) {
      this.setState({
        selected_agent: this.state.agent_id
      })
    }
  }


  onSearchRefNo = (e) => {
    if (!e || e == '' || e == null) {
      // this.setState({ref_nums:[]})
      return
    }
    API.get("/get_ref_no/" + e)
      .then((response) => {
        if (response.data.success) {
          this.setState({ ref_nums: response.data.ref_nums })
        }
      })
  }

  handleRefsno = (e) => {
    this.setState({ viewing_ref_no: e })
  }

  handleViewingNote = (e) => {

    this.setState({ viewing_note: e.target.value })
  }

  handleViewingDate = (date, dateString) => {
    this.setState({ viewing_date: dateString })
  };
  handleViewingPrice = (e) => {
    this.setState({ viewing_price: e.target.value })
  }

  handleViewingAgent = (e) => {
    this.setState({ viewing_agent: e })
  }

  fetchFollowUpDetails(leads_id) {
    API.post("/followup_details/" + leads_id, { type: "vendor" })
      .then((response) => {
        if (response.data.success) {
          this.setState({ followups_details: response.data.followups })
        }

      })
  }

  handleViewingOk = () => {
    const id = this.state.record_id
    const data = {
      listing_id: this.state.record_id,
      listing_refno: this.state.listing_details.refno,
      viewing_date: this.state.viewing_date,
      viewing_note: this.state.viewing_note
    }

    API.post("/add_listing_viewing", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ all: response.data.all, isviewingOpen: false })
          this.fetchViewings(id)

        }

      })

  }
  handleViewingCancel = () => {
    this.setState({ isviewingOpen: false })
  }



  handleEditViewing(view_id, e) {

    this.setState({ view_id: view_id })
    API.get("/get_viewing/" + view_id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            viewings: response.data.viewings, viewing_status: response.data.viewings.status,
            viewing_note: response.data.viewings.note, viewing_date: response.data.viewings.view_date,
            showPrice: response.data.viewings.status == "Offer" ? true : false,
            showDate: response.data.viewings.status == "Scheduled" ? true : false,
            viewing_price: response.data.viewings.amount, iseditviewingOpen: true
          })
        }

      })
  }

  handleViewStatus = (e) => {
    const status = e
    this.setState({ viewing_status: e })
    if (status == "Offer") {
      this.setState({ showPrice: true })
    } else {
      this.setState({ showPrice: false })
    }

    if (status == "Scheduled") {
      this.setState({ showDate: true })
    } else {
      this.setState({ showDate: false })
    }
  }

  handleViewStatusChange = () => {
    const id = this.state.record_id

    const data = {
      listing_id: this.state.record_id, view_id: this.state.view_id, viewing_status: this.state.viewing_status,
      viewing_price: this.state.viewing_price, viewing_note: this.state.viewing_note
    }

    API.post("/change_listing_viewing_status", data)
      .then((response) => {


        if (response.data.success) {
          this.setState({ iseditviewingOpen: false })
          this.fetchViewings(id)
          this.fetchOffers(id)
        }
      })

  }

  getImageArray = (imageString) => {
    if (imageString == "" || imageString == null) {
      return [];
    } else {
      let images = imageString.split(",");
      return images;
    }

  }
  handleViewStatusCancel = () => {
    this.setState({ iseditviewingOpen: false })
  }


  handlesetSelectedAmenities = (value) => {
    this.setState({ selectedFacilities: value })
  }

  handleYoutubeLink = (e) => {
    this.setState({ youtube_link: e.target.value })
  }
  handleLivetourLink = (e) => {
    this.setState({ livetour_link: e.target.value })
  }

  handleBrochure = (e) => {
    this.setState({ brochure: e.target.value })
  }

  handleCrutions = (e) => {
    this.setState({ curations: e.target.value })
  }
  handleLifestyle = (e) => {
    this.setState({ lifestyle: e.target.value })
  }

  handleMetaTitle = (e) => {
    this.setState({ meta_title: e.target.value })
  }
  handleMetaKeywords = (e) => {
    this.setState({ meta_keywords: e.target.value })
  }

  handleMetaDescription = (e) => {
    this.setState({ meta_description: e.target.value })
  }

  handleForWebsiteSubmit = () => {
    if (this.websiteValidate()) {
      const listing_id = this.state.record_id
      const data = {
        meta_title: this.state.meta_title,
        meta_description: this.state.meta_description,
        meta_keywords: this.state.meta_keywords,
        video_link: this.state.video_link,
        livetour_link: this.state.livetour_link,
        curations: this.state.curation_details,
        lifestyle: this.state.lifestyle_details
      }
      API.put("/update_website/" + listing_id, data)
        .then((response) => {
          if (response.data.success) {
            toast.success("Data for website is successfully updated")
          } else {
            toast.success("Something went wrong")
          }
        })
    }
  }

  handleSaveWebsite = () => {
    const id = this.state.record_id
    const data = {
      video_link: this.state.video_link,
      livetour_link: this.state.livetour_link,
      meta_title: this.state.meta_title,
      meta_description: this.state.meta_description,
      meta_keywords: this.state.meta_keywords
    }
    API.put("/update_website/" + id, data)
      .then((response) => {
        if (response.data.success) {
          toast.success("Data for website is successfully updated")
        } else {
          toast.success("Something went wrong")
        }
      })
  }

  handleInternational = (e) => {
    this.setState({ international: e.target.value })
  }
  handleCountry = (e) => {
    this.setState({ country: e.target.value })
    const data = {
      country_id: e.target.value
    }
    API.post("/cities", data)
      .then((response) => {
        if (response.data.success) {
          this.setState({ cities_details: response.data.cities })
        }
      })
  }
  handleCity = (e) => {
    this.setState({ city: e.target.value })
  }

  handleInternationalSubmit = () => {
    const id = this.state.record_id
    const data = {
      international: this.state.international,
      country: this.state.country,
      city: this.state.city
    }
    this.setState({ isInternationDisable: true })
    API.put("/update_international/" + id, data)
      .then((response) => {
        if (response.data.success) {
          toast.success("Successfully Updated")
          this.setState({ isInternationDisable: false })

        }
      })
  }

  handleShowOffer = (view_id, e) => {

    API.get("/get_offer/" + view_id)
      .then((response) => {

        if (response.data.success) {
          this.setState({ offer: response.data.offer })
          this.setState({ showOffer: true })
        }
      })

  }
  handleOkOffer = () => {
    this.setState({ showOffer: false })
  }
  handleCancelOffer = () => {
    this.setState({ showOffer: false })
  }

  handleCommunityFilter = (e) => {


    this.setState({ community_filter: e })

    API.get("/sub_community/" + e)
      .then((response) => {
        if (response.data.success)
          this.setState({ sub_communities: response.data.sub_communities })
      })

    const page = 1;
    const perPage = 10;
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: e,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);

  }

  handleAgentFilter = (e) => {
    // update this file
    this.setState({ agent_filter: e })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const page = 1;
    const perPage = 10;

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: e,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);

  }

  handleRangeDate = (value, dateString) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ dateRange: value == null ? [] : dateString })
    const page = 1;
    const perPage = 10;

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: (value == null) ? [] : dateString,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);

  }

  handleSubCommunityFilter = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    API.get("/property/" + e)
      .then((response) => {
        if (response.data.success)
          this.setState({ properties: response.data.properties })
      })


    this.setState({ sub_community_filter: e })



    const page = 1;
    const perPage = 10;

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: e,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);

  }

  handlePropertyFilter = (e) => {


    this.setState({ property_filter: e })

    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const page = 1;
    const perPage = 10;

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,

      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: e,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);

  }

  onChangeCallNote = (value) => {
    this.setState({ call_log: value })
  }

  onChangePortal(portal, e) {




    this.setState({ selected_portals_id: [...this.state.selected_portals_id, portal.id] })

    let portalExist = this.state.selected_portals.map((item) => {
      return (portal.id == item.portals_id) ? true : false
    });

    if (portalExist.includes(true)) {
      let new_portals = this.state.selected_portals.filter((item) => {
        return item.portals_id !== portal.id
      })
      this.setState({ selected_portals: new_portals })
    } else {

      this.state.selected_portals.push(
        {
          portals_id: portal.id,
          property_desc: "",
          property_title: "",
          portal_title: portal.title
        }
      );
      this.setState({ selected_portals: this.state.selected_portals })
    }
  }

  onChangeTitle(selected_portal, e) {
    this.state.selected_portals.filter((portal) => {
      if (portal.portals_id === selected_portal.portals_id) {
        this.setState({ selected_portal: portal })
        portal.property_title = e.target.value;
      }
    });
  }

  onChangeSameValue(selected_portal, e) {
    if (selected_portal.length != 0) {
      if (e.target.checked) {
        this.setState({ isSameValueCheck: true })
      } else {
        this.setState({ isSameValueCheck: false })
        // selected_portal.forEach((portal, index) => {
        //   if (index >= 1) {
        //     portal['property_desc'] = ""
        //     portal['property_title'] = ""
        //     this.setState({ selected_portal: portal })
        //   }
        // });
      }
    }
  }

  onChangeDescription(selected_portal, e) {
    this.state.selected_portals.filter((portal) => {
      if (portal.portals_id === selected_portal.portals_id) {
        this.setState({ selected_portal: portal })
        portal.property_desc = e.target.value;
      }
    });
  }

  isCheckSameValue(portals, e) {

    if (portals && portals.length != 0) {
      let sameData = 0;
      portals.map((portal) => {
        if ((portal.property_title == portals[0].property_title) && (portal.property_desc == portals[0].property_desc)) {
          sameData++
        }
      })
      if (sameData == portals.length) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  onSubmit(state, e) {


    const listings_id = state.listing_details.id;
    let updatedPortals = []
    if (this.state.isSameValueCheck) {

      this.state.selected_portals.forEach((portal, index) => {
        portal['property_desc'] = state.selected_portals[0]['property_desc']
        portal['property_title'] = state.selected_portals[0]['property_title']
        updatedPortals = [...state.selected_portals];
        updatedPortals[index] = portal;

      });
      this.setState({ selected_portals: updatedPortals });
    }

    const data = {
      selected_portals: this.state.isSameValueCheck ? updatedPortals : state.selected_portals,
      listings_id: state.listing_details.id
    }
    API.put("/update_portals/" + listings_id, data)
      .then((response) => {
        if (response.data.success) {
          toast.success("Portal successfully updated")
        } else {
          toast.success("Something went wrong")
        }
      })
  }

  getSubCommunity = (id) => {
    API.get("/sub_community/" + id)
      .then((response) => {
        if (response.data.success)
          this.setState({ sub_communities: response.data.sub_communities })
      })
  }

  getProperty = (id) => {
    API.get("/property/" + id)
      .then((response) => {
        if (response.data.success)
          this.setState({ properties: response.data.properties })
      })
  }

  onSelectCallMentions = (option) => {
    this.setState({ selectedCallUsers: [...this.state.selectedCallUsers, option] })

  }

  onChangeNoteNote = (value) => {
    this.setState({ note_log: value })
  }

  onSelectNoteMentions = (option) => {
    this.setState({ selectedNoteUsers: [...this.state.selectedNoteUsers, option] })

  }

  onChangeWhatsappNote = (value) => {
    this.setState({ whatsapp_log: value })
  }

  onSelectWhatsappMentions = (option) => {
    this.setState({ selectedWhatsappUsers: [...this.state.selectedWhatsappUsers, option] })

  }

  onChangeEmailNote = (value) => {
    this.setState({ email_log: value })
  }

  onSelectEmailMentions = (option) => {
    this.setState({ selectedEmailUsers: [...this.state.selectedEmailUsers, option] })

  }

  onChangeViewingNote = (value) => {
    this.setState({ viewing_log: value })
  }

  onSelectViewingMentions = (option) => {
    this.setState({ selectedViewingUsers: [...this.state.selectedEmailUsers, option] })

  }
  onChangeOfferNote = (value) => {
    this.setState({ offer_log: value })
  }

  onSelectOfferMentions = (option) => {
    this.setState({ selectedOfferUsers: [...this.state.selectedOfferUsers, option] })

  }
  onChangeFollowupNote = (value) => {
    this.setState({ followup_log: value })
  }

  onSelectFollowupMentions = (option) => {
    this.setState({ selectedFollowupUsers: [...this.state.selectedFollowupUsers, option] })

  }

  handlePublishDateValue_id = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    this.setState({ publish_date_value_id: e.target.value })
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,


      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: property_for_array,

      property_type: property_type_array,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: e.target.value,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (this.state.publish_date_value != '')
      this.fetchData(page, perPage, data);
  }

  handlePublishDateValue = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    this.setState({ publish_date_value: e.target.value })

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,


      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_first_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,

      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,


      property_for: property_for_array,

      property_type: property_type_array,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: e.target.value,
      publish_date_value_id: this.state.publish_date_value_id,
      filter_key: this.state.global_search
    }

    const page = 1;
    const perPage = 10;
    if (e.target.value != '')
      this.fetchData(page, perPage, data);
  }

  handleStatusChange = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ status_filter: e })

    const page = 1
    const perPage = 10
    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      property_type_filter: this.state.property_type_filter,
      status_filter: e,
      furnished_types_value: this.state.furnished_types_value,
      // status_filter:[...this.state.status_filter,stock_status],

      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      filter_key: this.state.global_search
    }
    this.fetchData(page, perPage, data);
  }


  handlePropertyTypeFilter = (e) => {


    this.setState({ property_type_filter: e })

    const page = 1;
    const perPage = 10;
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      bedroom_filter: this.state.bedroom_filter,
      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: e,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);
  }

  handleClearFilter = () => {
    this.props.removeListingReducer()
    this.props.removeStockReducer()
    this.setState({
      listings_id: [],
      last_call_value: "",
      aiSearch: false,
      selectedValue: "",
      last_call_value_id: 1,
      last_call_option_value: 1,
      matched_leads_and_viewings_morethan_option: false,
      matched_leads_and_no_viewings_option: false,
      matched_leads_viewing_value: "",
      pond_filter: false,
      pledge_filter: false,
      propert_for_value: [],
      lead_gen_filter: false,
      status_filter: [],
      property_type_filter: [],
      community_filter: "",
      agent_filter: "",
      sub_community_filter: "",
      property_filter: "",
      refno_first_value_id: 1,
      refno_and_or_option: true,
      refno_second_value_id: 1,
      refno_first_value: "",
      refno_second_value: "",
      bedroom_filter: "",
      unitno_first_value_id: 1,
      unitno_and_or_option: true,
      unitno_second_value_id: 1,
      unitno_first_value: "",
      unitno_second_value: "",

      price_first_value_id: 1,
      price_and_or_option: true,
      price_second_value_id: 1,
      price_first_value: "",
      price_second_value: "",
      price_min: 0,
      price_max: 0,

      leads_count_first_value_id: 1,
      leads_count_and_or_option: true,
      leads_count_second_value_id: 1,
      leads_count_first_value: "",
      leads_count_second_value: "",
      leads_count_min: 0,
      leads_count_max: 0,

      viewings_count_first_value_id: 1,
      viewings_count_and_or_option: true,
      viewings_count_second_value_id: 1,
      viewings_count_first_value: "",
      viewings_count_second_value: "",
      viewings_count_min: 0,
      viewings_count_max: 0,

      offers_count_first_value_id: 1,
      offers_count_and_or_option: true,
      offers_count_second_value_id: 1,
      offers_count_first_value: "",
      offers_count_second_value: "",
      offers_count_min: 0,
      offers_count_max: 0,
      available_date: [],

      date_first_value_id: 1,
      date_and_or_option: true,
      date_second_value_id: 1,
      date_first_value: "",
      date_second_value: "",
      date_morethan_option: true,
      date_lessthan_option: false,
      date_value: "",
      date_value_id: 1,

      publish_date_morethan_option: true,
      publish_date_lessthan_option: false,
      publish_date_value: "",
      publish_date_value_id: 1,

      property_type: [],
      property_for: [],
    })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents






    const page = 1;
    const perPage = 10;
    const data = {
      listings_id: [],
      selectedAgents: "",
      aiSearch: false,
      isMatchedListing: 0,
      isExpiringListing: 0,
      isMoveInDateListing: 0,
      last_call_value: "",
      last_call_value_id: 1,
      selectedValue: "",
      last_call_option_value: 1,
      matched_leads_and_viewings_morethan_option: false,
      matched_leads_and_no_viewings_option: false,
      matched_leads_viewing_value: "",
      lead_gen_filter: "No",
      pledge_filter: "No",
      pond_filter: "No",
      property_type_filter: [],
      status_filter: [],
      community_filter: "",
      sub_community_filter: "",
      agent_filter: "",
      property_filter: "",
      refno_first_value_id: 1,
      refno_and_or_option: true,
      refno_second_value_id: 1,
      refno_first_value: "",
      refno_second_value: "",
      bedroom_filter: "",
      unitno_first_value_id: 1,
      unitno_and_or_option: true,
      unitno_second_value_id: 1,
      unitno_first_value: "",
      unitno_second_value: "",

      price_first_value_id: 1,
      price_and_or_option: true,
      price_second_value_id: 1,
      price_first_value: "",
      price_second_value: "",
      price_min: 0,
      price_max: 0,

      leads_count_first_value_id: 1,
      leads_count_and_or_option: true,
      leads_count_second_value_id: 1,
      leads_count_first_value: "",
      leads_count_second_value: "",
      leads_count_min: 0,
      leads_count_max: 0,

      viewings_count_first_value_id: 1,
      viewings_count_and_or_option: true,
      viewings_count_second_value_id: 1,
      viewings_count_first_value: "",
      viewings_count_second_value: "",
      viewings_count_min: 0,
      viewings_count_max: 0,

      offers_count_first_value_id: 1,
      offers_count_and_or_option: true,
      offers_count_second_value_id: 1,
      offers_count_first_value: "",
      offers_count_second_value: "",
      offers_count_min: 0,
      offers_count_max: 0,

      date_first_value_id: 1,
      date_and_or_option: true,
      date_second_value_id: 1,
      date_first_value: "",
      date_second_value: "",
      date_morethan_option: true,
      date_lessthan_option: false,
      date_value: "",
      date_value_id: 1,

      publish_date_morethan_option: true,
      publish_date_lessthan_option: false,
      publish_date_value: "",
      publish_date_value_id: 1,
      available_date: [],

      property_type: [],
      property_for: [],
      filter_key: this.state.global_search

    }

    this.fetchData(page, perPage, data)
  }

  handleWhatsappOpen = () => {
    this.setState({ iswhatsappOpen: true })
  }

  handleGlobalSearch = (e) => {
    this.setState({ selectedValue: "" })
    this.setState({ aiSearch: false })
    this.setState({ global_search: e.target.value })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id
    const page = 1;
    const perPage = 10;

    const userdata = store.getState((state) => state);
    let authenticated = userdata.auth.loggedIn;
    let role = userdata.auth.user.role;
    let user = userdata.auth.user;
    let role_name = userdata.auth.user.role_name;
    let user_id = user.id
    if (role_name != "Admin" && role_name != "Super Admin") {
      this.state.agent_filter = user_id;
    }

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: e.target.value
    }


    this.fetchData(page, perPage, data);


  }

  handleWhatsAppAgents = (e) => {
    this.setState({ selectedAgents: e })
  }

  handlemourequests = (e) => {
    this.setState({ ismourequestOpen: true })
  }
  handlemourequestsok = (e) => {
    this.setState({ ismourequestOpen: false })
  }
  handlemourequestscancel = (e) => {
    this.setState({ ismourequestOpen: false })
  }

  onSearchClient = (e) => {

    API.get("/get_clients/" + e)
      .then((response) => {

        if (response.data.success) {
          this.setState({ whatsappClients: response.data.contacts })
        }
      })
  }

  handleSelectClients = (e) => {
    this.setState({ whatsappSelectedClients: e })
  }

  handleWhatsappOk = () => {

    if (this.state.SelectedRows.length < 1) {
      toast.error("Select Listings")
      return
    }

    if (this.state.whatsappSelectedClients.length < 1) {
      toast.error("Select clients")
      return
    }
    const data = {
      SelectedRows: this.state.SelectedRows,
      whatsappSelectedClients: this.state.whatsappSelectedClients
    }

    this.setState({ iswhatsappOpen: false, SelectedRows: [], whatsappSelectedClients: [] })
    toast.success("Listings sent")
    API.post("/send_whatsapp", data)
      .then((response) => {

        if (response.data.success) {
          toast.success("Listings recieved by clients")
        }
      })


  }

  handleWhatsappCancel = () => {
    this.setState({ iswhatsappOpen: false })
  }

  handlePreview = (record, e) => {
    const url = "https://www.luxuryproperty.com/property/preview/" + record.refno + "-" + record.id

    //  API.get("/get_preview/"+record)
    //  .then((response) =>{
    //   if(response.data.success){
    //     const response_data = response.data.listing
    //     const url = "https://www.luxuryproperty.com/property/preview/"+response_data.refno+"-"+response_data.id

    //     console.log("your url",url)
    //   }
    //  })
  }
  handlepurchaseprice = (e) => {
    this.setState({ purchaseprice: e.target.value })
  }

  handleservicecharge = (e) => {
    this.setState({ servicecharge: e.target.value })
  }
  handlerentalamount = (e) => {
    this.setState({ rentalamount: e.target.value })
  }
  handleCalculate = () => {
    const total = ((this.state.rentalamount - this.state.servicecharge) / this.state.purchaseprice) * 100
    this.setState({ rentalyieldtotal: total })
  }

  downloadPdf = (listing_id) => {



    // alert(listing_id)


    API.get("/listing_toDownload/" + listing_id)
      .then((response) => {
        if (response.data.success) {

          this.setState({ listing: response.data.listing })
          this.setState({ openPDF: true, record_id: listing_id })
        }
      })
    return
    // this.setState({isLoading:true})
    const data = {
      listing_id: listing_id
    }
    API.post("/download_pdf/", data)
      .then((response) => {
        this.setState({ isLoading: false })
        window.open(response.data.file_path, '_blank');
      }).catch((err) => {
        console.log(err)
        this.setState({ isLoading: false })
        toast.error('something went wrong');
      })
  }
  // bedroom filter
  handleBedFilter = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    this.setState({ bedroom_filter: e.target.value })

    const page = 1;
    const perPage = 10;

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",

      furnished_types_value: this.state.furnished_types_value,
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,

      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      publish_date_value: this.state.publish_date_value,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,
      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: e.target.value,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);

  }


  //   Photo Request
  handleShowAddPhotoRequest = (e) => {
    const id = this.state.record_id

    API.get("/property_contact/" + id)
      .then((response) => {
        if (response.data.success) {
          this.setState({
            property_contact: response.data.property_contact,
            property_contact_name: response.data.property_contact.fullname,
            property_contact_email: response.data.property_contact.email,
            property_contact_mobile: response.data.property_contact.mobile,
          })
          this.setState({ showPhotoRequest: true })
        }
      })

  }
  handleShowAddPhotoRequestok = (e) => {
    const data = {
      listing_id: this.state.record_id,
      photography_check: this.state.photography_check == true ? 1 : 0,
      videography_walk_check: this.state.videography_walk_check == true ? 1 : 0,
      videography_agent_check: this.state.videography_agent_check == true ? 1 : 0,
      haveNoc: this.state.haveNoc == true ? 1 : 0,
      property_contact_name: this.state.property_contact_name,
      property_contact_email: this.state.property_contact_email,
      property_contact_mobile: this.state.property_contact_mobile,
      photography_note: this.state.photography_note,
      present_photo: this.state.present_photo,
      photography_date: this.state.photography_date,
      photography_time: this.state.photography_time,
    }

    API.post("/photography", data)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            showPhotoRequest: false, photography_check: false,
            videography_walk_check: false,
            videography_agent_check: false,
            haveNoc: false,
            property_contact: {},
            property_contact_name: "",
            property_contact_email: "",
            property_contact_mobile: "",
            photography_note: "",
            present_photo: "",
            photography_date: "",
            photography_time: [],
          })
        }
      })
    //  return

    this.setState({ showPhotoRequest: false })
  }
  handleShowAddPhotoRequestCancel = (e) => {
    this.setState({ showPhotoRequest: false })
  }

  handleMapOpen = () => {
    this.setState({ mapOpen: true })
  }

  handleCloseMap = () => {
    this.setState({ mapOpen: false })
  }
  handleChangeLocation(lat, lng) {
    const location = this.state.map_location
    location["lat"] = lat
    location["lng"] = lng


    this.setState({ map_location: location });
  }

  handleChangeZoom(newZoom) {
    this.setState({ zoom: newZoom });
  }

  handlePhotography = () => {
    this.setState({ photography_check: !this.state.photography_check })
  }

  handleVideoWalk = () => {
    this.setState({ videography_walk_check: !this.state.videography_walk_check })
  }

  handleVidewAgent = () => {
    this.setState({ videography_agent_check: !this.state.videography_agent_check })
  }
  handleNOC = () => {
    this.setState({ haveNoc: !this.state.haveNoc });
  }

  handlePresentPhoto = (e) => {
    this.setState({ present_photo: e.target.value });
  }

  handlePhotographyNote = (e) => {
    this.setState({ photography_note: e.target.value })
  }

  handlePhotographyDate = (value, dateString) => {

    this.setState({ photography_date: value != null ? dateString : "" });
  }

  handlePhotographyTime = (value, timeString) => {
    this.setState({ photography_time: value != null ? timeString : [] })
  }

  //   Filter Popovers
  handleLeadFilterOpen = (isLeadsfilterOpen) => { this.setState({ isLeadsfilterOpen: true }) }
  handleLeadFilterClose = () => { this.setState({ isLeadsfilterOpen: false }) }
  handleViewingFilterOpen = () => { this.setState({ isViewingsfilterOpen: true }) }
  handleViewingFilterClose = () => { this.setState({ isViewingsfilterOpen: false }) }
  handleOfferFilterOpen = () => { this.setState({ isOfferfilterOpen: true }) }
  handleOfferFilterClose = () => { this.setState({ isOfferfilterOpen: false }) }
  handleMatchLeadsFilterOpen = () => { this.setState({ isMatchLeadsOpen: true }) }
  handleMatchLeadsFilterClose = () => { this.setState({ isMatchLeadsOpen: false }) }

  // Toggle Views
  handleToggleViewGallery = () => { this.setState({ isViewAsGallery: true }) }
  handleToggleViewTable = () => { this.setState({ isViewAsGallery: false }) }

  next() {
    this.carousel.next();
  }
  previous() {
    this.carousel.prev();
  }
  isValidHttpUrl = (string) => {
    try {
      const url = new URL(string);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (err) {
      return false;
    }
  }

  // Reset
  handleLeadsReset = () => {
    this.setState({
      leads_count_first_value_id: 1,
      leads_count_and_or_option: true,
      leads_count_second_value_id: 1,
      leads_count_first_value: null,
      leads_count_second_value: null,
      leads_count_min: 0, leads_count_max: 0
    })
  }

  leadGenFilter = (e) => {
    this.setState({ lead_gen_filter: !this.state.lead_gen_filter })
    const page = 1;
    const perPage = 10;
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: e.target.checked == true ? "Yes" : "No",
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);

  }

  handleMatchedLeadsAndViewingMorethan = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    this.setState({ matched_leads_and_viewings_morethan_option: true, matched_leads_viewing_value_show: true, matched_leads_and_no_viewings_option: false })
    const page = 1;
    const perPage = 10;

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: true,
      matched_leads_and_no_viewings_option: false,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }

    if (this.state.matched_leads_viewing_value > 0)
      this.fetchData(page, perPage, data);
  }

  handleMatchedLeadsAndNoViewing = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    this.setState({ matched_leads_and_viewings_morethan_option: false, matched_leads_viewing_value_show: false, matched_leads_and_no_viewings_option: true, matched_leads_viewing_value: "" })
    const page = 1;
    const perPage = 10;

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: 1,
      matched_leads_and_viewings_morethan_option: false,
      matched_leads_and_no_viewings_option: true,
      matched_leads_viewing_value: "",
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);
  }

  handleMatchedLeadsFilter = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    this.setState({ matched_leads_viewing_value: e.target.value })
    const page = 1;
    const perPage = 10;

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: e.target.value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);
  }

  handleMatchedLeadsFilter_Value_Option = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})

    this.setState({ matched_leads_viewing_value_id: e.target.value })
    const page = 1;
    const perPage = 10;



    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: e.target.value,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }

    if (this.state.matched_leads_viewing_value != "")
      this.fetchData(page, perPage, data);


  }

  pledgeFilter = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})

    this.setState({ pledge_filter: !this.state.pledge_filter })
    const page = 1;
    const perPage = 10;

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: e.target.checked == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);
  }

  // last call
  handleLastCallOptionValue = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    this.setState({ last_call_option_value: e.target.value })
    const page = 1;
    const perPage = 10;


    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: e.target.value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }

    if (this.state.last_call_value != "" && this.state.last_call_value > 0)
      this.fetchData(page, perPage, data);

  }
  handleLastCallValue_id = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id



    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})

    this.setState({ last_call_value_id: e.target.value })
    const page = 1;
    const perPage = 10;


    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: e.target.value,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }

    if (this.state.last_call_value != "" && this.state.last_call_value > 0)
      this.fetchData(page, perPage, data);
  }

  handleLastCallValue = (e) => {
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    this.setState({ last_call_value: e.target.value })
    const page = 1;
    const perPage = 10;

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: this.state.pond_filter == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: e.target.value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);
  }

  pondFilter = (e) => {
    this.setState({ pond_filter: !this.state.pond_filter })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const page = 1;
    const perPage = 10;

    const data = {
      listings_id: this.state.listings_id,
      pond_filter: e.target.checked == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);

  }
  referredToFilter = (e) => {
    this.setState({ referred_to_filter: !this.state.referred_to_filter })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id

    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})



    const page = 1;
    const perPage = 10;

    const data = {
      listings_id: this.state.listings_id,
      referred_to_filter: e.target.checked == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);

  }
  referredFilter = (e) => {
    this.setState({ referred_filter: !this.state.referred_filter })
    const all_data = store.getState((state) => state);
    const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
    const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
    const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
    const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

    const stock_status = all_data.stock_reducer.status
    const stock_community_id = all_data.stock_reducer.community_id
    const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
    const stock_property_id = all_data.stock_reducer.property_id


    // this.setState({status_filter:[...this.state.status_filter,stock_status],community_filter:stock_community_id,sub_community_filter:stock_subcommunity_id,property_filter:stock_property_id})


    const page = 1;
    const perPage = 10;

    const data = {
      listings_id: this.state.listings_id,
      referred_filter: e.target.checked == true ? "Yes" : "No",
      selectedAgents: selectedAgents,
      isMatchedListing: isMatchedListing == true ? 1 : 0,
      isExpiringListing: isExpiringListing == true ? 1 : 0,
      isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
      last_call_value: this.state.last_call_value,
      last_call_value_id: this.state.last_call_value_id,
      last_call_option_value: this.state.last_call_option_value,
      pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
      matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
      matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
      matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
      matched_leads_viewing_value: this.state.matched_leads_viewing_value,
      lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
      status_filter: this.state.status_filter,
      community_filter: this.state.community_filter,
      agent_filter: this.state.agent_filter,
      sub_community_filter: this.state.sub_community_filter,
      property_filter: this.state.property_filter,
      refno_first_value_id: this.state.refno_first_value_id,
      refno_and_or_option: this.state.refno_and_or_option,
      refno_second_value_id: this.state.refno_second_value_id,
      refno_first_value: this.state.refno_first_value,
      refno_second_value: this.state.refno_second_value,

      unitno_first_value_id: this.state.unitno_first_value_id,
      unitno_and_or_option: this.state.unitno_and_or_option,
      unitno_second_value_id: this.state.unitno_second_value_id,
      unitno_first_value: this.state.unitno_first_value,
      unitno_second_value: this.state.unitno_second_value,

      price_first_value_id: this.state.price_first_value_id,
      price_and_or_option: this.state.price_and_or_option,
      price_second_value_id: this.state.price_second_value_id,
      price_first_value: this.state.price_first_value,
      price_second_value: this.state.price_second_value,
      price_min: this.state.price_min,
      price_max: this.state.price_max,
      furnished_types_value: this.state.furnished_types_value,

      leads_count_first_value_id: this.state.leads_count_first_value_id,
      leads_count_and_or_option: this.state.leads_count_and_or_option,
      leads_count_second_value_id: this.state.leads_count_second_value_id,
      leads_count_first_value: this.state.leads_count_first_value,
      leads_count_second_value: this.state.leads_count_second_value,
      leads_count_min: this.state.leads_count_min,
      leads_count_max: this.state.leads_count_max,

      viewings_count_first_value_id: this.state.viewings_count_first_value_id,
      viewings_count_and_or_option: this.state.viewings_count_and_or_option,
      viewings_count_second_value_id: this.state.viewings_count_second_value_id,
      viewings_count_first_value: this.state.viewings_count_first_value,
      viewings_count_second_value: this.state.viewings_count_second_value,
      viewings_count_min: this.state.viewings_count_min,
      viewings_count_max: this.state.viewings_count_max,

      offers_count_first_value_id: this.state.offers_count_first_value_id,
      offers_count_and_or_option: this.state.offers_count_and_or_option,
      offers_count_second_value_id: this.state.offers_count_second_value_id,
      offers_count_first_value: this.state.offers_count_first_value,
      offers_count_second_value: this.state.offers_count_second_value,
      offers_count_min: this.state.offers_count_min,
      offers_count_max: this.state.offers_count_max,

      date_first_value_id: this.state.date_first_value_id,
      date_and_or_option: this.state.date_and_or_option,
      date_second_value_id: this.state.date_second_value_id,
      date_first_value: this.state.date_first_value,
      date_second_value: this.state.date_second_value,
      date_morethan_option: this.state.date_morethan_option,
      date_lessthan_option: this.state.date_lessthan_option,
      publish_date_morethan_option: this.state.publish_date_morethan_option,
      publish_date_lessthan_option: this.state.publish_date_lessthan_option,
      date_value: this.state.date_value,
      date_value_id: this.state.date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      publish_date_value_id: this.state.publish_date_value_id,
      available_date: this.state.dateRange,
      leads_count: this.state.leads_count,

      property_type: this.state.propert_type_value,
      property_for: this.state.propert_for_value,
      property_type_filter: this.state.property_type_filter,
      bedroom_filter: this.state.bedroom_filter,
      filter_key: this.state.global_search
    }


    this.fetchData(page, perPage, data);

  }

  handleNoAnswer = () => {


    const data = { listing_id: this.state.record_id }
    API.post("/listing_add_call_no_answer", data)
      .then((res) => {
        if (res.data.success) {
          Swal.fire({
            // title: "Create MOU Request!",

            text: "Would you like to make Follow up?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
            cancelButtonText: "No"
          }).then((result) => {
            if (result.isConfirmed) {
              this.setState({ isfollowupOpen: true })
            }
          })

          this.setState({
            all: res.data.all, notes: res.data.notes,
            call_notes: res.data.call_notes, whatsapp_notes: res.data.whatsapp_notes,
            email_notes: res.data.email_notes,
          })
        }
      })
  }

  handleisCollapsed = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  }

  uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },

      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);

        this.setState({ progress: percent })
        if (percent === 100) {
          setTimeout(() => this.setState({ progress: 0 }), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    const formData = new FormData();
    formData.append("listing_id", this.state.record_id);
    formData.append("files", file);
    API.post("/upload_listing_files", formData, config).then((response) => {

      if (response.data.success) {
        this.setState({ property_images: response.data.images })
        // remove();
        imagesArray = []
        onSuccess('Ok');
      }
    })
  };

  handleOnChange = ({ file, fileList, event }) => {

    this.setState({ fileList })
  };

  handleisReferralOpen = () => { this.setState({ isReferralOpen: true }) }
  handleisReferralClose = () => { this.setState({ isReferralOpen: false }) }

  handleCancelApprove = () => {
    this.setState({ openPDF: false })
  }

  handleLat = (e) => {
    this.setState({ lat: e.target.value })
  }

  handleLng = (e) => {
    this.setState({ lng: e.target.value })
  }

  handleReraPermit = (e) => {
    this.setState({ rera_permit: e.target.value })
  }

  handleDownloadAllDocument = () => {
    const listing_id = this.state.record_id
    const marginLeft = 10;
    const marginRight = 10;
    const marginTop = 10;
    const marginBottom = 10;
    const pageWidth = 420; // mm
    const pageHeight = 297; // mm
    const doc = new jsPDF({
      unit: 'mm',
      format: 'a4',
      orientation: 'portrait',
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
    });

    if (this.state.documents.length < 1) {
      toast.warning("No docs to download")
      return
    }



    // return 

    API.get("all_documents/" + listing_id)
      .then((res) => {



        if (res.data.success) {
          window.open(res.data.file_path, '_blank');
          return
          // this.setState({all_docs:res.data.documents,previewDoc:true})  
          const all = res.data.documents
          let content = `
        <style>
        table {
          font-size: 10px; /* Adjust the font size as needed */
        }
      </style>
          `;

          const appendDocumentToContent = (docTitle, docURL) => {

            content += `
            <hr /> <!-- Add a horizontal line to separate documents -->
          
          
              <img src="${docURL}"  alt="${docTitle}" />
            

           
          `;


            console.log("docURL", docURL);
          };


          // Iterate through the documents array and append each document to the content
          all.forEach((docItem) => {
            appendDocumentToContent(docItem.title, docItem.document);
          });

          console.log("content", content);

          doc.html(content, {
            callback: function () {
              // Save the PDF with a specified name
              doc.save('sample.pdf');
            },
          });

        }
      })








  }

  handleCancelPrevewDoc = () => {
    this.setState({ previewDoc: false })
  }

  handleDownloadPDF = () => {
    const listing_id = this.state.record_id
    this.setState({ isDownloading: true })
    API.get("/listing_toDownload/" + listing_id)
      .then((response) => {
        if (response.data.success) {
          setTimeout(() => {
            this.setState({ isDownloading: false })
          }, 1000);

          const listing = response.data.listing
          const images = listing.property_images ? listing.property_images.split(",").filter(Boolean) : [];
          const price = listing.price

          const reportTemplateRef = `<div class="printprevbrochure mt-2 d-flex position-relative">
        <img style="position: absolute; top: 0.55in; left: 0.00in; width:6.22in; height: 3.30in;" src="${baseurlImg}/${images[0]}" alt="Image 1" />
        <img style="position: absolute; top: 2.89in; left: 2.52in; width: 5.5in; height: 0.9in;" src="/brochure/ri_1.png" alt="Image 2" />
        
        <div style="position: relative;">



<div style="position: absolute; top: 2.95in; left: 2.9in; height: 1.05in;  width: 4.25in;">
    <span style="font-style: normal; font-weight: 700; font-size: 12px; color: #ffffff; disply:flex;flex-direction:row; width:100%; font-family: 'Poppins-Regular', sans-serif; line-height:1.2px">
    ${listing.property ? listing.property + ", " : ""}  ${listing.subcommunity ? listing.subcommunity + ", " : ""}${listing.community ? listing.community : ""}
    </span>
    
    <br />
</div>

<div style="position: absolute; top: 2.98in; left: 2.9in; width: 4.25in; line-height: 1.05in;">
    <span style="font-style: normal; font-weight: 700; font-size: 20pt; color: #ffffff; font-family: 'Poppins-Bold', sans-serif">
        ${price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : ''} 
    </span>
    <br />
</div>

<div style="position: absolute; top: 7.7in; width: 6.22in; height:70px; line-height: 0.18in;">
    <div style="background: #151515; display: flex; flex-direction: row; justify-content: space-between;">
        <div style="display: flex; flex-direction: row; padding: 1rem; width: 100%; align-items: center;">
            <div style="display: flex; border-radius: 50%; overflow: hidden; width: 40px; height: 40px; align-content: center; margin-right: 1rem;">
                <img src="${this.isValidHttpUrl(listing.profile_photo) ? listing.profile_photo : baseurlImg + 'lpcrm/public/uploads/clients/2036/users/' + listing.profile_photo}" style="height: 100%;" alt="Agent's Photo" />
            </div>
            <div style="display: flex; flex-direction: column;">
                <p style="color: #FFF; font-size: 14px; margin: 0 0 5px;">
                     ${listing.agent_name} 
                </p>
                <p style="color: #FFF; font-size: 11px; margin: 0;">
                   ${listing.jobtitle} 
                </p>
            </div>
            <div style="display: flex; flex-direction: column; margin-left: auto; text-align: right;">
                <p style="color: #FFF; font-size: 12px; margin: 0 0 5px;">
                    ${listing.mobile} 
                </p> 
                <p style="color: #FFF; font-size: 12px; margin: 0;">
                    ${listing.email}
                </p>
            </div>
        </div>
    </div>
    <br />
</div>
</div>

        
        ${images.length > 1 ? `
        <img style="position: absolute; top: 4.3in; left: 0.35in; width: 1.77in; height: 0.95in;" src="${baseurlImg}/${images[1]}" alt="Image 3" />
        ` : ''}
        
        ${images.length > 2 ? `
        <img style="position: absolute; top: 4.3in; left: 2.25in; width: 1.77in; height: 0.95in;" src="${baseurlImg}/${images[2]}" alt="Image 4" />
        ` : ''}
        
        ${images.length > 3 ? `
        <img style="position: absolute; top: 4.3in; left: 4.35in; width: 1.77in; height: 0.95in;" src="${baseurlImg}/${images[3]}" alt="Image 5" />
        ` : ''}
        
        ${images.length > 1 ? `
        <div style="position: absolute; top:3.87in; left: 0.35in; width: 1.18in; line-height: 0.24in;">
            <span style="font-style: normal; font-weight: bold; font-size: 10px; color: #151515;">OVERVIEW</span>
        </div>
        <img style="position: absolute; top: 4in; left: 2.25in; width: 7.23in; height: 0.01in;" src="/brochure/vi_6.png" alt="Overview Image 1" />
        <img style="position: absolute; top: 0.15in; left: 0.36in; width: 3.4in; height: 0.20in;" src="/brochure/vi_7.png" alt="Overview Image 2" />
        ` : ''}
        
        <div style="position: absolute; top: 0.10in; left: 0.48in; width: 3.84in; line-height: 0.24in;">
            <span style="font-size:8px; color: #151515; letter-spacing: 5px;">
                LUXURY PRIVATE ${listing.prop_type} <b>${listing.property_for == "rent" ? "FOR RENT" : "FOR SALE"}</b>
            </span>
        </div><br />
        
        <img style="position: absolute; top: 0.15in; left: 0.00in; width: 0.36in; height: 0.20in;" src="/brochure/vi_8.png" alt="Logo Image" />
        <img style="position: absolute; top: 0.20in; left: 4.4in; width: 1.4in;" src="/brochure/Lp-Logo-2021-Black-Web.png" alt="Company Logo" />
        
        <div style="position: absolute; top: 5.35in; left: 0.35in; width: 7.56in; line-height: 0.24in;">
            <span style="font-style: normal; font-weight: bold; font-size: 10px; color: #151515;">ABOUT THE PROPERTY</span>
        </div>
        
        <div style="position: absolute; top: 5.64in; left: 0.35in; width: 5.5in; line-height: 0.14in;">
            <div style="position: relative; left: 0.02in;">
                <span style="font-style: normal; font-weight: normal; font-size: 8px; color: #52555b;" class="pdfdesc">
                    ${listing.desc ? listing.desc.substring(0, 1100) : ''}
                </span>
            </div><br />
        </div>
        
        <img style="position: absolute; top: 5.45in; left: 2.58in; width: 7.56in; height: 0.01in;" src="/brochure/vi_32.png" alt="Separator Image" />
    </div>
    <style>
      .pdfdesc p {font-size:9px}
    </style>
    `;

          const currentDate = new Date();

          // Format the date and time components
          const year = currentDate.getFullYear();
          const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
          const day = String(currentDate.getDate()).padStart(2, '0');
          const hours = String(currentDate.getHours()).padStart(2, '0');
          const minutes = String(currentDate.getMinutes()).padStart(2, '0');
          const seconds = String(currentDate.getSeconds()).padStart(2, '0');

          // Create a filename with the formatted date and time
          const filename = `${listing.refno}-${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;



          const fontSize = 8;
          const doc = new jsPDF({
            format: [595, 842],
            orientation: "portrait",
            unit: "pt",
          });

          doc.setFontSize(fontSize);
          doc.html(reportTemplateRef, {
            async callback(doc) {
              await doc.save(filename);
            }
          });




        }
      })

  }

  handlePFPropertyDetails = (e) => {
    this.setState({ pf_property: e })
  }
  handlePFProjectDetails = (e) => {
    this.setState({ pf_project: e })
  }
  handlePreviewLink = (listing_id, refno, status, e) => {

    if (status != 'Y') {
      window.open("https://www.luxuryproperty.com/property/preview/" + refno + "-" + listing_id, "_blank");
      return
    }
    API.get("/get_url/" + listing_id)
      .then((res) => {
        if (res.data.success) {
          window.open(res.data.url, "_blank");

        }
      })

  }
  handleUnarchive = (listingID) => {
      Swal.fire({
        title: "Unarchive Listing?",
        text: "This action cannot be undone. Are you sure you want to proceed?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#25d366",
        cancelButtonColor: "#FFF",
        confirmButtonText: "Yes, unarchive it!",
        customClass: {confirmButton: "custom-confirm-button"},
      }).then((result) => {
        if (result.isConfirmed) {
          API.post("/unarchive_listing", listingID)
            .then((response) => {
              if (response.data.success) {
                toast.success("The listing has been successfully unarchived.")
                this.handleCloseEditDrawer();
              } else {
                toast.error('Something went wrong');
              }
            }).catch((error) => {
              console.log(error);
              toast.error('something went wrong');
            });
        }
      })
  }





  handlePriceApplication = (checked) => {
    this.setState({ price_application: !this.state.price_application })
  }
  isHTML = (content) => {
    const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
    return htmlRegex.test(content);
  }

  onChangeAvailableDate = (date, dateString) => {
    this.setState({ avail_d: dateString })
  };

  handleAvailableOk = () => {
    if (!this.state.avail_d) {
      toast.warning("Available date is required")
      return
    }
    this.setState({ showAvailableDateModel: false })
  }
  handleAvailableCancel = () => {
    this.setState({ showAvailableDateModel: false })
  }


  render() {
    const userdata = store.getState((state) => state);
    let authenticated = userdata.auth.loggedIn;
    let role = userdata.auth.user.role;
    let user = userdata.auth.user;
    let role_name = userdata.auth.user.role_name;
    let user_id = user.id
    const images = this.state.listing.property_images ? this.state.listing.property_images.split(",").filter(Boolean) : [];


    const price = this.state.listing.price
    const files = this.state.files.map(file => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    const onSelectChange = (newSelectedRowKeys) => {
      this.setState({ SelectedRows: newSelectedRowKeys });
    };

    const rowSelection = {
      // selectedRowKeys,
      onChange: onSelectChange,

    };

    const whatsappcols = [{
      title: 'Name',
      dataIndex: 'name',
      //render: (text) => <a>{text}</a>,
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      render: (text, record) => (

        console.log(record)
      ),
    },
    {
      title: 'Type',
      dataIndex: 'contacttype',
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <DeleteOutlined />
        </Space>
      ),
    },
    ];

    columnsTable = [



      {
        title: 'Ref',
        dataIndex: 'refno',

        sorter: (a, b) => a.refno.length - b.refno.length,
        render: (text, record) => (

          <Link className="btn-link" onClick={this.handleOpenDrawerToEdit.bind(this, record.id, false)}>{record.refno}</Link>
        ),
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Ref no
            <select className="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleRefnoFirstValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select>
            <div className='mt-2'>
              <input type="text" value={this.state.refno_first_value} className="form-control" onChange={this.handleRefnoFirst} placeholder="filter..." />
            </div>
            {this.state.refno_active_second_option ? (<div className='mt-2' >
              <center>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
                  <label className="form-check-label" for="flexRadioDefault1">
                    AND
                  </label>
                  <input className="form-check-input ml-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
                  <label className="form-check-label ml-4" for="flexRadioDefault1">
                    OR
                  </label>

                </div>
              </center>
              <select className="form-select" aria-label="Default select example" name='refno_second_filter_value_id' onChange={this.handleRefnoSecondValue_id}>
                <option value="1">Contain</option>
                <option value="2">Not contain</option>
                <option value="3">Equals</option>
                <option value="4">Not equal</option>
                <option value="5">Starts with</option>
                <option value="6">Ends with</option>
              </select>
              <div className='mt-2'>
                <input type="text" value={this.state.refno_second_value} className="form-control" onChange={this.handleRefnoSecond} placeholder="filter..." />
              </div>

            </div>) : null}



          </div>
        ),
      },
      {
        title: 'Pdf',
        render: (text, record) => (
          <div className="ms-auto">
            <Link to={"/download_pdf/" + record.id} className='btn btn p-0' target="_blank"  ><FilePdfOutlined /> </Link>
          </div>
        ),
      },
      {
        title: 'Status',
        className: 'liststat',
        dataIndex: 'status',

        render: (text, record) => {
          var pStatus = ''
          var color = ''

          switch (record.status) {
            case 'D':
              pStatus = 'Draft'
              color = 'draft'
              break;
            case 'UC':
              pStatus = 'Confirmed Owner'
              color = 'confirmedowner'
              break;
            case 'N':
              pStatus = 'Unpublished'
              color = 'unpublished'
              break;
            case 'OM':
              pStatus = 'Off-Market'
              color = 'offmarket'
              break;
            case 'CTM':
              pStatus = 'Coming to market'
              color = 'comingtomarket'
              break;
            case 'Y':
              pStatus = 'Published'
              color = 'published'
              break;
            case 'U':
              pStatus = 'Action'
              color = 'action'
              break;

            case 'SEO':
              pStatus = 'SEO'
              color = 'seo'
              break;
            case 'L':
              pStatus = 'Unlisted'
              color = 'unlisted'
              break;
            case 'S':
              pStatus = 'Sold'
              color = 'sold'
              break;
            case 'T':
              pStatus = 'Let'
              color = 'let'
              break;
            case 'P':
              pStatus = 'Review'
              color = 'review'
              break;
            case 'F':
              pStatus = 'Feeds'
              color = 'feeds'
              break;
            // new status
            case 'PO':
              pStatus = 'Pending owner'
              color = 'feeds'
              break;

            case 'SLP':
              pStatus = ['Sold by ', <img key="lpLogo" src='/LPLogo.png' height="13px" className='ml-1' />]
              color = 'feeds'
              break;
            case 'R':
              pStatus = 'Rented'
              color = 'feeds'
              break;
            case 'RLP':
              pStatus = ['Rented by ', <img key="lpLogo" src='/LPLogo.png' height="13px" className='ml-1' />]
              color = 'feeds'
              break;
            default:
              pStatus = ''
              color = ''
              break;
          }
          return (
            <div className={color + " pstat"} key={pStatus}>
              {pStatus}
            </div>
          );
        },
        filterDropdown: (
          <div style={{
            padding: '3px',
            borderRadius: '8px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Status
            <Select

              style={{
                width: '100%',
              }}
              allowClear
              showSearch
              mode="multiple"
              maxTagCount='responsive'
              value={this.state.status_filter}
              onChange={this.handleStatusChange}
              options={[
                {
                  value: 'D',
                  label: 'Draft',
                },
                {
                  value: 'UC',
                  label: 'Confirmed Owner',
                },
                {
                  value: 'N',
                  label: 'Unpublished',
                },
                {
                  value: 'OM',
                  label: 'Off-Market',
                },
                {
                  value: 'CTM',
                  label: 'Coming to market',
                },
                {
                  value: 'Y',
                  label: 'Published',
                },
                {
                  value: 'u',
                  label: 'Action',
                },
                {
                  value: 'SEO',
                  label: 'SEO',
                },
                {
                  value: 'L',
                  label: 'Unlisted',
                },
                {
                  value: 'S',
                  label: 'Sold',
                },
                {
                  value: 'T',
                  label: 'Let',
                },
                {
                  value: 'P',
                  label: 'Review',
                },
                {
                  value: 'F',
                  label: 'Feeds',
                },
                // new status
                {
                  value: 'PO',
                  label: 'Pending owner',
                },
                {
                  value: 'SLP',
                  label: 'Sold by LP',
                },
                {
                  value: 'R',
                  label: 'Rent',
                },
                {
                  value: 'RLP',
                  label: 'Rent by LP',
                },


              ]}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </div>
        ),
      },
      {
        title: 'For',
        dataIndex: 'property_for',
        sorter: (a, b) => {
          if (a && a.property_for && a.property_for.length && b && b.property_for && b.property_for.length) {
            return a.property_for.length - b.property_for.length;
          } else if (a && a.property_for && a.property_for.length) {
            return -1;
          } else if (b && b.property_for && b.property_for.length) {
            return 1;
          }
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Property for
            <Select
              mode="multiple"
              style={{
                width: '100%',
              }}
              value={this.state.propert_for_value}
              placeholder="Please select"
              onChange={this.handlePropertyFor}
              options={this.state.property_for_option}
            />


          </div>
        ),

      },
      // list view date picker
      {
        title: 'Available date',
        dataIndex: 'avail_date',
        render: (text, record) => (
          (record.avail_date == null | record.avail_date == "" | record.avail_date == '0000-00-00 00:00:00') ? "-" :
            (<Moment format="MMMM DD, YYYY">
              {record.avail_date}
            </Moment>)
        ),
        sorter: (a, b) => {
          if (a && a.avail_date && a.avail_date.length && b && b.avail_date && b.avail_date.length) {
            return a.avail_date.length - b.avail_date.length;
          } else if (a && a.avail_date && a.avail_date.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.avail_date && b.avail_date.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            <RangePicker style={{ height: '36px', overflow: 'hidden', position: 'relative' }} onChange={this.handleRangeDate} />
          </div>
        ),
      },
      {
        title: ' Community',
        dataIndex: 'community',
        sorter: (a, b) => a.community.length - b.community.length,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Community
            <Select
              allowClear
              showSearch
              style={{
                width: '100%',
              }}

              placeholder="Search"
              value={this.state.community_filter}
              onChange={this.handleCommunityFilter}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={this.state.communities}
            />


          </div>
        ),
      },
      {
        title: ' Sub Community',
        dataIndex: 'subcommunity',
        sorter: (a, b) => a.subcommunity.length - b.subcommunity.length,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Sub Community
            <Select
              showSearch
              allowClear
              style={{
                width: '100%',
              }}

              placeholder="Search"
              onChange={this.handleSubCommunityFilter}
              value={this.state.sub_community_filter}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={this.state.sub_communities}
            />


          </div>
        ),
      },
      {
        title: ' Property',
        dataIndex: 'property_project',
        sorter: (a, b) => a.property.length - b.property.length,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Property
            <Select
              showSearch
              allowClear
              style={{
                width: '100%',
              }}
              value={this.state.property_filter}
              placeholder="Search"
              onChange={this.handlePropertyFilter}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={this.state.properties}
            />


          </div>
        ),
      },

      {
        title: 'Agent',
        dataIndex: 'agent_name',
        sorter: (a, b) => a.agent.length - b.agent.length,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Agent
            <Select
              showSearch
              allowClear
              mode='multiple'
              style={{
                width: '100%',
              }}

              placeholder="Search"
              onChange={this.handleAgentFilter}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={this.state.agents}
            />


          </div>
        ),
      },

      {
        title: 'Type',
        dataIndex: 'prop_type',
        sorter: (a, b) => a.prop_type.length - b.prop_type.length,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Property type
            <Select
              showSearch
              allowClear

              style={{
                width: '100%',
              }}
              mode="multiple"
              value={this.state.property_type_filter}

              placeholder="Search"
              onChange={this.handlePropertyTypeFilter}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={this.state.propertytypes}
            />



          </div>
        ),

      },

      {
        title: 'Beds',
        dataIndex: 'bedrooms',

        sorter: (a, b) => a.bedrooms.length - b.bedrooms.length,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Bedroom
            <select value={this.state.bedroom_filter} onChange={this.handleBedFilter} id="matchingBedrooms" name="bedrooms" className="form-control" title tabIndex={-1}>
              <option value="">All</option>
              <option value="Studio">Studio</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3} >3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
            </select>
          </div>
        ),

      },


      {
        title: 'Unit no',
        dataIndex: 'unitno',
        sorter: (a, b) => a.unitno.length - b.unitno.length,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Unit no
            <select className="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleUnitnoFirstValue_id}>
              <option value="1">Contain</option>
              <option value="2">Not contain</option>
              <option value="3">Equals</option>
              <option value="4">Not equal</option>
              <option value="5">Starts with</option>
              <option value="6">Ends with</option>
            </select>
            <div className='mt-2'>
              <input type="text" value={this.state.unitno_first_value} className="form-control" onChange={this.handleUnitnoFirst} placeholder="filter..." />
            </div>
            {this.state.unitno_active_second_option ? (<div className='mt-2' >
              <center>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadio" id="flexRadio1" checked={this.state.unitno_and_or_option ? true : false} onChange={this.handleUnitRadio} />
                  <label className="form-check-label" for="flexRadio1">
                    AND
                  </label>
                  <input className="form-check-input ml-3" type="radio" name="flexRadio" id="flexRadio1" checked={!this.state.unitno_and_or_option ? true : false} onChange={this.handleUnitRadio} />
                  <label className="form-check-label ml-4" for="flexRadio1">
                    OR
                  </label>

                </div>
              </center>
              <select className="form-select" aria-label="Default select example" name='unitno_second_filter_value_id' onChange={this.handleUnitnoSecondValue_id}>
                <option value="1">Contain</option>
                <option value="2">Not contain</option>
                <option value="3">Equals</option>
                <option value="4">Not equal</option>
                <option value="5">Starts with</option>
                <option value="6">Ends with</option>
              </select>
              <div className='mt-2'>
                <input type="text" value={this.state.unitno_second_value} className="form-control" onChange={this.handleUnitnoSecond} placeholder="filter..." />
              </div>

            </div>) : null}



          </div>
        ),
      },
      {
        title: 'FF/UF',
        dataIndex: 'furnished',
      },
      {
        title: 'Added date',
        dataIndex: 'added_date',
        render: (text, record) => (
          <Moment fromNow>
            {record.added_date}
          </Moment>
        ),
        sorter: (a, b) => {
          if (a && a.added_date && a.added_date.length && b && b.added_date && b.added_date.length) {
            return a.added_date.length - b.added_date.length;
          } else if (a && a.added_date && a.added_date.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.added_date && b.added_date.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Date
            <div className="form-check">
              <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
              <label className="form-check-label" for="flexRadioDefault1">
                Less than
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
              <label className="form-check-label" for="flexRadioDefault2">
                More than
              </label>
            </div>

            <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <input type="text" value={this.state.date_value} className="form-control" onChange={this.handleDateValue} />
              <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                <option value="1">days</option>
                <option value="2">weeks</option>
                <option value="3">months</option>
                <option value="4">years</option>
              </select>
            </div>


          </div>
        ),
      },
      {
        title: 'Published date',
        dataIndex: 'published_date',
        render: (text, record) => (
          record.published_date == null | record.published_date == "" | record.published_date == '0000-00-00 00:00:00') ? "" :
          (<Moment fromNow>
            {record.published_date}
          </Moment>
          ),
        sorter: (a, b) => {
          if (a && a.published_date && a.published_date.length && b && b.published_date && b.published_date.length) {
            return a.published_date.length - b.published_date.length;
          } else if (a && a.published_date && a.published_date.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.published_date && b.published_date.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Date
            <div className="form-check">
              <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.publish_date_morethan_option} id="flexRadioDefault1" onChange={this.handlePublishDateMorethan} />
              <label className="form-check-label" for="flexRadioDefault1">
                Less than
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" checked={this.state.publish_date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handlePublishDateLessthan} />
              <label className="form-check-label" for="flexRadioDefault2">
                More than
              </label>
            </div>

            <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <input type="text" value={this.state.publish_date_value} className="form-control" onChange={this.handlePublishDateValue} />
              <select className='form-select ml-1' onChange={this.handlePublishDateValue_id}>
                <option value="1">days</option>
                <option value="2">weeks</option>
                <option value="3">months</option>
                <option value="4">years</option>
              </select>
            </div>


          </div>
        ),
      },
      {
        title: 'Price',
        dataIndex: 'price',
        render: (text, record) => {
          if (!isNaN(parseInt(text))) {
            return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
          } else {
            return null;
          }
        },

        sorter: (a, b) => {
          if (a && a.price && a.price.length && b && b.price && b.price.length) {
            return a.price.length - b.price.length;
          } else if (a && a.price && a.price.length) {
            // That means be has null rechargeType, so a will come first.
            return -1;
          } else if (b && b.price && b.price.length) {
            // That means a has null rechargeType so b will come first.
            return 1;
          }

          // Both rechargeType has null value so there will be no order change.
          return 0;
        },
        //a.price.length > 0 - b.price.length > 0,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Price
            <select className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
              <option value="1">Equals</option>
              <option value="2">Not equal</option>
              <option value="3">Less than</option>
              <option value="4">Less than or equals</option>
              <option value="5">Greater than</option>
              <option value="6">Greater than or equals</option>
              <option value="7">In range</option>
            </select>
            <div className='mt-2'>
              {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input type="text" value={this.state.price_min} style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMin} placeholder="min" />
                <input type="text" value={this.state.price_max} style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMax} placeholder="max" />
              </div> : <input type="text" value={this.state.price_first_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="filter..." />}
            </div>
            {this.state.price_active_second_option ? (<div className='mt-2' >
              <center>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="pricefirst" id="pricefirst" checked={this.state.price_and_or_option ? true : false} onChange={this.handlePriceRadio} />
                  <label className="form-check-label" for="pricefirst">
                    AND
                  </label>
                  <input className="form-check-input ml-3" type="radio" name="pricefirst" id="pricesecond" checked={!this.state.price_and_or_option ? true : false} onChange={this.handlePriceRadio} />
                  <label className="form-check-label ml-4" for="pricesecond">
                    OR
                  </label>

                </div>
              </center>
              <select className="form-select" aria-label="Default select example" name='price_second_filter_value_id' onChange={this.handlePriceSecondValue_id}>
                <option value="1">Equals</option>
                <option value="2">Not equal</option>
                <option value="3">Less than</option>
                <option value="4">Less than or equals</option>
                <option value="5">Greater than</option>
                <option value="6">Greater than or equals</option>
                <option value="7">In range</option>
              </select>
              <div className='mt-2'>
                <input type="text" value={this.state.price_second_value} className="form-control" onChange={this.handlePriceSecond} placeholder="filter..." />
              </div>

            </div>) : null}



          </div>
        ),
      },

      {
        title: 'Leads',
        dataIndex: 'leads_count',
        render: (text, record) => {
          if (record.leads_count != "" || record.leads_count != null) {
            if (record.leads_count > 0) {
              return <Link target="_blank" to={"all_leads/" + record.id}>{record.leads_count}</Link>
            } else {
              return <p>{record.leads_count}</p>
            }

          } else {
            return "-";
          }
        },
        sorter: (a, b) => a.leads_count.length - b.leads_count.length,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Leads
            <select className="form-select" aria-label="Default select example" name='leads_count_first_filter_value_id' onChange={this.handleLeadsCountFirstValue_id}>
              <option value="1">Equals</option>
              <option value="2">Not equal</option>
              <option value="3">Less than</option>
              <option value="4">Less than or equals</option>
              <option value="5">Greater than</option>
              <option value="6">Greater than or equals</option>
              <option value="7">In range</option>
            </select>
            <div className='mt-2'>
              {this.state.leads_count_inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input type="text" value={this.state.leads_count_min} style={{ width: '105px' }} className="form-control" onChange={this.handleLeadsCountMin} placeholder="min" />
                <input type="text" value={this.state.leads_count_max} style={{ width: '105px' }} className="form-control" onChange={this.handleLeadsCountMax} placeholder="max" />
              </div> : <input type="text" value={this.state.leads_count_first_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleLeadsCountFirst} placeholder="filter..." />}
            </div>
            {this.state.leads_count_active_second_option ? (<div className='mt-2' >
              <center>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="leadscountfirst" id="leadscountfirst" checked={this.state.leads_count_and_or_option ? true : false} onChange={this.handleLeadsCountRadio} />
                  <label className="form-check-label" for="leadscountfirst">
                    AND
                  </label>
                  <input className="form-check-input ml-3" type="radio" name="leadscountfirst" id="leadscountsecond" checked={!this.state.leads_count_and_or_option ? true : false} onChange={this.handleLeadsCountRadio} />
                  <label className="form-check-label ml-4" for="leadscountsecond">
                    OR
                  </label>
                </div>
              </center>
              <select className="form-select" aria-label="Default select example" name='leads_count_second_filter_value_id' onChange={this.handleLeadsCountSecondValue_id}>
                <option value="1">Equals</option>
                <option value="2">Not equal</option>
                <option value="3">Less than</option>
                <option value="4">Less than or equals</option>
                <option value="5">Greater than</option>
                <option value="6">Greater than or equals</option>
                <option value="7">In range</option>
              </select>
              <div className='mt-2'>
                <input type="text" value={this.state.leads_count_second_value} className="form-control" onChange={this.handleLeadsCountSecond} placeholder="filter..." />
              </div>
            </div>) : null}
          </div>
        ),
      },

      {
        title: 'Viewings',
        dataIndex: 'viewings_count',
        render: (text, record) => {
          if (record.viewings_count != "" || record.viewings_count != null) {
            return record.viewings_count;
          } else {
            return "-";
          }
        },
        sorter: (a, b) => a.viewings_count.length - b.viewings_count.length,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Viewings
            <select className="form-select" aria-label="Default select example" name='viewings_count_first_filter_value_id' onChange={this.handleViewingsCountFirstValue_id}>
              <option value="1">Equals</option>
              <option value="2">Not equal</option>
              <option value="3">Less than</option>
              <option value="4">Less than or equals</option>
              <option value="5">Greater than</option>
              <option value="6">Greater than or equals</option>
              <option value="7">In range</option>
            </select>
            <div className='mt-2'>
              {this.state.viewings_count_inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input type="text" value={this.state.viewings_count_min} style={{ width: '105px' }} className="form-control" onChange={this.handleViewingsCountMin} placeholder="min" />
                <input type="text" value={this.state.viewings_count_max} style={{ width: '105px' }} className="form-control" onChange={this.handleViewingsCountMax} placeholder="max" />
              </div> : <input type="text" value={this.state.viewings_count_first_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleViewingsCountFirst} placeholder="filter..." />}
            </div>
            {this.state.viewings_count_active_second_option ? (<div className='mt-2' >
              <center>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="viewingscountfirst" id="viewingscountfirst" checked={this.state.viewings_count_and_or_option ? true : false} onChange={this.handleViewingsCountRadio} />
                  <label className="form-check-label" for="viewingscountfirst">
                    AND
                  </label>
                  <input className="form-check-input ml-3" type="radio" name="viewingscountfirst" id="viewingscountsecond" checked={!this.state.viewings_count_and_or_option ? true : false} onChange={this.handleViewingsCountRadio} />
                  <label className="form-check-label ml-4" for="viewingscountsecond">
                    OR
                  </label>
                </div>
              </center>
              <select className="form-select" aria-label="Default select example" name='viewings_count_second_filter_value_id' onChange={this.handleViewingsCountSecondValue_id}>
                <option value="1">Equals</option>
                <option value="2">Not equal</option>
                <option value="3">Less than</option>
                <option value="4">Less than or equals</option>
                <option value="5">Greater than</option>
                <option value="6">Greater than or equals</option>
                <option value="7">In range</option>
              </select>
              <div className='mt-2'>
                <input type="text" value={this.state.viewings_count_second_value} className="form-control" onChange={this.handleViewingsCountSecond} placeholder="filter..." />
              </div>
            </div>) : null}
          </div>
        ),
      },

      {
        title: 'Offers',
        dataIndex: 'offers_count',
        render: (text, record) => {
          if (record.offers_count != "" || record.offers_count != null) {
            return record.offers_count;
          } else {
            return "-";
          }
        },

        sorter: (a, b) => a.offers_count.length - b.offers_count.length,
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Offers
            <select className="form-select" aria-label="Default select example" name='offers_count_first_filter_value_id' onChange={this.handleOffersCountFirstValue_id}>
              <option value="1">Equals</option>
              <option value="2">Not equal</option>
              <option value="3">Less than</option>
              <option value="4">Less than or equals</option>
              <option value="5">Greater than</option>
              <option value="6">Greater than or equals</option>
              <option value="7">In range</option>
            </select>
            <div className='mt-2'>
              {this.state.offers_count_inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input type="text" value={this.state.offers_count_min} style={{ width: '105px' }} className="form-control" onChange={this.handleOffersCountMin} placeholder="min" />
                <input type="text" value={this.state.offers_count_max} style={{ width: '105px' }} className="form-control" onChange={this.handleOffersCountMax} placeholder="max" />
              </div> : <input type="text" value={this.state.offers_count_first_value} className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handleOffersCountFirst} placeholder="filter..." />}
            </div>
            {this.state.offers_count_active_second_option ? (<div className='mt-2' >
              <center>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="offerscountfirst" id="offerscountfirst" checked={this.state.offers_count_and_or_option ? true : false} onChange={this.handleOffersCountRadio} />
                  <label className="form-check-label" for="offerscountfirst">
                    AND
                  </label>
                  <input className="form-check-input ml-3" type="radio" name="offerscountfirst" id="offerscountsecond" checked={!this.state.offers_count_and_or_option ? true : false} onChange={this.handleOffersCountRadio} />
                  <label className="form-check-label ml-4" for="offerscountsecond">
                    OR
                  </label>
                </div>
              </center>
              <select className="form-select" aria-label="Default select example" name='offers_count_second_filter_value_id' onChange={this.handleOffersCountSecondValue_id}>
                <option value="1">Equals</option>
                <option value="2">Not equal</option>
                <option value="3">Less than</option>
                <option value="4">Less than or equals</option>
                <option value="5">Greater than</option>
                <option value="6">Greater than or equals</option>
                <option value="7">In range</option>
              </select>
              <div className='mt-2'>
                <input type="text" value={this.state.offers_count_second_value} className="form-control" onChange={this.handleOffersCountSecond} placeholder="filter..." />
              </div>
            </div>) : null}
          </div>
        ),
      },
      {
        title: 'Match',
        dataIndex: 'match',
        render: (text, record) => (

          <Link target="_blank" to={"matched_list_leads/" + record.id}>
            {record.matched} ({record.new_matched})
          </Link>
        ),
        filterDropdown: (
          <div style={{
            padding: '8px',
            borderRadius: '6px',
            background: '#fff',
            boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
          }}>
            Matched
            <div className="form-check">
              <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.matched_leads_and_no_viewings_option} id="flexRadioDefault1" onChange={this.handleMatchedLeadsAndNoViewing} />
              <label className="form-check-label" for="flexRadioDefault1">
                Match leads & no viewings
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" checked={this.state.matched_leads_and_viewings_morethan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleMatchedLeadsAndViewingMorethan} />
              <label className="form-check-label" for="flexRadioDefault2">
                Match leads
              </label>
            </div>
            {this.state.matched_leads_viewing_value_show ?

              <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <select className="form-select font-12" aria-label="Default select example" name='offers_count_first_filter_value_id' onChange={this.handleMatchedLeadsFilter_Value_Option}>
                  <option value="1">Equals</option>
                  <option value="2">Not equal</option>
                  <option value="3">Less than</option>
                  <option value="4">Less than or equals</option>
                  <option value="5">Greater than</option>
                  <option value="6">Greater than or equals</option>
                  {/* <option value="7">In range</option> */}
                </select>
                <input type="text" value={this.state.matched_leads_viewing_value} className="form-control" onChange={this.handleMatchedLeadsFilter} />

              </div> : null}


          </div>
        ),
      },

      {
        title: 'Preview',
        render: (text, record) => (
          <div className="ms-auto">
            <a className='btn btn' onClick={this.handlePreviewLink.bind(this, record.id, record.refno, record.status)} ><EyeFilled /> </a>
          </div>
        ),
      },

    ];

    const props = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <CaretRightOutlined />,
      prevArrow: <CaretLeftOutlined />
    };

    columns = [
      {
        title: 'Image', dataIndex: 'listingimage', width: 280, className: 'listingimagewrap',
        render: (text, record) => (
          <>
            {record.property_images == null ?
              <>
                <div className='noimageyet'><img src={"../../images/noimageyet.png"} className='defimg mx-auto' width="80px" style={{ opacity: '0.6' }} /><span className='font-12'>Image pending</span></div>
              </>
              :
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItem: 'center' }}>
                <Carousel arrows style={{ width: '280px', height: '157px', overflow: 'hidden' }} effect="fade" {...props}>
                  {this.getImageArray(record.property_images).map((image) => {
                    return <img src={baseurlImg + "/" + image} style={{ width: '100%' }} />
                  })}
                </Carousel>
              </div>
            }
          </>
        )
      },


      {
        title: 'Ref',
        dataIndex: 'refno', width: 200,

        sorter: (a, b) => a.refno.length - b.refno.length,
        render: (text, record) => {
          var pStatus = ''
          var color = ''

          switch (record.status) {
            case 'D':
              pStatus = 'Draft'
              color = 'draft'
              break;
            case 'UC':
              pStatus = 'Confirmed Owner'
              color = 'confirmedowner'
              break;
            case 'N':
              pStatus = 'Unpublished'
              color = 'unpublished'
              break;
            case 'OM':
              pStatus = 'Off-Market'
              color = 'offmarket'
              break;
            case 'CTM':
              pStatus = 'Coming to market'
              color = 'comingtomarket'
              break;
            case 'Y':
              pStatus = 'Published'
              color = 'published'
              break;
            case 'U':
              pStatus = 'Action'
              color = 'action'
              break;

            case 'SEO':
              pStatus = 'SEO'
              color = 'seo'
              break;
            case 'L':
              pStatus = 'Unlisted'
              color = 'unlisted'
              break;
            case 'S':
              pStatus = 'Sold'
              color = 'sold'
              break;
            case 'T':
              pStatus = 'Let'
              color = 'let'
              break;
            case 'P':
              pStatus = 'Review'
              color = 'review'
              break;
            case 'F':
              pStatus = 'Feeds'
              color = 'feeds'
              break;
            // new status
            case 'PO':
              pStatus = 'Pending owner'
              color = 'feeds'
              break;

            case 'SLP':
              pStatus = ['Sold by ', <img key="lpLogo" src='/logowhite.png' height="15px" className='ml-1' />]
              color = 'slp'
              break;
            case 'R':
              pStatus = 'Rented'
              color = 'rented'
              break;
            case 'RLP':
              pStatus = ['Rented by ', <img key="lpLogo" src='/logowhite.png' height="15px" className='ml-1' />]
              color = 'rlp'
              break;
            default:
              pStatus = 'Draft'
              color = 'draft'
              break;
          }
          return (
            <>
              <Link className="btn-text listinginfo" onClick={this.handleOpenDrawerToEdit.bind(this, record.id, false)}>
                <div className={color + " pstat "} key={pStatus}>{pStatus}</div>
                <p className='font-bold fs-6 pt-2 mb-0'>{record.refno}</p>
                <p className='mb-0'>{record.property_project}</p>
                <p className='mb-0'>{record.subcommunity + ", " + record.community}</p>
              </Link>

            </>
          );
        },
      }, {
        title: 'For', width: 150,
        dataIndex: 'property_for',
        render: (text, record) => (
          <div className='listinginfowrap listingagentwrap'>
            <div className='info-group'>
              <p className='info-title'>Unit No:</p>
              <p>{record.unitno ? record.unitno : null}</p>
            </div>
            <div className='info-group'>
              <p className='info-title'>Agent:</p>
              <div className='agentwrap'>
                <Avatar className='d-flex' size={30} style={{ backgroundColor: "#FFF", marginRight: "0", }}
                  icon={<Avatar className='d-flex' style={{ backgroundColor: "#87d068", marginRight: "0", }} icon={<img src={record.profile_photo == null || record.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(record.profile_photo) ? record.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + record.profile_photo} style={{ width: "30px", height: "30px", borderRadius: "50%", }} />} />} />
                <p>{record.agent_name}</p>
              </div>
            </div>
          </div>
        ),
      }, {
        title: 'Price', width: 150,
        dataIndex: 'price',
        render: (text, record) => (
          <div className='listinginfowrap'>
            <div className='info-group'>
              <p className='info-title'>Price:</p>
              <p>{record.price ? record.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED" : null}</p>
            </div>

          </div>
        ),
      }, {
        title: 'Beds', width: 150,
        dataIndex: 'beds',
        render: (text, record) => (
          <div className='listinginfowrap'>
            <div className='info-group'>
              <p className='info-title'>Property For:</p>
              <p>{record.property_for ? record.property_for : null}</p>
            </div>
            <div className='info-group'>
              <p className='info-title'>Property Type:</p>
              <p>{record.prop_type ? record.prop_type : null}</p>
            </div>

          </div>
        ),
      }, {
        title: 'FF', width: 150,
        dataIndex: 'FF',
        render: (text, record) => (
          <div className='listinginfowrap'>
            <div className='info-group'>
              <p className='info-title'>Beds:</p>
              <p>{record.bedrooms}</p>
            </div>
            <div className='info-group'>
              <p className='info-title'>FF/UF:</p>
              <p>{record.furnished == 0 ? "Unfurnished" : "Furnished"}</p>
            </div>

          </div>
        ),
      }, {
        title: 'Avail date', width: 150,
        dataIndex: 'avail_date',
        render: (text, record) => (
          <div className='listinginfowrap'>
            <div className='info-group'>
              <p className='info-title'>Available Date:</p>
              <p>{(record.avail_date == null | record.avail_date == "" | record.avail_date == '0000-00-00 00:00:00') ? "-" :
                (<Moment format="DD MMM YYYY">
                  {record.avail_date}
                </Moment>)}</p>
            </div>
            <div className='info-group'>
              <p className='info-title'>Leads:</p>
              <p>{record.leads_count != "" || record.leads_count != null ?
                record.leads_count > 0 ?
                  <Link target="_blank" to={"all_leads/" + record.id}>{record.leads_count}</Link>
                  : record.leads_count
                : "-"}
              </p>
            </div>

          </div>
        ),
      }, {
        title: 'Added date', width: 150,
        dataIndex: 'added_date',
        render: (text, record) => (
          <div className='listinginfowrap'>
            <div className='info-group'>
              <p className='info-title'>Added Date:</p>
              <p>{(record.added_date == null | record.added_date == "" | record.added_date == '0000-00-00 00:00:00') ? "-" :
                (<Moment fromNow>
                  {record.added_date}
                </Moment>)}</p>
            </div>
            <div className='info-group'>
              <p className='info-title'>Viewings:</p>
              <p>{record.viewings_count != "" || record.viewings_count != null ? record.viewings_count : "-"}
              </p>
            </div>

          </div>
        ),
      }, {
        title: 'Published date', width: 150,
        dataIndex: 'published_date',
        render: (text, record) => (
          <div className='listinginfowrap'>
            <div className='info-group'>
              <p className='info-title'>Published Date:</p>
              <p>{(record.published_date == null | record.published_date == "" | record.published_date == '0000-00-00 00:00:00') ? "-" :
                (<Moment fromNow>
                  {record.published_date}
                </Moment>)}</p>
            </div>
            <div className='info-group'>
              <p className='info-title'>Offers:</p>
              <p>{record.offers_count != "" || record.offers_count != null ? record.offers_count : "-"}
              </p>
            </div>

          </div>
        )
      }, {
        title: 'Last Call', width: 150,
        dataIndex: 'last_call',
        render: (text, record) => (
          <div className='listinginfowrap'>
            <div className='info-group'>
              <p className='info-title'>Last Call:</p>
              {(record.last_call && record.last_call != null) ?
                <Moment fromNow>
                  {record.last_call}
                </Moment> : "-"
              }
            </div>
            <div className='info-group'>
              <p className='info-title'>Matched Leads:</p>
              <p>
                <Link target="_blank" to={"matched_list_leads/" + record.id}>
                  {record.matched} (<span className={record.new_matched == 0 ? "zerocolor" : "newmatchcolor"}>{record.new_matched}</span>)
                </Link>
              </p>
            </div>
          </div>
        )
      }, {
        title: 'Preview', width: 50, className: "actionwrap",
        render: (text, record) => (
          <>
            <div className="info-group d-flex flex-column align-items-center" style={{ height: '120px' }}>
              <p className='info-title'><i class="bi bi-three-dots"></i></p>
              <Tooltip placement="topRight" title={"Edit this listing"}><a className='btn btn-text px-0 align-items-center d-flex' onClick={this.handleOpenDrawerToEdit.bind(this, record.id, false)}><EditOutlined className='mr-1' /></a></Tooltip>
              <Tooltip placement="topRight" title={"Download brochure"}><a className='btn btn p-0 align-items-center d-flex mt-3' target="_blank" onClick={this.downloadPdf.bind(this, record.id)}><FilePdfOutlined className='mr-1' /></a></Tooltip>
              <Tooltip placement="topRight" title={"Preview listing page"}><a className='btn btn-text px-0 d-flex align-items-center mt-3' onClick={this.handlePreviewLink.bind(this, record.id, record.refno, record.status)} ><EyeFilled className='mr-1' /> </a></Tooltip>
            </div>
          </>
        ),
      }

    ];

    const allListingColumns = [
      {
        title: 'cardColumn', className: 'namecolwrap',
        // style: { zIndex: '9' },
        sorter: (a, b) => a.refno.length - b.refno.length,
        render: (text, record) => {
          var pStatus = ''
          var color = ''

          switch (record.status) {
            case 'D':
              pStatus = 'Draft'
              color = 'draft'
              break;
            case 'UC':
              pStatus = 'Confirmed Owner'
              color = 'confirmedowner'
              break;
            case 'N':
              pStatus = 'Unpublished'
              color = 'unpublished'
              break;
            case 'OM':
              pStatus = 'Off-Market'
              color = 'offmarket'
              break;
            case 'CTM':
              pStatus = 'Coming to market'
              color = 'comingtomarket'
              break;
            case 'Y':
              pStatus = 'Published'
              color = 'published'
              break;
            case 'U':
              pStatus = 'Action'
              color = 'action'
              break;

            case 'SEO':
              pStatus = 'SEO'
              color = 'seo'
              break;
            case 'L':
              pStatus = 'Unlisted'
              color = 'unlisted'
              break;
            case 'S':
              pStatus = 'Sold'
              color = 'sold'
              break;
            case 'T':
              pStatus = 'Let'
              color = 'let'
              break;
            case 'P':
              pStatus = 'Review'
              color = 'review'
              break;
            case 'F':
              pStatus = 'Feeds'
              color = 'feeds'
              break;
            // new status
            case 'PO':
              pStatus = 'Pending owner'
              color = 'feeds'
              break;
            case 'SLP':
              pStatus = ['Sold by ', <img key="lpLogo" src='/logowhite.png' height="15px" className='ml-1' />]
              color = 'slp'
              break;
            case 'R':
              pStatus = 'Rented'
              color = 'rented'
              break;
            case 'RLP':
              pStatus = ['Rented by ', <img key="lpLogo" src='/logowhite.png' height="15px" className='ml-1' />]
              color = 'rlp'
              break;
            default:
              pStatus = 'Draft'
              color = 'draft'
              break;
          }
          return (<>

            <div className="TableCard">
              <Link className="btn-text listinginfo" onClick={this.handleOpenDrawerToEdit.bind(this, record.id, false)}>
                <div className='row alignCenter headerGrayBgColor borderBottom'>
                  <div className='col-6'>
                    <div className='HeaderMainLeft'>
                      <div className={color + " pstat "} key={pStatus}>{pStatus}</div>
                      <p className='font-bold fs-6  mb-0'>{record.refno}</p>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                      <p className='info-title'>Added Date:</p>
                      <p>{(record.added_date == null | record.added_date == "" | record.added_date == '0000-00-00 00:00:00') ? "-" :
                        (<Moment fromNow>
                          {record.added_date}
                        </Moment>)}</p>
                    </div>
                    <div className='d-flex gap-1 justify-end HeaderMainDivRight'>
                      <p className='info-title'>Price:</p>
                      <p>{record.price ? record.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED" : null}</p>
                    </div>
                  </div>
                </div>


                <div className='row alignCenter borderBottom pt-2 pb-2'>
                  <div className='col-6'>
                    <div className='BodyFirstRowLeft'>

                      {record.property_images == null ?
                        <>
                          <div className='noimageyet'><img src={"../../images/noimageyet.png"} className='defimg mx-auto' width="80px" style={{ opacity: '0.6' }} /><span className='font-12'>Image pending</span></div>
                        </>
                        :
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItem: 'center' }}>
                          {/* <Carousel arrows style={{ width: '100%', height: 'auto' }}
                         effect="fade" {...props}>
                          {this.getImageArray(record.property_images).map((image) => {
                            if (image.includes('https://')) {
                              return <img src={image} style={{ width: '100%' }} />
                            } else { return <img src={baseurlImg + "/" + image} style={{ width: '100%' }} /> }
                          })}
                        </Carousel> */}
                          <BootStrapCarousel fade controls={false} indicators={false} style={{ width: '100%', height: 'auto' }}>
                            {this.getImageArray(record.property_images).map((image, index) => (
                              <BootStrapCarousel.Item key={index}>
                                <img
                                  className="d-block w-100"
                                  src={image.includes('https://') ? image : `${baseurlImg}/${image}`}
                                  alt={`Slide ${index + 1}`}
                                  style={{ objectFit: 'cover', height: 'auto', maxHeight: '400px' }}
                                /></BootStrapCarousel.Item>
                            ))}
                          </BootStrapCarousel>
                        </div>
                      }
                    </div>
                  </div>
                  <div className='col-6'>
                    {/* <div className='d-flex gap-1 BodyFirstRowRight'>
                      <p className='info-title'>Unit No:</p>
                      <p>{record.unitno ? record.unitno : null}</p>
                    </div> */}
                    <div className='BodyFirstRowRight'>
                      <p className='mb-0'>{record.property_project}</p>
                      <p className='mb-0'>{record.subcommunity + ", " + record.community}</p>
                    </div>

                  </div>
                  <div className='col-12'>
                    <div className='row alignCenter pt-2 pb-2'>
                      <div className='col-6'>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Unit No:</p>
                          <p>{record.unitno ? record.unitno : null}</p>
                        </div>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Property For:</p>
                          <p>{record.property_for ? record.property_for : null}</p>
                        </div>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Property Type:</p>
                          <p>{record.prop_type ? record.prop_type : null}</p>
                        </div>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Beds:</p>
                          <p>{record.bedrooms}</p>
                        </div>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>FF/UF:</p>
                          <p>{record.furnished == 0 ? "Unfurnished" : "Furnished"}</p>
                        </div>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Available Date:</p>
                          <p>{(record.avail_date == null | record.avail_date == "" | record.avail_date == '0000-00-00 00:00:00') ? "-" :
                            (<Moment format="DD MMM YYYY">
                              {record.avail_date}
                            </Moment>)}</p>
                        </div>

                      </div>
                      <div className='col-6'>

                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Leads:</p>
                          <p>{record.leads_count != "" || record.leads_count != null ?
                            record.leads_count > 0 ?
                              <Link target="_blank" to={"all_leads/" + record.id}>{record.leads_count}</Link>
                              : record.leads_count
                            : "-"}
                          </p>
                        </div>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Viewings:</p>
                          <p>{record.viewings_count != "" || record.viewings_count != null ? record.viewings_count : "-"}
                          </p>
                        </div>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Offers:</p>
                          <p>{record.offers_count != "" || record.offers_count != null ? record.offers_count : "-"}
                          </p>
                        </div>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Last Call:</p>
                          {(record.last_call && record.last_call != null) ?
                            <Moment fromNow>
                              {record.last_call}
                            </Moment> : "-"
                          }
                        </div>

                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Matched Leads:</p>
                          <p>
                            <Link target="_blank" to={"matched_list_leads/" + record.id}>
                              {record.matched} (<span className={record.new_matched == 0 ? "zerocolor" : "newmatchcolor"}>{record.new_matched}</span>)
                            </Link>
                          </p>
                        </div>
                        <div className='d-flex gap-1 BodyFirstRowRight'>
                          <p className='info-title'>Published Date:</p>
                          <p>{(record.published_date == null | record.published_date == "" | record.published_date == '0000-00-00 00:00:00') ? "-" :
                            (<Moment fromNow>
                              {record.published_date}
                            </Moment>)}</p>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <div className='row alignCenter pt-2 pb-2'>
                <div className='col-4'>
                  <div className='d-flex gap-1 alignCenter BodyFirstRowRight'>
                    <Avatar className='d-flex' size={30} style={{ backgroundColor: "#FFF", marginRight: "0", }}
                      icon={<Avatar className='d-flex' style={{ backgroundColor: "#87d068", marginRight: "0", }} icon={<img src={record.profile_photo == null || record.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(record.profile_photo) ? record.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + record.profile_photo} style={{ width: "30px", height: "30px", borderRadius: "50%", }} />} />} />
                    <div className='agentwrap'>
                      <p className="font-bold">{record.agent_name}</p>
                    </div>
                  </div>
                </div>

                <div className='col-8'>

                  <div className='d-flex gap-1 footerAlignment BodyFirstRowRight'>
                    <p className='info-title'><i class="bi bi-three-dots"></i></p>

                    <Tooltip placement="topRight" title={"Edit this listing"}>
                      <a className='btn btn-text p-0 align-items-center d-flex' onClick={this.handleOpenDrawerToEdit.bind(this, record.id, false)}><EditOutlined className='mr-1' /></a>
                    </Tooltip>

                    <Tooltip placement="topRight" title={"Download brochure"}>
                      <a className='btn btn p-0 align-items-center d-flex ' target="_blank" onClick={this.downloadPdf.bind(this, record.id)}><FilePdfOutlined className='mr-1' /></a>
                    </Tooltip>

                    <Tooltip placement="topRight" title={"Preview listing page"}>
                      <a className='btn btn-text p-0 d-flex align-items-center ' onClick={this.handlePreviewLink.bind(this, record.id, record.refno, record.status)}  ><EyeFilled className='mr-1' />
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>

          </>)
        }

      }
    ];


    const handleTableChange = (pagination, filters) => {
      if (this.state.aiSearch) {


        this.setState({ isLoading: true })
        const selectedOption = this.state.smartSearchOptions.find(
          (option) => option.value === this.state.selectedValue
        );
        if (selectedOption && selectedOption.complete_data) {
          const { complete_data } = selectedOption;
          const page = pagination.current;
          const perPage = pagination.pageSize;
          const data = {
            search_key: this.state.smartSearchValue,
            selected_key: complete_data
          }
          API.post(`/get_smart_search_result?page=${page}&perPage=${perPage}`, data)
            .then((response) => {
              if (response.data.success) {
                this.setState({
                  mylistings: response.data.listings,
                  isLoading: false,
                  all_property_images: response.data.listings.data.property_images ? response.data.listings.data.property_images.split(",") : []
                })
              }
              return response.data;
            })
            .catch((e) => {
              console.log("error", e)
            }).finally(() => {
              this.setState({ isLoading: false })
            })

        }


      } else {
        const all_data = store.getState((state) => state);
        const isExpiringListing = all_data.expiring_listing_reducer.isExpiringListing
        const isMoveInDateListing = all_data.expiring_listing_reducer.isMoveInDateListing
        const isMatchedListing = all_data.expiring_listing_reducer.isMatchedListing
        const selectedAgents = all_data.expiring_listing_reducer.selectedAgents

        const stock_status = all_data.stock_reducer.status
        const stock_community_id = all_data.stock_reducer.community_id
        const stock_subcommunity_id = all_data.stock_reducer.subcommunity_id
        const stock_property_id = all_data.stock_reducer.property_id




        const data = {
          listings_id: this.state.listings_id,
          pond_filter: this.state.pond_filter == true ? "Yes" : "No",
          selectedAgents: selectedAgents,
          isMatchedListing: isMatchedListing == true ? 1 : 0,
          isExpiringListing: isExpiringListing == true ? 1 : 0,
          isMoveInDateListing: isMoveInDateListing == true ? 1 : 0,
          last_call_value: this.state.last_call_value,
          last_call_value_id: this.state.last_call_value_id,
          last_call_option_value: this.state.last_call_option_value,
          pledge_filter: this.state.pledge_filter == true ? "Yes" : "No",
          matched_leads_viewing_value_id: this.state.matched_leads_viewing_value_id,
          matched_leads_and_viewings_morethan_option: this.state.matched_leads_and_viewings_morethan_option,
          matched_leads_and_no_viewings_option: this.state.matched_leads_and_no_viewings_option,
          matched_leads_viewing_value: this.state.matched_leads_viewing_value,
          lead_gen_filter: this.state.lead_gen_filter == true ? "Yes" : "No",
          bedroom_filter: this.state.bedroom_filter,
          property_type_filter: this.state.property_type_filter,
          furnished_types_value: this.state.furnished_types_value,
          status_filter: this.state.status_filter,
          community_filter: this.state.community_filter,
          agent_filter: this.state.agent_filter,
          sub_community_filter: this.state.sub_community_filter,
          property_filter: this.state.property_filter,
          refno_first_value_id: this.state.refno_first_value_id,
          refno_and_or_option: this.state.refno_and_or_option,
          refno_second_value_id: this.state.refno_second_value_id,
          refno_first_value: this.state.refno_first_value,
          refno_second_value: this.state.refno_second_value,

          unitno_first_value_id: this.state.unitno_first_value_id,
          unitno_and_or_option: this.state.unitno_and_or_option,
          unitno_second_value_id: this.state.unitno_second_value_id,
          unitno_first_value: this.state.unitno_first_value,
          unitno_second_value: this.state.unitno_second_value,

          price_first_value_id: this.state.price_first_value_id,
          price_and_or_option: this.state.price_and_or_option,
          price_second_value_id: this.state.price_second_value_id,
          price_first_value: this.state.price_first_value,
          price_second_value: this.state.price_second_value,
          price_min: this.state.price_min,
          price_max: this.state.price_max,

          leads_count_first_value_id: this.state.leads_count_first_value_id,
          leads_count_and_or_option: this.state.leads_count_and_or_option,
          leads_count_second_value_id: this.state.leads_count_second_value_id,
          leads_count_first_value: this.state.leads_count_first_value,
          leads_count_second_value: this.state.leads_count_second_value,
          leads_count_min: this.state.leads_count_min,
          leads_count_max: this.state.leads_count_max,

          viewings_count_first_value_id: this.state.viewings_count_first_value_id,
          viewings_count_and_or_option: this.state.viewings_count_and_or_option,
          viewings_count_second_value_id: this.state.viewings_count_second_value_id,
          viewings_count_first_value: this.state.viewings_count_first_value,
          viewings_count_second_value: this.state.viewings_count_second_value,
          viewings_count_min: this.state.viewings_count_min,
          viewings_count_max: this.state.viewings_count_max,

          offers_count_first_value_id: this.state.offers_count_first_value_id,
          offers_count_and_or_option: this.state.offers_count_and_or_option,
          offers_count_second_value_id: this.state.offers_count_second_value_id,
          offers_count_first_value: this.state.offers_count_first_value,
          offers_count_second_value: this.state.offers_count_second_value,
          offers_count_min: this.state.offers_count_min,
          offers_count_max: this.state.offers_count_max,

          date_first_value_id: this.state.date_first_value_id,
          date_and_or_option: this.state.date_and_or_option,
          date_second_value_id: this.state.date_second_value_id,
          date_first_value: this.state.date_first_value,
          date_second_value: this.state.date_second_value,

          date_morethan_option: this.state.date_morethan_option,
          date_lessthan_option: this.state.date_lessthan_option,
          date_value: this.state.date_value,
          date_value_id: this.state.date_value_id,

          publish_date_morethan_option: this.state.publish_date_morethan_option,
          publish_date_lessthan_option: this.state.publish_date_lessthan_option,
          publish_date_value: this.state.publish_date_value,
          publish_date_value_id: this.state.publish_date_value_id,
          available_date: this.state.dateRange,
          leads_count: this.state.leads_count,
          property_type: this.state.propert_type_value,
          property_for: this.state.propert_for_value,
          filter_key: this.state.global_search,


        }



        this.fetchData(pagination.current, pagination.pageSize, data);
      }
    };

    const items = [
      {
        key: '1',
        label: `New listings  (10)`,
        closable: true,
      },
      {
        key: '2',
        label: `Contacted listings  (0)`,
      },
      {
        key: '3',
        label: `Other listings  (13)`,
      },
      {
        key: '4',
        label: `Other listings  (13)`,
      },
      {
        key: '5',
        label: `Other listings  (13)`,
      },
      {
        key: '6',
        label: `Other listings  (13)`,
      },
    ];

    // const userdata = store.getState((state) => state);
    // let role_name = userdata.auth.user.role_name;
    // console.log("debugging for role", )




    const sellers = [
      { title: 'Name', dataIndex: 'name' },
      { title: 'Mobile', dataIndex: 'mobile' },
      { title: 'Email', dataIndex: 'email' },
      { title: 'Agent', dataIndex: 'agent' },
    ]

    const sellersdata = [
      { name: 'Rohit Batra', mobile: '0505588463', email: '', agent: 'Claire Towle' },
      { name: 'Asha', mobile: '447739408250', email: '', agent: 'George Hughes' },
    ]


    function onChangeImage(sourceId, sourceIndex, targetIndex, targetId) {
      console.log(targetId, sourceId);
      // if (targetId) {
      //   const result = move(
      //     itemsLeft[sourceId],
      //     itemsLeft[targetId],
      //     sourceIndex,
      //     targetIndex
      //   );
      //   return setItems({
      //     ...itemsLeft,
      //     [sourceId]: result[0],
      //     [targetId]: result[1]
      //   });
      // }

      // const result = swap(itemsLeft[sourceId], sourceIndex, targetIndex);
      // return setItems({
      //   ...itemsLeft,
      //   [sourceId]: result
      // });
    }


    const resetFilterButton = (
      <Button type='primary' style={{ margin: window.isIpadView ? '0' : '0.5rem' }} className=" ml-2" onClick={this.handleClearFilter} icon={<ClearOutlined />} >Reset filter</Button>
    )

    const tableGallary = (
      <Row span={24} align="middle" justify="space-between" className='mb-3' >
        {window.isIpadView ? '' :

          <Col span={window.isIpadView ? 12 : 24} className='d-flex flex-row viewswitcher gap-1' align="center" >

            <p style={{ marginBottom: '0' }}>View As:</p>
            <div className='viewswitcher-btn'>
              <Button type="text" onClick={this.handleToggleViewGallery} className={this.state.isViewAsGallery ? "activeview" : ""}><i class="bi bi-view-list"></i></Button>
              <Button type='text' onClick={this.handleToggleViewTable} className={this.state.isViewAsGallery ? "" : "activeview"}><i class="bi bi-table"></i></Button>
            </div>
          </Col>}

        {window.isIpadView ?
          <Col span={window.isIpadView ? 12 : 24} className='d-flex flex-row viewswitcher justify-content-end' >
            {resetFilterButton}
          </Col> : ''}
      </Row>
    )




    const advancedFilters = (<>
      <div className='listingfilter-wrap'>
        {window.isIpadView ? (tableGallary) : ''}
        <Row span={24} gutter={8}>

          <Col pan={3} xl={3} lg={3} md={12} sm={24} xs={24}  >
            {/* Property For 
                Change to buttons Sale | Rent
                */}
            <div className='w-100 form-group propertyfor-wrap'>
              <Select mode="multiple" placeholder="Property For" value={this.state.propert_for_value} onChange={this.handlePropertyFor} options={this.state.property_for_option} maxTagCount='responsive' allowClear />
            </div>
          </Col>

          <Col span={3} xl={3} lg={3} md={12} sm={24} xs={24}>
            {/* Ref No 
                Can be converted to autocomplete search bar - ref no, community, subcommunity, property
                */}
            <div className='searchbar w-100 form-group'>
              {/* <AutoComplete className="w-100" placeholder="Search listings" /> */}
              <Input type="text" value={this.state.refno_first_value} className="form-control" onChange={this.handleRefnoFirst} placeholder='Ref No' allowClear />
            </div>
          </Col>
          <Col span={6} xl={6} lg={6} md={24} sm={24} xs={24}>
            <div className='w-100 form-group'>
              <Select allowClear showSearch key="1" placeholder="Community" value={this.state.community_filter ? this.state.community_filter : "Community"} onChange={this.handleCommunityFilter} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={this.state.communities} />
            </div>
          </Col>
          <Col span={6} xl={6} lg={6} md={24} sm={24} xs={24}>
            <div className='w-100 form-group'>
              <Select showSearch allowClear key="1" placeholder="Sub Community" onChange={this.handleSubCommunityFilter} value={this.state.sub_community_filter ? this.state.sub_community_filter : "Sub Community"} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={this.state.sub_communities} />
            </div>
          </Col>
          <Col span={6} xl={6} lg={6} md={24} sm={24} xs={24}>
            <div className='w-100 form-group'>
              <Select showSearch allowClear key="1" value={this.state.property_filter ? this.state.property_filter : "Property"} placeholder="Property" onChange={this.handlePropertyFilter} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={this.state.properties} />
            </div>
          </Col>
        </Row>
        <Row span={24} gutter={8}>
          <Col span={4} xl={4} lg={4} md={24} sm={24} xs={24}>
            <div className={window.isIpadView ? 'w-100 form-group' : 'w-100 form-group mb-0'}>
              <Select showSearch allowClear placeholder="Agent" mode='multiple' key="1" onChange={this.handleAgentFilter} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={this.state.agents}
              // value={this.state.agent_filter == "" ? "Agent" : this.state.agent_filter}
              />
            </div>
          </Col>
          <Col span={4} xl={4} lg={4} md={12} sm={24} xs={24}>
            <div className={window.isIpadView ? 'w-100 form-group' : 'w-100 form-group mb-0'}>
              <Select showSearch allowClear mode="multiple" value={this.state.property_type_filter ? this.state.property_type_filter : 'Property Type'} placeholder="Property Type" onChange={this.handlePropertyTypeFilter} filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={this.state.propertytypes} />
            </div>
          </Col>
          <Col span={4} xl={4} lg={4} md={12} sm={24} xs={24}>
            <div className={window.isIpadView ? 'w-100 form-group matchbedroom' : 'w-100 form-group mb-0 matchbedroom'}>
              <select allowClear value={this.state.bedroom_filter} onChange={this.handleBedFilter} id="matchingBedrooms" name="bedrooms" className="form-control" title tabIndex={-1} placeholder='Bedrooms'>
                <option value="">Bedroom</option>
                <option value="Studio">Studio</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3} >3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
                <option value={13}>13</option>
                <option value={14}>14</option>
                <option value={15}>15</option>
              </select>
            </div>
          </Col>
          <Col span={6} xl={6} lg={6} md={24} sm={24} xs={24}>
            <div className={window.isIpadView ? 'w-100 form-group flex-row unitwrap' : 'w-100 form-group mb-0 flex-row unitwrap'}>
              <div className='d-flex flex-row border' style={{ borderRadius: '0.3rem', width: window.isIpadView ? '100%' : '' }}>
                <p className='mb-0' style={{ fontSize: 13, lineHeight: '36px', padding: '0 7px', whiteSpace: 'nowrap' }}>Unit No</p>
                <select className="form-select font-12" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleUnitnoFirstValue_id}>
                  <option value="1">Contain</option>
                  <option value="2">Not contain</option>
                  <option value="3">Equals</option>
                  <option value="4">Not equal</option>
                  <option value="5">Starts with</option>
                  <option value="6">Ends with</option>
                </select>
                <div className=''>
                  <input type="text" value={this.state.unitno_first_value} className="form-control" onChange={this.handleUnitnoFirst} placeholder="Unit No" />
                </div>
                {this.state.unitno_active_second_option ? (<div className='' >
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="flexRadio" id="flexRadio1" checked={this.state.unitno_and_or_option ? true : false} onChange={this.handleUnitRadio} />
                    <label className="form-check-label" for="flexRadio1">
                      AND
                    </label>
                    <input className="form-check-input ml-3" type="radio" name="flexRadio" id="flexRadio1" checked={!this.state.unitno_and_or_option ? true : false} onChange={this.handleUnitRadio} />
                    <label className="form-check-label ml-4" for="flexRadio1">
                      OR
                    </label>

                  </div>
                  <select className="form-select" aria-label="Default select example" name='unitno_second_filter_value_id' onChange={this.handleUnitnoSecondValue_id}>
                    <option value="1">Contain</option>
                    <option value="2">Not contain</option>
                    <option value="3">Equals</option>
                    <option value="4">Not equal</option>
                    <option value="5">Starts with</option>
                    <option value="6">Ends with</option>
                  </select>
                  <div className='0'>
                    <input type="text" value={this.state.unitno_second_value} className="form-control" onChange={this.handleUnitnoSecond} placeholder="filter..." />
                  </div>

                </div>) : null}
              </div>
            </div>
          </Col>
          <Col span={6} xl={6} lg={6} md={24} sm={24} xs={24}>
            <div className={window.isIpadView ? 'w-100 form-group price-wrap' : 'w-100 form-group mb-0 price-wrap'}>
              <div className='d-flex flex-row border' style={{ borderRadius: '0.3rem' }}>
                <p className='mb-0' style={{ fontSize: 13, lineHeight: '36px', padding: '0 7px' }}>Price</p>
                <div className='selectwrap'><select className="form-select" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
                  <option value="1">Equals</option>
                  <option value="2">Not equal</option>
                  <option value="3">Less than</option>
                  <option value="4">Less than or equals</option>
                  <option value="5">Greater than</option>
                  <option value="6">Greater than or equals</option>
                  <option value="7">In range</option>
                </select></div>

                <div className='priceinputwrap'>
                  {this.state.inrange ?
                    <div style={{ display: 'flex', flexDirection: 'row' }} className='priceinrangewrap'>
                      <input type="text" value={this.state.price_min} className="form-control" onChange={this.handlePriceMin} placeholder="Min" />
                      <span className="px-1" style={{ lineHeight: "36px", height: 36 }}>-</span>
                      <input type="text" value={this.state.price_max} className="form-control ml-auto" onChange={this.handlePriceMax} placeholder="Max" />
                    </div>
                    :
                    <input type="text" value={this.state.price_first_value} className="form-control w-100" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="Enter number" style={{ border: 'none' }} />}
                </div>
                {this.state.price_active_second_option ? (
                  <div className='' >
                    <center>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="pricefirst" id="pricefirst" checked={this.state.price_and_or_option ? true : false} onChange={this.handlePriceRadio} />
                        <label className="form-check-label" for="pricefirst">AND</label>
                        <input className="form-check-input ml-3" type="radio" name="pricefirst" id="pricesecond" checked={!this.state.price_and_or_option ? true : false} onChange={this.handlePriceRadio} />
                        <label className="form-check-label ml-4" for="pricesecond">OR</label>
                      </div>
                    </center>
                    <select className="form-select" aria-label="Default select example" name='price_second_filter_value_id' onChange={this.handlePriceSecondValue_id}>
                      <option value="1">Equals</option>
                      <option value="2">Not equal</option>
                      <option value="3">Less than</option>
                      <option value="4">Less than or equals</option>
                      <option value="5">Greater than</option>
                      <option value="6">Greater than or equals</option>
                      <option value="7">In range</option>
                    </select>
                    <input type="text" value={this.state.price_second_value} className="form-control" onChange={this.handlePriceSecond} placeholder="Enter price" style={{ border: 'none' }} />

                  </div>) : null}
              </div>

            </div>
          </Col>
        </Row>
      </div>

      <div className='listingfilter-third mt-3'>
        <Row span={24} className='datesfilter-wrap' gutter={8}>
          <Col span={2} lg={2} md={2} sm={24} xs={24}>
            <p className='mr-2 mb-0 font-12 font-bold' style={{ whiteSpace: 'nowrap' }}>Filter by Dates:</p>
          </Col>
          <Col span={6} xl={6} lg={6} md={24} sm={24} xs={24}>
            {/*grid view date picker*/}
            <div className='availdate-wrap form-group d-flex flex-row'>
              <p className='titledate'>Available Date</p>
              <RangePicker onChange={this.handleRangeDate} />
            </div>
          </Col>
          <Col span={8} xl={8} lg={8} md={24} sm={24} xs={24}>
            <div className='addeddate-wrap form-group d-flex flex-row'>
              <p className='titledate'>Added Date</p>
              <div className="form-check mb-0">
                <input className="form-check-input" type="radio" name="addeddateoption" checked={this.state.date_morethan_option} id="addeddateLessthanoption" onChange={this.handleDateMorethan} />
                <label className="form-check-label" for="addeddateLessthanoption">More than</label>
              </div>
              <div className="form-check mb-0">
                <input className="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="addeddateoption" id="addeddateMorethanoption" onChange={this.handleDateLessthan} />
                <label className="form-check-label" for="addeddateMorethanoption">Less than</label>
              </div>
              <div className='form-check mb-0' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <input type="text" value={this.state.date_value} className="form-control font-12" onChange={this.handleDateValue} />
                <select className='form-select ml-1 font-12' onChange={this.handleDateValue_id}>
                  <option value="1">days</option>
                  <option value="2">weeks</option>
                  <option value="3">months</option>
                  <option value="4">years</option>
                </select>
              </div>
            </div>
          </Col>
          <Col span={8} xl={8} lg={8} md={24} sm={24} xs={24}>
            <div className='publisheddate-wrap form-group d-flex flex-row'>
              <p className='titledate'>Published Date</p>
              <div className="form-check mb-0">
                <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.publish_date_morethan_option} id="publisheddateMorethanoption" onChange={this.handlePublishDateMorethan} />
                <label className="form-check-label font-12" for="publisheddateMorethanoption">More than</label>
              </div>
              <div className="form-check mb-0">
                <input className="form-check-input" type="radio" checked={this.state.publish_date_lessthan_option} name="flexRadioDefault" id="publisheddateLessthanoption" onChange={this.handlePublishDateLessthan} />
                <label className="form-check-label font-12" for="publisheddateLessthanoption">Less than</label>
              </div>

              <div className='form-check mb-0' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <input type="text" value={this.state.publish_date_value} className="form-control font-12" onChange={this.handlePublishDateValue} />
                <select className='form-select ml-1 font-12' onChange={this.handlePublishDateValue_id}>
                  <option value="1">days</option>
                  <option value="2">weeks</option>
                  <option value="3">months</option>
                  <option value="4">years</option>
                </select>
              </div>
            </div>
          </Col>
        </Row>
      </div></>)

    const refineFilters = (
      <CheckboxGroup className="refineSoldRent checkBoxGroupWrap" 
        value={this.state.status_filter} onChange={this.handleStatusChange} placeholder="Status"
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        options={[
          { value: 'SLP', label: ['Sold by ', <img key="lpLogo" src='/LPLogo.png' height="13px" className='ml-1' />] },
          { value: 'R', label: 'Rented' },
          { value: 'RLP', label: ['Rented by ', <img key="lpLogo" src='/LPLogo.png' height="13px" className='ml-1' />] }
        ]} />
    )

    const statusFilters = (
      <>
        <CheckboxGroup className='checkBoxGroupWrap'
          value={this.state.status_filter} onChange={this.handleStatusChange} placeholder="Status"
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          options={[
            { value: 'Y', label: 'Published' },
            { value: 'F', label: 'Feeds' },
            { value: 'UC', label: 'Confirmed Owner' },
            { value: 'CTM', label: 'Coming to market' },
            { value: 'PO', label: 'Pending owner' },
            //   { value: 'SLP', label: 'Sold by LP' },
            //   { value: 'R', label: 'Rented'},
            //   { value: 'RLP', label: 'Rented by LP' }
          ]} />
        <div className='form-group  mb-0 leadgengrp ml-3'>
          <Checkbox checked={this.state.lead_gen_filter} onChange={this.leadGenFilter}>Lead Gen</Checkbox>
        </div>
        <div className='form-group mb-0 pledge ml-3'>
          <Checkbox checked={this.state.pledge_filter} onChange={this.pledgeFilter}>Pledge</Checkbox>
        </div>

        <div className='form-group mb-0 pond ml-3'>
          <Checkbox checked={this.state.pond_filter} onChange={this.pondFilter}>Pond</Checkbox>
        </div>
      </>
    )
    const filterByReferral = (
      <div className="d-flex flex-row">
        <div className='form-group mb-0 refreceived ml-3'>
          <Checkbox checked={this.state.referred_to_filter} onChange={this.referredToFilter}> Received</Checkbox>
        </div>

        <div className='form-group mb-0 refSent ml-3'>
          <Checkbox checked={this.state.referred_filter} onChange={this.referredFilter}> Sent</Checkbox>
        </div>
      </div>
    )

    const filterBy = (
      <>
        <div className='leadsfilterwrap'>
          <Popover title="Filter listing by Leads" trigger="click" placement="bottomRight"
            content={<>
              <select className="form-select font-12" aria-label="Default select example" name='leads_count_first_filter_value_id' onChange={this.handleLeadsCountFirstValue_id}>
                <option value="1">Equals</option>
                <option value="2">Not equal</option>
                <option value="3">Less than</option>
                <option value="4">Less than or equals</option>
                <option value="5">Greater than</option>
                <option value="6">Greater than or equals</option>
                <option value="7">In range</option>
              </select>
              <div className='mt-2'>
                {this.state.leads_count_inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <input type="text" value={this.state.leads_count_min} style={{ width: '105px' }} className="form-control font-12" onChange={this.handleLeadsCountMin} placeholder="min" />
                  <input type="text" value={this.state.leads_count_max} style={{ width: '105px' }} className="form-control font-12" onChange={this.handleLeadsCountMax} placeholder="max" />
                </div> : <input type="text" value={this.state.leads_count_first_value} className="form-control font-12" pattern='[0-9]*\.?[0-9]*' onChange={this.handleLeadsCountFirst} placeholder="Enter leads count" />}
              </div>
              {this.state.leads_count_active_second_option ? (<div className='mt-2' >
                <center>
                  <div className="form-check">
                    <input className="form-check-input font-12" type="radio" name="leadscountfirst" id="leadscountfirst" checked={this.state.leads_count_and_or_option ? true : false} onChange={this.handleLeadsCountRadio} />
                    <label className="form-check-label" for="leadscountfirst">
                      AND
                    </label>
                    <input className="form-check-input ml-3 font-12" type="radio" name="leadscountfirst" id="leadscountsecond" checked={!this.state.leads_count_and_or_option ? true : false} onChange={this.handleLeadsCountRadio} />
                    <label className="form-check-label ml-4" for="leadscountsecond">
                      OR
                    </label>
                  </div>
                </center>
                <select className="form-select font-12" name='leads_count_second_filter_value_id' onChange={this.handleLeadsCountSecondValue_id}>
                  <option value="1">Equals</option>
                  <option value="2">Not equal</option>
                  <option value="3">Less than</option>
                  <option value="4">Less than or equals</option>
                  <option value="5">Greater than</option>
                  <option value="6">Greater than or equals</option>
                  <option value="7">In range</option>
                </select>
                <div className='mt-2'>
                  <input type="text" value={this.state.leads_count_second_value} className="form-control font-12" onChange={this.handleLeadsCountSecond} placeholder="Enter leads count" />
                </div>
              </div>) : null}
            </>}>
            <Button type="primary" className='font-12'>
              {this.state.leads_count_first_value == null || this.state.leads_count_first_value == "" && this.state.leads_count_min == 0 ?
                <><span>Leads<i class="bi bi-chevron-down ml-2"></i></span></>
                : <> {
                  this.state.leads_count_first_value_id == 1 ? "Leads equals to " + this.state.leads_count_first_value
                    : this.state.leads_count_first_value_id == 2 ? "Leads not equal to " + this.state.leads_count_first_value
                      : this.state.leads_count_first_value_id == 3 ? "Leads less than " + this.state.leads_count_first_value
                        : this.state.leads_count_first_value_id == 4 ? "Leads less than or equals " + this.state.leads_count_first_value
                          : this.state.leads_count_first_value_id == 5 ? "Leads greater than " + this.state.leads_count_first_value
                            : this.state.leads_count_first_value_id == 6 ? "Leads greater than or equals " + this.state.leads_count_first_value
                              : this.state.leads_count_first_value_id == 7 ? "Leads in range from " + this.state.leads_count_min + " to " + (this.state.leads_count_max ? this.state.leads_count_max : '0')
                                : "none"}
                  <i class="bi bi-x-circle-fill ml-2" onClick={this.handleLeadsReset}></i>
                </>}
            </Button>
          </Popover>
        </div>
        <div className='viewingsfilterwrap'>
          {/* Viewings Filter */}
          <Popover title="Filter listing by Viewings" trigger="click" placement="bottomRight"
            content={<>
              <select className="form-select font-12" aria-label="Default select example" name='viewings_count_first_filter_value_id' onChange={this.handleViewingsCountFirstValue_id}>
                <option value="1">Equals</option>
                <option value="2">Not equal</option>
                <option value="3">Less than</option>
                <option value="4">Less than or equals</option>
                <option value="5">Greater than</option>
                <option value="6">Greater than or equals</option>
                <option value="7">In range</option>
              </select>
              <div className='mt-2'>
                {this.state.viewings_count_inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <input type="text" value={this.state.viewings_count_min} style={{ width: '105px' }} className="form-control font-12" onChange={this.handleViewingsCountMin} placeholder="min" />
                  <input type="text" value={this.state.viewings_count_max} style={{ width: '105px' }} className="form-control font-12" onChange={this.handleViewingsCountMax} placeholder="max" />
                </div> : <input type="text" value={this.state.viewings_count_first_value} className="form-control font-12" pattern='[0-9]*\.?[0-9]*' onChange={this.handleViewingsCountFirst} placeholder="Enter viewings count" />}
              </div>
              {this.state.viewings_count_active_second_option ? (<div className='mt-2' >
                <center>
                  <div className="form-check">
                    <input className="form-check-input font-12" type="radio" name="viewingscountfirst" id="viewingscountfirst" checked={this.state.viewings_count_and_or_option ? true : false} onChange={this.handleViewingsCountRadio} />
                    <label className="form-check-label" for="viewingscountfirst">
                      AND
                    </label>
                    <input className="form-check-input ml-3 font-12" type="radio" name="viewingscountfirst" id="viewingscountsecond" checked={!this.state.viewings_count_and_or_option ? true : false} onChange={this.handleViewingsCountRadio} />
                    <label className="form-check-label ml-4 font-12" for="viewingscountsecond">
                      OR
                    </label>
                  </div>
                </center>
                <select className="form-select font-12" aria-label="Default select example" name='viewings_count_second_filter_value_id' onChange={this.handleViewingsCountSecondValue_id}>
                  <option value="1">Equals</option>
                  <option value="2">Not equal</option>
                  <option value="3">Less than</option>
                  <option value="4">Less than or equals</option>
                  <option value="5">Greater than</option>
                  <option value="6">Greater than or equals</option>
                  <option value="7">In range</option>
                </select>
                <div className='mt-2'>
                  <input type="text" value={this.state.viewings_count_second_value} className="form-control font-12" onChange={this.handleViewingsCountSecond} placeholder="Enter viewings count" />
                </div>
              </div>) : null}
            </>}>
            <Button type="primary" className='font-12'>
              {this.state.viewings_count_first_value == null || this.state.viewings_count_first_value == "" && this.state.viewings_count_min == 0 ?
                <><span>Viewings<i class="bi bi-chevron-down ml-2"></i></span></>
                : <>{
                  this.state.viewings_count_first_value_id == 1 ? "Viewings equals to " + this.state.viewings_count_first_value
                    : this.state.viewings_count_first_value_id == 2 ? "Viewings not equal to " + this.state.viewings_count_first_value
                      : this.state.viewings_count_first_value_id == 3 ? "Viewings less than " + this.state.viewings_count_first_value
                        : this.state.viewings_count_first_value_id == 4 ? "Viewings less than or equals " + this.state.viewings_count_first_value
                          : this.state.viewings_count_first_value_id == 5 ? "Viewings greater than " + this.state.viewings_count_first_value
                            : this.state.viewings_count_first_value_id == 6 ? "Viewings greater than or equals " + this.state.viewings_count_first_value
                              : this.state.viewings_count_first_value_id == 7 ? "Viewings in range from " + this.state.viewings_count_min + " to " + (this.state.viewings_count_max ? this.state.viewings_count_max : 0)
                                : null}
                  <i class="bi bi-x-circle-fill ml-2"></i></>
              }
            </Button>
          </Popover>
        </div>
        <div className='offersfilterwrap'>
          {/* OFFERS Filter */}
          <Popover title="Filter listing by Offers" trigger="click" placement="bottomRight"
            content={<>
              <select className="form-select font-12" aria-label="Default select example" name='offers_count_first_filter_value_id' onChange={this.handleOffersCountFirstValue_id}>
                <option value="1">Equals</option>
                <option value="2">Not equal</option>
                <option value="3">Less than</option>
                <option value="4">Less than or equals</option>
                <option value="5">Greater than</option>
                <option value="6">Greater than or equals</option>
                <option value="7">In range</option>
              </select>
              <div className='mt-2'>
                {this.state.offers_count_inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <input type="text" value={this.state.offers_count_min} style={{ width: '105px' }} className="form-control font-12" onChange={this.handleOffersCountMin} placeholder="min" />
                  <input type="text" value={this.state.offers_count_max} style={{ width: '105px' }} className="form-control font-12" onChange={this.handleOffersCountMax} placeholder="max" />
                </div> : <input type="text" value={this.state.offers_count_first_value} className="form-control font-12" pattern='[0-9]*\.?[0-9]*' onChange={this.handleOffersCountFirst} placeholder="Enter offers count" />}
              </div>
              {this.state.offers_count_active_second_option ? (<div className='mt-2' >
                <center>
                  <div className="form-check">
                    <input className="form-check-input font-12" type="radio" name="offerscountfirst" id="offerscountfirst" checked={this.state.offers_count_and_or_option ? true : false} onChange={this.handleOffersCountRadio} />
                    <label className="form-check-label font-12" for="offerscountfirst">
                      AND
                    </label>
                    <input className="form-check-input ml-3 font-12" type="radio" name="offerscountfirst" id="offerscountsecond" checked={!this.state.offers_count_and_or_option ? true : false} onChange={this.handleOffersCountRadio} />
                    <label className="form-check-label ml-4 font-12" for="offerscountsecond">
                      OR
                    </label>
                  </div>
                </center>
                <select className="form-select font-12" name='offers_count_second_filter_value_id' onChange={this.handleOffersCountSecondValue_id}>
                  <option value="1">Equals</option>
                  <option value="2">Not equal</option>
                  <option value="3">Less than</option>
                  <option value="4">Less than or equals</option>
                  <option value="5">Greater than</option>
                  <option value="6">Greater than or equals</option>
                  <option value="7">In range</option>
                </select>
                <div className='mt-2'>
                  <input type="text" value={this.state.offers_count_second_value} className="form-control font-12" onChange={this.handleOffersCountSecond} placeholder="Enter offers count" />
                </div>
              </div>) : null}
            </>}>
            <Button type="primary" className='font-12'>
              {this.state.offers_count_first_value == null || this.state.offers_count_first_value == "" && this.state.offers_count_min == 0 ?
                <><span>Offers<i class="bi bi-chevron-down ml-2"></i></span></>
                : <>{
                  this.state.offers_count_first_value_id == 1 ? "Offers equals to " + this.state.offers_count_first_value
                    : this.state.offers_count_first_value_id == 2 ? "Offers not equal to " + this.state.offers_count_first_value
                      : this.state.offers_count_first_value_id == 3 ? "Offers less than " + this.state.offers_count_first_value
                        : this.state.offers_count_first_value_id == 4 ? "Offers less than or equals " + this.state.offers_count_first_value
                          : this.state.offers_count_first_value_id == 5 ? "Offers greater than " + this.state.offers_count_first_value
                            : this.state.offers_count_first_value_id == 6 ? "Offers greater than or equals " + this.state.offers_count_first_value
                              : this.state.offers_count_first_value_id == 7 ? "Offers in range from " + this.state.offers_count_min + " to " + (this.state.offers_count_max ? this.state.offers_count_max : 0)
                                : null}
                  <i class="bi bi-x-circle-fill ml-2"></i></>
              }
            </Button>
          </Popover>
        </div>
        <div className='matchleadsfilterwrap'>
          {/* Match Leads Filter */}
          <Popover title="Filter listing by Match Leads" trigger="click" placement="bottomRight"
            content={<>
              <div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="matchleadsgrp" checked={this.state.matched_leads_and_no_viewings_option} id="matchleadsgrp1" onChange={this.handleMatchedLeadsAndNoViewing} />
                  <label className="form-check-label font-12" for="matchleadsgrp1">Match leads & no viewings</label>
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="radio" checked={this.state.matched_leads_and_viewings_morethan_option} name="matchleadsgrp" id="matchleadsgrp2" onChange={this.handleMatchedLeadsAndViewingMorethan} />
                  <label className="form-check-label font-12" for="matchleadsgrp2">Match leads</label>
                </div>
                {this.state.matched_leads_viewing_value_show ? <>
                  <select className="form-select font-12" aria-label="Default select example" name='offers_count_first_filter_value_id' onChange={this.handleMatchedLeadsFilter_Value_Option}>
                    <option value="1">Equals</option>
                    <option value="2">Not equal</option>
                    <option value="3">Less than</option>
                    <option value="4">Less than or equals</option>
                    <option value="5">Greater than</option>
                    <option value="6">Greater than or equals</option>
                    {/* <option value="7">In range</option> */}
                  </select>
                  <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <input type="text" value={this.state.matched_leads_viewing_value} className="form-control font-12 mt-1" onChange={this.handleMatchedLeadsFilter} />
                  </div></> : null}
              </div>
            </>}>
            <Button type="primary" className='font-12'>
              {this.state.matched_leads_and_no_viewings_option == false && this.state.matched_leads_and_viewings_morethan_option == false ?
                <><span>Match Leads<i class="bi bi-chevron-down ml-2"></i></span></>
                : this.state.matched_leads_and_no_viewings_option == true ?
                  <><span>Match leads & no viewings<i class="bi bi-x-circle-fill ml-2"></i></span></>
                  : this.state.matched_leads_and_viewings_morethan_option == true ?
                    <><span>Match leads equals to {this.state.matched_leads_viewing_value}<i class="bi bi-x-circle-fill ml-2"></i></span></>
                    : null
              }
            </Button>
          </Popover>
        </div>
        <div className='lastcallfilterwrap'>
          {/* Last Call Filter */}
          <Popover title="Filter listing by Last Call" trigger="click" placement="bottomRight"
            content={<>
              <div>
                <div className='mb-1'>
                  <select value={this.state.last_call_option_value} className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handleLastCallOptionValue} >
                    <option value="1">Equals</option>
                    <option value="2">Not equal</option>
                    <option value="4">Less than</option>
                    <option value="3">Greater than</option>

                  </select>
                </div>

                <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <input type="text" className="form-control" onChange={this.handleLastCallValue} />
                  <select className='form-select ml-1' onChange={this.handleLastCallValue_id}>
                    <option value="1">days</option>
                    <option value="2">weeks</option>
                    <option value="3">months</option>
                    <option value="4">years</option>
                  </select>
                </div>
              </div>
            </>}>
            <Button type="primary" className='font-12'>
              Last Call
              <i class="bi bi-chevron-down ml-2"></i>
            </Button>
          </Popover>
        </div>
      </>
    )


    const arr_note = []



    return (
      <div>
        {/* Call Log Pop up */}
        <Modal title="Available date" open={this.state.showAvailableDateModel}
          onOk={this.handleAvailableOk}
          onCancel={this.handleAvailableCancel}
        >
          <label>Available date:</label>
          <DatePicker
            format="YYYY-MM-DD"
            onChange={this.onChangeAvailableDate}
          />
        </Modal>

        <Modal width={'90%'} title={""} className={'modaltenancypdf'}
          open={this.state.previewDoc}
          //   onOk={handleOk} 
          onCancel={this.handleCancelPrevewDoc}
          footer={null}
          maskClosable={false}
        >
          <Row span={24} className='w-100'>
            <Col span={6}>
              <div className='p-4 tenancypdf-buttons' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <p className='font-bold fs-5'>Preview B</p>

                <div className="" />
                <ReactToPrint
                  content={() => this.componentRef}
                  trigger={() => <button className="btn btn-text font-14"><PrinterOutlined />Print to PDF</button>}

                />
                <a className='btn btn-text font-14' onClick={this.handleCancelPrevewDoc}>Close</a>
              </div>

            </Col>
            <Col span={18} className='pdfscroll'>

              <div className='printprevbrochure mt-3 d-flex position-relative' ref={(response) => (this.componentRef = response)}>
                <ul>
                  {this.state.all_docs.map((all_doc, index) => {
                    return <li><embed src={all_doc.document} key={index} alt={all_doc.title} style={{ height: "400px", width: "500px" }} /></li>
                    // {all_doc.document.endsWith('.pdf') ? (
                    //   <embed src={all_doc.document}  key={index} type="application/pdf" width="100%" height="500px" />
                    // ) : (
                    //  return <img src={all_doc.document}  key={index} alt={all_doc.title} style={{height: "400px", width: "500px"}}/>
                  }
                  )}
                </ul>
                {/* )} */}









              </div>
            </Col>
          </Row>
        </Modal>
        <Modal width={'90%'} title={""} className={'modaltenancypdf'}
          open={this.state.openPDF}
          //   onOk={handleOk} 
          onCancel={this.handleCancelApprove}
          footer={null}
          maskClosable={false}
        >
          <Row span={24} className='w-100'>
            <Col span={6}>
              <div className='p-4 tenancypdf-buttons' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <p className='font-bold fs-5'>Preview Brochure</p>

                <div className="" />
                <ReactToPrint
                  content={() => this.componentRef}
                  trigger={() => <button className="btn btn-text font-14"><PrinterOutlined /> Print to PDF</button>}

                />
                <a className='btn btn-text font-14' onClick={this.handleDownloadPDF}>

                  <DownloadOutlined /> {this.state.isDownloading ? <>Downloading <BarLoader color="#151515" /></> : "Download PDF"}
                </a>

                <a className='btn btn-text font-14' onClick={this.handleCancelApprove}>Close</a>

              </div>

            </Col>
            <Col span={18} className='pdfscroll'>

              <div className='printprevbrochure mt-3 d-flex position-relative' ref={(response) => (this.componentRef = response)}>
                <img style={{ position: 'absolute', top: '0.8in', left: '0.00in', width: '9.02in', height: '4.79in' }} src={baseurlImg + "/" + images[0]} />
                <img style={{ position: 'absolute', top: '3.9in', left: '3.52in', width: '5.5in', height: '1.7in' }} src="/brochure/ri_1.png" />
                <div style={{ position: 'absolute', top: '4.15in', left: '3.9in', lineHeight: '1.05in' }}><span style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: '12pt', color: '#ffffff' }}>{this.state.listing.community},&nbsp; {this.state.listing.subcommunity},&nbsp; {this.state.listing.property}  </span><br /></div>
                <div style={{ position: 'absolute', top: '4.55in', left: '3.9in', width: '3.25in', lineHeight: '1.05in' }}><span style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: '20pt', color: '#ffffff' }}>{price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : ''}  </span><br /></div>
                <div style={{ position: 'absolute', top: '11.4in', width: '100%', lineHeight: '0.18in' }}><div style={{ background: '#151515', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', padding: '1rem', width: '100%', alignItems: 'center' }}>
                    <div style={{ display: 'flex', borderRadius: '50%', overflow: 'hidden', width: 60, height: 60, alignContent: 'center', marginRight: '1rem' }}><img src={this.isValidHttpUrl(this.state.listing.profile_photo) ? this.state.listing.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + this.state.listing.profile_photo} style={{ height: '100%' }} /></div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p style={{ color: '#FFF', fontSize: 14, margin: '0 0 5px' }}>{this.state.listing.agent_name}</p>
                      <p style={{ color: '#FFF', fontSize: 11, margin: 0 }}>{this.state.listing.jobtitle}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', textAlign: 'right' }}>
                      <p style={{ color: '#FFF', fontSize: 12, margin: '0 0 5px' }}>{this.state.listing.mobile}</p>
                      <p style={{ color: '#FFF', fontSize: 12, margin: 0 }}>{this.state.listing.email}</p>
                    </div>
                  </div>
                </div><br /></div>
                {images.length > 1 ? <img style={{ position: 'absolute', top: '6.2in', left: '0.55in', width: '2.5inin', height: '1.35in' }} src={baseurlImg + "/" + images[1]} /> : null}
                {images.length > 2 ? <img style={{ position: 'absolute', top: '6.2in', left: '3.29in', width: '2.5in', height: '1.35in' }} src={baseurlImg + "/" + images[2]} />
                  : null}
                {images.length > 3 ? <img style={{ position: 'absolute', top: '6.2in', left: '6.04in', width: '2.5in', height: '1.35in' }} src={baseurlImg + "/" + images[3]} />
                  : null}
                {images.length > 1 ? <>
                  <div style={{ position: 'absolute', top: '5.80in', left: '0.55in', width: '1.18in', lineHeight: '0.24in' }}><span style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: '12pt', color: '#151515' }}>OVERVIEW</span></div>
                  <img style={{ position: 'absolute', top: '5.95in', left: '2.25in', width: '6.23in', height: '0.01in' }} src="/brochure/vi_6.png" />
                  <img style={{ position: 'absolute', top: '0.25in', left: '0.56in', width: '5.36in', height: '0.36in' }} src="/brochure/vi_7.png" /></> : null}
                <div style={{ position: 'absolute', top: '0.30in', left: '0.85in', width: '4.84in', lineHeight: '0.24in' }}><span style={{ fontSize: '10pt', color: '#151515', letterSpacing: 6 }}>LUXURY PRIVATE {this.state.listing.prop_type} <b>{this.state.listing.property_for == "rent" ? "FOR RENT" : "FOR SALE"}</b></span></div><br />
                <img style={{ position: 'absolute', top: '0.25in', left: '0.00in', width: '0.56in', height: '0.36in' }} src="/brochure/vi_8.png" />
                <img style={{ position: 'absolute', top: '0.30in', left: '6.5in', width: '2.3in' }} src="/brochure/Lp-Logo-2021-Black-Web.png" />

                <div style={{ position: 'absolute', top: '7.75in', left: '0.55in', width: '7.56in', lineHeight: '0.24in' }}><span style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: '12pt', color: '#151515' }}>ABOUT THE PROPERTY </span><br /></div>
                <div style={{ position: 'absolute', top: '8.3in', left: '0.55in', width: '8in', lineHeight: '0.14in' }}><div style={{ position: 'relative', left: '0.02in' }}><span style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: '5pt', color: '#52555b' }}>{this.state.listing.desc && parse(this.state.listing.desc.substring(0, 949))}</span></div><br /></div>
                <img style={{ position: 'absolute', top: '7.9in', left: '3.38in', width: '5.10in', height: '0.01in' }} src="/brochure/vi_32.png" />
              </div>
            </Col>
          </Row>
        </Modal>
        <Modal title="Add Call Log" open={this.state.isCallLogOpen}
          // onOk={this.handleCallSubmit}
          onCancel={this.handleCallLogModalCancel}
          footer={<><Button className={this.state.isCallEnable ? "d-flex" : "d-none"} onClick={this.handleCallLogSubmit}>Add Call</Button>
            {/* <Button>Add Note</Button> */}
          </>}
        >
          <div className='form-group w-100'>
            <label>What did you discuss with the vendor?</label>
            <TextArea rows={2} onChange={this.handleCallLogDiscuss} onBlur={this.handleCallNoteButtons} />
          </div>
          <div className='form-group w-100 d-flex flex-row align-items-center'>
            <label className=''>Is this property still available?</label>
            <Switch className="ml-2" onChange={this.handlePropertyStillAvailable} checkedChildren={"Yes"} unCheckedChildren={"No"} />
          </div>
          <div className='form-group w-100'>
            <label>Confirm Price: </label>
            <Input name='confirmprice' onChange={this.handleConfirmPrice} />
          </div>
          <div className='form-group w-100'>
            <label>{this.state.isCallEnable ? "Comments:" : "Note:"} </label>
            <TextArea rows={4} name='comments' onChange={this.handleNoteComment} />
          </div>
        </Modal>


        <ToastContainer />

        {/* Photography request
        */}

        {/* show map */}

        <Modal title="Map" width={600} open={this.state.mapOpen}
          // onOk={handleOk}
          onCancel={this.handleCloseMap}
        >
          <MapPicker defaultLocation={this.state.map_location}
            zoom={this.state.zoom}
            mapTypeId="roadmap"
            style={{ height: '700px' }}
            onChangeLocation={this.handleChangeLocation}
            onChangeZoom={this.handleChangeZoom}
            apiKey='AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8' />
        </Modal>

        <Modal width={window.isIpadView ? '100%' : 1000} title="Add Request" open={this.state.showPhotoRequest} className='photorequest'
          onOk={this.handleShowAddPhotoRequestok}
          onCancel={this.handleShowAddPhotoRequestCancel}
        >
          <Row span={24} className='w-100' gutter={24}>
            <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isMobileView ? '' : 'pl-0'}`}>
              <div className='form-group'>
                <p>Type of shoot <em>*</em></p>
                <span>Please only select the applicable option(s) to allocate appropriate time for the shoot</span>
                <div className='d-flex flex-row flex-wrap'>
                  <Checkbox checked={this.state.photography_check} onChange={this.handlePhotography} >
                    Photography
                  </Checkbox>
                  <Checkbox checked={this.state.videography_walk_check} onChange={this.handleVideoWalk} >
                    Videography - Walkthrough
                  </Checkbox>
                  <Checkbox checked={this.state.videography_agent_check} onChange={this.handleVidewAgent}  >
                    Videography - Agent Tour
                  </Checkbox>

                </div>
              </div>
            </Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isMobileView ? '' : 'pr-0'}`}>
              <div className='form-group'>
                <p>Preferred date and time for shoot <em>*</em></p>
                <span>Not guaranteed, based on availability on first come first serve basis</span>
                <div className='d-flex flex-column' style={{ gap: '0.6rem' }}>
                  <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} onChange={this.handlePhotographyDate} />
                  <TimePicker.RangePicker use12Hours format="h:mm a" minuteStep={15} style={{ width: '100%' }} onChange={this.handlePhotographyTime} />
                </div>
              </div>
            </Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isMobileView ? '' : 'pr-0'}`}>
              <div className='form-group'>
                <p className='info-title'>Document Checklist <em>*</em></p>
                <div className='info-check'>
                  <CloseOutlined />
                  <p>Title Deed</p>
                </div>
                <div className='info-check'>
                  <CloseOutlined />
                  <p>Landlord Passport/ID</p>
                </div>
                <div className='info-check'>
                  <CloseOutlined />
                  <p>{this.state.listing_details.property_for == 'sale' ? "Form A" : "Leasing Form"}</p>
                </div>
                <div className='form-group mt-3'>
                  <Checkbox className='info-title' value={this.state.haveNoc} onChange={this.handleNOC}>Do you have NOC?</Checkbox >
                </div>
              </div>
            </Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isMobileView ? '' : 'pr-0'}`}>
              <div className='form-group'>
                <p className='info-title'>Are you gonna be present during the photography?</p>
                <Radio.Group className='d-flex flex-row' onChange={this.handlePresentPhoto} >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2} defaultChecked>No</Radio>
                </Radio.Group>

              </div>
            </Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isMobileView ? '' : 'pr-0'}`}>
              <div className='form-group'>
                <p className='info-title'>Property contact name <em>*</em></p>
                <Input value={this.state.property_contact_name} />
              </div>
            </Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isMobileView ? '' : 'pr-0'}`}>
              <div className='form-group'>
                <p className='info-title'>Property contact email <em>*</em></p>
                <Input value={this.state.property_contact_email} />
              </div>
            </Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isMobileView ? '' : 'pr-0'}`}>
              <div className='form-group'>
                <p className='info-title'>Property contact number <em>*</em></p>
                <Input value={this.state.property_contact_mobile} />
              </div>
            </Col>
            <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isMobileView ? '' : 'pr-0'}`}>
              <div className='form-group'>
                <a type='primary' className='info-title' onClick={this.handleMapOpen}>Pin Location <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                </svg></a>

              </div>
            </Col>

            <Col span={12} lg={12} md={12} sm={24} xs={24} className={`${window.isMobileView ? '' : 'pr-0'}`}>
              <div className='form-group'>
                <p className='info-title'>Notes:</p>
                <span>How to access the property, any additional requirements or relevant information.</span>
                <TextArea rows={4} onChange={this.handlePhotographyNote} />
              </div>
            </Col>
          </Row>
        </Modal>





        <Modal width={1000} title="Offers" open={this.state.showOffer}
          onOk={this.handleOkOffer} onCancel={this.handleCancelOffer}
        >
          <label>Date</label>
          <p>{this.state.offer.view_date}</p>

          <label>Note</label>
          <p>
            {this.state.offer.note}
          </p>
          <label>Price</label>
          <p>
            {this.state.offer.price}
          </p>
        </Modal>

        <Modal title="Send to WhatsApp" open={this.state.iswhatsappOpen}
          onCancel={this.handleWhatsappCancel}
          onOk={this.handleWhatsappOk}
        >
          <div className="d-flex flex-column w-100">
            <div className="form-group">
              <label className="form-label">Search clients contact</label>
              <Select
                style={{
                  width: "100%",
                }}
                mode={"multiple"}
                value={this.state.whatsappSelectedClients}
                showSearch
                allowClear
                placeholder="Search"
                maxTagCount='responsive'
                optionFilterProp="children"
                onChange={this.handleSelectClients}
                onSearch={this.onSearchClient}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.whatsappClients}
              />
            </div>
            <div className="table-responsive tablelistwrap row py-2 px-1">
              <label className="form-label mb-0" style={{ fontSize: "12px" }}>Clients (Maximum of 10)</label>
              <Table
                columns={whatsappcols}
                style={{ overflowX: "auto", width: "100%" }}

                dataSource={
                  this.state?.whatsappSelectedClients
                    ? this.state?.whatsappSelectedClients
                    : []
                }

              />
            </div>
          </div>
        </Modal>

        <Modal width={600} title="Viewing" open={this.state.iseditviewingOpen} onOk={this.handleViewStatusChange} onCancel={this.handleViewStatusCancel} >
          <div className="container">
            <div className='row w-100 d-flex flex-row'>
              <div className='px-0 pt-1 form-group'>
                <label>Ref No</label>
                <p className="form-control-disabled">{this.state.viewings.listing_refno}</p>
              </div>
              <div className='px-0 pt-1 form-group'>
                <label>Agent Name</label>
                <p className="form-control-disabled">{this.state.viewings.fullname}</p>
              </div>
            </div>
            <div className='row w-100 mt-3 d-flex flex-row'>

              <div className='px-0 pt-1 form-group'>
                <label>Date</label>
                {this.state.showDate ? <DatePicker style={{ width: '100%' }} showTime format="YYYY-MM-DD HH:mm" onChange={this.handleViewingDate} /> : <p className="form-group">{this.state.viewing_date}</p>}

              </div>
              <div className='px-0 pt-1 mt-3 form-group'>
                <label>Status</label>
                <Select
                  style={{
                    width: 160,
                  }}
                  showSearch
                  value={this.state.viewing_status}
                  maxTagCount={"responsive"}
                  placeholder="Select beds"
                  optionFilterProp="children"
                  onChange={this.handleViewStatus}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={[
                    { value: 'Offer', label: 'Offer' },
                    { value: "Successful", label: 'Successful' },
                    { value: "Cancelled", label: 'Cancelled' },
                    { value: "Unsuccessful", label: 'Unsuccessful' },
                    { value: "Scheduled", label: 'Scheduled' }
                  ]}
                />
                {/* <select onChange={this.handleViewStatus} value={this.state.viewing_status} className='form-select'>
              <option value="Offer">Offer</option>
                <option value="Successful">Successful</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Unsuccessful">Unsuccessful</option>
                <option value="Scheduled">Scheduled</option>
              </select> */}
              </div>
              {this.state.showPrice ? <div className='form-group'>
                <label>Price</label>
                <input onChange={this.handleViewingPrice} type="text" className="form-control" value={this.state.viewing_price} />
              </div> : null}
            </div>
            <div className='row w-100 mt-3'>
              <div className='w-100 px-0 pt-1'>
                <label>Note</label>
                <TextArea className="form-group" value={this.state.viewing_note} style={{ width: '100%' }} onChange={this.handleViewingNote} />
              </div>


            </div>
          </div>
        </Modal>

        <Modal width={600} title="Add Viewings" open={this.state.isviewingOpen}
          onOk={this.handleViewingOk} onCancel={this.handleViewingCancel}
        >
          <Divider />

          <div className='row w-100'>
            <div className='col-md-12 px-0 pt-1'>

              <div className="row">

                <div className="col-6">
                  <div className="form-group">
                    <label className="">Date / Time:</label>
                    <DatePicker style={{ width: '100%' }} showTime format="YYYY-MM-DD HH:mm" onChange={this.handleViewingDate} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="">Notes:</label>
                  <TextArea style={{ width: '100%' }} onChange={this.handleViewingNote} />
                </div>
              </div>
            </div>
          </div>
        </Modal>


        {/* EDIT LISTING DRAWER */}
        <Drawer
          title={`Listing details`}
          placement="left"
          // size={"large"}
          width={"98%"}
          onClose={this.handleCloseEditDrawer}
          open={this.state.openToEdit}
          extra={
            <Space className='d-flex flex-row align-content-center' style={{ gap: '0.8rem' }}>
              {/* <a target="_blank" href={"https://www.luxuryproperty.com/property/preview/" + this.state.listing_details.refno+"-"+this.state.record_id} className="btn btn-default btn-sm btn-preview" style={{height: '31px'}}> <EyeFilled />Preview</a> */}
              <div className="d-flex flex-row px-0" style={{ gap: "0.6rem" }}>

                


                <a
                  className="btn btn-primary btn-sm align-content-center"
                  style={{
                    backgroundColor: this.state.disabledPrev /*|| this.props.match.params.id */ ? 'lightgray' : 'white'
                  }}
                  onClick={
                    this.state.disabledPrev /*|| this.props.match.params.id */ ?
                      null
                      :
                      this.handlePreviousRecord
                  }
                >
                  <ArrowLeftOutlined /> Previous
                </a>
                <a
                  className="btn btn-primary btn-sm"
                  style={{
                    backgroundColor: this.state.disabledNext /*|| this.props.match.params.id */ ? 'lightgray' : 'white'
                  }}
                  onClick={
                    this.state.disabledNext /*|| this.props.match.params.id */ ?
                      null
                      :
                      this.handleNextRecord
                  }
                >
                  Next <ArrowRightOutlined />
                </a>
              </div>
              <Button type='primary' danger ghost onClick={this.handleCloseEditDrawer} icon={<CloseOutlined />} style={{ height: '31px', justifyContent: 'center' }}></Button>
              {/* <Button type="primary" onClick={this.handleSaveEditDrawer}>
              Save changes
            </Button> */}

            </Space>
          }
        >
          <div className="cont-wrap container">

            {
              this.state.loader ?
                <div style={{ position: 'absolute', alignSelf: "center", top: '50%' }} >
                  <Spin size='large' />
                </div>
                :
                <>
                  <div className="row column-wrap m-0">
                    <div className="col-12 d-flex flex-row toptitle px-0">
                      <div className='d-flex flex-row col-md-3 pl-0 pr-4 justify-content-between' style={{ gap: "0.6rem" }}>

                        <h2 className="page-title fs-5 font-bold my-1 ">{this.state.listing_details.refno}</h2>
                        <p className="d-flex mb-0">
                          {this.state.listing_details.status == "D" ? <span className="draft pstat">Draft</span> : ""}
                          {this.state.listing_details.status == "UC" ? <span className="confirmedowner pstat">Confirmed Owner</span> : ""}
                          {this.state.listing_details.status == "N" ? <span className="unpublished pstat">Unpublished</span> : ""}
                          {this.state.listing_details.status == "OM" ? <span className="offmarket pstat">Off-Market</span> : ""}
                          {this.state.listing_details.status == "CTM" ? <span className="comingtomarket pstat">Coming to Market</span> : ""}
                          {this.state.listing_details.status == "Y" ? <span className="published pstat">Published</span> : ""}
                          {this.state.listing_details.status == "U" ? <span className="action pstat">Action</span> : ""}
                          {this.state.listing_details.status == "SEO" ? <span className="seo pstat">SEO</span> : ""}
                          {this.state.listing_details.status == "L" ? <span className="unlisted pstat">Unlisted</span> : ""}
                          {this.state.listing_details.status == "S" ? <span className="sold pstat">Sold</span> : ""}
                          {this.state.listing_details.status == "T" ? <span className="let pstat">Let</span> : ""}
                          {this.state.listing_details.status == "P" ? <span className="review pstat">Review</span> : ""}
                          {this.state.listing_details.status == "F" ? <span className="feeds pstat">Feeds</span> : ""}
                        </p>
                      </div>
                      <div className="pinfo col-md-6">

                        <p className="mb-0">
                          {this.state.listing_details.unitno + " "}
                          {this.state.listing_details.property_title == null ? "" : this.state.listing_details.property_title + ", "}
                          {this.state.listing_details.sub_community_title == null ? "" : this.state.listing_details.sub_community_title + ", "}
                          {this.state.listing_details.community_title}
                        </p>
                        <i className="bi bi-circle-fill" style={{ fontSize: "5px" }}></i>
                        <span>{this.state.listing_details.property_for}</span>
                        <i className="bi bi-circle-fill" style={{ fontSize: "5px" }}></i>
                        <span className="">{this.state.listing_details.property_type}</span>
                        <i className="bi bi-circle-fill" style={{ fontSize: "5px" }}></i>
                        <span><i className="fa fa-bed mr-1" aria-hidden="true"></i>{this.state.listing_details.bedrooms}</span>
                        <i className="bi bi-circle-fill" style={{ fontSize: "5px" }}></i>

                        <span>{parseInt(this.state.listing_details.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " AED"}</span>
                      </div>
                      <div className="col-md-3 d-flex flex-row pr-1 justify-content-between" >
                        <a target="_blank" href={"https://www.luxuryproperty.com/property/preview/" + this.state.listing_details.refno + "-" + this.state.record_id} className="btn btn-default btn-sm btn-preview ml-auto" style={{ height: '36px' }} > <EyeFilled />Preview</a>


                      </div>

                    </div>
                  </div>
                  <div className="row column-wrap mt-3">
                    <div className="col-12 col-md-3">
                      {role == 1 || user_id == this.state.listing_details.agent_id || this.state.listing_details.status == "UC" || this.state.listing_details.agent_id == 430 || this.state.listing_details.status == "CTM" ?
                        <div className="bg-white border-top ownersdetails">
                          <div className="d-flex flex-row justify-content-between">
                            <h3>Owner Details</h3>
                            <button type="button" className="btn btn-primary requestchange">Request Change</button>
                            {/*{role_name === ("Super Admin" || "Admin") && <button type="button" className="btn btn-primary requestchange">Request Change</button>}*/}
                          </div>
                          <div className="d-flex flex-column formfieldset mt-3">
                            <div className="d-flex flex-column form-group">
                              <label className="control-labels mr-4"> Name</label>

                              {/* editing */}
                              <EdiText

                                value={this.state.owner_name}
                                type="text"
                                onSave={this.handleSaveOwnerName}

                              />

                            </div>
                            <div className="d-flex flex-column form-group">
                              <label className="control-labels mr-4">Phone </label>
                              <p>{this.state.owner_mobile}</p>
                            </div>
                            <div className="d-flex flex-column form-group">
                              <label className="control-labels mr-4">Email </label>
                              <EdiText

                                value={this.state.owner_email}
                                type="text"
                                onSave={this.handleSaveOwnerEmail}
                              />
                            </div>
                            <div className="d-flex flex-column form-group">
                              <label className="control-labels mr-4">Owner's Other Properties</label>
                              <div className="col-12 d-flex flex-column border-wrap listtable-wrap">
                                {this.state.owner_other_properties.map((owner_other_property) => {
                                  var pStatus = 'Draft'
                                  switch (owner_other_property.status) {
                                    case 'D':
                                      pStatus = 'Draft'
                                      break;
                                    case 'UC':
                                      pStatus = 'Confirmed Owner'
                                      break;
                                    case 'N':
                                      pStatus = 'Unpublish'
                                      break;
                                    case 'OM':
                                      pStatus = 'Off-Market'
                                      break;
                                    case 'CTM':
                                      pStatus = 'Coming to market'
                                      break;
                                    case 'Y':
                                      pStatus = 'Publish'
                                      break;
                                    case 'U':
                                      pStatus = 'Action'
                                      break;

                                    case 'SEO':
                                      pStatus = 'SEO'
                                      break;
                                    case 'L':
                                      pStatus = 'Unlisted'
                                      break;
                                    case 'S':
                                      pStatus = 'Sold'
                                      break;
                                    case 'T':
                                      pStatus = 'Let'
                                      break;
                                    case 'P':
                                      pStatus = 'Review'
                                      break;
                                    case 'F':
                                      pStatus = 'Feeds'
                                      break;
                                    default:
                                      pStatus = 'Draft'
                                      break;
                                  }
                                  return (
                                    <a href className="d-flex flex-row p-2 w-100">
                                      <div className="col-8 listtable">
                                        <p className="propfor">{owner_other_property.property_for}</p>
                                        <p className="refno">{owner_other_property.refno}</p>
                                        <p className="propadd">{owner_other_property.unitno},  {owner_other_property.subcommunity}</p>
                                        <p className="community">{owner_other_property.community}</p>
                                      </div>
                                      <div className="col-4">
                                        <div className="d-flex flex-row stat publishstat">
                                          <i className="bi bi-circle-fill" />
                                          <span>

                                            {pStatus}
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  )
                                })}

                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-column mt-3 val-wrap">
                            <button className="btn btn-primary" id="getvaluation">Get Valuation</button>
                            <p id="predicted_value" />
                            <p id="precision" />
                          </div>
                        </div> : null}
                      {this.state.listing_details.property_for == "sale" ?
                        /* Mini Calculator */
                        <div className="bg-white border-top offers p-4 mt-3">
                          <div className="d-flex flex-row justify-content-between">
                            <h3 className="mr-4">Rental Yield Calculator</h3>
                          </div>
                          <div className="col-12 px-0 form-group d-flex flex-column mt-2">
                            <div className="form-group">
                              <label className="control-labels ">Purchase Price (AED)</label>
                              <input type="text" id="purchaseprice" onChange={this.handlepurchaseprice} />
                            </div>
                            <div className="form-group">
                              <label className="control-labels ">Service Charges - Per Year (AED)</label>
                              <input type="text" id="servicecharges" onChange={this.handleservicecharge} />
                            </div>
                            <div className="form-group">
                              <label className="control-labels ">Rental Amount (AED)</label>
                              <input type="text" id="rentalamount" onChange={this.handlerentalamount} />
                            </div>
                            <div className="d-flex flex-column">
                              <button className="btn btn-primary" id="getcalculate" onClick={this.handleCalculate}>Calculate</button>
                              {/* total(%) = (rental amount - service charge ) / purchase price
                when they click the calculate total shows and it will add a value on the column Rental Yield
              */}

                            </div>

                            {this.state.rentalyieldtotal >= 0 ?
                              <div className="d-flex flex-row w-100 mt-4 rentalyield">
                                <p className="form-control">Rental Yield  : <strong>{this.state.rentalyieldtotal + "%"}</strong></p>
                              </div> : null}
                          </div>
                        </div> : null}
                      {/* FORMS */}
                      <div className="bg-white border-top offers p-4 mt-3">
                        <div className="d-flex flex-row justify-content-between">
                          <h3 className="mr-4">Forms</h3>
                        </div>
                        <div className="col-12 px-0 form-group d-flex flex-column uploadformgroup">
                          <div className="form-group">
                            <label className="control-labels ">Form A</label>
                            <select onChange={this.handleFormA} value={this.state.form_a} className="select2 select2-offscreen" id="forma_doc" title tabIndex={-1}>
                              <option value>Select</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label className="control-labels ">Emirates ID</label>
                            <select onChange={this.handleEmirateID} value={this.state.emirate_id} className="select2 select2-offscreen" id="eid_doc" title tabIndex={-1}>
                              <option value>Select</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label className="control-labels ">Title Deed</label>
                            <select onChange={this.handleTitleDeed} value={this.state.title_deed} className="select2 select2-offscreen" id="titledeed_doc" title tabIndex={-1}>
                              <option value>Select</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label className="control-labels ">Exclusive</label>
                            <select onChange={this.handleExclusive} value={this.state.exclusive} className="select2 select2-offscreen" id="exclusive_doc" title tabIndex={-1}>
                              <option value>Select</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label className="control-labels ">Deed Expiry</label>

                            <input onChange={this.handleDeedExpire} defaultValue={this.state.deed_expire} type="datetime-local" id="dead_expiry" />
                          </div>
                        </div>
                        <button onClick={this.handleFormSubmit} className="btn btn-primary" id="saveFormaCheck">Save</button>
                      </div>{/* END FORMS*/}
                      <div className="bg-white border-top marketrequest p-4 mt-4">
                        <h3>Marketing Request</h3>
                        <div className="d-flex flex-row mb-2">
                          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-star-fill" /><span>Premium</span></button>
                          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#photorequest" onClick={this.handleShowAddPhotoRequest}><i className="bi bi-camera-fill" /><span>Photos</span></button>
                        </div>
                        <div className="d-flex flex-row mb-2">
                          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-file-earmark-arrow-down-fill" /><span>Brochure</span></button>
                          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-house-fill" />Open House</button>
                        </div>
                        <div className="d-flex flex-row mb-2">
                          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-instagram" /><span>Social Media</span></button>
                          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-whatsapp" /><span>WhatsApp Blast</span></button>
                        </div>
                        <div className="d-flex flex-row mb-2">
                          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-envelope-fill" /><span>EDM</span></button>
                          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-file-earmark-pdf-fill" /><span>Market Report</span></button>
                        </div>
                        <div className="d-flex flex-row mb-2">
                          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-image" /><span>Photo Edit</span></button>
                          <button type="button" className="col-6 btn btn-secondary" data-toggle="modal" data-target="#myModal"><i className="bi bi-signpost-fill" /><span>Sign Board</span></button>
                        </div>
                        <div className="d-flex flex-column border-wrap marketrequesthistory">
                          {/* <a href className="d-flex flex-row p-2 w-100 marketwrap">
                      <div className="col-12 requesttable d-flex flex-row">
                        <div className="col-6">
                          <p className="requestfor">Premium</p>
                          <p className="requestname">Jason Hayes</p>
                        </div>
                        <div className="col-6 text-end">
                          <p className="requestdate">10/02/2023</p>
                          <p className="requeststat">In Review</p>
                        </div>
                      </div>
                    </a>
                    <a href className="d-flex flex-row p-2 w-100 marketwrap">
                      <div className="col-12 requesttable d-flex flex-row">
                        <div className="col-6">
                          <p className="requestfor">Brochure</p>
                          <p className="requestname">Jason Hayes</p>
                        </div>
                        <div className="col-6 text-end">
                          <p className="requestdate">10/02/2023</p>
                          <p className="requeststat">In Review</p>
                        </div>
                      </div>
                    </a>
                    <a href className="d-flex flex-row p-2 w-100 marketwrap">
                      <div className="col-12 requesttable d-flex flex-row">
                        <div className="col-6">
                          <p className="requestfor">Social Media</p>
                          <p className="requestname">Jason Hayes</p>
                        </div>
                        <div className="col-6 text-end">
                          <p className="requestdate">10/02/2023</p>
                          <p className="requeststat">In Review</p>
                        </div>
                      </div>
                    </a> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 timeline-wrap">
                      <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4">
                        <h3>Timeline</h3>
                        {/* ADD NOTES */}
                        <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                          <button className="nav-link active" id="nav-call-tab" data-bs-toggle="tab" data-bs-target="#nav-call" type="button" role="tab" aria-controls="nav-call" aria-selected="true">Call</button>
                          <button className="nav-link" id="nav-notes-tab" data-bs-toggle="tab" data-bs-target="#nav-notes" type="button" role="tab" aria-controls="nav-notes" aria-selected="false">Notes</button>
                          <button className="nav-link" id="nav-whatsapp-tab" data-bs-toggle="tab" data-bs-target="#nav-whatsapp" type="button" role="tab" aria-controls="nav-whatsapp" aria-selected="false">WhatsApp</button>
                          <button className="nav-link" id="nav-email-tab" data-bs-toggle="tab" data-bs-target="#nav-email" type="button" role="tab" aria-controls="nav-email" aria-selected="false">Email</button>
                          <button className="nav-link" id="nav-offer-tab" data-bs-toggle="tab" data-bs-target="#nav-offer" type="button" role="tab" aria-controls="nav-offer" aria-selected="false">Offer</button>
                          <button className="nav-link" id="nav-viewing-tab" data-bs-toggle="tab" data-bs-target="#nav-viewing" type="button" role="tab" aria-controls="nav-viewing" aria-selected="false">Viewing</button>
                          <button className="nav-link" id="nav-followup-tab" data-bs-toggle="tab" data-bs-target="#nav-followup" type="button" role="tab" aria-controls="nav-followup" aria-selected="false">Follow Up</button>
                        </div>
                        <div className="tab-content" id="nav-tabContent">
                          <div className="tab-pane fade show active" id="nav-call" role="tabpanel" aria-labelledby="nav-call-tab" tabIndex={0}>
                            <Mentions
                              style={{
                                width: '100%',
                              }}
                              value={this.state.call_log}
                              onChange={this.onChangeCallNote}
                              onSelect={this.onSelectCallMentions}
                              options={this.state.userClients}
                            />
                            {
                              this.state.call_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                  <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button id="calllogbtn" onClick={this.handleCallSubmit} type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" /> Add call log</button>
                            }
                          </div>
                          <div className="tab-pane fade" id="nav-notes" role="tabpanel" aria-labelledby="nav-notes-tab" tabIndex={0}>
                            <Mentions
                              style={{
                                width: '100%',
                              }}
                              value={this.state.note_log}
                              onChange={this.onChangeNoteNote}
                              onSelect={this.onSelectNoteMentions}
                              options={this.state.userClients}
                            />
                            {
                              this.state.note_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                  <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button id="logsbtn" onClick={this.handleNoteSubmit} type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add note</button>
                            }
                          </div>
                          <div className="tab-pane fade" id="nav-whatsapp" role="tabpanel" aria-labelledby="nav-whatsapp-tab" tabIndex={0}>
                            <Mentions
                              style={{
                                width: '100%',
                              }}
                              value={this.state.whatsapp_log}
                              onChange={this.onChangeWhatsappNote}
                              onSelect={this.onSelectWhatsappMentions}
                              options={this.state.userClients}
                            />
                            {
                              this.state.whatsapp_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                  <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button onClick={this.handleWhatsappSubmit} id="whatsapplogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add WhatsApp Note</button>
                            }
                          </div>
                          <div className="tab-pane fade" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab" tabIndex={0}>
                            <input type="text" placeholder="Subject" className="form-control font-14 mb-2"></input>
                            <Mentions
                              style={{
                                width: '100%',
                              }}
                              value={this.state.email_log}
                              onChange={this.onChangeEmailNote}
                              onSelect={this.onSelectEmailMentions}
                              options={this.state.userClients}
                            />
                            {
                              this.state.email_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                  <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button onClick={this.handleEmailSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add email</button>
                            }
                          </div>
                          <div className="tab-pane fade" id="nav-offer" role="tabpanel" aria-labelledby="nav-offer-tab" tabIndex={0}>
                            <Mentions
                              style={{
                                width: '100%',
                              }}
                              value={this.state.offer_log}
                              onChange={this.onChangeOfferNote}
                              onSelect={this.onSelectOfferMentions}
                              options={this.state.userClients}
                            />
                            {
                              this.state.offer_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                  <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button onClick={this.handleOfferSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add Offer</button>
                            }
                          </div>
                          <div className="tab-pane fade" id="nav-viewing" role="tabpanel" aria-labelledby="nav-viewing-tab" tabIndex={0}>
                            <Mentions
                              style={{
                                width: '100%',
                              }}
                              value={this.state.viewing_log}
                              onChange={this.onChangeViewingNote}
                              onSelect={this.onSelectViewingMentions}
                              options={this.state.userClients}
                            />
                            {
                              this.state.viewing_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                  <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button onClick={this.handleViewingSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add Viewing</button>
                            }
                          </div>
                          <div className="tab-pane fade" id="nav-followup" role="tabpanel" aria-labelledby="nav-followup-tab" tabIndex={0}>
                            <div className="form-group">
                              <label>Date / Time</label>
                              <DatePicker style={{ maxWidth: "50%", height: 32, overflow: "hidden" }} showTime onChange={this.handleFollowupDate} />
                            </div>
                            <div className="form-group">
                              <label>Note</label>
                              <Mentions
                                style={{
                                  width: '100%',
                                }}
                                value={this.state.followup_log}
                                onChange={this.onChangeFollowupNote}
                                onSelect={this.onSelectFollowupMentions}
                                options={this.state.userClients}
                              />
                            </div>
                            {
                              this.state.followUp_loader ?
                                <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                                  <Spin style={{ alignSelf: 'center' }} />
                                </div>
                                :
                                <button onClick={this.handleFollowupSubmit} id="emaillogbtn" type="button" className="btn btn-primary requestchange mt-2"><i className="bi bi-plus me-1 fs-5" />Add Follow Up</button>
                            }
                          </div>
                        </div>
                        {/* TIMELINE */}
                        <div className="timeline border-wrap">
                          <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-all-tab" data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all" aria-selected="true">All</button>
                            <button className="nav-link" id="nav-notewrap-tab" data-bs-toggle="tab" data-bs-target="#nav-notewrap" type="button" role="tab" aria-controls="nav-notewrap" aria-selected="false">Notes</button>
                            <button className="nav-link" id="nav-calllist-tab" data-bs-toggle="tab" data-bs-target="#nav-calllist" type="button" role="tab" aria-controls="nav-calllist" aria-selected="false">Calls</button>
                            <button className="nav-link" id="nav-wa-tab" data-bs-toggle="tab" data-bs-target="#nav-wa" type="button" role="tab" aria-controls="nav-wa" aria-selected="false">WhatsApp</button>
                            <button className="nav-link" id="nav-mail-tab" data-bs-toggle="tab" data-bs-target="#nav-mail" type="button" role="tab" aria-controls="nav-mail" aria-selected="false">Email</button>
                            <button className="nav-link" id="nav-offers-tab" data-bs-toggle="tab" data-bs-target="#nav-offers" type="button" role="tab" aria-controls="nav-offers" aria-selected="false">Offers</button>
                            <button className="nav-link" id="nav-view-tab" data-bs-toggle="tab" data-bs-target="#nav-view" type="button" role="tab" aria-controls="nav-view" aria-selected="false">Viewings</button>
                            <button className="nav-link" id="nav-followups-tab" data-bs-toggle="tab" data-bs-target="#nav-followups" type="button" role="tab" aria-controls="nav-followups" aria-selected="false">Follow Up</button>
                          </div>
                          <div className="tab-content" id="nav-tabContent">
                            {/* ALL */}
                            <div className="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab" tabIndex={0}>
                              <div className="timeline-list">

                                {this.state.all.map((al) => {
                                  return (
                                    <div className={"timegroup " + al.note_type}>
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          {al.note_type == "phone" ? <i className="bi bi-telephone-fill"></i> : null}
                                          {al.note_type == "email" ? <i className="bi bi-envelope-fill" /> : null}
                                          {al.note_type == "whatsapp" ? <i className="bi bi-whatsapp" /> : null}
                                          {al.note_type == "note" ? <i className="bi bi-pin-angle-fill"></i> : null}
                                          {al.note_type == "followup" ? <i className="bi bi-calendar"></i> : null}
                                          {al.note_type == "req_view" ? " requested to view listing" : null}
                                        </div>
                                      </div>
                                      <div className="col-11 px-0">
                                        <div className="col-12 d-flex flex-row">
                                          <div className="agentdetails">
                                            <div className="d-flex flex-row justify-content-between">
                                              <div className='d-flex flex-row align-items-center' style={{ gap: '0.3rem' }}>
                                                <Avatar
                                                  className='d-flex'
                                                  size={40}
                                                  style={{
                                                    backgroundColor: "#87d068",
                                                    marginRight: "0",
                                                  }}
                                                  icon={
                                                    <img
                                                      src={al.profile_photo == null || al.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" :
                                                        this.isValidHttpUrl(al.profile_photo) ? al.profile_photo :
                                                          baseurlImg +
                                                          "lpcrm/public/uploads/clients/2036/users/" +
                                                          al.profile_photo
                                                      }
                                                      style={{
                                                        width: "55x",
                                                        height: "55px",
                                                        borderRadius: "10px",
                                                      }}
                                                    />
                                                  }
                                                />
                                                <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                                  {al.note_type == "phone" ? " logged a call" : null}
                                                  {al.note_type == "email" ? " sent an email" : null}
                                                  {al.note_type == "whatsapp" ? "" : null}
                                                  {al.note_type == "note" ? " added a note" : null}
                                                  {al.note_type == "followup" ? " scheduled a follow up" : null}
                                                  {al.note_type == "req_view" ? " requested to view listing" : null}
                                                </p>
                                              </div>
                                              <p className="atime">
                                                <Moment fromNow>
                                                  {/*{this.getCurrentTime(al.time)}*/}
                                                  {(al.time)}
                                                </Moment>
                                              </p>
                                            </div>
                                            <div className="adesc">
                                              {al.note_type == "phone" ?
                                                <><p className='font-12'><strong className='mr-1 font-12'>Price:</strong>{al.confirm_price && al.confirm_price > 0 ? al.confirm_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED' : 0}</p>
                                                  <p className='font-12'><strong className='mr-1 font-12'>Available:</strong>{al.isPropertyAvailable}</p>
                                                  <p className='font-12'><strong className='mr-1 font-12'>Discuss with vendor:</strong>{al.call_log_discuss}</p>
                                                  <p className='font-12'>{al.note}</p></>
                                                : (al.note_type == "req_view") ? <>
                                                  <p>{al.note}</p>
                                                  {al.leads_user.map((lead_id, index) => (
                                                    <>
                                                      <a key={index} href={liveUrl + "/leads/" + lead_id} target="_blank">{lead_id}</a><br /><br />
                                                    </>
                                                  ))}
                                                </> : <>{al.note}</>
                                              }

                                            </div>
                                            price:{al.confirm_price}
                                            Available:{al.isPropertyAvailable}
                                            Discuss with vendor:{al.call_log_discuss}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            {/* NOTES */}
                            <div className="tab-pane fade" id="nav-notewrap" role="tabpanel" aria-labelledby="nav-notewrap-tab" tabIndex={0}>
                              <div className="timeline-list">
                                {this.state.notes.map((note) => {
                                  return (
                                    <div className="timegroup note">
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          <i className="bi bi-pin-angle-fill" />
                                        </div>
                                      </div>
                                      <div className="col-11 px-0">
                                        <div className="col-12 d-flex flex-column">
                                          <div className="agentdetails">
                                            <div className='d-flex flex-row align-items-center' style={{ gap: '0.3rem' }}>
                                              <Avatar
                                                className='d-flex'
                                                size={40}
                                                style={{
                                                  backgroundColor: "#87d068",
                                                  marginRight: "0",
                                                }}
                                                icon={
                                                  <img
                                                    src={note.profile_photo == null || note.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" :
                                                      this.isValidHttpUrl(note.profile_photo) ? note.profile_photo :
                                                        baseurlImg +
                                                        "lpcrm/public/uploads/clients/2036/users/" +
                                                        note.profile_photo
                                                    }
                                                    style={{
                                                      width: "55x",
                                                      height: "55px",
                                                      borderRadius: "10px",
                                                    }}
                                                  />
                                                }
                                              />
                                              <div className="calldetails">
                                                <p className="awriter font-bold">
                                                  <span className="font-bold">{note.by_user_name}</span>
                                                  <span> added a note</span>
                                                </p>
                                              </div>
                                            </div>
                                            <p className="atime">{note.time}</p>
                                          </div>
                                          <div className="adesc">
                                            {note.note}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            {/* CALLS */}
                            <div className="tab-pane fade" id="nav-calllist" role="tabpanel" aria-labelledby="nav-calllist-tab" tabIndex={0}>
                              <div className="timeline-list">

                                {this.state.call_notes.map((call_note) => {
                                  return (
                                    <div className="timegroup phone">
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          <i className="bi bi-telephone-fill" />
                                        </div>
                                      </div>
                                      <div className="col-11 px-0">
                                        <div className="col-12 d-flex flex-column">
                                          <div className="agentdetails">
                                            <div className='d-flex flex-row align-items-center' style={{ gap: '0.3rem' }}>
                                              <Avatar
                                                className='d-flex'
                                                size={40}
                                                style={{
                                                  backgroundColor: "#87d068",
                                                  marginRight: "0",
                                                }}
                                                icon={
                                                  <img
                                                    src={call_note.profile_photo == null || call_note.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" :
                                                      this.isValidHttpUrl(call_note.profile_photo) ? call_note.profile_photo :
                                                        baseurlImg +
                                                        "lpcrm/public/uploads/clients/2036/users/" +
                                                        call_note.profile_photo
                                                    }
                                                    style={{
                                                      width: "55x",
                                                      height: "55px",
                                                      borderRadius: "10px",
                                                    }}
                                                  />
                                                }
                                              />
                                              <div className="calldetails">
                                                <p className="awriter">
                                                  <span className="font-bold">{call_note.by_user_name}</span>
                                                  <span> logged a call.</span>
                                                </p>
                                              </div>
                                            </div>
                                            <p className="atime">{call_note.time}</p>
                                          </div>
                                          <div className="adesc">

                                            <p>Price:{call_note.confirm_price}</p>
                                            <p>Available:{call_note.isPropertyAvailable}</p>
                                            <p>Discuss with vendor:{call_note.call_log_discuss}</p>
                                            <p>{call_note.note}</p>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            {/* WHATSAPP */}
                            <div className="tab-pane fade" id="nav-wa" role="tabpanel" aria-labelledby="nav-wa-tab" tabIndex={0}>
                              <div className="timeline-list">
                                {this.state.whatsapp_notes.map((whatsapp_note) => {
                                  return (
                                    <div className="timegroup whatsapp">
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          <i className="bi bi-whatsapp" />
                                        </div>
                                      </div>
                                      <div className="col-10">
                                        <div className="col-12 d-flex flex-column">
                                          <div className="agentdetails">
                                            <div className='d-flex flex-row align-items-center' style={{ gap: '0.3rem' }}>
                                              <Avatar
                                                className='d-flex'
                                                size={40}
                                                style={{
                                                  backgroundColor: "#87d068",
                                                  marginRight: "0",
                                                }}
                                                icon={
                                                  <img
                                                    src={whatsapp_note.profile_photo == null || whatsapp_note.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" :
                                                      this.isValidHttpUrl(whatsapp_note.profile_photo) ? whatsapp_note.profile_photo :
                                                        baseurlImg +
                                                        "lpcrm/public/uploads/clients/2036/users/" +
                                                        whatsapp_note.profile_photo
                                                    }
                                                    style={{
                                                      width: "55x",
                                                      height: "55px",
                                                      borderRadius: "10px",
                                                    }}
                                                  />
                                                }
                                              />
                                              <div className="calldetails">
                                                <p className="awriter font-bold">
                                                  <span className="font-bold">{whatsapp_note.by_user_name}</span>
                                                  <span> sent a whatsapp message.</span>
                                                </p>
                                              </div>
                                            </div>
                                            <p className="atime">{whatsapp_note.time}</p>
                                          </div>
                                          <div className="adesc">
                                            {whatsapp_note.note}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            <div className="tab-pane fade" id="nav-mail" role="tabpanel" aria-labelledby="nav-mail-tab" tabIndex={0}>
                              <div className="timeline-list">
                                {this.state.email_notes.map((email_note) => {
                                  return (
                                    <div className="timegroup email">
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          <i className="bi bi-envelope-fill" />
                                        </div>
                                      </div>
                                      <div className="col-10">
                                        <div className="col-12 d-flex flex-column">
                                          <div className="agentdetails">
                                            <div className='d-flex flex-row align-items-center' style={{ gap: '0.3rem' }}>
                                              <Avatar
                                                className='d-flex'
                                                size={40}
                                                style={{
                                                  backgroundColor: "#87d068",
                                                  marginRight: "0",
                                                }}
                                                icon={
                                                  <img
                                                    src={email_note.profile_photo == null || email_note.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" :
                                                      this.isValidHttpUrl(email_note.profile_photo) ? email_note.profile_photo :
                                                        baseurlImg +
                                                        "lpcrm/public/uploads/clients/2036/users/" +
                                                        email_note.profile_photo
                                                    }
                                                    style={{
                                                      width: "55x",
                                                      height: "55px",
                                                      borderRadius: "10px",
                                                    }}
                                                  />
                                                }
                                              />
                                              <div className="calldetails">
                                                <p className="awriter font-bold">
                                                  <span className="font-bold">{email_note.by_user_name}</span>
                                                  <span> sent an email.</span>
                                                </p>
                                              </div>
                                            </div>
                                            <p className="atime">{email_note.time}</p>
                                          </div>
                                          <div className="adesc">
                                            {email_note.note}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            <div className="tab-pane fade" id="nav-offers" role="tabpanel" aria-labelledby="nav-offers-tab" tabIndex={0}>
                              <div className="timeline-list">
                                {this.state.email_notes.map((email_note) => {
                                  return (
                                    <div className="timegroup offer">
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          <i className="bi bi-tag-fill"></i>
                                        </div>
                                      </div>
                                      <div className="col-10">
                                        <div className="col-12 d-flex flex-column">
                                          <div className="agentdetails">
                                            <div className="calldetails">
                                              <p className="awriter font-bold">
                                                <span className="font-bold">{email_note.by_user_name}</span>
                                                <span> added an offer.</span>
                                              </p>
                                            </div>
                                            <p className="atime">{email_note.time}</p>
                                          </div>
                                          <div className="adesc">
                                            <div className="col-12">
                                              <p className="name font-bold">Goran Buvac </p>
                                              <p className="address">Offer: AED 2,500,000</p>
                                              <p className="price">2023-02-15 11:32:22</p></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            <div className="tab-pane fade" id="nav-view" role="tabpanel" aria-labelledby="nav-view-tab" tabIndex={0}>
                              <div className="timeline-list">
                                {this.state.email_notes.map((email_note) => {
                                  return (
                                    <div className="timegroup viewing">
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          <i className="bi bi-eye-fill"></i>
                                        </div>
                                      </div>
                                      <div className="col-10">
                                        <div className="col-12 d-flex flex-column">
                                          <div className="agentdetails">
                                            <div className="calldetails">
                                              <p className="awriter font-bold">
                                                <span className="font-bold">{email_note.by_user_name}</span>
                                                <span> added an offer.</span>
                                              </p>
                                            </div>
                                            <p className="atime">{email_note.time}</p>
                                          </div>
                                          <div className="adesc">
                                            <div className="col-12">
                                              <p className="name font-bold">Goran Buvac </p>
                                              <p className="address">Offer: AED 2,500,000</p>
                                              <p className="price">2023-02-15 11:32:22</p></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                            <div className="tab-pane fade" id="nav-followups" role="tabpanel" aria-labelledby="nav-followups-tab" tabIndex={0}>
                              <div className="timeline-list">
                                {this.state.email_notes.map((email_note) => {
                                  return (
                                    <div className="timegroup followup">
                                      <div className="col-1">
                                        <div className="iconwrap">
                                          <i className="bi bi-calendar"></i>
                                        </div>
                                      </div>
                                      <div className="col-10">
                                        <div className="col-12 d-flex flex-column">
                                          <div className="agentdetails">
                                            <div className="calldetails">
                                              <p className="awriter font-bold">
                                                <span className="font-bold">{email_note.by_user_name}</span>
                                                <span> scheduled a follow up.</span>
                                              </p>
                                            </div>
                                            <p className="atime">{email_note.time}</p>
                                          </div>
                                          <div className="adesc">
                                            <div className="col-12">
                                              <p className="name font-bold">Reminder Note</p>
                                              <p className="price">2023-02-15 11:32:22</p></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column w-100 bg-white border-top ownersdetails mt-3 accordion accordion-flush">
                        <div className="accordion-item">
                          <h3 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              Property Details
                            </button>
                          </h3>
                          {/* Property Details */}
                          <div id="flush-collapseOne" className="accordion-collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="nav nav-tabs nav-fill" id="prop-tab" role="tablist">
                              <button className="nav-link active" id="nav-details-tab" data-bs-toggle="tab" data-bs-target="#nav-details" type="button" role="tab" aria-controls="nav-details" aria-selected="true">Details</button>
                              <button className="nav-link" id="nav-forwebsite-tab" data-bs-toggle="tab" data-bs-target="#nav-forwebsite" type="button" role="tab" aria-controls="nav-forwebsite" aria-selected="false">For website</button>
                              <button className="nav-link" id="nav-documents-tab" data-bs-toggle="tab" data-bs-target="#nav-documents" type="button" role="tab" aria-controls="nav-documents" aria-selected="false">Documents</button>
                              <button className="nav-link" id="nav-portal-tab" data-bs-toggle="tab" data-bs-target="#nav-portal" type="button" role="tab" aria-controls="nav-portal" aria-selected="false">Portal</button>
                              <button className="nav-link" id="nav-images-tab" data-bs-toggle="tab" data-bs-target="#nav-images" type="button" role="tab" aria-controls="nav-images" aria-selected="false">Images</button>
                              <button className="nav-link" id="nav-international-tab" data-bs-toggle="tab" data-bs-target="#nav-international" type="button" role="tab" aria-controls="nav-international" aria-selected="false">International</button>
                            </div>
                            <div className="tab-content" id="nav-tabContent">
                              {/* DETAILS */}
                              <div className="tab-pane fade show active" id="nav-details" role="tabpanel" aria-labelledby="nav-details-tab" tabIndex={0}>
                                <div className="row">
                                  <div className="input-group mb-3 col-3">
                                    <div className="input-group-text">
                                      <input className="form-check-input mt-0" type="checkbox" checked={this.state.lead_gen} aria-label="Checkbox for following text input" id="leadgen" onChange={this.onLeadGen} />
                                      <label className="control-labels" htmlFor="leadgen">Lead Gen</label>
                                    </div>
                                  </div>
                                  <div className="input-group mb-3 col-3">
                                    <div className="input-group-text">
                                      <input className="form-check-input mt-0" type="checkbox" checked={this.state.just_listed} aria-label="Checkbox for following text input" id="justlisted" onChange={this.onJustListed} />
                                      <label className="control-labels" htmlFor="justlisted">Just Listed</label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">

                                  {role == 1 || this.state.listing_details.status == "UC" || user_id == this.state.listing_details.agent_id || this.state.listing_details.agent_id == 430 ? <div className="col-6 form-group d-flex flex-column">
                                    <label className="control-labels">Agent Name</label>
                                    <select onChange={this.handleAgentDetails} value={this.state.agent_name_details} name="listing_agent" id="listing_agent" className="form-control select2 required selectsearch select2-offscreen" title tabIndex={-1}>
                                      <option value="">Select Agent</option>
                                      {this.state.agents.map((agent) => {
                                        return (
                                          <option value={agent.value}>{agent.label}</option>
                                        )
                                      })}


                                    </select>
                                  </div> : null}

                                  <div className="col-6 form-group mb-0">
                                    <label className="control-labels">Listing Status</label>
                                    <select onChange={this.handleListingStatusDetails} name="listingstatus" value={this.state.listing_status_details}>
                                      {(role_name == "Admin" || role_name == "Super Admin") && <option value="D">Draft</option>}
                                      <option value="UC">Confirmed Owner</option>
                                      <option value="N">Unpublished</option>
                                      <option value="OM">Off-Market</option>
                                      <option value="CTM">Coming to Market</option>
                                      {(role_name == "Admin" || role_name == "Super Admin") && <option value="Y">Published</option>}
                                      <option value="U">Action</option>
                                      {(role_name == "Admin" || role_name == "Super Admin") && <option value="SEO">SEO</option>}
                                      <option value="T">Unlisted</option>
                                      {(role_name == "Admin" || role_name == "Super Admin") && <option value="S">Sold</option>}
                                      {(role_name == "Admin" || role_name == "Super Admin") && <option value="L">Let</option>}
                                      {(role_name == "Admin" || role_name == "Super Admin") && <option value="N">Review</option>}
                                      {(role_name == "Admin" || role_name == "Super Admin") && <option value="F">Feeds</option>}
                                      {(role_name == "Admin" || role_name == "Super Admin") && <Option value="Deleted">Move to Archive</Option>}
                                      {(role_name == "Admin" || role_name == "Super Admin") && <Option value="PO">Pending owner</Option>}
                                      {(role_name == "Admin" || role_name == "Super Admin") && <Option value="SLP">Sold by LP</Option>}
                                      {(role_name == "Admin" || role_name == "Super Admin") && <Option value="R">Rented</Option>}
                                      {(role_name == "Admin" || role_name == "Super Admin") && <Option value="RLP">Rented by LP</Option>}
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Property For</label>
                                    <select onChange={this.handlePropertyForDetails} value={this.state.property_for_details} name="propertyfor">
                                      <option value="">Select Property For</option>
                                      <option value="sale">Sale</option>
                                      <option value="rent">Rent</option>
                                      <option value="short-term">Short term</option>
                                      <option value="pond">Pond</option>
                                    </select>
                                  </div>
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Completion Status</label>
                                    <select onChange={this.handleCompletionDetails} value={this.state.completion_status} name="completion_status">
                                      <option value="">Select Completion Status</option>
                                      <option value="Yes">Completed</option>
                                      <option value="Offplan">Offplan</option>
                                      <option value="Offplan_development">Offplan Development</option>
                                    </select>
                                  </div>
                                </div>
                                {(this.state.property_for_details == "rent" || this.state.property_for_details == "short-term") ?
                                  <div className='row'>
                                    <div className='col-6 form-group'>
                                      <label>Available Date</label>

                                      <DatePicker onChange={this.handleAvailableDateDetails} format="YYYY-MM-DD" value={(this.state.avail_d) ? dayjs(this.state.avail_d) : ""} onOk={this.handleAvailableDateDetails} style={{ height: 36, overflow: "hidden", fontSize: 12 }} />
                                    </div>
                                  </div> : null}
                                <div className="row">
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Property Type</label>
                                    <select onChange={this.handlePropertyTypeDetails} value={this.state.property_type_details} name="propertyfor">
                                      <option value="">Select</option>
                                      {this.state.propertytypes.map((propertytype) => {
                                        return <option selected={propertytype.value === this.state.listing_details.property_category_id} value={propertytype.value}>{propertytype.label}</option>
                                      })}
                                    </select>
                                  </div>

                                  <div className="col-6 form-group">
                                    <label className="control-labels">Style of Property</label>
                                    <select onChange={this.handlePropertyStyleDetails} name="style_of_property" value={this.state.style_property_details}>
                                      <option value="">Select</option>
                                      <option value="mediterranean">Mediterranean</option>
                                      <option value="spanish">Spanish</option>
                                      <option value="modern">Modern</option>
                                      <option value="contemporary">Contemporary</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Unit No</label>
                                    <input onChange={this.handleUnitNoDetails} type="text" placeholder="Unit no" value={this.state.unit_no_details} />
                                  </div>
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Community</label>
                                    <select onChange={this.handleCommunityDetails} value={this.state.community_details} name="community">
                                      <option>Choose Community </option>
                                      {this.state.communities.map((community) => {
                                        return <option value={community.value}>{community.label}</option>
                                      })}
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Sub Community</label>
                                    <select onChange={this.handleSubCommunityDetails} value={this.state.subcommunity_details} name="community">
                                      <option>Choose Sub Community</option>
                                      {this.state.sub_communities.map((subcommunity) => {
                                        return <option value={subcommunity.value}>{subcommunity.label}</option>
                                      })}
                                    </select>
                                  </div>
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Property</label>
                                    <select onChange={this.handlePropertyDetails} name="community" value={this.state.property_details}>
                                      <option>Select</option>
                                      {this.state.properties.map((property) => {
                                        return <option
                                          value={property.value}>{property.label}</option>
                                      })}
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Bedroom</label>
                                    <select onChange={this.handleBedDetails} value={this.state.bedroom_details} name="community">
                                      <option value>Select</option>
                                      {this.state.bedrooms.map((bedroom) => {
                                        return <option
                                          value={bedroom.id}>{bedroom.bedroom}</option>
                                      })}
                                    </select>
                                  </div>
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Bathroom</label>
                                    <select onChange={this.handleaBathroomDetails} name="community" value={this.state.bathroom_details}>
                                      <option value>Select</option>
                                      {(this.state.bathrooms) && this.state.bathrooms.map((bathroom) => {
                                        return <option
                                          value={bathroom.id}>{bathroom.bathroom}</option>
                                      })}
                                    </select>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="form-group col-6 price">
                                    <label className="control-labels">BUA (Sq.Ft)</label>
                                    <input onChange={this.handleBua} type="text" name="price" id="property_price" value={this.state.bua} className="form-control" placeholder="BUA" />
                                  </div>
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Plot Size (Sq.Ft)</label>
                                    <input onChange={this.handlePlotArea} value={this.state.plot_area} type="text" placeholder="Plot Size" />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="form-group col-6 price">
                                    <label className="control-labels">Price<em>*</em></label>
                                    <input onChange={this.handlePriceDetails} type="text" name="price" id="property_price" value={this.state.price_details} className="form-control" placeholder="Price" />
                                    <button className="btn btn-primary ml-auto requestchange mt-2 font-14">Request Price Change</button>
                                  </div>
                                  <div className="col-6 form-group">
                                    <label className="control-labels">View</label>
                                    <input onChange={this.handleViewDetails} value={this.state.view_details} type="text" />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Upgraded</label>
                                    <select onChange={this.handleUpgradedDetails} name="upgraded" value={this.state.upgraded_details}>
                                      <option value="">Select</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                  </div>
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Furnished</label>
                                    <select onChange={this.handleFurnishedDetails} name="furnished" value={this.state.furnished_details}>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                  </div>

                                </div>
                                <div className="row">
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Vacant</label>
                                    <select onChange={this.handleVacantDetails} name="vacant" value={this.state.vacant_details}>
                                      <option value="">Select</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                  </div>
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Maids Room</label>
                                    <select onChange={this.handleMaidsDetails} name="maids_room" value={this.state.maids_room}>
                                      <option value="">Select</option>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Rera Permit</label>
                                    <input onChange={this.handleReraPermit} value={this.state.rera_permit_details} type="text" placeholder="Enter Rera Permit" />
                                  </div>
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Tags</label>
                                    <textarea style={{ border: '1px solid gainsboro' }} onChange={this.handleTagsDetails} rows={3} defaultValue={""} />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 form-group">
                                    <div className='form-group'>
                                      <label>Title</label>
                                      <input type="text" className="form-control" placeholder="Enter Property Title"
                                        onChange={this.handleTitleDetails} value={this.state.title_details} />
                                      <p style={{ fontSize: '10px' }}>{(this.state.title_details == "" || this.state.title_details == null) ? "0" : this.state.title_details.length} characters</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 form-group">
                                    <div className="d-flex flex-row justify-content-between">
                                      <label className="control-labels">Description</label>
                                      <button type="button" className="btn btn-primary btn-sm mb-1" disabled={(this.state.description_loader)} onClick={this.generateDescription}>{(this.state.description_loader) ? <Spin style={{ alignSelf: 'center' }} /> : "Generate Description"}</button>
                                    </div>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={(this.state.description_details != null) ? this.state.description_details : ""}
                                      onChange={(event, editor) => {
                                        this.setState({ description_details: (editor) ? editor.getData() : "" })
                                      }}
                                    />
                                    <p style={{ fontSize: '10px' }}>{(this.state.description_details == "" || this.state.description_details == null) ? "0" : this.state.description_details.length} characters</p>
                                  </div>
                                </div>
                                <div className="row savegroup">
                                  <div className="col-12 d-flex flex-row justify-content-between">

                                    <button onClick={this.handleSetailsSubmit} className="btn btn-primary px-3 ml-auto">Save</button>
                                  </div>
                                </div>
                              </div>
                              {/* SEO Details */}
                              <div className="row seogroup">
                                <label className="mb-2">SEO Details</label>
                                <div className="mb-3 col-6">
                                  <div className="form-group">
                                    <label className="control-labels">Meta Title</label>
                                    <input type="text" placeholder="Meta Title" onChange={this.handleMetaTitle} value={this.state.meta_title} />
                                    <p style={{ fontSize: '10px' }}>{(this.state.meta_title == "" || this.state.meta_title == null) ? "0" : this.state.meta_title.length} characters</p>
                                  </div>
                                  <div className="form-group mb-0">
                                    <label className="control-labels">Meta Keywords</label>
                                    <input type="text" placeholder="Meta Keywords" onChange={this.handleMetaKeywords} value={this.state.meta_keywords} />
                                    <p style={{ fontSize: '10px' }}>{(this.state.meta_keywords == "" || this.state.meta_keywords == null) ? "0" : this.state.meta_keywords.length} characters</p>
                                  </div>
                                </div>
                                <div className="col-6 form-group">
                                  <label className="control-labels">Meta Description</label>
                                  <textarea id="metadesc" className="h-100" rowSpan={4} onChange={this.handleMetaDescription} value={this.state.meta_description} />
                                  <p style={{ fontSize: '10px' }}>{(this.state.meta_description == "" || this.state.meta_description == null) ? "0" : this.state.meta_description.length} characters</p>
                                </div>
                              </div>
                              <div className="tab-pane fade" id="nav-forwebsite" role="tabpanel" aria-labelledby="nav-forwebsite-tab" tabIndex={0}>
                                <div className="row">
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Youtube Video Link</label>
                                    <input type="text" placeholder="youtube" onChange={this.handleVideoLink} value={this.state.video_link} />
                                  </div>
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Livetour 360 Video Link</label>
                                    <input type="text" placeholder="livetour" onChange={this.handleLiveTourLink} value={this.state.livetour_link} />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="input-group mb-3 form-group uploadgrp">
                                    <label className="control-labels">Brochure</label>
                                    <input type="file" className="form-control w-100" id="inputGroupFile02" />
                                  </div>
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Facilites &amp; Amenities (Maximum of 10)</label>
                                    <button disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")} onClick={this.handleOpenAmenities} type="button" className="btn btn-primary w-100">Choose Amenities</button>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Curations</label>
                                    <Select
                                      mode="multiple"
                                      style={{
                                        width: '100%',
                                      }}
                                      disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                        (role_name != "Super Admin" && role_name != "Admin")}
                                      value={this.state.curation_details}
                                      placeholder="Choose Curations"
                                      maxTagCount={"responsive"}
                                      onChange={this.handleCurationDetails}
                                      optionFilterProp="children"
                                    >
                                      {this.state.curation.map(item => (
                                        <Option key={item.id} value={item.id}>{item.title}</Option>
                                      ))}
                                    </Select>
                                  </div>
                                  <div className="col-6 form-group">
                                    <label className="control-labels">Life Style</label>
                                    <select disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")} onChange={this.handleLifeStyleDetails} value={this.state.lifestyle_details} name="life_style">
                                      <option>Choose Life Style</option>
                                      {this.state.life_style.map((lifestyle) => {
                                        return <option value={lifestyle.id}>{lifestyle.title}</option>
                                      })}
                                    </select>
                                  </div>
                                </div>
                                <button onClick={this.handleForWebsiteSubmit} className="btn btn-primary" id="saveForWebsiteCheck">Save</button>
                              </div>
                              <div className="tab-pane fade" id="nav-documents" role="tabpanel" aria-labelledby="nav-documents-tab" tabIndex={0}>
                                <div className="row">
                                  <div className="form-group col-12">
                                    <label className="control-labels">Notes</label>
                                    <div className="d-flex flex-row" style={{ gap: '0.3rem' }}>
                                      <textarea onChange={this.handlePropertyNotes} name="notes[]" className="form-control notes" placeholder="Notes" rowSpan={1} value={this.state.property_note} />
                                      <a onClick={this.handlePropertyNotesSubmit} className="btn btn-success btnnotes" id="addobj1" href="javascript:void(0)"><i className="bi bi-plus" /> </a>
                                    </div>
                                  </div>
                                  <div className='col-md-12'>
                                    <table className='table table-bordered'>
                                      <thead>
                                        <tr>
                                          <th>Note</th>
                                          <th>Date</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.document_notes.map((document_note) => {
                                          return (
                                            <tr>
                                              <td> {document_note.note}</td>
                                              <td> {document_note.added_date}</td>
                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="form-group col-12">
                                    <label className="control-labels">Documents</label>
                                    <table className="table border-wrap">
                                      <thead>
                                        <tr><th>Title</th>
                                          <th>File</th>
                                          <th>Action</th>
                                        </tr></thead>
                                      <tbody>
                                        <tr>
                                          <td><input onChange={this.handleDocumentTitle} type="text" tabIndex={2} name="doctitle" placeholder="Title" className="form-control input-sm" id="doctitle" /></td>
                                          <td><input onChange={this.handleDocumentFile} type="file" tabIndex={2} name="documents" className="form-control input-sm" accept="image/gif, image/jpeg,application/pdf,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /></td>
                                          <td><button onClick={this.handleDocumentsSubmit} className="btn btn-success" type="submit" href="javascript:void(0)" data-href><i className="bi bi-plus" /></button></td>
                                        </tr>
                                        {this.state.documents && this.state.documents.map((document) => {
                                          return (
                                            <tr>
                                              <td> {document.title}</td>
                                              <td>  <a href={(document.document) ? document.document : "#"} target="_blank">
                                                <button className="btn btn-text p-0">
                                                  <i className="bi bi-eye-fill" />
                                                </button>
                                              </a> </td>
                                              <td>
                                                <button onClick={this.handleDocumentDelete.bind(this, document)} className="btn btn-text p-0"><i className="bi bi-trash-fill" /></button>
                                              </td>

                                            </tr>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div className="tab-pane fade portal" id="nav-portal" role="tabpanel"
                                aria-labelledby="nav-portal-tab" tabIndex={0}>
                                <div className="row">
                                  {this.state.portals && this.state.portals.map((portal, index) => {

                                    if (this.state.selected_portals_id && this.state.selected_portals_id.includes(portal.id)) {
                                      var inputProps = {
                                        defaultChecked: 'true'
                                      };
                                    } else {
                                      var inputProps = {};
                                    }
                                    return (
                                      <div className="input-group col-4">
                                        <div className="input-group-text">
                                          <input className="form-check-input mt-0"
                                            type="checkbox"
                                            defaultValue
                                            onChange={this.onChangePortal.bind(this, portal)}
                                            aria-label="Checkbox for following text input"
                                            id={portal.feedkey}
                                            {...inputProps}
                                          />
                                          <label className="control-labels"
                                            htmlFor="leadgen">{portal.title}</label>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>

                                <div className="input-group col-4">
                                  <div className="input-group-text">

                                    <input className="form-check-input mt-0"
                                      type="checkbox"
                                      defaultChecked={this.state.isSameValueCheck}
                                      onChange={this.onChangeSameValue.bind(this, this.state.selected_portals)}
                                      aria-label="Checkbox for using same value"
                                      id="same_value"
                                    />
                                    <label className="control-labels"
                                      htmlFor="leadgen">Use same value</label>
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    {!this.state.isSameValueCheck && <Tabs>
                                      <TabList>
                                        {this.state.selected_portals && this.state.selected_portals.map((selected_portal, index) => {
                                          return (
                                            <Tab>{selected_portal.portal_title}</Tab>
                                          )
                                        })}
                                      </TabList>


                                      {this.state.selected_portals && this.state.selected_portals.map((selected_portal, index) => {

                                        return (
                                          <TabPanel>
                                            <div className="tab-content p-3"
                                              id="nav-tabContent">
                                              <div className="form-group mb-0">
                                                <div className="d-flex flex-column">
                                                  <label
                                                    className="control-labels">Title</label>
                                                  <input type="text"
                                                    className="p-1"
                                                    value={selected_portal.property_title}
                                                    disabled={(role_name == "Super Admin" || role_name == "Admin" || this.state.listing_status_details == "Y") ? false : true}
                                                    placeholder="Add title"
                                                    onChange={this.onChangeTitle.bind(this, selected_portal)}
                                                  />
                                                  <p style={{ fontSize: '10px' }}>{(selected_portal.property_title == "" || selected_portal.property_title == null) ? "0" : selected_portal.property_title.length} characters</p>

                                                </div>
                                              </div>
                                              <div className="form-group mb-0">
                                                <div className="d-flex flex-column">
                                                  <label
                                                    className="control-labels">Description</label>
                                                  <CKEditor
                                                    editor={ClassicEditor}
                                                    disabled={(role_name == "Super Admin" || role_name == "Admin" || this.state.listing_status_details == "Y") ? false : true}
                                                    data={(selected_portal != null) ? selected_portal.property_desc : ""}
                                                    onChange={(event, editor) => {
                                                      this.state.selected_portals.filter((portal) => {
                                                        if (portal.portals_id === selected_portal.portals_id) {
                                                          this.setState({ selected_portal: portal })
                                                          portal.property_desc = (editor) ? editor.getData() : "";
                                                        }
                                                      });
                                                    }}
                                                  />
                                                  <p style={{ fontSize: '10px' }}>{(selected_portal.property_desc == "" || selected_portal.property_desc == null) ? "0" : selected_portal.property_desc.length} characters</p>
                                                </div>
                                              </div>
                                            </div>
                                          </TabPanel>
                                        )
                                      })}
                                    </Tabs>}
                                    {this.state.isSameValueCheck && <Tabs>
                                      {this.state.selected_portals &&
                                        <TabPanel>
                                          <div className="tab-content p-3"
                                            id="nav-tabContent">
                                            <div className="form-group mb-0">
                                              <div className="d-flex flex-column">
                                                <label
                                                  className="control-labels">Title</label>
                                                <input type="text"
                                                  className="p-1"
                                                  value={this.state.selected_portals[0].property_title}
                                                  disabled={(role_name == "Super Admin" || role_name == "Admin" || this.state.listing_status_details == "Y") ? false : true}
                                                  placeholder="Add title"
                                                  onChange={this.onChangeTitle.bind(this, this.state.selected_portals[0])}
                                                />
                                                <p style={{ fontSize: '10px' }}>{(this.state.selected_portals[0].property_title == "" || this.state.selected_portals[0].property_title == null) ? "0" : this.state.selected_portals[0].property_title.length} characters</p>

                                              </div>
                                            </div>
                                            <div className="form-group mb-0">
                                              <div className="d-flex flex-column">
                                                <label
                                                  className="control-labels">Description</label>
                                                <CKEditor
                                                  editor={ClassicEditor}
                                                  disabled={(role_name == "Super Admin" || role_name == "Admin" || this.state.listing_status_details == "Y") ? false : true}
                                                  data={(this.state.selected_portals[0] != null) ? this.state.selected_portals[0].property_desc : ""}
                                                  onChange={(event, editor) => {
                                                    this.state.selected_portals.filter((portal) => {
                                                      if (portal.portals_id === this.state.selected_portals[0].portals_id) {
                                                        this.setState({ selected_portal: portal })
                                                        portal.property_desc = (editor) ? editor.getData() : "";
                                                      }
                                                    });
                                                  }}
                                                />
                                                <p style={{ fontSize: '10px' }}>{(this.state.selected_portals[0].property_desc == "" || this.state.selected_portals[0].property_desc == null) ? "0" : this.state.selected_portals[0].property_desc.length} characters</p>
                                              </div>
                                            </div>
                                          </div>
                                        </TabPanel>
                                      }
                                    </Tabs>}

                                  </div>
                                  <button id="calllogbtn" type="button"
                                    onClick={this.onSubmit.bind(this, this.state)}
                                    className="btn btn-primary requestchange mt-2">Submit
                                  </button>
                                </div>
                              </div>

                              <div className="tab-pane fade images" id="nav-images" role="tabpanel" aria-labelledby="nav-images-tab" tabIndex={0}>
                                <div className="row">
                                  <div className="d-flex flex-row justify-content-between btngrp">
                                    <div className="d-flex flex-row">
                                      {/*<button onClick = {this.handleUploadImages} type="button" className="btn btn-primary">Upload All</button>*/}
                                      {/*<button type="button" className="btn btn-secondary">Rename Images</button>*/}
                                    </div>
                                    {/*<button type="button" className="btn btn-primary">Preview</button> r*/}
                                  </div>
                                </div>
                                <div className="row upload-group">

                                  <ReactDropzoneUploader
                                    getUploadParams={this.getUploadParams}
                                    onChangeStatus={this.handleChangeStatus}
                                    onSubmit={this.handleUploadImages}
                                    styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
                                  />
                                </div>
                                <div className="row imgupgrp" style={{ marginTop: '20px' }}>
                                  <SortableContainer
                                    updateImages={this.updateImages}
                                    role={role}
                                    status={this.state.listing_details.status}
                                    onUpdate={this.handleSortUpdate}
                                    property_images={this.state.property_images}
                                    listing_id={this.state.record_id}
                                  />
                                </div>
                              </div>
                              <div className="tab-pane fade international" id="nav-international" role="tabpanel" aria-labelledby="nav-international-tab" tabIndex={0}>
                                {/*<div className="row">*/}
                                {/*  <div className="col-12 form-group d-flex flex-column">*/}
                                {/*    <label className="control-labels">International</label>*/}
                                {/*    <select name="international" id="international" className="form-control">*/}
                                {/*      <option>Yes</option>*/}
                                {/*      <option selected>No</option>*/}
                                {/*    </select>*/}
                                {/*  </div>*/}
                                {/*</div>*/}
                                <div className="row">
                                  <div className="col-12 form-group d-flex flex-column">
                                    <label className="control-labels">International</label>
                                    <select name="international" id="international" className="form-control" onChange={this.handleInternational} value={this.state.international}>
                                      <option value="1">Yes</option>
                                      <option value="0">No</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-6 form-group d-flex flex-column">
                                    <label className="control-labels">Country</label>
                                    <select name="intlcountry" id="intlcountry" className="form-control" onChange={this.handleCountry} value={this.state.country}>
                                      {this.state.countries_details.map((countries_detail) => {
                                        return (
                                          <option value={countries_detail.id}>{countries_detail.name}</option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                  <div className="col-6 form-group d-flex flex-column">
                                    <label className="control-labels">City</label>
                                    <select name="intlcity" id="intlcity" className="form-control" onChange={this.handleCity} value={this.state.city}>
                                      {this.state.cities_details.map((cities_detail) => {
                                        return (
                                          <option value={cities_detail.id}>{cities_detail.name}</option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                </div>
                                {/*<div className="row">*/}
                                {/*  <div className="col-6 form-group d-flex flex-column">*/}
                                {/*    <label className="control-labels">Country</label>*/}
                                {/*    <select name="intlcountry" id="intlcountry" className="form-control">*/}
                                {/*      <option selected>United Arab Emirates</option>*/}
                                {/*      <option>United Kingdom</option>*/}
                                {/*      <option>United States</option>*/}
                                {/*    </select>*/}
                                {/*  </div>*/}
                                {/*  <div className="col-6 form-group d-flex flex-column">*/}
                                {/*    <label className="control-labels">City</label>*/}
                                {/*    <select name="intlcity" id="intlcity" className="form-control">*/}
                                {/*      <option selected>Dubai</option>*/}
                                {/*      <option>Abu Dhabi</option>*/}
                                {/*      <option>Ras Al Khaimah</option>*/}
                                {/*    </select>*/}
                                {/*  </div>*/}
                                {/*</div>*/}
                                <div className="row">
                                  <div className="col-12">
                                    <button className="btn btn-primary ml-auto" onClick={this.handleInternationalSubmit} disabled={this.state.isInternationDisable === true}>Save</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-3">
                      <div className="d-flex flex-column w-100 bg-white border-top ownersdetails p-4">
                        <h3>Match Details</h3>
                        <div className="d-flex flex-column pt-3">
                          <div className="d-flex flex-column matchfilter">
                            <div className="col-12 px-0">
                              <div className="form-group">
                                <label for="community" className="mb-0">Community:</label>
                                <Select id="community"
                                  style={{
                                    width: 160,
                                  }}

                                  showSearch
                                  placeholder="Select a person"
                                  optionFilterProp="children"
                                  onChange={this.handleCommunityMatch}
                                  // onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  options={this.state.communities}
                                />
                              </div>
                            </div>
                            <div className="col-12 px-0">
                              <div className="form-group">
                                <label for="propsubcommunity" className="control-labels " htmlFor="propsubcommunity">Sub-Community:</label>
                                <Select id="propsubcommunity"
                                  style={{
                                    width: 160,
                                  }}
                                  // mode={"multiple"}

                                  showSearch
                                  maxTagCount={"responsive"}
                                  placeholder="Select a person"
                                  optionFilterProp="children"
                                  onChange={this.handleSubCommunityMatch}
                                  // onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                  }
                                  options={this.state.sub_communities}
                                />
                              </div>
                            </div>
                            <div className="col-12 px-0">
                              <div className="form-group d-flex flex-row budget">
                                <label for="matchingBudgetMin">Budget:</label>
                                <div className="d-flex flex-row">

                                  <CurrencyInput
                                    id="input-min"
                                    name="input-min"
                                    placeholder="Min"

                                    decimalsLimit={2}
                                    onChange={this.handleMinBudget}
                                  />
                                  {/* <input type="text" onChange={this.handleMinBudget} className="currencyInput" id="matchingBudgetMin" placeholder="Min" /> */}
                                  <span className="dash" style={{}}>-</span>
                                  <CurrencyInput
                                    id="input-max"
                                    name="input-max"
                                    placeholder="Max"

                                    decimalsLimit={2}
                                    onChange={this.handleMaxBudget}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12 px-0">
                              <div className="form-group">
                                <label for="matchingBedrooms">Beds:</label>
                                <select onChange={this.handleBeds} id="matchingBedrooms" name="bedrooms" className="form-control" title tabIndex={-1}>
                                  <option value>Select</option>
                                  <option value="Studio">Studio</option>
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                  <option value={3} >3</option>
                                  <option value={4}>4</option>
                                  <option value={5}>5</option>
                                  <option value={6}>6</option>
                                  <option value={7}>7</option>
                                  <option value={8}>8</option>
                                  <option value={9}>9</option>
                                  <option value={10}>10</option>
                                  <option value={11}>11</option>
                                  <option value={12}>12</option>
                                  <option value={13}>13</option>
                                  <option value={14}>14</option>
                                  <option value={15}>15</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 px-0">
                              <div className="form-group d-flex flex-row w-100">
                                <label for="addedDateM">Added Date:</label>
                                <select onChange={this.handleAddedDateMatch} id="addedDateM" className="form-control" title tabIndex={-1}>
                                  <option value>Select</option>
                                  <option value={1}>Last 24 Hours</option>
                                  <option value={3}>Last 3 Days</option>
                                  <option value={7}>Last 7 Days</option>
                                  <option value={14}>Last 14 Days</option>
                                  <option value={30}>Last 1 Month</option>
                                  <option value={90}>Last 3 Month</option>
                                  <option value={180}>Last 6 Month</option>
                                  <option value={365}>Last 1 Year</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 px-0">
                              <div className="form-group d-flex flex-row">
                                <label for="matchingPfor">Property For:</label>
                                <select onChange={this.handlePropertForMatch} id="matchingPfor" className="form-control" title tabIndex={-1}>
                                  <option value>Select</option>
                                  <option value="rent">Rent</option>
                                  <option value="sale">Sale</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 px-0">
                              <div className="form-group d-flex flex-row">
                                <label for="matchingType">Type:</label>
                                <select onChange={this.handlePropertTypeMatch} id="matchingType" className="select2 select2-offscreen" title tabIndex={-1}>
                                  <option>select</option>
                                  {this.state.propertytypes.map((propertytype) => {
                                    return <option value={propertytype.value}>{propertytype.label}</option>
                                  })}


                                </select>
                              </div>
                            </div>
                            <div className="col-12 px-0">
                              <button type="button" onClick={this.handleMatch} className="btn btn-dark w-100 mb-2">Match</button>
                            </div>

                          </div>
                          {/* <div className="comment advmatch w-100">
                      <div className="showMatchClass"><span className="font-bold">Match using: </span></div>
                      <div className="form-controls">
                        <div className="col-6 form-group px-0">
                          <label className="control-labels ">Community<em>*</em></label>
                          <Select
                                style={{
                                  width: 160,
                                }}

                              showSearch
                              placeholder="Select a person"
                              optionFilterProp="children"
                              onChange={this.handleCommunityMatch}
                              // onSearch={onSearch}
                              filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.communities}
                          />
                        </div>
                        <div className="col-6 form-group px-0">
                          <label className="control-labels " htmlFor="propsubcommunity">Sub-Community</label>
                          <Select
                                style={{
                                  width: 160,
                                }}
                              // mode={"multiple"}

                              showSearch
                              maxTagCount={"responsive"}
                              placeholder="Select a person"
                              optionFilterProp="children"
                              onChange={this.handleSubCommunityMatch}
                              // onSearch={onSearch}
                              filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.sub_communities}
                          />
                        </div>
                      </div>
                      <div className="form-controls">
                        <div className="col-12 px-0 form-group d-flex flex-column">
                          <label>Budget</label>
                          <div className="d-flex flex-row">
                            <input type="text" onChange={this.handleMinBudget} className="currencyInput" id="matchingBudgetMin" placeholder="Min" />
                            <span className="dash" style={{}}>-</span>
                            <input type="text" onChange={this.handleMaxBudget}  className="currencyInput" id="matchingBudgetMax" placeholder="Max" />
                          </div>
                        </div>
                      </div>
                      <div className="form-controls">
                        <div className="col-6 px-0 form-group d-flex flex-column w-100">
                          <label>Beds</label>
                          <select onChange={this.handleBeds} id="matchingBedrooms" name="bedrooms" className="form-control select2 w-100 select2-offscreen" title tabIndex={-1}>
                            <option value>Select</option>
                            <option value="Studio">Studio</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3} >3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                          </select>
                        </div>
                        <div className="col-6 px-0">
                          <div className="form-controls">
                            <div className="col-12 px-0 form-group d-flex flex-column w-100">
                              <label>Added Date</label>
                              <select  id="addedDateM" className="form-control select2 select2-offscreen" title tabIndex={-1}>
                                <option value>Select</option>
                                <option value={1}>Last 24 Hours</option>
                                <option value={3}>Last 3 Days</option>
                                <option value={7}>Last 7 Days</option>
                                <option value={14}>Last 14 Days</option>
                                <option value={30}>Last 1 Month</option>
                                <option value={90}>Last 3 Month</option>
                                <option value={180}>Last 6 Month</option>
                                <option value={365}>Last 1 Year</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-controls">
                        <div className="w-50 px-0 form-group d-flex flex-column">
                          <label>Property For</label>
                          <select onChange={this.handlePropertForMatch} id="matchingPfor" className="select2 select2-offscreen" title tabIndex={-1}>
                            <option value>Select</option>
                            <option value="rent">Rent</option>
                            <option value="sale">Sale</option>
                          </select>
                        </div>
                        <div className="w-50 px-0 form-group d-flex flex-column">
                          <label>Type</label>
                          <select onChange={this.handlePropertTypeMatch} id="matchingType" className="select2 select2-offscreen" title tabIndex={-1}>
                            <option>select</option>
                            {this.state.propertytypes.map((propertytype)=>{
                        return <option value={propertytype.id}>{propertytype.title}</option>
                            })}


                          </select>
                        </div>
                      </div>
                      <div className="form-controls">
                        <div className="col-12 px-0 form-group d-flex flex-column w-100">
                          <label>Added Date</label>
                          <select onChange={this.handleAddedDateMatch}  id="addedDateM" className="select2 select2-offscreen" title tabIndex={-1}>
                            <option value>Select</option>
                            <option value={1}>Last 24 Hours</option>
                            <option value={3}>Last 3 Days</option>
                            <option value={7}>Last 7 Days</option>
                            <option value={14}>Last 14 Days</option>
                            <option value={30}>Last 1 Month</option>
                            <option value={90}>Last 3 Month</option>
                            <option value={180}>Last 6 Month</option>
                            <option value={365}>Last 1 Year</option>
                          </select>
                        </div>
                      </div>
                      <button type="button" onClick={this.handleMatch} className="btn btn-dark w-100 mb-2">Match</button>
                    </div> */}
                          <div className="matchresults">
                            <div className="d-flex flex-row justify-content-between">
                              <label className="control-labels mr-4">Match with access</label>
                            </div>
                            <div className="matchresult">

                              {this.state.matchs.map((match) => {
                                const match_price = match.price
                                return (
                                  <a href className="matchitem">
                                    <div className="matchinfo">
                                      <div className="col-9 px-0">
                                        <p className="name">{match.owner}</p>
                                        <p className="address">{match.address}</p>
                                        <p className="price">{match_price > 0 ? match_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED' : null}</p>
                                      </div>
                                      <div className="col-3 px-0">
                                        <p className="addeddate">
                                          <Moment fromNow>
                                            {match.added_date}
                                          </Moment>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="matchhistory">
                                      <span className="time"></span>
                                      <p className="linkarrow"><i className="bi bi-chevron-right" /></p>
                                    </div>
                                  </a>
                                )
                              })}


                              {this.state.matchs.length > 0 ? <Link onClick={this.handleAllMatch} className="matchitem">

                                View All

                              </Link> : null}
                            </div>
                          </div>
                          <div className="matchresults">
                            <label className="control-labels mr-4">Match with restricted access</label>
                            <div className="matchresult">
                              {this.state.not_matchs.map((not_match) => {
                                const not_match_price = not_match.price
                                return (
                                  <a href className="matchitem">
                                    <div className="matchinfo">
                                      <div className="col-9 px-0">
                                        <p className="name">{not_match.owner}</p>
                                        <p className="address">{not_match.address}</p>
                                        <p className="price">{not_match_price > 0 ? not_match_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED' : null}</p>
                                      </div>
                                      <div className="col-3 px-0">
                                        <p className="addeddate">
                                          <Moment fromNow>
                                            {not_match.added_date}
                                          </Moment>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="matchhistory">
                                      <span className="time"></span>
                                      <p className="linkarrow"><i className="bi bi-chevron-right" /></p>
                                    </div>
                                  </a>
                                )
                              })}

                              {this.state.not_matchs.length > 0 ? <Link onClick={this.handleAllNotMatch} className="matchitem">

                                View All

                              </Link> : null}



                            </div>
                          </div>
                        </div>
                      </div> {/* END Match*/}
                      {/* OFFERS */}
                      <div className="bg-white border-top offers p-4 mt-4">
                        <div className="d-flex flex-row justify-content-between">
                          <h3 className="mr-4">Offers</h3>

                        </div>
                        <div className="d-flex flex-column border-wrap">

                          {this.state.listing_offers.map((listing_offer) => (
                            <a onClick={this.handleShowOffer.bind(this, listing_offer.views_id)} className="matchitem">
                              <div className="matchinfo">
                                <div className="col-9 px-0">
                                  <p className="name">{listing_offer.fullname}</p>
                                  <p className="address">Offer: AED {listing_offer.price}</p>
                                  <p className="price">{listing_offer.view_date}</p>
                                </div>
                                <div className="col-3 px-0">
                                  <button type="button" className="btn btn-link addfeedback">View feedback</button>
                                </div>
                              </div>
                            </a>
                          ))}


                          {this.state.listing_offers.length > 0 ? <Link to={"/all_offers/" + this.state.record_id} className="btn btn-link viewall">View All</Link>
                            : null}

                        </div>
                      </div> {/* END OFFERS */}
                      {/* VIEWINGS */}
                      <div className="bg-white border-top viewings p-4 mt-4">
                        <div className="d-flex flex-row justify-content-between">
                          <h3 className="mr-4">Viewings</h3>
                          <Link onClick={this.handleNewViewing} className="btn btn-link viewall">Add viewings</Link>
                        </div>
                        <div className="d-flex flex-column border-wrap">
                          {this.state.property_viewings.map((property_viewing) => {
                            return (
                              <a onClick={this.handleEditViewing.bind(this, property_viewing.views_id)} className="matchitem">
                                <div className="matchinfo">
                                  <div className="col-9 px-0">
                                    <p className="name">{property_viewing.fullname}</p>
                                    <p className="address">Offer: AED {property_viewing.price}</p>
                                    <p className="price">{property_viewing.view_date}</p>
                                  </div>
                                  <div className="col-3 px-0">
                                    <button type="button" className="btn btn-link addfeedback">Add feedback</button>
                                  </div>
                                </div>
                              </a>
                            )
                          })}

                          {/* <a href className="matchitem">
                      <div className="matchinfo">
                        <div className="col-9">
                          <p className="name">Mark Castley</p>
                          <p className="address">Offer: AED 19,000,000</p>
                          <p className="price">December 10, 2022</p>
                        </div>
                        <div className="col-3">
                          <button type="button" className="btn btn-link viewfeedback">View feedback</button>
                        </div>
                      </div>
                    </a> */}
                          {/* <a href className="matchitem">
                      <div className="matchinfo">
                        <div className="col-9">
                          <p className="name">Mark Castley</p>
                          <p className="address">Offer: AED 19,000,000</p>
                          <p className="price">December 10, 2022</p>
                        </div>
                        <div className="col-3">
                          <button type="button" className="btn btn-link addfeedback">Add feedback</button>
                        </div>
                      </div>
                    </a> */}
                          {/* <a href className="matchitem">
                      <div className="matchinfo">
                        <div className="col-9">
                          <p className="name">Mark Castley</p>
                          <p className="address">Offer: AED 19,000,000</p>
                          <p className="price">December 10, 2022</p>
                        </div>
                        <div className="col-3">
                          <button type="button" className="btn btn-link viewfeedback">View feedback</button>
                        </div>
                      </div>
                    </a> */}

                          {this.state.property_viewings.length > 0 ? <Link to={"/all_viewings/" + this.state.record_id} className="btn btn-link viewall">View All</Link>
                            : null}

                          {/* {this.state.property_viewings.length > 0?

                      <a href className="matchitem viewall">
                        View All
                      </a>:null
                      } */}

                        </div>
                      </div> {/* END VIEWINGS*/}

                      {/* Followup */}
                      <div className="bg-white border-top viewings p-4 mt-4">
                        <div className="d-flex flex-row justify-content-between">
                          <h3 className="mr-4">Follow Up</h3>
                          <a onClick={this.handleNewFollowUp} className="btn btn-link viewall">Add Followup</a>
                        </div>
                        <div className="d-flex flex-column border-wrap">
                          {this.state.followups_details.map((followup) => {
                            return (
                              <a onClick={this.handleViewFollowup.bind(this, followup.id)} className="matchitem">
                                <div className="matchinfo">
                                  <div className="col-9">
                                    {/*<p className="price">{followups.first_name}</p>*/}
                                    <p className="price">{(followup.type === 1) ? "Vendor" : "Client"}</p>
                                    <p className="price">{(followup.status === 1) ? "Active" : "Inactive"}</p>
                                    <p className="address">
                                      {followup.notes && followup.notes.length > 40 ?
                                        `${followup.notes.substring(0, 40)}...` : followup.notes
                                      }
                                    </p>
                                    <p className="price">{followup.date}</p>
                                  </div>
                                  <div className="col-3">
                                    <button type="button" className="btn btn-link addfeedback"></button>
                                  </div>
                                </div>
                              </a>
                            )
                          })}
                          {this.state.property_viewings.length > 0 ? <a className="matchitem viewall">
                            View All
                          </a> : null}

                        </div>
                      </div> {/* END Followup*/}



                    </div>
                  </div>
                </>
            }
          </div>


        </Drawer>

        <Drawer
          title={`Add new listing`}
          placement="left"
          // size={"large"}
          width={window.isTabView ? "100%" : "98%"}
          onClose={this.handleCloseDrawer}
          open={this.state.open}
          extra={
            <Space>
              <Button type='primary' danger ghost onClick={this.handleCloseDrawer} icon={<CloseOutlined />}>Cancel</Button>
              <Button type="primary" onClick={this.handleSaveData} disabled={this.state.is_loading}>
                Save changes
              </Button>
            </Space>
          }
        >

          <div className="addlisting">

            <div className="container px-0"><h3 className='title' >Property Details</h3></div>
            <div className='container border-wrap addwrap' style={{
              padding: 24,
              minHeight: 380,
              background: "#fff",
            }}>
              <div className='row'>
                <div className='col-6 col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label>Unit no<span style={{ color: '#FF0000' }}>*</span></label>
                    <input type="text" className="form-control" onChange={this.handleUnitNo} />
                    <div
                      className="text-danger">{this.state.validationerrors.unit_no}</div>
                  </div>
                </div>
                <div className='col-6 col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label>Community<span style={{ color: '#FF0000' }}>*</span></label>
                    <Select
                      style={{
                        width: '100%',
                      }}
                      showSearch
                      placeholder="Select a person"
                      optionFilterProp="children"
                      onChange={this.handleCommunity}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={this.state.communities}
                    />
                    <div
                      className="text-danger">{this.state.validationerrors.community}</div>
                  </div>

                </div>
              </div>

              <div className='row'>
                <div className='col-6 col-md-6 col-sm-12'>

                  <div className='form-group'>
                    <label>Sub community<span style={{ color: '#FF0000' }}>*</span></label>
                    <Select
                      style={{
                        width: '100%',
                      }}
                      showSearch
                      placeholder="Select a person"
                      optionFilterProp="children"
                      onChange={this.handleSubCommunity}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={this.state.sub_communities}
                    />
                    <div
                      className="text-danger">{this.state.validationerrors.sub_community}</div>
                  </div>
                </div>
                <div className='col-6 col-md-6 col-sm-12'>

                  <div className='form-group'>
                    <label>Property</label>
                    <Select
                      style={{
                        width: '100%',
                      }}
                      showSearch
                      placeholder="Select a person"
                      optionFilterProp="children"
                      onChange={this.handleProperty}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={this.state.properties}
                    />
                  </div>

                </div>
              </div>

              {/*<div className='row'>*/}
              {/*    <div className='col-md-6'>*/}
              {/*        <label>Form A</label>*/}
              {/*        <input type="file" className='form-control form-control-lg' />*/}
              {/*    </div>*/}

              {/*    <div className='col-md-6'>*/}
              {/*        <label>Passport</label>*/}
              {/*        <input type="file" className='form-control form-control-lg' />*/}
              {/*    </div>*/}

              {/*</div>*/}

              {/*<div className='row mt-3'>*/}
              {/*    <div className='col-md-6'>*/}
              {/*        <label>Emirates ID</label>*/}
              {/*        <input type="file" className='form-control form-control-lg' />*/}
              {/*    </div>*/}

              {/*    <div className='col-md-6'>*/}
              {/*        <label>Title deed</label>*/}
              {/*        <input type="file" className='form-control form-control-lg' />*/}
              {/*    </div>*/}

              {/*</div>*/}

              <div className='row mt-3'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <label>Owner's Name<span style={{ color: '#FF0000' }}>*</span></label>
                  <input type="text" className="form-control" onChange={this.handleOwnerName} />
                  <div
                    className="text-danger">{this.state.validationerrors.owner_name}</div>
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <label>Owner's Phone Number<span style={{ color: '#FF0000' }}>*</span></label>
                  <input type="text" className="form-control" onChange={this.handleOwnerPhone} />
                  <div
                    className="text-danger">{this.state.validationerrors.owner_mobile}</div>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <label>Owner's Email Address </label>
                  <input type="text" className="form-control" onChange={this.handleOwnerEmail} />
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <label>Property Type<span style={{ color: '#FF0000' }}>*</span></label>
                  <Select
                    style={{
                      width: '100%',
                    }}
                    showSearch
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={this.handlePropertyTypeValue}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={this.state.propertytypes}
                  />
                  <div
                    className="text-danger">{this.state.validationerrors.property_type}</div>
                </div>

              </div>

              <div className='row mt-3'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <label>Stage<span style={{ color: '#FF0000' }}>*</span></label>
                  <select className='form-select' onChange={this.handleStage}>
                    <option value="UC">Confirmed owner</option>
                    <option value="CTM">Coming to market</option>
                  </select>
                  <div
                    className="text-danger">{this.state.validationerrors.stage}</div>
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <label>Bedrooms<span style={{ color: '#FF0000' }}>*</span></label>
                  <select className='form-select' onChange={this.handleBedroom}>
                    <option value>Select</option>
                    <option value="Studio">Studio</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3} >3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                    <option value={11}>11</option>
                    <option value={12}>12</option>
                    <option value={13}>13</option>
                    <option value={14}>14</option>
                    <option value={15}>15</option>
                  </select>
                  <div
                    className="text-danger">{this.state.validationerrors.bedroom}</div>
                </div>

              </div>

              <div className='row mt-3 mb-4'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <label>Price<span style={{ color: '#FF0000' }}>*</span></label>
                  <input type="text" className='form-control' onChange={this.handlePrice} />
                  <div
                    className="text-danger">{this.state.validationerrors.price}</div>
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <label>Property for<span style={{ color: '#FF0000' }}>*</span></label>
                  <select className='form-select' onChange={this.handlePropertFor}>
                    <option>select property for</option>
                    <option value="rent">Rent</option>
                    <option value="sale">Sale</option>
                    <option value="short-term">Short term</option>
                    <option value="pond">Pond</option>
                  </select>
                  <div
                    className="text-danger">{this.state.validationerrors.property_for}</div>
                </div>

              </div>

              {(this.state.property_for == 'rent' || this.state.property_for == 'short-term') &&
                <div className='row'>
                  <div className='col-lg-6 col-md-6 col-sm-12 form-group'>
                    <label>Available date<span style={{ color: '#FF0000' }}>*</span></label>
                    <DatePicker showTime onChange={this.handleAvailableDate.bind(this, this.state)} onOk={this.handleAvailableDateOk} />
                    <div
                      className="text-danger">{this.state.validationerrors.avail_date}</div>
                  </div>
                </div>
              }

              <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12 form-group'>
                  <label>Note</label>
                  <TextArea rows={3} value={this.state.listing_note} style={{ width: '100%' }} onChange={this.onChangeListingNote} />
                </div>
              </div>


            </div>
          </div>

        </Drawer>

        <Modal open={this.state.isfollowupOpen} title="Add Follow Up" onOk={this.handleFollowupOk} onCancel={this.handleFollowupCancel}>
          <div className="d-flex flex-column w-100 mt-4">
            <div className="form-group">
              <Radio.Group className='d-flex flex-row' onChange={this.onTypeChange} value={this.state.type} disabled>
                <Radio value={1}>Vendor</Radio>
                <Radio value={2}>Client</Radio>
              </Radio.Group>
            </div>
            {this.state.type == 1 && <div className="form-group">
              <label className="form-label">Vendor:</label>
              <span className='form-control font-14'>{this.state.owner_name}</span>
            </div>}
            {this.state.type == 2 && <div className="form-group">
              <label className="form-label">Client:</label>
              <span className='form-control font-14'>{this.state.owner_name}</span>
            </div>}

            <div className="d-flex flex-row">
              <div className="form-group col-6 pl-0 mb-1">
                <label className="form-label">Follow up for</label>
                <select onChange={this.onChangeFollowupFor} value={this.state.followup_for}
                  name="followup_for" id="followup_for"
                  className="form-control select2 required selectsearch select2-offscreen" title
                  tabIndex={-1}>
                  <option value="">Select Follow up For</option>
                  <option value={1}>Follow Up</option>
                  <option value={2}>Call</option>
                  <option value={3}>Email</option>
                  <option value={4}>Viewing</option>
                  <option value={5}>Offer</option>
                  <option value={6}>Whatsapp</option>
                </select>
                <div
                  className="text-danger">{this.state.validationerrors.followup_for}</div>
              </div>
              {(role_name == "Admin" || role_name == "Super Admin") && <div className="form-group col-6 pr-0 mb-1">
                <label className="form-label">Agent</label>
                <select onChange={this.handleAgentDetailsFollowup}
                  value={this.state.selected_agent}
                  name="selected_agent" id="selected_agent"
                  className="form-control select2 required selectsearch select2-offscreen"
                  title
                  tabIndex={-1}>
                  <option value="">Select Agent</option>
                  {this.state.agents.map((agent) => {
                    return (
                      <option value={agent.value}>{agent.label}</option>
                    )
                  })}
                </select>
                <div
                  className="text-danger">{this.state.validationerrors.selected_agent}</div>
              </div>}
              {(role_name != "Admin" && role_name != "Super Admin") && <div className="form-group col-6 pr-0 mb-1">
                <label className="form-label">Agent</label>
                <select onChange={this.handleAgentDetailsFollowup}
                  value={this.state.selected_agent}
                  name="selected_agent" id="selected_agent"
                  className="form-control select2 required selectsearch select2-offscreen"
                  title
                  tabIndex={-1}>
                  <option value="">Select Agent</option>
                  {this.state.agents.map((agent) => {
                    return (
                      <option value={agent.value}>{agent.label}</option>
                    )
                  })}
                </select>
                <div
                  className="text-danger">{this.state.validationerrors.selected_agent}</div>
              </div>}
            </div>

            <div className="form-group mb-0">
              <div className='d-flex flex-row'>
                <div className='form-group col-6 pl-0 mb-0'>
                  <label className="form-label">Date and Time</label>
                  <DatePicker
                    changeOnBlur={true}
                    value={(this.state.followup_date != "") ? dayjs(this.state.followup_date) : ""}
                    format="YYYY-MM-DD HH:mm"
                    style={{ width: '100%', height: '36px', 'overflow': 'hidden' }}
                    showTime={{ minuteStep: 5 }}
                    onChange={this.handleFollowupDate} />
                  <div
                    className="text-danger">{this.state.validationerrors.followup_date}</div>
                </div>
                <div className='form-group col-6 pl-0 mb-0'>
                  <label className="form-label">Reminder</label>
                  <select onChange={this.onChangeReminder} value={this.state.reminder} name="reminder" id="reminder" className="form-control select2 required selectsearch select2-offscreen" title tabIndex={-1}>
                    <option value="">Select Reminder</option>
                    <option value="600">10 minutes before</option>
                    <option value="1800">30 minutes before</option>
                    <option value="3600">1 hour before</option>
                    <option value="86400">1 day before</option>
                    <option value="604800">1 week before</option>
                  </select>
                </div>
              </div>

            </div>
            <div className="form-group">
              <label className="form-label">Note</label>
              <TextArea rows={4} onChange={this.handleFollowupNote} />
              <div
                className="text-danger">{this.state.validationerrors.followup_note}</div>
            </div>
          </div>
        </Modal>


        <Modal width={1000} title="Choose Amenities" open={this.state.openToAmenities} onOk={this.handleSaveAmenities} onCancel={this.handleCancelAmenities}>
          <div className='row'>
            {this.state.facilities.map((facility) => {
              return (
                <div className='col-md-4'>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id={facility.value} onChange={this.onChangeFacilities.bind(this, facility)} checked={(this.state.selected_facilities.includes(facility.value) ? true : false)} />
                    <label className="form-check-label" for={facility.value}>
                      {facility.label}
                    </label>
                  </div>

                </div>
              )
            })}
          </div>
        </Modal>



        <Modal width={600} title="Add New Filter" open={this.state.isFilterOpen} onOk={this.handleOk} onCancel={this.handleCancel}>

          <div className='col-12 d-flex flex-row px-0'>
            <div className='form-group w-100'>
              <label>Title</label>
              <input type="text" className="form-control" value={this.state.filter_title} onChange={this.handleFilterTitle} />
            </div>
          </div>


          <Select

            showSearch
            style={{
              width: 300,
            }}
            placeholder="Add New Filter"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={this.state.filter_options}
            onChange={this.handleAddRow}
          />

          {this.state.rows.map((item, idx) => (
            <div className="w-100">
              {item.id == 1 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                  <div className='form-group w-100 px-0 mb-0'>
                    <label>Status</label>
                    <Select
                      mode="multiple"
                      style={{
                        width: '100%', minWidth: "7rem"
                      }}

                      placeholder="Select Status"
                      onChange={this.handleStatusChange}
                      options={this.state.status_types}
                    />
                  </div>
                </div>
                <div className='col-1 pr-0  d-flex align-items-end'>  <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                ><CloseOutlined style={{ display: "flex", }} />
                </button></div>
              </div> : null}

              {item.id == 2 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                  <div className='form-group w-100 px-0 mb-0'>
                    <label>Property For</label>
                    <Select
                      mode="multiple"
                      style={{
                        width: '100%', minWidth: "7rem"
                      }}
                      value={this.state.propert_for_value}

                      placeholder="Select Property For"
                      onChange={this.handlePropertyFor}
                      options={this.state.property_for_option}
                    />
                  </div>
                </div>
                <div className='col-1 pr-0  d-flex align-items-end'>  <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                ><CloseOutlined style={{ display: "flex", }} />
                </button></div>
              </div> : null}
              {item.id == 3 ?
                <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                  <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                    <div className='w-100'>
                      <label>Available Date</label>
                      <RangePicker style={{ height: '36px', overflow: 'hidden', position: 'relative', width: '100%' }} onChange={this.handleRangeDate} />
                    </div>
                  </div>
                  <div className='col-1 pr-0  d-flex align-items-end'>  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={this.handleRemoveSpecificRow(idx, item.id)}
                  ><CloseOutlined style={{ display: "flex", }} />
                  </button></div></div> : null}
              {item.id == 4 ? <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                <div className='col-6 d-flex flex-row mt-3 align-content-end pl-0'>
                  <div className='form-group w-100 px-0 mb-0'>
                    <label>Community / Sub Community</label>
                    <Select
                      allowClear
                      showSearch
                      style={{
                        width: '100%',
                      }}
                      value={this.state.community_filter}

                      placeholder="Search"
                      onChange={this.handleCommunityFilter}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={this.state.communities}
                    />

                  </div>
                </div>      <div className='col-5 pr-0 d-flex flex-row mt-3 align-content-end'>
                  <div className='form-group w-100 px-0 mb-0'>
                    <label>Sub community</label>
                    <Select
                      showSearch
                      allowClear
                      style={{
                        width: '100%',
                      }}
                      value={this.state.sub_community_filter}
                      placeholder="Search"
                      onChange={this.handleSubCommunityFilter}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={this.state.sub_communities}
                    />
                  </div>
                </div><div className='col-1 pr-0  d-flex align-items-end'>  <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                ><CloseOutlined style={{ display: "flex", }} />
                </button></div></div> : null}
              {item.id == 5 ?
                <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                  <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                    <div className='w-100'>
                      <label>Property</label>
                      <Select
                        showSearch
                        allowClear
                        style={{
                          width: '100%',
                        }}
                        value={this.state.property_filter}
                        placeholder="Search"
                        onChange={this.handlePropertyFilter}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={this.state.properties}
                      />
                    </div>
                  </div>
                  <div className='col-1 pr-0  d-flex align-items-end'>  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={this.handleRemoveSpecificRow(idx, item.id)}
                  ><CloseOutlined style={{ display: "flex", }} />
                  </button></div></div> : null}

              {item.id == 6 ?
                <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                  <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                    <div className='w-100'>
                      <label>Property Type</label>
                      <Select
                        showSearch
                        allowClear

                        style={{
                          width: '100%',
                        }}
                        mode="multiple"
                        value={this.state.property_type_filter}

                        placeholder="Search"
                        onChange={this.handlePropertyTypeFilter}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={this.state.propertytypes}
                      />
                    </div>
                  </div>
                  <div className='col-1 pr-0  d-flex align-items-end'>  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={this.handleRemoveSpecificRow(idx, item.id)}
                  ><CloseOutlined style={{ display: "flex", }} />
                  </button></div></div> : null}

              {item.id == 7 ?
                <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                  <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                    <div className='w-100'>
                      <label>Bedrooms</label>
                      <select value={this.state.bedroom_filter} onChange={this.handleBedFilter} id="matchingBedrooms" name="bedrooms" className="form-control" title tabIndex={-1}>
                        <option value="">All</option>
                        <option value="Studio">Studio</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3} >3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                        <option value={13}>13</option>
                        <option value={14}>14</option>
                        <option value={15}>15</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-1 pr-0  d-flex align-items-end'>  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={this.handleRemoveSpecificRow(idx, item.id)}
                  ><CloseOutlined style={{ display: "flex", }} />
                  </button></div></div> : null}

              {item.id == 8 ?
                <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                  <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                    <div className='w-100'>
                      <label>Furnished / Unfurnished</label>
                      <Select
                        mode="multiple"
                        style={{
                          width: '100%',
                        }}

                        placeholder="Please select"
                        onChange={this.handleFurnishedTypes}
                        options={this.state.furnished_types}
                      />
                    </div>
                  </div>
                  <div className='col-1 pr-0  d-flex align-items-end'>  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={this.handleRemoveSpecificRow(idx, item.id)}
                  ><CloseOutlined style={{ display: "flex", }} />
                  </button></div></div> : null}

              {item.id == 9 ?
                <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                  <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                    <div className='w-100'>
                      <label>Added Date</label>
                      <div className="form-check">
                        <input className="form-check-input" type="radio"
                          name="flexRadioDefault"
                          checked={this.state.date_morethan_option}
                          id="flexRadioDefault1"
                          onChange={this.handleDateMorethan} />
                        <label className="form-check-label"
                          htmlFor="flexRadioDefault1">
                          Less than
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio"
                          checked={this.state.date_lessthan_option}
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          onChange={this.handleDateLessthan} />
                        <label className="form-check-label"
                          htmlFor="flexRadioDefault2">
                          More than
                        </label>
                      </div>

                      <div className='' style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}>
                        <input type="text" value={this.state.date_value}
                          className="form-control"
                          onChange={this.handleDateValue} />
                        <select className='form-select ml-1'
                          onChange={this.handleDateValue_id}>
                          <option value="1">days</option>
                          <option value="2">weeks</option>
                          <option value="3">months</option>
                          <option value="4">years</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-1 pr-0  d-flex align-items-end'>  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={this.handleRemoveSpecificRow(idx, item.id)}
                  ><CloseOutlined style={{ display: "flex", }} />
                  </button></div></div> : null}

              {item.id == 10 ?
                <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                  <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                    <div className='w-100'>
                      <label>Price</label>
                      <select className="form-select"
                        aria-label="Default select example"
                        name='price_first_filter_value_id'
                        onChange={this.handlePriceFirstValue_id}>
                        <option value="1">Equals</option>
                        <option value="2">Not equal</option>
                        <option value="3">Less than</option>
                        <option value="4">Less than or equals</option>
                        <option value="5">Greater than</option>
                        <option value="6">Greater than or equals
                        </option>
                        <option value="7">In range</option>
                      </select>
                      <div className='mt-2'>
                        {this.state.inrange ? <div style={{
                          display: 'flex',
                          flexDirection: 'row'
                        }}>
                          <input type="text"
                            value={this.state.price_min}
                            style={{ width: '105px' }}
                            className="form-control"
                            onChange={this.handlePriceMin}
                            placeholder="min" />
                          <input type="text"
                            value={this.state.price_max}
                            style={{ width: '105px' }}
                            className="form-control"
                            onChange={this.handlePriceMax}
                            placeholder="max" />
                        </div> : <input type="text"
                          value={this.state.price_first_value}
                          className="form-control"
                          pattern='[0-9]*\.?[0-9]*'
                          onChange={this.handlePriceFirst}
                          placeholder="filter..." />}
                      </div>
                      {this.state.price_active_second_option ? (
                        <div className='mt-2'>
                          <center>
                            <div className="form-check">
                              <input className="form-check-input"
                                type="radio"
                                name="pricefirst"
                                id="pricefirst"
                                checked={this.state.price_and_or_option ? true : false}
                                onChange={this.handlePriceRadio} />
                              <label className="form-check-label"
                                htmlFor="pricefirst">
                                AND
                              </label>
                              <input
                                className="form-check-input ml-3"
                                type="radio" name="pricefirst"
                                id="pricesecond"
                                checked={!this.state.price_and_or_option ? true : false}
                                onChange={this.handlePriceRadio} />
                              <label
                                className="form-check-label ml-4"
                                htmlFor="pricesecond">
                                OR
                              </label>

                            </div>
                          </center>
                          <select className="form-select"
                            aria-label="Default select example"
                            name='price_second_filter_value_id'
                            onChange={this.handlePriceSecondValue_id}>
                            <option value="1">Equals</option>
                            <option value="2">Not equal</option>
                            <option value="3">Less than</option>
                            <option value="4">Less than or equals
                            </option>
                            <option value="5">Greater than</option>
                            <option value="6">Greater than or
                              equals
                            </option>
                            <option value="7">In range</option>
                          </select>
                          <div className='mt-2'>
                            <input type="text"
                              value={this.state.price_second_value}
                              className="form-control"
                              onChange={this.handlePriceSecond}
                              placeholder="filter..." />
                          </div>

                        </div>) : null}
                    </div>
                  </div>
                  <div className='col-1 pr-0  d-flex align-items-end'>  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={this.handleRemoveSpecificRow(idx, item.id)}
                  ><CloseOutlined style={{ display: "flex", }} />
                  </button></div></div> : null}

              {item.id == 11 ?
                <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                  <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                    <div className='w-100'>
                      <label>No of Leads</label>
                      <select className="form-select"
                        aria-label="Default select example"
                        name='price_first_filter_value_id'
                      >
                        <option value="1">Equals</option>
                        <option value="2">Not equal</option>
                        <option value="3">Less than</option>
                        <option value="4">Less than or equals</option>
                        <option value="5">Greater than</option>
                        <option value="6">Greater than or equals
                        </option>
                        <option value="7">In range</option>
                      </select>
                      <div className='mt-2'>
                        {this.state.inrange ? <div style={{
                          display: 'flex',
                          flexDirection: 'row'
                        }}>
                          <input type="text"
                            value={this.state.price_min}
                            style={{ width: '105px' }}
                            className="form-control"
                            onChange={this.handlePriceMin}
                            placeholder="min" />
                          <input type="text"
                            value={this.state.price_max}
                            style={{ width: '105px' }}
                            className="form-control"
                            onChange={this.handlePriceMax}
                            placeholder="max" />
                        </div> : <input type="text"
                          value={this.state.price_first_value}
                          className="form-control"
                          pattern='[0-9]*\.?[0-9]*'
                          onChange={this.handlePriceFirst}
                          placeholder="filter..." />}
                      </div>
                      {this.state.price_active_second_option ? (
                        <div className='mt-2'>
                          <center>
                            <div className="form-check">
                              <input className="form-check-input"
                                type="radio"
                                name="pricefirst"
                                id="pricefirst"
                                checked={this.state.price_and_or_option ? true : false}
                                onChange={this.handlePriceRadio} />
                              <label className="form-check-label"
                                htmlFor="pricefirst">
                                AND
                              </label>
                              <input
                                className="form-check-input ml-3"
                                type="radio" name="pricefirst"
                                id="pricesecond"
                                checked={!this.state.price_and_or_option ? true : false}
                                onChange={this.handlePriceRadio} />
                              <label
                                className="form-check-label ml-4"
                                htmlFor="pricesecond">
                                OR
                              </label>

                            </div>
                          </center>
                          <select className="form-select"
                            aria-label="Default select example"
                            name='price_second_filter_value_id'
                            onChange={this.handlePriceSecondValue_id}>
                            <option value="1">Equals</option>
                            <option value="2">Not equal</option>
                            <option value="3">Less than</option>
                            <option value="4">Less than or equals
                            </option>
                            <option value="5">Greater than</option>
                            <option value="6">Greater than or
                              equals
                            </option>
                            <option value="7">In range</option>
                          </select>
                          <div className='mt-2'>
                            <input type="text"
                              value={this.state.price_second_value}
                              className="form-control"
                              onChange={this.handlePriceSecond}
                              placeholder="filter..." />
                          </div>

                        </div>) : null}
                    </div>
                  </div>
                  <div className='col-1 pr-0  d-flex align-items-end'>  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={this.handleRemoveSpecificRow(idx, item.id)}
                  ><CloseOutlined style={{ display: "flex", }} />
                  </button></div></div> : null}

              {item.id == 12 ?
                <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                  <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                    <div className='w-100'>
                      <label>No of Viewings</label>
                      <select className="form-select"
                        aria-label="Default select example"
                        name='price_first_filter_value_id'
                      >
                        <option value="1">Equals</option>
                        <option value="2">Not equal</option>
                        <option value="3">Less than</option>
                        <option value="4">Less than or equals</option>
                        <option value="5">Greater than</option>
                        <option value="6">Greater than or equals
                        </option>
                        <option value="7">In range</option>
                      </select>
                      <div className='mt-2'>
                        {this.state.inrange ? <div style={{
                          display: 'flex',
                          flexDirection: 'row'
                        }}>
                          <input type="text"
                            value={this.state.price_min}
                            style={{ width: '105px' }}
                            className="form-control"
                            onChange={this.handlePriceMin}
                            placeholder="min" />
                          <input type="text"
                            value={this.state.price_max}
                            style={{ width: '105px' }}
                            className="form-control"
                            onChange={this.handlePriceMax}
                            placeholder="max" />
                        </div> : <input type="text"
                          value={this.state.price_first_value}
                          className="form-control"
                          pattern='[0-9]*\.?[0-9]*'
                          onChange={this.handlePriceFirst}
                          placeholder="filter..." />}
                      </div>
                      {this.state.price_active_second_option ? (
                        <div className='mt-2'>
                          <center>
                            <div className="form-check">
                              <input className="form-check-input"
                                type="radio"
                                name="pricefirst"
                                id="pricefirst"
                                checked={this.state.price_and_or_option ? true : false}
                                onChange={this.handlePriceRadio} />
                              <label className="form-check-label"
                                htmlFor="pricefirst">
                                AND
                              </label>
                              <input
                                className="form-check-input ml-3"
                                type="radio" name="pricefirst"
                                id="pricesecond"
                                checked={!this.state.price_and_or_option ? true : false}
                                onChange={this.handlePriceRadio} />
                              <label
                                className="form-check-label ml-4"
                                htmlFor="pricesecond">
                                OR
                              </label>

                            </div>
                          </center>
                          <select className="form-select"
                            aria-label="Default select example"
                            name='price_second_filter_value_id'
                            onChange={this.handlePriceSecondValue_id}>
                            <option value="1">Equals</option>
                            <option value="2">Not equal</option>
                            <option value="3">Less than</option>
                            <option value="4">Less than or equals
                            </option>
                            <option value="5">Greater than</option>
                            <option value="6">Greater than or
                              equals
                            </option>
                            <option value="7">In range</option>
                          </select>
                          <div className='mt-2'>
                            <input type="text"
                              value={this.state.price_second_value}
                              className="form-control"
                              onChange={this.handlePriceSecond}
                              placeholder="filter..." />
                          </div>

                        </div>) : null}

                      {item.id == 13 ?
                        <div className='row d-flex flex-row addfilterwrap w-100 m-0'>
                          <div className='col-11 d-flex flex-row mt-3 align-content-end px-0'>
                            <div className='w-100'>
                              <label>No of Offers</label>
                              <select className="form-select"
                                aria-label="Default select example"
                                name='price_first_filter_value_id'
                              >
                                <option value="1">Equals</option>
                                <option value="2">Not equal</option>
                                <option value="3">Less than</option>
                                <option value="4">Less than or equals</option>
                                <option value="5">Greater than</option>
                                <option value="6">Greater than or equals
                                </option>
                                <option value="7">In range</option>
                              </select>
                              <div className='mt-2'>
                                {this.state.inrange ? <div style={{
                                  display: 'flex',
                                  flexDirection: 'row'
                                }}>
                                  <input type="text"
                                    value={this.state.price_min}
                                    style={{ width: '105px' }}
                                    className="form-control"
                                    onChange={this.handlePriceMin}
                                    placeholder="min" />
                                  <input type="text"
                                    value={this.state.price_max}
                                    style={{ width: '105px' }}
                                    className="form-control"
                                    onChange={this.handlePriceMax}
                                    placeholder="max" />
                                </div> : <input type="text"
                                  value={this.state.price_first_value}
                                  className="form-control"
                                  pattern='[0-9]*\.?[0-9]*'
                                  onChange={this.handlePriceFirst}
                                  placeholder="filter..." />}
                              </div>
                              {this.state.price_active_second_option ? (
                                <div className='mt-2'>
                                  <center>
                                    <div className="form-check">
                                      <input className="form-check-input"
                                        type="radio"
                                        name="pricefirst"
                                        id="pricefirst"
                                        checked={this.state.price_and_or_option ? true : false}
                                        onChange={this.handlePriceRadio} />
                                      <label className="form-check-label"
                                        htmlFor="pricefirst">
                                        AND
                                      </label>
                                      <input
                                        className="form-check-input ml-3"
                                        type="radio" name="pricefirst"
                                        id="pricesecond"
                                        checked={!this.state.price_and_or_option ? true : false}
                                        onChange={this.handlePriceRadio} />
                                      <label
                                        className="form-check-label ml-4"
                                        htmlFor="pricesecond">
                                        OR
                                      </label>

                                    </div>
                                  </center>
                                  <select className="form-select"
                                    aria-label="Default select example"
                                    name='price_second_filter_value_id'
                                    onChange={this.handlePriceSecondValue_id}>
                                    <option value="1">Equals</option>
                                    <option value="2">Not equal</option>
                                    <option value="3">Less than</option>
                                    <option value="4">Less than or equals
                                    </option>
                                    <option value="5">Greater than</option>
                                    <option value="6">Greater than or
                                      equals
                                    </option>
                                    <option value="7">In range</option>
                                  </select>
                                  <div className='mt-2'>
                                    <input type="text"
                                      value={this.state.price_second_value}
                                      className="form-control"
                                      onChange={this.handlePriceSecond}
                                      placeholder="filter..." />
                                  </div>

                                </div>) : null}
                            </div>
                          </div>
                          <div className='col-1 pr-0  d-flex align-items-end'>  <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={this.handleRemoveSpecificRow(idx, item.id)}
                          ><CloseOutlined style={{ display: "flex", }} />
                          </button></div></div> : null}
                    </div>
                  </div>
                  <div className='col-1 pr-0  d-flex align-items-end'>  <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={this.handleRemoveSpecificRow(idx, item.id)}
                  ><CloseOutlined style={{ display: "flex", }} />
                  </button></div></div> : null}



            </div>
          ))}


        </Modal>


        {/* edit filter */}
        <Modal width={1000} title="Filter" open={this.state.isFilterEditOpen} onOk={this.handleOk} onCancel={this.handleCancelEit}>

          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group w-100'>
                <label>Title</label>
                <input type="text" className="form-control" value={this.state.filter_title} onChange={this.handleFilterTitle} />
              </div>
            </div>
          </div>


          <Select

            showSearch
            style={{
              width: 300,
            }}
            placeholder="add filter"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={this.state.filter_options}
            onChange={this.handleAddRow}
          //   onChange={add()}
          />

          {this.state.rows.map((item, idx) => (
            <div >
              {item.id == 1 ? <div className='row mt-3'>  <div className='col-md-4'>
                <div className='form-group'>
                  <label>Ref no</label>
                  <div >

                    <select className="form-select" aria-label="Default select example" name='refno_first_filter_value_id' onChange={this.handleRefnoFirstValue_id}>
                      <option value="1">Contain</option>
                      <option value="2">Not contain</option>
                      <option value="3">Equals</option>
                      <option value="4">Not equal</option>
                      <option value="5">Starts with</option>
                      <option value="6">Ends with</option>
                    </select>
                    <div className='mt-2'>
                      <input type="text" className="form-control" onChange={this.handleRefnoFirst} placeholder="filter..." />
                    </div>
                    {this.state.refno_active_second_option ? (<div className='mt-2' >
                      <center>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
                          <label className="form-check-label" for="flexRadioDefault1">
                            AND
                          </label>
                          <input className="form-check-input ml-3" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!this.state.refno_and_or_option ? true : false} onChange={this.handleRefRadio} />
                          <label className="form-check-label ml-4" for="flexRadioDefault1">
                            OR
                          </label>

                        </div>
                      </center>
                      <select className="form-select" aria-label="Default select example" name='refno_second_filter_value_id' onChange={this.handleRefnoSecondValue_id}>
                        <option value="1">Contain</option>
                        <option value="2">Not contain</option>
                        <option value="3">Equals</option>
                        <option value="4">Not equal</option>
                        <option value="5">Starts with</option>
                        <option value="6">Ends with</option>
                      </select>
                      <div className='mt-2'>
                        <input type="text" className="form-control" onChange={this.handleRefnoSecond} placeholder="filter..." />
                      </div>

                    </div>) : null}



                  </div>
                </div>
              </div>
                <div className='col-md-4'></div>
                <div className='col-md-4 mt-4'>  <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                >
                  Remove
                </button></div>
              </div> : null}
              {item.id == 2 ? <div className='row'>  <div className='col-md-4'>
                <div className='form-group'>
                  <label>Property for</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: '100%',
                    }}
                    value={this.state.propert_for_value}
                    placeholder="Please select"
                    onChange={this.handlePropertyFor}
                    options={this.state.property_for_option}
                  />

                </div>
              </div>      <div className='col-md-4'>
                </div><div className='col-md-4 mt-4'>  <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                >
                  Remove
                </button></div></div> : null}
              {item.id == 3 ? <div className='row'>  <div className='col-md-4'>
                <div className='form-group'>
                  <label>Property types</label>
                  <Select
                    mode="multiple"
                    style={{
                      width: '100%',
                    }}
                    placeholder="Please select"
                    onChange={this.handlePropertyType}
                    options={this.state.property_type_option}
                  />

                </div>
              </div>      <div className='col-md-4'>
                </div><div className='col-md-4 mt-4'>  <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                >
                  Remove
                </button></div></div> : null}
              {item.id == 4 ? <div className='row mt-3'>

                <div className='col-md-4'>

                  Unit no
                  <select className="form-select" aria-label="Default select example" name='unitno_first_filter_value_id' onChange={this.handleUnitnoFirstValue_id}>
                    <option value="1">Contain</option>
                    <option value="2">Not contain</option>
                    <option value="3">Equals</option>
                    <option value="4">Not equal</option>
                    <option value="5">Starts with</option>
                    <option value="6">Ends with</option>
                  </select>
                  <div className='mt-2'>
                    <input type="text" className="form-control" onChange={this.handleUnitnoFirst} placeholder="filter..." />
                  </div>
                  {this.state.unitno_active_second_option ? (<div className='mt-2' >
                    <center>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="flexRadio" id="flexRadio1" checked={this.state.unitno_and_or_option ? true : false} onChange={this.handleUnitRadio} />
                        <label className="form-check-label" for="flexRadio1">
                          AND
                        </label>
                        <input className="form-check-input ml-3" type="radio" name="flexRadio" id="flexRadio1" checked={!this.state.unitno_and_or_option ? true : false} onChange={this.handleUnitRadio} />
                        <label className="form-check-label ml-4" for="flexRadio1">
                          OR
                        </label>

                      </div>
                    </center>
                    <select className="form-select" aria-label="Default select example" name='unitno_second_filter_value_id' onChange={this.handleUnitnoSecondValue_id}>
                      <option value="1">Contain</option>
                      <option value="2">Not contain</option>
                      <option value="3">Equals</option>
                      <option value="4">Not equal</option>
                      <option value="5">Starts with</option>
                      <option value="6">Ends with</option>
                    </select>
                    <div className='mt-2'>
                      <input type="text" className="form-control" onChange={this.handleUnitnoSecond} placeholder="filter..." />
                    </div>

                  </div>) : null}



                </div>
                <div className='col-md-4'></div>
                <div className='col-md-4 mt-4'>  <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                >
                  Remove
                </button></div> </div> : null}

              {item.id == 5 ?
                <div className='row mt-3'>
                  <div className='col-md-4'>
                    <label>Created</label>


                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" checked={this.state.date_morethan_option} id="flexRadioDefault1" onChange={this.handleDateMorethan} />
                      <label className="form-check-label" for="flexRadioDefault1">
                        More than
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" checked={this.state.date_lessthan_option} name="flexRadioDefault" id="flexRadioDefault2" onChange={this.handleDateLessthan} />
                      <label className="form-check-label" for="flexRadioDefault2">
                        Less than
                      </label>
                    </div>

                    <div className='' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <input type="text" className="form-control" onChange={this.handleDateValue} />
                      <select className='form-select ml-1' onChange={this.handleDateValue_id}>
                        <option value="1">days</option>
                        <option value="2">weeks</option>
                        <option value="3">months</option>
                        <option value="4">years</option>
                      </select>
                    </div>

                  </div>
                  <div className='col-md-4'></div><div className='col-md-4 mt-4'> <button
                    className="btn btn-outline-danger btn-sm"
                    onClick={this.handleRemoveSpecificRow(idx, item.id)}
                  >
                    Remove
                  </button></div></div> : null}
              {item.id == 6 ? <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label>Price</label>
                    <div >

                      <select className="form-select" aria-label="Default select example" name='price_first_filter_value_id' onChange={this.handlePriceFirstValue_id}>
                        <option value="1">Equals</option>
                        <option value="2">Not equal</option>
                        <option value="3">Less than</option>
                        <option value="4">Less than or equals</option>
                        <option value="5">Greater than</option>
                        <option value="6">Greater than or equals</option>
                        <option value="7">In range</option>
                      </select>
                      <div className='mt-2'>
                        {this.state.inrange ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMin} placeholder="min" />
                          <input type="text" style={{ width: '105px' }} className="form-control" onChange={this.handlePriceMax} placeholder="max" />
                        </div> : <input type="text" className="form-control" pattern='[0-9]*\.?[0-9]*' onChange={this.handlePriceFirst} placeholder="filter..." />}
                      </div>
                      {this.state.price_active_second_option ? (<div className='mt-2' >
                        <center>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="pricefirst" id="pricefirst" checked={this.state.price_and_or_option ? true : false} onChange={this.handlePriceRadio} />
                            <label className="form-check-label" for="pricefirst">
                              AND
                            </label>
                            <input className="form-check-input ml-3" type="radio" name="pricefirst" id="pricesecond" checked={!this.state.price_and_or_option ? true : false} onChange={this.handlePriceRadio} />
                            <label className="form-check-label ml-4" for="pricesecond">
                              OR
                            </label>

                          </div>
                        </center>
                        <select className="form-select" aria-label="Default select example" name='price_second_filter_value_id' onChange={this.handlePriceSecondValue_id}>
                          <option value="1">Equals</option>
                          <option value="2">Not equal</option>
                          <option value="3">Less than</option>
                          <option value="4">Less than or equals</option>
                          <option value="5">Greater than</option>
                          <option value="6">Greater than or equals</option>
                          <option value="7">In range</option>
                        </select>
                        <div className='mt-2'>
                          <input type="text" className="form-control" onChange={this.handlePriceSecond} placeholder="filter..." />
                        </div>

                      </div>) : null}



                    </div>


                  </div>
                </div>
                <div className='col-md-4'></div>
                <div className='col-md-4 mt-4'> <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                >
                  Remove
                </button></div>

              </div> : null}
              {item.id == 7 ? <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label>Furnished / Unfurnished</label>
                    <Select
                      mode="multiple"
                      style={{
                        width: '100%',
                      }}

                      placeholder="Please select"
                      onChange={this.handleFurnishedTypes}
                      options={this.state.furnished_types}
                    />

                  </div>
                </div>
                <div className='col-md-4'></div>
                <div className='col-md-4 mt-4'> <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.handleRemoveSpecificRow(idx, item.id)}
                >
                  Remove
                </button></div>

              </div> : null}


            </div>
          ))}


        </Modal>

        <Modal title="Add Referral" open={this.state.isReferralOpen} onOk={this.handleisReferralOpen} onCancel={this.handleisReferralClose}
        >
          <Row className='w-100'>
            <Col span={24}>
              <div className='form-group'>
                <label>Add agent</label>
                <Select allowClear showSearch filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={[
                    { value: '1', label: 'Geoff Forknell' },
                    { value: '2', label: 'Armand Janse Van Rensburg' },
                    { value: '3', label: 'George Hughes' },
                    { value: '4', label: 'Alex Fegan' },
                    { value: '5', label: 'Craig Scrim' },
                    { value: '6', label: 'Charlotte Jones' },
                    { value: '7', label: 'Elijah Crabtree' },
                    { value: '8', label: 'Mary Borghosizian' },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Modal>

        {/* EDIT LISTING V2 */}

        <Drawer
          title={<p className='fs-4 font-bold mb-0'>{this.state.listing_details.refno}  </p>} className='veditlisting'
          placement="left"
          // size={"large"}
          width={"98%"}
          onClose={this.handleCloseEditDrawer}
          open={this.state.openToEdit}
          extra={
            <Space className='d-flex flex-row align-content-center flex-wrap' style={{ gap: '0.8rem' }}>
                <Button type='text' className='d-flex flex-row align-items-center' onClick={this.handleUnarchive.bind(this,this.state.listing_details.id)} style={{ height: '36px', textDecoration: 'none' }} ><i className="bi bi-box-arrow-left"></i>Unarchive</Button>
              <Button type='text' className='d-flex flex-row align-items-center' onClick={this.handlePreviewLink.bind(this, this.state.listing_details.id, this.state.listing_details.refno, this.state.listing_details.status)} style={{ height: '36px', textDecoration: 'none' }} ><EyeFilled />Preview</Button>
              <Button type='text' className='d-flex flex-row  align-items-center mt-0' disabled={this.state.disabledPrev ? true : false} onClick={this.state.disabledPrev ? null : this.handlePreviousRecord}><ArrowLeftOutlined /> Previous</Button>
              <Button type='text' className='d-flex flex-row  align-items-center mt-0' disabled={this.state.disabledNext ? true : false} onClick={this.state.disabledNext ? null : this.handleNextRecord} > Next <ArrowRightOutlined /> </Button>
              <Button type='text' className='d-flex flex-row align-items-center mt-0' onClick={this.handleCloseEditDrawer} style={{ height: '31px', justifyContent: 'center' }}><i class="bi bi-x-lg font-bold"></i></Button>
            </Space>
          }
        >
          <div className="cont-wrap editlistingv2-wrap">
            <Row>
              <Col span={5} xl={5} lg={5} md={24} sm={24} xs={24} className='edit-actions pr-0'>
                <div className='editwrap propertyimage'>
                  {this.state.property_images == null ?
                    <>
                      <div className='noimageyet'><img src={"../../images/noimageyet.png"} className='defimg mx-auto' width="80px" style={{ opacity: '0.6' }} /><span className='font-12'>Image pending</span></div>
                    </>
                    :
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItem: 'center' }}>
                      <Carousel arrows style={{ width: '246px', height: '160px', overflow: 'hidden' }} effect="fade" {...props}>
                        {this.state.property_images.map((image) => {
                          return <Image src={baseurlImg + "/" + image.image} style={{ width: '100%' }} />

                        })}
                      </Carousel>
                    </div>
                  }
                </div>
                {/* OWNER DETAILS */}
                {role == 1 || user_id == this.state.listing_details.agent_id || this.state.listing_details.status == "UC" || this.state.listing_details.status == "CTM" || this.state.listing_details.agent_id=="313" ?
                  <div className='d-flex flex-column ownerdetails editwrap'>
                    <h3>Owner Details</h3>
                    <div className='form-group'>
                      <label>Name:</label>
                      <EdiText disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                        (role_name != "Super Admin" && role_name != "Admin")} value={this.state.owner_name} type="text" onSave={this.handleSaveOwnerName} />
                    </div>
                    <div className='form-group'>
                      <label>Phone:</label>
                      <span disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                        (role_name != "Super Admin" && role_name != "Admin")} className='font-bold font-12'>{this.state.owner_mobile}</span>
                    </div>
                    <div className='form-group'>
                      <label>Email:</label>
                      <EdiText disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                        (role_name != "Super Admin" && role_name != "Admin")} value={this.state.owner_email} type="text" onSave={this.handleSaveOwnerEmail} />
                    </div>
                    <Collapse ghost expandIcon={({ isActive }) => isActive ? <DoubleRightOutlined rotate={isActive ? 90 : 0} /> : <DoubleRightOutlined />}>
                      <Panel header={
                        <div className='d-flex flex-row'><span className='font-12 mr-1'>See owner's other listing</span>
                          <span className='cntwrap'>{this.state.owner_other_properties.length}</span></div>} key="1">
                        <div className="col-12 d-flex flex-column border listtable-wrap">
                          {this.state.owner_other_properties.map((owner_other_property) => {
                            var pStatus = 'Draft'
                            switch (owner_other_property.status) {
                              case 'D': pStatus = 'Draft'; break;
                              case 'UC': pStatus = 'Confirmed Owner'; break;
                              case 'N': pStatus = 'Unpublish'; break;
                              case 'OM': pStatus = 'Off-Market'; break;
                              case 'CTM': pStatus = 'Coming to market'; break;
                              case 'Y': pStatus = 'Publish'; break;
                              case 'U': pStatus = 'Action'; break;
                              case 'SEO': pStatus = 'SEO'; break;
                              case 'L': pStatus = 'Unlisted'; break;
                              case 'S': pStatus = 'Sold'; break;
                              case 'T': pStatus = 'Let'; break;
                              case 'P': pStatus = 'Review'; break;
                              case 'F': pStatus = 'Feeds'; break;
                              default: pStatus = 'Draft'; break;
                            }
                            return (
                              <a href className="d-flex flex-row p-2 w-100">
                                <div className="col-8 listtable p-0">
                                  <p className="propfor">{owner_other_property.property_for}</p>
                                  <p className="refno">{owner_other_property.refno}</p>
                                  <p className="propadd">{owner_other_property.unitno},  {owner_other_property.subcommunity}</p>
                                  <p className="community">{owner_other_property.community}</p>
                                </div>
                                <div className="col-4 p-0">
                                  <div className={pStatus ? "d-flex flex-row pstat " + pStatus.toLowerCase().replace(/\s/g, '') : ""}>
                                    <i className="bi bi-circle-fill" />
                                    <span className='font-10 text-left'>
                                      {pStatus}
                                    </span>
                                  </div>
                                </div>
                              </a>
                            )
                          })}

                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                  : null}
                {this.state.listing_details.property_for == "sale" ?
                  /* Mini Calculator */
                  <div className='editwrap rentalwrap'>
                    <Collapse ghost>
                      <Panel header={<>
                        <span className='font-bold'>Rental Yield Calculator</span>
                      </>} key="1">
                        <div className="bg-white">
                          <div className="col-12 px-0 form-group d-flex flex-column mt-2">
                            <div className="form-group">
                              <label className="control-labels ">Purchase Price (AED)</label>
                              <input type="text" id="purchaseprice" onChange={this.handlepurchaseprice} />
                            </div>
                            <div className="form-group">
                              <label className="control-labels ">Service Charges - Per Year (AED)</label>
                              <input type="text" id="servicecharges" onChange={this.handleservicecharge} />
                            </div>
                            <div className="form-group">
                              <label className="control-labels ">Rental Amount (AED)</label>
                              <input type="text" id="rentalamount" onChange={this.handlerentalamount} />
                            </div>
                            <div className="d-flex flex-column">
                              <Button type='primary' id="getcalculate" onClick={this.handleCalculate}>Calculate</Button>
                              {/* total(%) = (rental amount - service charge ) / purchase price
                                            when they click the calculate total shows and it will add a value on the column Rental Yield
                                        */}
                            </div>

                            {this.state.rentalyieldtotal >= 0 ?
                              <div className="d-flex flex-row w-100 mt-4 rentalyield">
                                <p className="form-control">Rental Yield  : <strong>{this.state.rentalyieldtotal + "%"}</strong></p>
                              </div> : null}
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </div> : null}

                <div className='editwrap offerwrap'>
                  <Collapse ghost>
                    <Panel header={<>
                      <span className='font-bold'>Offers</span>
                      {/* <span className='cntwrap'>{this.state.listing_offers.length}</span> */}
                    </>} key="1">
                      {this.state.property_viewings.length > 0 ? <>
                        {this.state.listing_offers.map((listing_offer) => (
                          <a onClick={this.handleShowOffer.bind(this, listing_offer.views_id)} className="matchitem">
                            <div className="matchinfo">
                              <div className="col-9 px-0">
                                <p className="name">{listing_offer.fullname}</p>
                                <p className="address">Offer: AED {listing_offer.price}</p>
                                <p className="price">{listing_offer.view_date}</p>
                              </div>
                              <div className="col-3 px-0">
                                <button type="button" className="btn btn-link addfeedback">View feedback</button>
                              </div>
                            </div>
                          </a>
                        ))}
                        {this.state.listing_offers.length > 0 ? <Link to={"/all_offers/" + this.state.record_id} className="btn btn-link viewall">View All</Link> : null}
                      </> : <div className='emptystate'>
                        <i class="bi bi-tag-fill fs-5"></i>
                        <span className='font-12'>You don't have any offers.</span>
                      </div>
                      }
                    </Panel>
                  </Collapse>
                </div>
                <div className='editwrap viewingswrap'>
                  <Collapse ghost>
                    <Panel header={<>
                      <span className='font-bold'>Viewings</span>
                      {/* <span className='cntwrap'>{this.state.property_viewings.length}</span> */}
                    </>} key="2">
                      {this.state.property_viewings.length > 0 ? <>
                        {this.state.property_viewings.map((property_viewing) => {
                          return (
                            <a onClick={this.handleEditViewing.bind(this, property_viewing.views_id)} className="matchitem">
                              <div className="matchinfo">
                                <div className="col-9 px-0">
                                  <p className="name">{property_viewing.fullname}</p>
                                  <p className="address">Offer: AED {property_viewing.price}</p>
                                  <p className="price">{property_viewing.view_date}</p>
                                </div>
                                <div className="col-3 px-0">
                                  <button type="button" className="btn btn-link addfeedback">Add feedback</button>
                                </div>
                              </div>
                            </a>
                          )
                        })}
                        {this.state.property_viewings.length > 0 ? <Link to={"/all_viewings/" + this.state.record_id} className="btn btn-link viewall">View All</Link> : null}
                      </> : <div className='emptystate'>
                        <i class="bi bi-eye-fill fs-5"></i>
                        <span className='font-12'>You don't have any viewings yet.</span>
                      </div>}
                      <Button type='secondary' onClick={this.handleNewViewing} className="mx-auto d-flex mt-1  font-10">Add viewings</Button>
                    </Panel>
                  </Collapse>
                </div>
                <div className='editwrap followup'>
                  <Collapse ghost>
                    <Panel header={<>
                      <span className='font-bold'>Follow Up</span>
                      {/* <span className='cntwrap'>{this.state.property_viewings.length}</span> */}
                    </>} key="3">
                      {this.state.followups_details.length > 0 ? <>
                        {this.state.followups_details.map((followup) => {
                          return (
                            <a onClick={this.handleViewFollowup.bind(this, followup.id)} className="matchitem">
                              <div className="matchinfo">
                                <div className="col-9">
                                  {/*<p className="price">{followups.first_name}</p>*/}
                                  <p className="price">{(followup.type === 1) ? "Vendor" : "Client"}</p>
                                  <p className="price">{(followup.status === 1) ? "Active" : "Inactive"}</p>
                                  <p className="address">
                                    {followup.notes && followup.notes.length > 40 ?
                                      `${followup.notes.substring(0, 40)}...` : followup.notes
                                    }
                                  </p>
                                  <p className="price">{followup.date}</p>
                                </div>
                                <div className="col-3">
                                  <button type="button" className="btn btn-link addfeedback"></button>
                                </div>
                              </div>
                            </a>
                          )
                        })}
                        {this.state.property_viewings.length > 0 ? <a className="matchitem viewall mx-auto ">View All</a> : null}
                      </> : <div className='emptystate'>
                        <i class="bi bi-calendar2-check-fill fs-5"></i>
                        <span className='font-12'>You don't have any follow up scheduled yet.</span>
                      </div>}
                      <Button type="secondary" onClick={this.handleNewFollowUp} className="mx-auto d-flex mt-1 font-10">Add Followup</Button>
                    </Panel>
                  </Collapse>
                </div>
                <div className='editwrap matchdetails'>
                  <Collapse ghost>
                    <Panel header={<>
                      <span className='font-bold'>Match Details</span>
                    </>} key="4">
                      <div className="d-flex flex-column matchfilter">
                        <div className="col-12 px-0">
                          <div className="form-group">
                            <label for="community" className="mb-0 ">Community:</label>
                            <Select id="community" showSearch placeholder="Select" className='form-control'
                              optionFilterProp="children"
                              onChange={this.handleCommunityMatch}
                              // onSearch={onSearch}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.communities}
                            />
                          </div>
                        </div>
                        <div className="col-12 px-0">
                          <div className="form-group">
                            <label for="propsubcommunity" className="control-labels " htmlFor="propsubcommunity">Sub Community:</label>
                            <Select id="propsubcommunity" showSearch maxTagCount={"responsive"} placeholder="Select" optionFilterProp="children"
                              onChange={this.handleSubCommunityMatch} className='form-control'
                              // onSearch={onSearch}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              options={this.state.sub_communities}
                            />
                          </div>
                        </div>
                        <div className="col-12 px-0">
                          <div className="form-group d-flex flex-column budget">
                            <label for="matchingBudgetMin">Budget:</label>
                            <div className="d-flex flex-row">
                              <CurrencyInput id="input-min" name="input-min" placeholder="Min" decimalsLimit={2} onChange={this.handleMinBudget} className='form-control' />
                              <span className="dash" style={{}}>-</span>
                              <CurrencyInput id="input-max" name="input-max" placeholder="Max" decimalsLimit={2} onChange={this.handleMaxBudget} className='form-control' />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 px-0">
                          <div className="form-group">
                            <label for="matchingBedrooms">Beds:</label>
                            <Select onChange={this.handleBeds} id="matchingBedrooms" name="bedrooms" className="form-control" allowClear filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} showSearch
                              options={[
                                { value: "Studio", label: "Studio" },
                                { value: "1", label: "1" }, { value: "2", label: "2" },
                                { value: "3", label: "3" },
                                { value: "4", label: "4" },
                                { value: "5", label: "5" },
                                { value: "6", label: "6" },
                                { value: "7", label: "7" },
                                { value: "8", label: "8" },
                                { value: "9", label: "9" },
                                { value: "10", label: "10" },
                                { value: "11", label: "11" },
                                { value: "12", label: "12" },
                                { value: "13", label: "13" },
                                { value: "14", label: "14" },
                                { value: "15", label: "15" },
                              ]} />
                          </div>
                        </div>
                        <div className="col-12 px-0">
                          <div className="form-group d-flex flex-column w-100">
                            <label for="addedDateM" className="control-labels ">Added Date:</label>
                            <Select onChange={this.handleAddedDateMatch} id="addedDateM" className="form-control" allowClear filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} showSearch
                              options={[
                                { value: '1', label: 'Last 24 hours' }, { value: '3', label: 'Last 3 Days' },
                                { value: '7', label: 'Last 7 Days' }, { value: '14', label: 'Last 14 Days' },
                                { value: '30', label: 'Last Month' }, { value: '90', label: 'Last 3 Months' },
                                { value: '180', label: 'Last 6 Months' }, { value: '365', label: 'Last Year' },
                              ]} />
                          </div>
                        </div>
                        <div className="col-12 px-0">
                          <div className="form-group d-flex flex-column">
                            <label for="matchingPfor" className="control-labels ">Property For:</label>
                            <Select onChange={this.handlePropertForMatch} id="matchingPfor" className="form-control" allowClear filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} showSearch
                              options={[
                                { value: 'rent', label: 'Rent' }, { value: 'sale', label: 'Sale' }
                              ]} />
                          </div>
                        </div>
                        <div className="col-12 px-0">
                          <div className="form-group d-flex flex-column">
                            <label for="matchingType" className="control-labels ">Type:</label>
                            <Select onChange={this.handlePropertTypeMatch} id="matchingType" className="form-control" allowClear filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} showSearch
                              options={this.state.propertytypes.map((propertytype) => (
                                { value: propertytype.value, label: propertytype.label }
                              ))
                              } />
                          </div>
                        </div>
                        <div className="col-12 px-0 mt-2">
                          <Button type="primary" onClick={this.handleMatch} className="w-100 mb-2">Match</Button>
                        </div>

                      </div>

                      <div className="matchresults">
                        <div className="d-flex flex-row justify-content-between">
                          <label className="control-labels mr-4">Match with access</label>
                        </div>
                        <div className="matchresult">

                          {this.state.matchs.map((match) => {
                            const match_price = match.price
                            return (
                              <a href className="matchitem">
                                <div className="matchinfo">
                                  <div className="col-9 px-0">
                                    <p className="name">{match.owner}</p>
                                    <p className="address">{match.address}</p>
                                    <p className="price">{match_price > 0 ? match_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED' : null}</p>
                                  </div>
                                  <div className="col-3 px-0">
                                    <p className="addeddate">
                                      <Moment fromNow>
                                        {match.added_date}
                                      </Moment>
                                    </p>
                                  </div>
                                </div>
                                <div className="matchhistory">
                                  <span className="time"></span>
                                  <p className="linkarrow"><i className="bi bi-chevron-right" /></p>
                                </div>
                              </a>
                            )
                          })}


                          {this.state.matchs.length > 0 ? <Link onClick={this.handleAllMatch} className="matchitem">

                            View All

                          </Link> : null}
                        </div>
                      </div>
                      <div className="matchresults">
                        <label className="control-labels mr-4">Match with restricted access</label>
                        <div className="matchresult">
                          {this.state.not_matchs.map((not_match) => {
                            const not_match_price = not_match.price
                            return (
                              <a href className="matchitem">
                                <div className="matchinfo">
                                  <div className="col-9 px-0">
                                    <p className="name">{not_match.owner}</p>
                                    <p className="address">{not_match.address}</p>
                                    <p className="price">{not_match_price > 0 ? not_match_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED' : null}</p>
                                  </div>
                                  <div className="col-3 px-0">
                                    <p className="addeddate">
                                      <Moment fromNow>
                                        {not_match.added_date}
                                      </Moment>
                                    </p>
                                  </div>
                                </div>
                                <div className="matchhistory">
                                  <span className="time"></span>
                                  <p className="linkarrow"><i className="bi bi-chevron-right" /></p>
                                </div>
                              </a>
                            )
                          })}

                          {this.state.not_matchs.length > 0 ? <Link onClick={this.handleAllNotMatch} className="matchitem">

                            View All

                          </Link> : null}



                        </div>
                      </div>


                    </Panel>
                  </Collapse>
                </div>

                <div className='editwrap marketingagent'>
                  <Collapse ghost>
                    <Panel header={<>
                      <span className='font-bold'>Marketing Agent</span>
                    </>} key="5">
                      <Row>
                        <Col span={12} style={{ minWidth: '100%' }}>
                          <div className="form-group">
                            <Select showSearch
                              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                              onChange={this.handleMarketingAgentChange}
                              name="agents_assign" value={this.state.marketing_agent ? parseInt(this.state.marketing_agent) : ""}>
                              <Option
                                value="">Select Marketing Agent</Option>
                              {this.state.agents.map((agent) => {
                                return <>
                                  <Option
                                    value={agent.value}>{agent.label}</Option>
                                </>
                              })}
                            </Select>
                          </div>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                </div>
                <div className='editwrap referral'>
                  <Collapse ghost>
                    <Panel header={<>
                      <span className='font-bold'>Referral</span>
                    </>} key="5">
                      <Row>
                        <Col span={24}>
                          <div className="form-group  d-flex flex-column">
                            <label className='mb-0'>Referred by:</label>
                            <Select showSearch defaultValue={this.state.referred_by}
                              disabled={role_name != "Admin" && role_name != "Super Admin" && this.state.is_referred}
                              onChange={this.changeReferred_by}
                              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                              name="agents_assign">

                              <Option
                                value="">Refer agent</Option>
                              {this.state.agents.map((agent) => {
                                return <>
                                  <Option
                                    value={agent.value}>{agent.label}</Option>
                                </>
                              })}
                            </Select>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="form-group d-flex flex-column">
                            <label className='mb-0'>Referred to:</label>
                            <Select showSearch
                              disabled={role_name != "Admin" && role_name != "Super Admin" && this.state.is_referred}
                              defaultValue={this.state.referred_to}
                              onChange={this.changeReferred_to}
                              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                              name="agents_assign">
                              <Option
                                value="">Refer agent</Option>
                              {this.state.agents.map((agent) => {
                                return <>
                                  <Option
                                    value={agent.value}>{agent.label}</Option>
                                </>
                              })}
                            </Select>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="form-group mt-2">
                            <Button
                              onClick={this.onSaveRef}
                              type="primary"
                              disabled={role_name != "Admin" && role_name != "Super Admin" && this.state.is_referred}
                              className="d-flex flex-row">
                              <i className="bi bi-plus me-1 fs-5" />
                              Save</Button>
                          </div>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                </div>
                <div className='editwrap request'>
                  <Collapse ghost defaultActiveKey={1}>
                    <Panel header={<>
                      <span className='font-bold'>Requests</span>
                    </>} key="1">
                      <Button type='text' onClick={this.handleShowAddPhotoRequest} className='p-0'><i className="bi bi-camera-fill mr-1" /><span className='px-1'>Photography</span></Button>
                    </Panel>
                  </Collapse>
                </div>
              </Col>
              <Col span={this.state.isCollapsed ? 0 : 12} xl={this.state.isCollapsed ? 0 : 12} lg={this.state.isCollapsed ? 0 : 12} md={this.state.isCollapsed ? 0 : 24} sm={this.state.isCollapsed ? 0 : 24} xs={this.state.isCollapsed ? 0 : 24} className={'edit-details ' + this.state.isCollapsed ? "hideeditdetails" : null}>

                <Row className='rowone justify-content-between w-100 d-flex flex-row'>
                  <Col className='d-flex flex-row'>
                    <div className='infogrp'>
                      <p>Leads:</p><p className='font-bold'>0</p>
                    </div>
                    <div className='infogrp'>
                      <p>Viewings:</p><p className='font-bold'>0</p>
                    </div>
                    <div className='infogrp'>
                      <p>Offers:</p><p className='font-bold'>0</p>
                    </div>
                  </Col>
                  <Col className='d-flex flex-row'>
                    <p className='font-bold mb-0'>Match Leads:</p>
                    <div className='infogrp'>
                      <p>Exact:</p><p className='font-bold'>0</p>
                    </div>
                    <div className='infogrp'>
                      <p>Comparable:</p><p className='font-bold'>0</p>
                    </div>
                  </Col>
                </Row>

                <Row className='rowtwo d-flex flex-row p-4'>
                  <Col span={24}>
                    <span className='font-bold'>Listing Details</span>
                  </Col>
                  <Col span={24} className='edittabs'>
                    <Tabs>
                      <TabList>
                        <Tab>Details</Tab>
                        <Tab>SEO</Tab>
                        <Tab>Documents</Tab>
                        <Tab>Images</Tab>
                        <Tab>Portal</Tab>
                        <Tab>International</Tab>
                      </TabList>
                      <TabPanel>
                        {/* DETAILS */}
                        <Row className='editdetailswrap pb-3'>
                          <Col span={24}>
                            <Row className='justify-content-between'>
                              <Col>
                                <div className='form-group'>
                                  <Select value={this.state.listing_status_details} name="listingstatus"
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    onChange={this.handleListingStatusDetails}
                                    className={this.state.listing_status_details ? "stat listingstat-" + this.state.listing_status_details.trim().toLowerCase() : ""}>
                                    {(role_name == "Admin" || role_name == "Super Admin") && <Option value="D">Draft</Option>}
                                    <Option value="UC">Confirmed Owner</Option>
                                    <Option value="N">Unpublished</Option>
                                    <Option value="OM">Off-Market</Option>
                                    <Option value="CTM">Coming to Market</Option>
                                    {(role_name == "Admin" || role_name == "Super Admin") && <Option value="Y">Publish</Option>}
                                    <Option value="U">Action</Option>
                                    {(role_name == "Admin" || role_name == "Super Admin") && <Option value="SEO">SEO</Option>}
                                    <Option value="T">Unlisted</Option>
                                    {(role_name == "Admin" || role_name == "Super Admin") && <Option value="S">Sold</Option>}
                                    {(role_name == "Admin" || role_name == "Super Admin") && <Option value="L">Let</Option>}
                                    {(role_name == "Admin" || role_name == "Super Admin") && <Option value="F">Feeds</Option>}
                                    {(role_name == "Admin" || role_name == "Super Admin") && <Option value="Deleted">Move to Archive</Option>}
                                    {(role_name == "Admin" || role_name == "Super Admin") && <Option value="PO">Pending owner</Option>}
                                    {(role_name == "Admin" || role_name == "Super Admin") && <Option value="SLP">Sold by LP</Option>}
                                    {(role_name == "Admin" || role_name == "Super Admin") && <Option value="R">Rented</Option>}
                                    {(role_name == "Admin" || role_name == "Super Admin") && <Option value="RLP">Rented by LP</Option>}
                                  </Select>
                                </div>
                              </Col>
                              <Col>
                                <div className="input-group-text">
                                  <label className="control-labels pr-1" htmlFor="leadgen">Lead Gen</label>
                                  <Switch checked={this.state.lead_gen} disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} id="leadgen" onChange={this.onLeadGen} checkedChildren={'Yes'} unCheckedChildren={'No'} />
                                </div>
                              </Col>
                            </Row>
                            <Row className='justify-content-between'>
                              <Col sm={12} xs={24}>
                                {/* AGENT */}
                                {role == 1 || this.state.listing_details.status == "UC" ?
                                  <div className="form-group d-flex flex-row avatar-group">
                                    <Avatar className='d-flex mr-1' size={50} icon={<Avatar className='d-flex' icon={<img src={this.state.listing_details.profile_photo == null || this.state.listing_details.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(this.state.listing_details.profile_photo) ? this.state.listing_details.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + this.state.listing_details.profile_photo} />} />} />
                                    <Select value={this.state.listing_details.agent_name} disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                      onChange={this.handleAgentDetails}
                                      style={{ minWidth: '200px' }}>
                                      {this.state.agents.map((agent) => {
                                        return (
                                          <Option value={agent.value}>{agent.label}</Option>
                                        )
                                      })}
                                    </Select>

                                  </div>
                                  : null}
                              </Col>
                              <Col sm={6} xs={12} className={`${window.isMobileView ? 'pr-1' : ''}`}>
                                {/* Property For */}
                                <div className="form-group">
                                  <label className="control-labels">Property For</label>
                                  <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    onChange={this.handlePropertyForDetails}
                                    value={this.state.property_for_details} name="propertyfor">
                                    <Option value="sale">Sale</Option>
                                    <Option value="rent">Rent</Option>
                                    <Option value="short-term">Short term</Option>
                                    <Option value="pond">Pond</Option>
                                  </Select>
                                </div>
                              </Col>
                              <Col sm={6} xs={12} className={`${window.isMobileView ? 'pr-1' : ''}`}>
                                {/* Property For */}
                                <div className="form-group">
                                  <label className="control-labels">Property Category</label>
                                  <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    onChange={this.handlePropertyCategory}
                                    value={this.state.property_category} name="propertyfor">
                                    <Option value="Residential">Residential</Option>
                                    <Option value="Commercial">Commercial</Option>
                                  </Select>
                                </div>
                              </Col>
                              <Col>
                                {/* Property Type */}
                                <div className="form-group">
                                  <label className="control-labels">Property Type</label>
                                  <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    onChange={this.handlePropertyTypeDetails}
                                    value={this.state.property_type_details} name="propertyfor">
                                    {this.state.propertytypes.map((propertytype) => {
                                      return <Option selected={propertytype.value === this.state.listing_details.property_category_id} value={propertytype.value}>{propertytype.label}</Option>
                                    })}
                                  </Select>

                                </div>
                              </Col>
                              <Col>
                                {/* Completion Status */}
                                <div className="form-group">
                                  <label className="control-labels">Completion Status</label>
                                  <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    onChange={this.handleCompletionDetails}
                                    value={this.state.completion_status} name="completion_status" placeholder="-">
                                    <Option value="Yes">Completed</Option>
                                    <Option value="Offplan">Offplan</Option>
                                    <Option value="Offplan_development">Offplan Development</Option>
                                  </Select>
                                </div>
                              </Col>

                            </Row>
                            <Row className='justify-content-between py-3' gutter={8}>
                              <Col span={24}><p className='unitlabel'>Unit Location</p></Col>
                              <Col span={6} sm={6} xs={12}>
                                {/* Unit No */}
                                <div className="form-group">
                                  <label className="control-labels">Unit No:</label>
                                  <input onChange={this.handleUnitNoDetails} disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} type="text" placeholder="Unit no" value={this.state.unit_no_details} />
                                </div>
                              </Col>
                              <Col span={6} sm={6} xs={12}>
                                {/* Community */}
                                <div className="form-group">
                                  <label className="control-labels">Community:</label>
                                  {/* <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    onChange={this.handleCommunityDetails}
                                    value={this.state.community_details} name="community" showSearch >
                                    {this.state.communities.map((community) => {
                                      return <Option value={community.value}>{community.label}</Option>
                                    })}
                                    
                                  </Select> */}
                                  <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    // style={{
                                    //     width: 300,
                                    // }}
                                    showSearch
                                    optionFilterProp="children"
                                    value={this.state.community_details}
                                    onChange={this.handleCommunityDetails}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.communities}
                                  />
                                </div>
                              </Col>
                              <Col span={6} sm={6} xs={12}>
                                {/* Sub Community */}
                                <div className="form-group">
                                  <label className="control-labels">Sub Community:</label>
                                  {/* <Select disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} onChange={this.handleSubCommunityDetails} value={this.state.subcommunity_details} name="community">
                                    {this.state.sub_communities.map((subcommunity) => {
                                      return <Option value={subcommunity.value}>{subcommunity.label}</Option>
                                    })}
                                  </Select> */}
                                  <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    // style={{
                                    //     width: 300,
                                    // }}
                                    showSearch
                                    optionFilterProp="children"
                                    value={this.state.subcommunity_details}
                                    onChange={this.handleSubCommunityDetails}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.sub_communities}
                                  />
                                </div>
                              </Col>
                              <Col span={6} sm={6} xs={12}>
                                <div className="form-group">
                                  <label className="control-labels">Property:</label>
                                  <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    // style={{
                                    //     width: 300,
                                    // }}
                                    showSearch
                                    optionFilterProp="children"
                                    value={this.state.property_details ? parseInt(this.state.property_details) : "-"}
                                    onChange={this.handlePropertyDetails}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.properties}
                                  />
                                  {/* <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    onChange={this.handlePropertyDetails}
                                    name="properties" value={this.state.property_details ? parseInt(this.state.property_details) : "-"}>
                                    {this.state.properties.map((property) => {
                                      return <Option
                                        value={property.value}>{property.label}</Option>
                                    })}
                                  </Select> */}
                                </div>
                              </Col>
                            </Row>
                            <Row className='py-3' gutter={8}>
                              <Col span={6} sm={6} xs={12}>
                                <div className="form-group">
                                  <label className="control-labels">Price<em>*</em>:</label>
                                  <input onChange={this.handlePriceDetails} disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} type="text" name="price" id="property_price" value={this.state.price_details} className="form-control" placeholder="Price" />
                                </div>
                              </Col>
                              <Col span={6} sm={6} xs={12}>
                                <div className="form-group d-flex flex-column">
                                  <label className="control-labels mb-1">Price on Application?</label>
                                  <Switch checkedChildren={'Yes'} disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} unCheckedChildren={'No'} style={{ width: '60px' }} checked={this.state.price_application} onChange={this.handlePriceApplication} />
                                </div>
                              </Col>
                              <Col span={6} sm={6} xs={12}>
                                <div className="form-group">
                                  <label className="control-labels">Built Up Area:</label>
                                  <input type="text" name="bua" disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} onChange={this.handleBua} id="bua" value={this.state.bua} className="form-control" placeholder="-" />
                                </div>
                              </Col>
                              <Col span={6} sm={6} xs={12}>
                                <div className="form-group">
                                  <label className="control-labels">Plot Size (Sq.Ft):</label>
                                  <input type="text" name="plotarea" disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} id="plotarea" onChange={this.handlePlotArea} value={this.state.plot_area} className="form-control" placeholder="-" />
                                </div>
                              </Col>

                              <Col span={6}>
                                <div className="form-group">
                                  <label className="control-labels">Rera Permit:</label>
                                  <input type="text" name="plotarea" onChange={this.handleReraPermit} value={this.state.rera_permit} className="form-control" />
                                </div>
                              </Col>
                            </Row>
                            <Row className={`py-3 ${window.isTabView ? '' : 'justify-content-between'}`} gutter={8}>



                              <Col span={4} lg={4} md={4} sm={6} xs={12}>
                                <div className="form-group">
                                  <label className="control-labels">Bedroom:</label>
                                  <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    onChange={this.handleBedDetails}
                                    defaultValue={this.state.bedroom_details} name="bedroom">
                                    {this.state.bedrooms.map((bedroom) => {
                                      return <Option
                                        value={bedroom.id}>{bedroom.bedroom}</Option>
                                    })}
                                  </Select>
                                </div>
                              </Col>
                              <Col span={4} lg={4} md={4} sm={6} xs={12}>
                                <div className="form-group">
                                  <label className="control-labels">Bathroom:</label>
                                  <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    onChange={this.handleaBathroomDetails}
                                    name="Bathroom" defaultValue={this.state.bathroom_details} placeholder="-">
                                    <option value>Select</option>
                                    {(this.state.bathrooms) && this.state.bathrooms.map((bathroom) => {
                                      return <Option
                                        value={bathroom.id}>{bathroom.bathroom}</Option>
                                    })}
                                  </Select>
                                </div>
                              </Col>
                              <Col span={4} lg={4} md={4} sm={6} xs={12}>
                                <div className="form-group">
                                  <label className="control-labels">FF/UF:</label>
                                  <Select disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} onChange={this.handleFurnishedDetails} name="furnished" defaultValue={this.state.furnished_details == 0 ? "No" : "Yes"} placeholder="-">
                                    <Option value="1">Yes</Option>
                                    <Option value="0">No</Option>
                                  </Select>
                                </div>
                              </Col>
                              <Col span={4} lg={4} md={4} sm={6} xs={12}>
                                <div className="form-group">
                                  <label className="control-labels">Upgraded:</label>
                                  <Select disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} onChange={this.handleUpgradedDetails} name="upgraded" defaultValue={this.state.upgraded_details == 0 ? "No" : "Yes"} placeholder="-">
                                    <Option value="1">Yes</Option>
                                    <Option value="0">No</Option>
                                  </Select>
                                </div>
                              </Col>
                              <Col span={4} lg={4} md={4} sm={6} xs={12}>
                                <div className="form-group">
                                  <label className="control-labels">Vacant:</label>
                                  <Select disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} onChange={this.handleVacantDetails} name="vacant" defaultValue={this.state.vacant_details == 0 ? "No" : "Yes"} placeholder="-">
                                    <Option value="1">Yes</Option>
                                    <Option value="0">No</Option>
                                  </Select>
                                </div>
                              </Col>
                              <Col span={4} lg={4} md={4} sm={6} xs={12}>
                                <div className="form-group">
                                  <label className="control-labels">Maids Room:</label>
                                  <Select disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} onChange={this.handleMaidsDetails} name="maids_room" defaultValue={this.state.maids_room == 0 ? "No" : "Yes"} placeholder="-">
                                    <Option value="1">Yes</Option>
                                    <Option value="0">No</Option>
                                  </Select>
                                </div>
                              </Col>
                            </Row>
                            <Row className={`py-3 ${window.isTabView ? '' : 'justify-content-between'}`} gutter={8}>
                              <Col span={6} sm={24} xs={24}>
                                <div className="form-group">
                                  <label className="control-labels">Available Date:</label>
                                  <DatePicker disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} format='DD MMMM YYYY' style={{ width: '90%' }} />
                                </div>
                              </Col>
                              <Col span={6} sm={24} xs={24}>
                                <div className="form-group">
                                  <label className="control-labels">Added Date:</label>
                                  {this.state.added_date ? <Moment fromNow format='DD MMMM YYYY'> {this.state.added_date} </Moment> : '-'}
                                </div>
                              </Col>
                              <Col span={6} sm={24} xs={24}>
                                <div className="form-group">
                                  <label className="control-labels">Published Date:</label>
                                  {this.state.published_date ? <Moment fromNow format='DD MMMM YYYY'> {this.state.published_date}</Moment> : '-'}
                                </div>
                              </Col>
                              <Col span={6} sm={24} xs={24}>
                                <div className="form-group">
                                  <label className="control-labels">Last Call:</label>
                                  {this.state.last_call ? <Moment fromNow format='DD MMMM YYYY'> {this.state.last_call} </Moment> : '-'}
                                </div>
                              </Col>
                            </Row>

                            <Row className={`py-3 ${window.isTabView ? '' : 'justify-content-between'}`} gutter={8}>



                              <Col span={24}><h3 className='d-flex flex-row align-items-center'><i className="bi bi-geo-alt mr-1"></i>Map details:</h3></Col>

                              <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                <div className="form-group">
                                  <label className="control-labels">Latitude :</label>
                                  <Input value={this.state.lat} />
                                </div>
                              </Col>
                              <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                <div className="form-group">
                                  <label className="control-labels">Longitude :</label>
                                  <Input value={this.state.lng} />
                                </div>
                              </Col>

                            </Row>
                            <Row className='py-3 justify-content-between' gutter={24}>
                              <p>Property finder</p><br />

                              <Col span={12} lg={12} md={12} sm={24} xs={24}>
                                {/* Sub Community */}
                                <div className="form-group">
                                  <label className="control-labels">Sub Community:</label>
                                  <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    // style={{
                                    //     width: 300,
                                    // }}
                                    showSearch
                                    optionFilterProp="children"
                                    value={this.state.pf_project}
                                    onChange={this.handlePFProjectDetails}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.sub_communities}
                                  />
                                  {/* <Select disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} onChange={this.handlePFSubCommunityDetails} value={this.state.pf_project} name="community">
                                    {this.state.sub_communities.map((subcommunity) => {
                                      return <Option value={subcommunity.value}>{subcommunity.label}</Option>
                                    })}
                                  </Select> */}
                                </div>
                              </Col>
                              <Col span={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="form-group">
                                  <label className="control-labels">Property:</label>
                                  <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    // style={{
                                    //     width: 300,
                                    // }}
                                    showSearch
                                    optionFilterProp="children"
                                    value={this.state.pf_property}
                                    onChange={this.handlePFPropertyDetails}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={this.state.properties}
                                  />
                                  {/* <Select
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    onChange={this.handlePFPropertyDetails}
                                    name="properties" defaultValue={this.state.pf_property ? parseInt(this.state.pf_property) : "-"}>
                                    {this.state.properties.map((property) => {
                                      return <Option
                                        value={property.value}>{property.label}</Option>
                                    })}
                                  </Select> */}
                                </div>
                              </Col>
                            </Row>
                            <Row className='py-3'>
                              <Col span={24} className='d-flex flex-column'>
                                <div className="form-group mb-2">
                                  <label className="control-labels">Title:</label>
                                  <input type="text" disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} className="form-control font-bold" placeholder="Enter title" onChange={this.handleTitleDetails} value={this.state.title_details} />
                                  <p style={{ fontSize: '10px' }}>{(this.state.title_details == "" || this.state.title_details == null) ? "0" : this.state.title_details.length} characters</p>
                                </div>
                              </Col>
                              <Col span={24} className='d-flex flex-column'>
                                <div className="form-group">
                                  <div className='d-flex flex-row justify-content-between'>
                                    <label className="control-labels">Description:</label>
                                    <Button type="text" className="generatebtn" disabled={(this.state.description_loader)} onClick={this.generateDescription}>{(this.state.description_loader) ? <Spin style={{ alignSelf: 'center' }} /> : <><ReloadOutlined /><span className='font-10'>Generate new description</span></>}</Button>
                                  </div>
                                  <CKEditor
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    editor={ClassicEditor}
                                    data={(this.state.description_details != null) ? this.state.description_details : ""}
                                    onChange={(event, editor) => {
                                      this.setState({ description_details: (editor) ? editor.getData() : "" })
                                    }}
                                  />
                                  <p style={{ fontSize: '10px' }}>{(this.state.description_details == "" || this.state.description_details == null) ? "0" : this.state.description_details.length} characters</p>
                                </div>
                              </Col>
                            </Row>
                            <Row className='py-3' gutter={8}>
                              <Col span={12} sm={12} xs={24}>
                                <div className="form-group">
                                  <label className="control-labels">Youtube Video Link</label>
                                  <input disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} type="text" placeholder="-" onChange={this.handleVideoLink} value={this.state.video_link} />
                                </div>
                              </Col>
                              <Col span={12} sm={12} xs={24}>
                                <div className="form-group">
                                  <label className="control-labels">Livetour 360 Video Link</label>
                                  <input type="text" disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} placeholder="-" onChange={this.handleLiveTourLink} value={this.state.livetour_link} />
                                </div>
                              </Col>
                              <Col span={12} sm={12} xs={24}>
                                <div className="form-group uploadgrp">
                                  <label className="control-labels">Brochure</label>
                                  <input type="file" disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} className="form-control w-100" id="inputGroupFile02" placeholder='-' />
                                </div>
                              </Col>
                              <Col span={12} sm={12} xs={24}>
                                <div className="form-group">
                                  <label className="control-labels">Facilites &amp; Amenities (Maximum of 10)</label>
                                  <Button type="secondary" onClick={this.handleOpenAmenities} >Choose Amenities</Button>
                                </div>
                              </Col>
                              <Col span={12} sm={12} xs={24}>
                                <div className="form-group">
                                  <label className="control-labels">Curations</label>
                                  <Select className='font-12'
                                    disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                      (role_name != "Super Admin" && role_name != "Admin")}
                                    mode="multiple"
                                    value={this.state.curation_details}
                                    placeholder="Choose Curations"
                                    maxTagCount={"responsive"}
                                    onChange={this.handleCurationDetails}
                                    optionFilterProp="children"
                                  >
                                    {this.state.curation.map(item => (
                                      <Option key={item.id} value={item.id}>{item.title}</Option>
                                    ))}
                                  </Select>
                                </div>
                              </Col>
                              <Col span={12} sm={12} xs={24}>
                                <div className="form-group">
                                  <label className="control-labels">Life Style</label>
                                  <select onChange={this.handleLifeStyleDetails} disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} value={this.state.lifestyle_details} name="life_style">
                                    <option>Choose Life Style</option>
                                    {this.state.life_style.map((lifestyle) => {
                                      return <option value={lifestyle.id}>{lifestyle.title}</option>
                                    })}
                                  </select>
                                </div>
                              </Col>
                            </Row>
                            <Row className='pb-3 mb-4'>
                              <Col span={24}>
                                <Button type='primary' onClick={this.handleSetailsSubmit} className=" ml-auto">Save details</Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </TabPanel>
                      <TabPanel>
                        {/* SEO */}
                        <div className='editseowrap pt-3'>
                          <Row gutter={8}>
                            <Col span={24}>
                              <div className="form-group">
                                <label className="control-labels">Meta Title</label>
                                <input type="text" disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                  (role_name != "Super Admin" && role_name != "Admin")} placeholder="Meta Title" onChange={this.handleMetaTitle} value={this.state.meta_title} />
                                <p style={{ fontSize: '10px' }}>{(this.state.meta_title == "" || this.state.meta_title == null) ? "0" : this.state.meta_title.length} characters</p>
                                <div
                                  className="text-danger">{this.state.websitevalidationerrors.meta_title}</div>
                              </div>
                              <div className="form-group mb-0">
                                <label className="control-labels">Meta Keywords</label>
                                <input type="text" disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                  (role_name != "Super Admin" && role_name != "Admin")} placeholder="Meta Keywords" onChange={this.handleMetaKeywords} value={this.state.meta_keywords} />
                                <p style={{ fontSize: '10px' }}>{(this.state.meta_keywords == "" || this.state.meta_keywords == null) ? "0" : this.state.meta_keywords.length} characters</p>
                                <div
                                  className="text-danger">{this.state.websitevalidationerrors.meta_keywords}</div>
                              </div>
                              <div className="form-group">
                                <label className="control-labels">Meta Description</label>
                                <textarea id="metadesc" disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                  (role_name != "Super Admin" && role_name != "Admin")} className="h-100 border" rowSpan={4} onChange={this.handleMetaDescription} value={this.state.meta_description} />
                                <p style={{ fontSize: '10px' }}>{(this.state.meta_description == "" || this.state.meta_description == null) ? "0" : this.state.meta_description.length} characters</p>
                                <div
                                  className="text-danger">{this.state.websitevalidationerrors.meta_description}</div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <button onClick={this.handleForWebsiteSubmit} className="btn btn-primary" id="saveForWebsiteCheck">Save</button>
                          </Row>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        {/* DOCUMENTS */}
                        <div className='editdocumentswrap pt-3'>
                          <Row gutter={8}>
                            <Col span={24}>
                              <div className="form-group col-12">
                                <label className="control-labels">Notes</label>
                                <div className="d-flex flex-row" style={{ gap: '0.3rem' }}>
                                  <textarea disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")} onChange={this.handlePropertyNotes} name="notes[]" className="form-control notes" placeholder="Notes" rowSpan={1} value={this.state.property_note} />
                                  <a onClick={this.handlePropertyNotesSubmit} className="btn btn-success btnnotes" id="addobj1" href="javascript:void(0)"><i className="bi bi-plus" /> </a>
                                </div>
                              </div>
                            </Col>
                            <Col span={24}>
                              <table className='table table-bordered'>
                                <thead>
                                  <tr>
                                    <th>Note</th>
                                    <th>Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.document_notes.map((document_note) => {
                                    return (
                                      <tr>
                                        <td> {document_note.note}</td>
                                        <td> {document_note.added_date}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <div className="form-group col-12">
                                <label className="control-labels">Documents</label>
                                {this.state.documents.length > 0 ? <Button onClick={this.handleDownloadAllDocument}>Download documents</Button> : null}


                                <table className="table border-wrap">
                                  <thead>
                                    <tr><th>Title</th>
                                      <th>File</th>
                                      <th>Action</th>
                                    </tr></thead>
                                  <tbody>
                                    <tr>
                                      <td><input disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                        (role_name != "Super Admin" && role_name != "Admin")} onChange={this.handleDocumentTitle} type="text" tabIndex={2} name="doctitle" placeholder="Title" className="form-control input-sm" id="doctitle" /></td>
                                      <td><input disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                        (role_name != "Super Admin" && role_name != "Admin")} onChange={this.handleDocumentFile} type="file" tabIndex={2} name="documents" className="form-control input-sm" accept="image/gif, image/jpeg,application/pdf,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /></td>
                                      <td><button disabled={(role != 1 && this.state.listing_details.agent_id != user_id)} onClick={this.handleDocumentsSubmit} className="btn btn-success" type="submit" href="javascript:void(0)" data-href><i className="bi bi-plus" /></button></td>
                                    </tr>
                                    {this.state.documents.map((document) => {
                                      return (
                                        <tr>
                                          <td> {document.title}</td>
                                          <td>  <a href={(document.document) ? document.document : "#"} target="_blank">
                                            <button className="btn btn-text p-0">
                                              <i className="bi bi-eye-fill" />
                                            </button>
                                          </a> </td>
                                          <td>
                                            <button onClick={this.handleDocumentDelete.bind(this, document)} className="btn btn-text p-0"><i className="bi bi-trash-fill" /></button>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </Col>
                          </Row>

                        </div>
                      </TabPanel>
                      <TabPanel>
                        {/* IMAGES */}
                        <Row>
                          <Col span={24} className="upload-group">
                            {/* <Upload
                                          multiple={true}
                                          accept="image/*"
                                        customRequest={this.uploadImage}
                                        onChange={this.handleOnChange}
                                        onDrop = {this.handleOnChange}
                                          listType="picture-card"
                                          fileList={this.state.fileList}
                                        >
                                      
                                      <div style={{backgroundColor: 'lightgrey',
                                                width: '200px',
                                                border: '1px solid grey',
                                                padding: '50px',
                                                margin: '20px'}}>
                                  Drag Files or Click to Browse
                                </div>
                                        
                                        </Upload> */}

                            <FileUploader
                              disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                (role_name != "Super Admin" && role_name != "Admin")}
                              multiple={true}
                              handleChange={this.handleUploadAllFiles}
                              name="file"
                              types={fileTypes}
                            />
                            {/* <ReactDropzoneUploader
                                                getUploadParams={this.getUploadParams}
                                                onChangeStatus={this.handleChangeStatus}
                                                onSubmit={this.handleUploadImages}
                                                styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
                                            /> */}
                          </Col>
                          <Col span={24} className="row imgupgrp">
                            <SortableContainer
                              updateImages={this.updateImages}
                              role={role}
                              status={this.state.listing_details.status}
                              onUpdate={this.handleSortUpdate}
                              property_images={this.state.property_images}
                              listing_id={this.state.record_id}
                            />
                          </Col>
                        </Row>
                      </TabPanel>
                      <TabPanel>
                        {/* PORTAL */}
                        <Row className='tabportal'>
                          <Col span={24} className='d-flex flex-row'>
                            {this.state.portals && this.state.portals.map((item) => (
                              <div className="input-group col-4 col-sm-6">
                                <div className="input-group-text" key={item.id}>



                                  <input
                                    className="form-check-input mt-0"
                                    type="checkbox"
                                    onChange={this.onChangePortal.bind(this, item)}
                                    checked={this.state.selected_portals_id.includes(item.id)}
                                    readOnly
                                  />
                                  <label className="control-labels"
                                    htmlFor={item.title}>{item.title}</label>
                                </div>
                              </div>
                            ))}
                            {/* {this.state.portals && this.state.portals.map((portal, index) => {
                              if (this.state.selected_portals_id && this.state.selected_portals_id.includes(portal.id)) {
                                var inputProps = {
                                  defaultChecked: 'true'
                                };
                              } else {
                                var inputProps = {};
                              }
                              return (
                                <div className="input-group col-4">
                                  <div className="input-group-text">
                                    <input className="form-check-input mt-0"
                                      type="checkbox"
                                      disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                        (role_name != "Super Admin" && role_name != "Admin")}
                                      defaultValue
                                      onChange={this.onChangePortal.bind(this, portal)}
                                      aria-label="Checkbox for following text input"
                                      id={portal.feedkey}
                                      {...inputProps}
                                    />
                                    <label className="control-labels"
                                      htmlFor={portal.title}>{portal.title}</label>
                                  </div>
                                </div>
                              )
                            })} */}
                          </Col>
                          <Col span={24}>
                            <div className="input-group col-4">
                              <div className="input-group-text">

                                <input className="form-check-input mt-0"
                                  type="checkbox"
                                  disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                                    (role_name != "Super Admin" && role_name != "Admin")}
                                  defaultChecked={this.state.isSameValueCheck}
                                  onChange={this.onChangeSameValue.bind(this, this.state.selected_portals)}
                                  aria-label="Checkbox for using same value"
                                  id="same_value"
                                />
                                <label className="control-labels"
                                  htmlFor="leadgen">Use same value</label>
                              </div>
                            </div>
                          </Col>
                          <Col span={24}>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                              {!this.state.isSameValueCheck && <Tabs>
                                <TabList>
                                  {this.state.selected_portals && this.state.selected_portals.map((selected_portal, index) => {
                                    return (
                                      <Tab>{selected_portal.portal_title}</Tab>
                                    )
                                  })}
                                </TabList>


                                {this.state.selected_portals && this.state.selected_portals.map((selected_portal, index) => {
                                  return (
                                    <TabPanel>
                                      <div className="tab-content p-3"
                                        id="nav-tabContent">
                                        <div className="form-group mb-0">
                                          <div className="d-flex flex-column">
                                            <label
                                              className="control-labels">Title</label>
                                            <input type="text"
                                              className="p-1"
                                              value={selected_portal.property_title}
                                              disabled={(role_name == "Super Admin" || role_name == "Admin" || this.state.listing_status_details == "Y") ? false : true}
                                              placeholder="Add title"
                                              onChange={this.onChangeTitle.bind(this, selected_portal)}
                                            />
                                            <p style={{ fontSize: '10px' }}>{(selected_portal.property_title == "" || selected_portal.property_title == null) ? "0" : selected_portal.property_title.length} characters</p>

                                          </div>
                                        </div>
                                        <div className="form-group mb-0">
                                          <div className="d-flex flex-column">
                                            <label
                                              className="control-labels">Description</label>
                                            <CKEditor
                                              editor={ClassicEditor}
                                              disabled={(role_name == "Super Admin" || role_name == "Admin" || this.state.listing_status_details == "Y") ? false : true}
                                              data={(selected_portal != null) ? selected_portal.property_desc : ""}
                                              onChange={(event, editor) => {
                                                this.state.selected_portals.filter((portal) => {
                                                  if (portal.portals_id === selected_portal.portals_id) {
                                                    this.setState({ selected_portal: portal })
                                                    portal.property_desc = (editor) ? editor.getData() : "";
                                                  }
                                                });
                                              }}
                                            />
                                            <p style={{ fontSize: '10px' }}>{(selected_portal.property_desc == "" || selected_portal.property_desc == null) ? "0" : selected_portal.property_desc.length} characters</p>
                                          </div>
                                        </div>
                                      </div>
                                    </TabPanel>
                                  )
                                })}
                              </Tabs>}
                              {this.state.isSameValueCheck && <Tabs>
                                {this.state.selected_portals &&
                                  <TabPanel>
                                    <div className="tab-content p-3"
                                      id="nav-tabContent">
                                      <div className="form-group mb-0">
                                        <div className="d-flex flex-column">
                                          <label
                                            className="control-labels">Title</label>
                                          <input type="text"
                                            className="p-1"
                                            value={this.state.selected_portals[0].property_title}
                                            disabled={(role_name == "Super Admin" || role_name == "Admin" || this.state.listing_status_details == "Y") ? false : true}
                                            placeholder="Add title"
                                            onChange={this.onChangeTitle.bind(this, this.state.selected_portals[0])}
                                          />
                                          <p style={{ fontSize: '10px' }}>{(this.state.selected_portals[0].property_title == "" || this.state.selected_portals[0].property_title == null) ? "0" : this.state.selected_portals[0].property_title.length} characters</p>

                                        </div>
                                      </div>
                                      <div className="form-group mb-0">
                                        <div className="d-flex flex-column">
                                          <label
                                            className="control-labels">Description</label>
                                          <CKEditor
                                            editor={ClassicEditor}
                                            disabled={(role_name == "Super Admin" || role_name == "Admin" || this.state.listing_status_details == "Y") ? false : true}
                                            data={(this.state.selected_portals[0] != null) ? this.state.selected_portals[0].property_desc : ""}
                                            onChange={(event, editor) => {
                                              this.state.selected_portals.filter((portal) => {
                                                if (portal.portals_id === this.state.selected_portals[0].portals_id) {
                                                  this.setState({ selected_portal: portal })
                                                  portal.property_desc = (editor) ? editor.getData() : "";
                                                }
                                              });
                                            }}
                                          />
                                          <p style={{ fontSize: '10px' }}>{(this.state.selected_portals[0].property_desc == "" || this.state.selected_portals[0].property_desc == null) ? "0" : this.state.selected_portals[0].property_desc.length} characters</p>
                                        </div>
                                      </div>
                                    </div>
                                  </TabPanel>
                                }
                              </Tabs>}

                            </div>

                          </Col>
                          <Col span={24}>
                            <Button id="calllogbtn" type="primary" onClick={this.onSubmit.bind(this, this.state)} >Submit </Button>
                          </Col>
                        </Row>
                      </TabPanel>
                      <TabPanel>
                        <div className="row">
                          <div className="col-12 form-group d-flex flex-column">
                            <label className="control-labels">International</label>
                            <select name="international" disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                              (role_name != "Super Admin" && role_name != "Admin")} id="international" className="form-control" onChange={this.handleInternational} value={this.state.international}>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 form-group d-flex flex-column">
                            <label className="control-labels">Country</label>
                            <select disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                              (role_name != "Super Admin" && role_name != "Admin")} name="intlcountry" id="intlcountry" className="form-control" onChange={this.handleCountry} value={this.state.country}>
                              {this.state.countries_details.map((countries_detail) => {
                                return (
                                  <option value={countries_detail.id}>{countries_detail.name}</option>
                                )
                              })}
                            </select>
                          </div>
                          <div className="col-6 form-group d-flex flex-column">
                            <label className="control-labels">City</label>
                            <select disabled={(this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') &&
                              (role_name != "Super Admin" && role_name != "Admin")} name="intlcity" id="intlcity" className="form-control" onChange={this.handleCity} value={this.state.city}>
                              {this.state.cities_details.map((cities_detail) => {
                                return (
                                  <option value={cities_detail.id}>{cities_detail.name}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <button className="btn btn-primary ml-auto" disabled={((this.state.listing_details.status == 'Y' || this.state.listing_details.status == 'F') || this.state.isInternationDisable === true) &&
                              (role_name != "Super Admin" && role_name != "Admin")} onClick={this.handleInternationalSubmit} >Save</button>
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </Col>
                </Row>

              </Col>
              <Col className='timeline-details' span={this.state.isCollapsed ? 19 : 7} xl={this.state.isCollapsed ? 19 : 7} lg={this.state.isCollapsed ? 19 : 7} md={24} sm={24} xs={24}>

                {window.isIpadView ? '' : <CaretLeftOutlined onClick={this.handleisCollapsed} className='listtimelinehide' />}
                <Row className='mt-4 pt-1'>
                  <Col span={24}><h3 className='px-4'>Timeline</h3></Col>
                  <Col span={24}>
                    <div className='px-4'>
                      <Mentions style={{ width: '100%', }}
                        value={this.state.note_log}
                        onChange={this.onChangeNoteNote}
                        onSelect={this.onSelectNoteMentions}
                        options={this.state.userClients}
                        rows={2}
                        placeholder='Add note (Type @ to tag someone)'
                      />
                      {
                        this.state.note_loader ?
                          <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', marginTop: '1rem' }} >
                            <Spin style={{ alignSelf: 'center' }} />
                          </div>
                          :
                          <div className='d-flex flex-row justify-content-center mx-auto'>
                            <Button type="secondary" id="logsbtn" onClick={this.handleNoteSubmit} className="d-flex flex-row mt-2"><i className="bi bi-plus me-1 fs-5" />Add note</Button>
                            <Button id="calllogbtn" type="primary" onClick={this.handleCallLogModal} className="d-flex flex-row align-items-center mt-2 mb-3 ml-2"><i className="bi bi-plus me-1 fs-5" /> <span className='font-12'>Add Call</span></Button>
                          </div>
                      }
                    </div>
                  </Col>
                  <Col span={24} className='filteractivity px-3'>
                    <p className='font-bold'>Activity Log</p>
                    {/* <Button type='text'>
                                <i class="bi bi-funnel"></i>
                                <span>Filter Activity</span>
                            </Button> */}
                  </Col>
                  <Col span={24} className='timelinelist'>
                    <Timeline>
                      {this.state.all.length > 0 ?
                        this.state.all.map((al) => {
                          return (<>
                            {al.note_type == "phone" || al.note_type == "whatsapp" ?
                              <Timeline.Item dot={
                                al.note_type == "phone" ? <i class="bi bi-telephone-fill"></i>
                                  : al.note_type == "email" ? <i class="bi bi-envelope-fill"></i>
                                    : al.note_type == "whatsapp" ? <i class="bi bi-whatsapp"></i>
                                      : null
                              } className={"time" + al.note_type}>
                                <div className='d-flex flex-column'>
                                  <div className='d-flex flex-row justify-content-between'>
                                    <div className='d-flex flex-row align-items-start' style={{ gap: '0.3rem' }}>
                                      <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                      <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                        {al.note_type == "phone" ? " logged a call" : null}
                                        {al.note_type == "email" ? " sent an email" : null}
                                        {al.note_type == "whatsapp" ? "" : null}
                                        {al.note_type == "note" ?
                                          al.note && al.note.includes("Criteria") ? " used a custom match" : " added a note"
                                          : null}
                                      </p>
                                    </div>
                                    <div className='align-content-center'>
                                      <p className="atime">
                                        <Moment fromNow>
                                          {/*{this.getCurrentTime(al.time)}*/}
                                          {(al.time)}
                                        </Moment>
                                      </p>
                                    </div>
                                  </div>
                                  <div className='d-flex adesc'>
                                    {al.note && al.note.includes("Criteria") ?
                                      <>
                                        <p className='font-bold'>Matched using the following criteria:</p>
                                        <p>{al.note.split("<br>").slice(1).map((matchnote) => (
                                          <>
                                            <p>{matchnote}</p>
                                          </>
                                        ))
                                        }</p>
                                      </>
                                      : <>
                                        <p className='font-12'><strong className='mr-1 font-12'>Price:</strong>{al.confirm_price && al.confirm_price > 0 ? al.confirm_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'AED' : 0}</p>
                                        <p className='font-12'><strong className='mr-1 font-12'>Available:</strong>{al.isPropertyAvailable}</p>
                                        <p className='font-12'><strong className='mr-1 font-12'>Discuss with vendor:</strong>{al.call_log_discuss}</p>
                                        <p className='font-12'>{al.note}</p>
                                      </>}
                                  </div>
                                </div>
                              </Timeline.Item>
                              : al.note_type == "note" ?
                                <Timeline.Item dot={<i class="bi bi-pin-angle-fill"></i>} className={"time" + al.note_type}>
                                  <div className='d-flex flex-column'>
                                    <div className='d-flex flex-row justify-content-between'>
                                      <div className='d-flex flex-row align-items-start' style={{ gap: '0.3rem' }}>
                                        <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                        <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                          {al.note_type == "phone" ? " logged a call" : null}
                                          {al.note_type == "email" ? " sent an email" : null}
                                          {al.note_type == "whatsapp" ? "" : null}
                                          {al.note_type == "note" ?
                                            al.note && al.note.includes("Criteria") ? " used a custom match" : " added a note"
                                            : null}
                                        </p>
                                      </div>
                                      <div className='align-content-center'>
                                        <p className="atime">
                                          <Moment fromNow>
                                            {/*{this.getCurrentTime(al.time)}*/}
                                            {(al.time)}
                                          </Moment>
                                        </p>
                                      </div>
                                    </div>
                                    <div className='d-flex adesc'>
                                      {al.note && al.note.includes("Criteria") ?
                                        <>
                                          <p className='font-bold'>Matched using the following criteria:</p>
                                          <p>{al.note.split("<br>").slice(1).map((matchnote) => (
                                            <>
                                              <p>{matchnote}</p>
                                            </>
                                          ))
                                          }</p>
                                        </>
                                        : <>
                                          <p className='font-12'>{al.note && (this.isHTML(al.note) ? parse(al.note) : al.note)}</p>
                                        </>}
                                    </div>
                                  </div>
                                </Timeline.Item>
                                // viewing note
                                : al.note_type == "viewing" ?
                                  <Timeline.Item dot={<i class="bi bi-eye-fill"></i>} className={"time" + al.note_type}>
                                    <div className='d-flex flex-column'>
                                      <div className='d-flex flex-row justify-content-between'>
                                        <div className='d-flex flex-row align-items-start' style={{ gap: '0.3rem' }}>
                                          <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                          <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                            {al.note_type == "viewing" ? " booked a viewing" : null}
                                          </p>
                                        </div>
                                        <div className='align-content-center'>
                                          <p className="atime">
                                            <Moment fromNow>
                                              {/*{this.getCurrentTime(al.time)}*/}
                                              {(al.time)}
                                            </Moment>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="adesc">
                                        <p className='font-12'><strong className='mr-1 font-12'>Date:</strong>{al.view_date ? al.view_date : "-"}</p>
                                        <p className='font-12'><strong className='mr-1 font-12'>Note:</strong>{al.note}</p>
                                      </div>
                                    </div>
                                  </Timeline.Item>
                                  // viewing note
                                  : al.note_type == "offer" ?
                                    <Timeline.Item dot={<i class="bi bi-tag-fill"></i>} className={"time" + al.note_type}>
                                      <div className='d-flex flex-column'>
                                        <div className='d-flex flex-row justify-content-between'>
                                          <div className='d-flex flex-row align-items-start' style={{ gap: '0.3rem' }}>
                                            <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                            <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                              {al.note && al.note.includes("Viewing status") ? <span> updated the viewing status</span>
                                                : al.note && al.note.includes("Offer status") ? <span> updated the offer status</span>
                                                  : null}
                                            </p>
                                          </div>
                                          <div className='align-content-center'>
                                            <p className="atime">
                                              <Moment fromNow>{this.getCurrentTime(al.time)}</Moment>
                                            </p>
                                          </div>
                                        </div>
                                        <div className='d-flex adesc'>
                                          <p className='font-12'>
                                            {al.note}
                                          </p>
                                        </div>
                                        <div className="d-flex adesc-stat mt-1">
                                          {al.note && al.note.includes("Viewing status changed to Offer") ? <>
                                            <Tag className='pstat viewing'>Viewing</Tag>
                                            <i class="bi bi-arrow-right"></i>
                                            <Tag className='pstat offer'>Offer</Tag>
                                          </> : al.note && al.note.includes("Not Interested") ? <>
                                            <Tag className='pstat viewing'>Viewing</Tag>
                                            <i class="bi bi-arrow-right"></i>
                                            <Tag className='pstat cancelled'>Not Interested</Tag>
                                          </> : al.note && al.note.includes("Offer Sent") ? <>
                                            <Tag className='pstat offer'>Offer</Tag>
                                            <i class="bi bi-arrow-right"></i>
                                            <Tag className='pstat offer'>Offer Sent</Tag>
                                          </> : al.note && al.note.includes("Offer Accepted") ? <>
                                            <Tag className='pstat offer'>Offer Sent</Tag>
                                            <i class="bi bi-arrow-right"></i>
                                            <Tag className='pstat secondviewing'>Offer Accepted</Tag>
                                          </> : al.note && al.note.includes("Offer Rejected") ? <>
                                            <Tag className='pstat offer'>Offer</Tag>
                                            <i class="bi bi-arrow-right"></i>
                                            <Tag className='pstat scheduled'>Offer Rejected</Tag>
                                          </> : al.note && al.note.includes("Deal Signed") ? <>
                                            <Tag className='pstat offer'>Offer</Tag>
                                            <i class="bi bi-arrow-right"></i>
                                            <Tag className='pstat deal'>Deal Signed</Tag>
                                          </> : null}
                                        </div>
                                      </div>
                                    </Timeline.Item>
                                    : al.note_type == 'req_view' ?
                                      <Timeline.Item dot={<RiseOutlined />} className={"time" + al.note_type}>
                                        <div className='d-flex flex-column'>
                                          <div className='d-flex flex-row justify-content-between'>
                                            <div className='d-flex flex-row align-items-start' style={{ gap: '0.3rem' }}>
                                              <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                              <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                                <span> requested to share this property with the following leads.</span></p>
                                            </div>
                                            <div className='align-content-center'>
                                              <p className="atime">
                                                <Moment fromNow>{this.getCurrentTime(al.time)}</Moment>
                                              </p>
                                            </div>
                                          </div>
                                          <div className='d-flex adesc'>
                                            <p className='font-12'>
                                              {al.leads_user.map((lead_id, index) => (
                                                <>
                                                  <Tag><a key={index} href={liveUrl + "/leads/" + lead_id} target="_blank">
                                                    {lead_id}
                                                  </a></Tag>
                                                </>
                                              ))}
                                            </p>
                                          </div>
                                        </div>
                                      </Timeline.Item>
                                      : al.note_type == 'followup' ?
                                        <Timeline.Item dot={<i class="bi bi-calendar"></i>} className={"time" + al.note_type}>
                                          <div className='d-flex flex-column'>
                                            <div className='d-flex flex-row justify-content-between'>
                                              <div className='d-flex flex-row align-items-start' style={{ gap: '0.3rem' }}>
                                                <Avatar className='d-flex mt-1' size={25} icon={<img src={al.profile_photo == null || al.profile_photo == "" ? baseurlImg + "lpcrm/public/uploads/clients/2036/users/LPDubai11.jpg" : this.isValidHttpUrl(al.profile_photo) ? al.profile_photo : baseurlImg + "lpcrm/public/uploads/clients/2036/users/" + al.profile_photo} />} />
                                                <p className="awriter"><span className="font-bold">{al.by_user_name}</span>
                                                  <span> scheduled a follow up</span></p>
                                              </div>
                                              <div className='align-content-center'>
                                                <p className="atime">
                                                  <Moment fromNow>{this.getCurrentTime(al.time)}</Moment>
                                                </p>
                                              </div>
                                            </div>
                                            <div className='d-flex adesc'>
                                              <Row className='d-flex flex-row w-100'>
                                                <Col span={6}>
                                                  <p className='font-10'>Date / Time :</p>
                                                  <p><Moment format={"hh:mm A"} className='font-bold font-16'>{al.time}</Moment></p>
                                                  <p><Moment format={"DD MMMM YYYY"}>{al.time}</Moment></p>
                                                </Col>
                                                <Col span={18}>
                                                  <p>Note:</p>
                                                  <p className='font-12'>{al.note}</p>
                                                </Col>
                                              </Row>
                                            </div>
                                          </div>
                                        </Timeline.Item>
                                        : null}
                          </>)
                        })
                        :
                        <Empty description={<>
                          <p className='font-bold font-16 mb-1'>No Activity Found</p>
                          <p>It seems, no activity happened yet.</p>
                        </>} />
                      }
                    </Timeline>
                  </Col>
                </Row>
              </Col>

            </Row>
          </div>

        </Drawer>

        <div className='contentwrap' style={{

          minHeight: 380,
        }}>
          <Tabs style={{ color: '#8D949C', width: "100%" }} hideAdd type="editable-card" items={this.state.filters} onChange={this.handleTabs} onEdit={this.handleDeleteFilter} />

          {/* <Tabs style={{color:'#8D949C',width: "100%"}}  hideAdd type="editable-card" defaultActiveKey="1"  onChange={this.handleTabs}   onEdit={this.handleDeleteFilter}>
{this.state.filters
 .map((key) => (

   <TabPane
     tab={
       <span>
        <EditOutlined onClick={this.handleEditFilter.bind(this,key.key)}/>
         {key.label}


       </span>
     }
     key={key.key}
   >

   </TabPane>
 ))
   }
</Tabs> */}
          {this.state.filters.length > 0 ?
            <Tabs >
              <TabList>
                {this.state.filters.length > 0 ? <Tab onClick={this.handleClearFilter}>All Listing</Tab> : null}

                {this.state.filters.map((filter) => (
                  <>

                    <Tab><span onClick={this.handleTabs.bind(this, filter.key)} className="mr-3">{filter.label}</span><CloseOutlined onClick={this.handleDeleteFilter.bind(this, filter.key)} style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '12px', verticalAlign: 'middle' }} /></Tab>

                  </>
                ))}

              </TabList>


            </Tabs>
            : <div style={{ height: 15 }}></div>
          }
          <div className='ml-auto d-flex px-0'>
            <div className='row px-3 m-0 w-100'>
              <div className={`d-flex ${window.isMobileView ? 'flex-column' : 'flex-row'}  lex-wrap justify-content-between mt-1 mb-3 p-0`}>
                {window.isIpadView ? <>
                  <div className='viewas-wrap'>
                    <Row span={24}>
                      <Col span={24} >
                        <Button type='text' onClick={this.handleFilterDrawerOpen}><FilterOutlined />Advanced Search</Button>
                      </Col>
                    </Row>
                  </div>

                  <Drawer
                    title="Advanced Search"
                    placement="left"
                    width={window.isMobileView ? "100%" : "50%"}
                    onClose={this.handleFilterDrawerClose}
                    open={this.state.FilterDrawerOpen}
                    closeIcon={this.false}
                    extra={
                      <Space>
                        <Button onClick={this.handleFilterDrawerClose}>Cancel</Button>
                      </Space>
                    }
                  >
                    {advancedFilters}
                  </Drawer>
                </>
                  :
                  <div className='viewas-wrap'>
                    {tableGallary}
                  </div>
                }
                <div className={`d-flex ${window.isMobileView? 'flex-wrap' : ''} ${window.isMobileView? 'gap-2' : ''} ${window.isMobileView? 'justify-content-center' : 'justify-content-between'}flex-row  ml-auto topbtnswrap`}>
                  <Button type='primary' className="sendtowhatsapp ml-2 font-12" onClick={this.handleWhatsappOpen} icon={<WhatsAppOutlined />}>Send to WhatsApp</Button>
                  <Button type='primary' className="addfilter ml-2" onClick={this.handleNewFilter} icon={<PlusOutlined />}>Add new filter</Button>
                  {window.isIpadView ? '' : (resetFilterButton)}
                  <Button className='ml-2 addnewlisting' type='primary' onClick={this.handleOpenDrawer} icon={<PlusCircleOutlined />}>Add new listing</Button>
                </div>
              </div>
            </div>
          </div>
          <Row className='px-3'>
            <Col span={24}>
              <div className='searchbar w-100 form-group d-flex flex-row align-items-center'>
                <label className='font-bold mr-2 mb-0'>Smart Search: </label>
                <Input type="text" value={this.state.global_search} onChange={this.handleGlobalSearch} placeholder='Search' allowClear style={{ height: '36px', maxWidth: '400px', borderRadius: '0' }} />
              </div>
            </Col>
          </Row>

          <Row className='px-3'>
            <Col span={24} className='aisearchwrap'>
              <div className='searchbar w-100 form-group d-flex flex-row align-items-center'>
                <label className='font-bold mr-2 mb-0'>AI Search: </label>
                <div onKeyDown={this.handleEnterKeyPress}>
                  <AutocompleteReact className="aisearchinput w-100"
                    items={this.state.smartSearchOptions.map((option) => ({ value: option.value }))}
                    shouldItemRender={(item, value) => item.value.toLowerCase().includes(value.toLowerCase())}
                    getItemValue={(item) => item.value}
                    renderItem={(item, isHighlighted) => (
                      <div className="itemlabel" style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                        {item.value}
                      </div>
                    )}
                    value={this.state.smartSearchValue}
                    onChange={this.onChangeSmartSearch}
                    onSelect={this.onSelectSmartSearch}
                    {...this.menuStyle}
                  />
                </div>
              </div>
            </Col>
          </Row>



          {this.state.isViewAsGallery ? <>
            {window.isIpadView ? '' :
              <Collapse className='moreoptions px-3 w-100'>
                <Panel header={<p className='mr-2 mb-0 font-12 font-bold'>Advanced Search</p>} key="1">
                  {advancedFilters}

                </Panel>
              </Collapse>

            }
            <div className='listingfilter-second'>
              <Row span={24}>
                <Col span={24}>
                  {/* Status */}
                  <div className='d-flex flex-row align-items-end form-group formcheckboxgroup'>
                    <div className="d-flex flex-column align-items-start "> {/* 11-23-2023 */}

                      {window.isIpadView ? <>
                        <Collapse className={window.isIpadView ? 'moreoptions px-3 w-100' : 'moreoptions w-100'}>
                          <Panel header={<p className='mr-1 mb-0 font-10 font-bold' 
                          style={{ whiteSpace: "nowrap" }}>Refine</p>} key="2">
                            {/* {!this.state.isViewingTabShow && !this.state.isOfferTabShow?<> */}

                            {refineFilters}
                          </Panel>
                        </Collapse> </> :
                        <div className='d-flex flex-row flex-wrap  align-items-center'>
                          <p className='mr-2 mb-0 font-12 font-bold'>Refine: </p>
                          {refineFilters}
                        </div>
                      }
                      {window.isIpadView ? <>
                        <Collapse className={window.isIpadView ? 'moreoptions px-3 w-100' : 'moreoptions w-100'}>
                          <Panel header={<p className='mr-1 mb-0 font-10 font-bold' style={{ whiteSpace: "nowrap" }}>Status</p>} key="3">
                            {/* {!this.state.isViewingTabShow && !this.state.isOfferTabShow?<> */}

                            {statusFilters}
                          </Panel>
                        </Collapse> </> :
                        <div className='d-flex flex-row flex-wrap  align-items-center'>
                          <p className='mr-2 mb-0 font-12 font-bold'>Status:</p>
                          {statusFilters}
                        </div>
                      }
                    </div>
                    <div className='d-flex flex-column justify-content-end'>
                      {window.isIpadView ? <>
                        <Collapse className={window.isIpadView ? 'moreoptions px-3 w-100' : 'moreoptions w-100'}>
                          <Panel header={<p className='mr-1 mb-0 font-10 font-bold' style={{ whiteSpace: "nowrap" }}>Filter by Referral</p>} key="4">
                            {/* {!this.state.isViewingTabShow && !this.state.isOfferTabShow?<> */}

                            {filterByReferral}
                          </Panel>
                        </Collapse> </> :
                        <div className='d-flex flex-row mb-2'>
                          <p className='titlefilter font-bold mb-0' style={{ fontSize: '0.73rem' }}>Filter by Referral:</p>
                          {filterByReferral}
                        </div>
                      }
                      {window.isIpadView ? <>
                        <Collapse className={window.isIpadView ? 'moreoptions px-3 w-100' : 'moreoptions w-100'}>
                          <Panel header={<p className='mr-1 mb-0 font-10 font-bold' style={{ whiteSpace: "nowrap" }}>Filter by</p>} key="5">
                            <div className='countsfilterwrap ml-auto flex-wrap'>

                              {filterBy}
                            </div>
                          </Panel>
                        </Collapse> </> :
                        <div className='countsfilterwrap ml-auto'>
                          <p className='titlefilter font-bold'>Filter by:</p>
                          {/* Leads filter */}
                          {filterBy}
                        </div>
                      }
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="table-responsive tablelistwrap px-3 py-2 tblistingwrap" >

              <ReactDragListView.DragColumn onDragEnd={this.onDragEnd}
                nodeSelector="th">

                <Table onChange={handleTableChange}
loading={this.state.isLoading ?
  { indicator: <Spin size="large" style={{ color: '#151515' }} /> } : false
}
                  pagination={{
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total:
                      this.state?.mylistings?.total > 0 &&
                      this.state.mylistings.total,
                    pageSize: this.state.mylistings.per_page,
                    current: this.state.mylistings.current_page,
                    showTotal: (total, range) =>
                      window.isMobileView ? '' : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  style={{ overflowX: window.isTabView ? '' : "auto" }}
                  columns={window.isTabView ? allListingColumns :columns}
                  showHeader={false}
                  dataSource={
                    this.state?.mylistings?.data
                      ? this.state?.mylistings?.data
                      : []
                  }

                  // rowKey={(record) => record}
                  // Farooq
                  rowKey={(record) => record.id}
                />
              </ReactDragListView.DragColumn>

            </div>

          </> : <>
            {/* TABLE VIEW */}
            <div className="table-responsive tablelistwrap px-3 py-2" >
              <ReactDragListView.DragColumn onDragEnd={this.onDragEnd}
                nodeSelector="th">

                <Table onChange={handleTableChange}


                  loading={this.state.isLoading}
                  rowSelection={rowSelection}
                  //className="table-striped table-bordered"
                  pagination={{
                    pageSizeOptions: ['10', '20', '50', '100'],
                    total:
                      this.state?.mylistings?.total > 0 &&
                      this.state.mylistings.total,
                    pageSize: this.state.mylistings.per_page,
                    current: this.state.mylistings.current_page,
                    showTotal: (total, range) =>
                      window.isMobileView ? '' : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  style={{ overflowX: "auto" }}
                  columns={window.isTabView ? allListingColumns :columnsTable}

                  dataSource={
                    this.state?.mylistings?.data
                      ? this.state?.mylistings?.data
                      : []
                  }

                  expandable={{
                    expandedRowRender: (record) =>
                      <div className='listingdropinfo'>
                        <div className='d-flex flex-row'>
                          <Carousel style={{ width: '400px' }} autoplay>
                            {record.property_images != '' ? this.getImageArray(record.property_images).map((image) => {
                              return <img src={baseurlImg + "/" + image} style={{ width: '550px', height: '375px' }} />
                            }) : <img src="https://www1.luxuryproperty.com/uploads/images/550x375/5-bedroom-villa-for-sale-golf_place-LP16243-1cb6c6be042b5200.jpg" style={{ width: '550px', height: '375px' }} />}
                          </Carousel>
                        </div>
                        <div className='listdropinfo-info'>
                          {(record.unitno) &&
                            <p className="font-bold fs-6 mb-0">{"Unit no: " + record.unitno}</p>
                          }

                          {(record.unitno) &&
                            <br />
                          }
                          <p className="font-bold fs-6 mb-0">
                            {record.property == null ? "" : record.property + ', '}
                            {record.subcommunity == null ? "" : record.subcommunity + ', '}
                            {record.community == null ? "" : record.community}
                          </p>
                          <p> {!isNaN(parseInt(record.price)) ? record.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED' : ""}</p>
                          <p className='d-flex font-12'>{record.desc == null ? "" : record.desc}</p>
                          <Link className="btn-link" onClick={this.handleOpenDrawerToEdit.bind(this, record.id, false)}>Read More</Link>
                        </div>
                      </div>
                  }}


                  // rowKey={(record) => record}
                  // Farooq
                  rowKey={(record) => record.id}
                />
              </ReactDragListView.DragColumn>

            </div>
          </>}
        </div>
      </div>

    )
  }
}


const mapDispatchToProps = dispatch => {
  return {
    setNoMatch: (data) => dispatch({ type: "SET_RESTRICT_LEAD", payload: data }),
    setMatch: (data) => dispatch({ type: "SET_ACCESS_LEAD", payload: data }),
    removeListingReducer: () => dispatch({ type: "REMOVE_LISTING_STATISTICS", payload: false }),
    removeStockReducer: () => dispatch({ type: "REMOVE_STOCK_REDUCER", payload: false }),


  }
}

export default connect(null, mapDispatchToProps)(ArchiveListings)

// export default  AllListings;
