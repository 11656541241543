import React, { Component, createRef } from 'react';
import grapesjs from 'grapesjs';
import gswebpage from 'grapesjs-preset-webpage';
import 'grapesjs/dist/css/grapes.min.css';
import './blogbuilder.css'
import { ToastContainer, toast } from 'react-toastify';
import API from '../../../utils/API';

class BlogBuilder extends Component {
    constructor(props) {
      super(props);
      this.editorRef = createRef();
      this.editor = null;
    }
    
    uploadImage = async (file) => {
        if (!file) {
            console.error("No file provided to uploadImage");
            return null;
        }
        try {
            const formData = new FormData();
            // Use 'image' as the field name instead of 'file'
            formData.append('image', file);
    
            const response = await API.post("/upload_image", formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
    
            if (response.data && response.data.image_url) {
                toast.success("Image uploaded successfully:", response.data.image_url);
                const image = {
                    type: 'image',
                    src: "https://www1.luxuryproperty.com/" + response.data.image_url,
                };
                this.editor.AssetManager.add([image]); // Add the image to the asset manager
                return "https://www1.luxuryproperty.com/" +response.data.image_url;
            } else {
                console.error('Upload failed or missing URL in response');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.error("Error uploading image");
        }
        return null;
    };
    

    triggerUploadImage = () => {
        // Open asset manager for uploading image
        this.editor.runCommand('open-assets', {
            target: this.editor.getSelected(),
            types: ['image'],
        });
    };

  componentDidMount() {
    this.editor = grapesjs.init({
        container: '#gjs',
        fontFamily:'"Proxima Nova", sans-serif',
        height: '100vh',
        width: '100%',
        assetManager: {
            upload: false, // Disable default upload
            // uploadFile: async (files) => {
            //     console.log("Files received:", files);
            // },
            base64: false,
            uploadFile: async (files) => {
                if (!files || files.length === 0) {
                    console.error("No files provided for upload");
                    return;
                }
                const file = files.target.files[0];                 
                if (!file) {
                    console.error("No valid file found in files array");
                    return;
                }
                const url = await this.uploadImage(file);
                if (url) {
                    // If the upload is successful, add the image to the asset manager
                    this.editor.AssetManager.add({
                        type: 'image',
                        src: url,
                    });
                }
            },
            uploadResponse: (res) => res.data.url
        }, 
        assets: [],
        selectorManager: { componentFirst: true },
        canvas: {
            styles: [ 'https://fonts.googleapis.com/css2?family=Proxima+Nova:wght@400;600;700&display=swap',],
        },
        styleManager: {
            
          },
        plugins: [gswebpage, 'gjs-blocks-basic', 'grapesjs-plugin-forms'],
        pluginsOpts: { 'gjs-blocks-basic': { flexGrid: true } },
      });

      if (this.editor) {
        // Add custom blocks after editor initializes
        const blockManager = this.editor.BlockManager;
        this.addCustomBlocks(blockManager);

        // Listen for component selection
        this.editor.on('component:selected', (component) => {
            if (component && component.get('type') === 'image') {
                this.triggerUploadImage();
            }
        });

        // Listen for asset addition to assign to selected component
        this.editor.on('asset:add', (asset) => {
            const selected = this.editor.getSelected();
            if (selected && asset.get('type') === 'image') {
                selected.set({ src: asset.get('src') });
            }
        });
    }

    const blockManager = this.editor.BlockManager;
    this.addCustomBlocks(blockManager);

    this.getContent();
    this.addCustomSettingsPanel();
    }
    
    addCustomBlocks(blockManager) {
        // 1 Column
        // blockManager.add('1-column', {
        //     label: '1 Column',
        //     content: `
        //        <div class="row my-custom-class" style="min-height: 150px; padding: 10px; border: 1px dashed #ccc;">
        //         <div class="col inner-content" data-gjs-droppable="true" style="min-height: 100px;">
                   
        //         </div>
        //     </div>
        //         `,
        //     category: 'Layout',
        //     media: '<i class="bi bi-square"></i>',
        // });

        // 2 Columns
        // blockManager.add('2-columns', {
        //     label: '2 Columns',
        //     content: `
        //     <div class="row" style="display:flex;">
        //         <div class="col-6" style="flex:0 0 50%; max-width:50%;" data-gjs-droppable="true">Column 1</div>
        //         <div class="col-6" style="flex:0 0 50%; max-width:50%;" data-gjs-droppable="true">Column 2</div>
        //     </div>
        //     `,
        //     category: 'Layout',
        //     media: '<i class="bi bi-layout-split"></i>', // Bootstrap icon
        // });

        // 3 Columns
        // blockManager.add('3-columns', {
        //     label: '3 Columns',
        //     content: `
        //     <div class="row" style="display:flex;">
        //         <div class="col-4" style="flex:0 0 33.33%; max-width:33.33%;" data-gjs-droppable="true">Column 1</div>
        //         <div class="col-4" style="flex:0 0 33.33%; max-width:33.33%;" data-gjs-droppable="true">Column 2</div>
        //         <div class="col-4" style="flex:0 0 33.33%; max-width:33.33%;" data-gjs-droppable="true">Column 3</div>
        //     </div>
        //     `,
        //     category: 'Layout',
        //     media: '<i class="bi bi-layout-three-columns"></i>', // Bootstrap icon
        // });

        // 2 Columns 3/7
        // blockManager.add('2-columns-3-7', {
        //     label: '2 Columns (3/7)',
        //     content: `
        //     <div class="row" style="display:flex;">
        //         <div class="col-6" style="flex:0 0 66.66%; max-width:66.66%;" data-gjs-droppable="true">Column 1</div>
        //         <div class="col-6" style="flex:0 0 33.33%; max-width:33.33%;" data-gjs-droppable="true">Column 2</div>
        //     </div>
        //     `,
        //     category: 'Layout',
        //     media: '<i class="bi bi-layout-sidebar"></i>', // Bootstrap icon
        // });

        blockManager.add('text', {
        label: 'Text',
        content: '<p>This is a text block</p>',
        category: 'Basic',
        media: '<i class="bi bi-fonts"></i>',
        style: {
            'font-family': '"Proxima Nova", sans-serif',
            'font-size': '16px',
            'color': '#000'
        }
        });

        blockManager.add('image', {
            label: 'Image',
            category: 'Basic',
            media: '<i class="bi bi-card-image"></i>',
            content: {
                type: 'image',
                editable: true,
                droppable: false,
            }
        });

        blockManager.add('button', {
            label: 'Button',
            content: '<button class="btn btn-primary">Click Me</button>',
            category: 'Basic',
            media: '<i class="bi bi-usb"></i>'
        });
    
        blockManager.add('video', {
            label: 'Video',
            content: '<video controls src="https://www.w3schools.com/html/mov_bbb.mp4"></video>',
            category: 'Basic',
            media: '<i class="bi bi-youtube"></i>',
        });
        blockManager.add('link', {
            label: 'Link',
            content: '<a href="#">This is a link</a>',
            category: 'Basic',
            media:'<i class="bi bi-link"></i>'
        });
    
        blockManager.add('card', {
            label: 'Card',
            content: `
            <div class="card" style="width: 18rem; border-radius:5px; border:1px solid rgba(21,21,21,0.16)">
                <img src="https://via.placeholder.com/150" class="card-img-top" alt="...">
                <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">Some quick example text to build on the card title.</p>
                <a href="#" class="btn btn-primary">Go somewhere</a>
                </div>
            </div>
            `,
            category: 'Basic',
            media: '<i class="bi bi-card-heading"></i>'
        });
    
        blockManager.add('list-group', {
            label: 'List Group',
            content: `
            <ul class="list-group">
                <li class="list-group-item">Cras justo odio</li>
                <li class="list-group-item">Dapibus ac facilisis in</li>
                <li class="list-group-item">Morbi leo risus</li>
                <li class="list-group-item">Porta ac consectetur ac</li>
            </ul>
            `,
            category: 'Basic',
            media: '<i class="bi bi-list-ul"></i>'
        });

        // Adding H1 to H6 heading blocks
        for (let i = 2; i <= 6; i++) {
            blockManager.add(`heading-h${i}`, {
            label: `H${i}`, // The label for the block
            category: 'Typography', // Group it under the 'Typography' category
            media: `<i class="bi bi-type-h${i}"></i>`, // Icon for the block
            content: `<h${i}>This is an H${i} heading</h${i}>`, // The HTML content
            });
        }

        // Make the column droppable and restrict components
        // this.editor.BlockManager.get('1-column').set({ droppable: true });

        // Restrict types of components
        // this.editor.DomComponents.addType('inner-content', {
        //     isComponent: (el) => el.classList && el.classList.contains('inner-content'),
        //     model: {
        //         defaults: {
        //             tagName: 'div',
        //             draggable: true,
        //             droppable: true,
        //             editable: true,
        //             attributes: { class: 'inner-content' },
        //             // Add any other default properties here
        //         },
        //     },
        // });

        this.editor.DomComponents.addType('text', {
            isComponent: (el) => el.tagName === 'p' && el.innerText === 'This is a text block',
            model: {
                defaults: {
                    tagName: 'p',
                    editable: true,
                    droppable: false,
                    traits: [
                        {type: 'text', label: 'Text Content', name: 'content',changeProp: 1},
                        { type: 'text', label: 'Font Size', name: 'font-size',changeProp: 1},
                        {type: 'color',label: 'Text Color', name: 'color',changeProp: 1 }
                    ],
                    style: {
                        'font-family': '"Proxima Nova", sans-serif', 'color': '#151515','line-height':'1.8' }
                }
            }
        });

        // Clear the content if on /addblog page
        if (window.location.pathname.includes('/add')) {
            this.editor.setComponents('');
            this.editor.setStyle('');
        } else {
            this.getContent();
        }

        this.editor.on('load', () => {
            const iframe = this.editor.Canvas.getFrameEl();
            const head = iframe.contentDocument.head;
            
            // Append the font stylesheet to the head of the iframe
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = 'https://fonts.googleapis.com/css2?family=Proxima+Nova:wght@400;600;700&display=swap';
            head.appendChild(link);
    
            // Apply the font-family to the entire content of the iframe
            const style = document.createElement('style');
            style.innerHTML = `
                * { font-family: 'Proxima Nova', sans-serif; line-height:1.8 }
                body { padding:1rem}
                img {object-fit:cover; max-height:27rem; width:100%}
                a {font-family: 'Proxima Nova Semibold', sans-serif !important; color:#151515  }
                h1,h2,h3,h4,h5,h6 {font-family:'Proxima Nova Bold', sans-serif !important; color:#151515}
                h1{font-size:3.5rem} h2{font-size:2.5rem}
                h3 {font-size:1.5rem}
                `;
            head.appendChild(style);
        });

        this.getContent()
    }

    addCustomSettingsPanel() {
        // Create the custom settings panel using plain JavaScript
        const traitsSector = document.createElement('div');
        traitsSector.className = 'gjs-sm-sector no-select';
        traitsSector.innerHTML = `
            <div class="gjs-sm-sector-title">
                <span class="icon-settings fa fa-cog"></span> 
                <span class="gjs-sm-sector-label">Settings</span>
            </div>
            <div class="gjs-sm-properties" style="display: none;"></div>
        `;
    
        // Append the '.gjs-traits-cs' content to the '.gjs-sm-properties' div
        const traitsProps = traitsSector.querySelector('.gjs-sm-properties');
        const traitsCs = document.querySelector('.gjs-traits-cs');
        if (traitsCs) traitsProps.appendChild(traitsCs);
    
        // Insert the custom panel before the first `.gjs-sm-sectors` element
        const sectors = document.querySelector('.gjs-sm-sectors');
        if (sectors) sectors.parentNode.insertBefore(traitsSector, sectors);
    
        // Toggle display on title click
        const title = traitsSector.querySelector('.gjs-sm-sector-title');
        title.addEventListener('click', () => {
            traitsProps.style.display = traitsProps.style.display === 'none' ? 'block' : 'none';
        });
    }

    getContent() {
        if (this.editor) {
          // Retrieve the current HTML and CSS from the editor
          const html = this.editor.getHtml(); // Replace `getHtml` with your editor's method to get the HTML
          const css = this.editor.getCss();   // Replace `getCss` with your editor's method to get the CSS
      
          return { html, css };
        }
        return { html: '', css: '' }; // Fallback in case the editor is not initialized
      }
      
    
      componentDidUpdate(prevProps) {
        const { blogContent } = this.props;        
    
        // Check if the pathname is not `/addblog`, and blogContent has changed
        if (blogContent && window.location.pathname !== '/addblog') {
            if (prevProps.blogContent !== blogContent) {
                // Ensure photo URLs are retained as-is (no conversion to base64)
                const components = blogContent.html || '';
                const styles = blogContent.css || '';
    
                // You can customize the logic for handling image URLs if needed
                // For example, finding and processing image URLs in `components` if needed
                this.editor.setComponents(components);
                this.editor.setStyle(styles);
            }
        } 
        // Optional: clear editor only if pathname is `/addblog` and blogContent was cleared
        else if (window.location.pathname !== '/addblog' && !blogContent) {
            this.editor.setComponents('');
            this.editor.setStyle('');
        }
    }
    

    componentWillUnmount() {
        if (this.editor) {
        this.editor.destroy();
        }
    }

    render() {
        return <div id="gjs" className='blogcontent border'><ToastContainer /></div>;
    }
};

export default React.memo(BlogBuilder);
