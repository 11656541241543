import React, { Component } from 'react'
import PageBuilder from '../../../components/cms/pages/PageBuilder';
import { Col, Row, Typography, Card, Layout, Space, Button } from 'antd';
import {SnippetsOutlined } from '@ant-design/icons';

const { Title } = Typography;
const customStyles = `
:root {
  --gjs-block-bg: #333;
  --gjs-block-label-color: #fff;
  --gjs-primary-color: #1abc9c;
  --gjs-secondary-color: #16a085;
  --gjs-toolbar-bg: #2c3e50;
  --gjs-panel-bg: #34495e;
  --gjs-canvas-bg: #ecf0f1;
  --gjs-btn-bg: #1abc9c;
  --gjs-btn-color: #fff;
}
`;

class AddPage extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            
        }
    }

    render() {
        return (<>
        
        <style>{customStyles}</style>
            
            <Card className='websettingwrap' title={
                <Space className='d-flex flex-row justify-content-between align-items-center'><Space className='d-flex align-items-center'><SnippetsOutlined style={{fontSize:21}} /><Title style={{fontSize:21}} className='font-bold mb-0'>Add Pages</Title></Space>
                </Space>} >
                <Layout className="d-flex flex-row gap-3 justify-content-between relative w-100">
                    <PageBuilder />
                </Layout>
            </Card>
            </>);
    }
  
};

export default AddPage;
