import React, { Component, createRef } from 'react';
import { Col, Row, Card, Layout, Space, Select, Typography, Button, DatePicker, TimePicker, Upload, Input, Table, Modal, Switch } from 'antd';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppstoreAddOutlined, LinkOutlined, DeleteOutlined,EditOutlined, WarningOutlined, FormOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import API from '../../../utils/API';
import Swal from "sweetalert2";

const { Title } = Typography;
const {Sider } = Layout;
const { TextArea } = Input;

class AddCategory extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: false, saving:false,
            categories:[],
            currentPage: 1,
            openEditCategory:false,
            editID: '',
            
        };
    }

    componentDidMount() {
        this.fetchBlogCategories(this.state.currentPage); // Call fetchBlogs with the initial page
    }

    fetchBlogCategories = (page) => {
        this.setState({ loading: true });
        API.get(`/get_all_categories?page=${page}`)
            .then((res) => {
                if (res.data.success) {
                    this.setState({ categories: res.data.blogs, loading: false });
                }
            })
            .catch((error) => {
                console.error("Error fetching blog categories data:", error);
                this.setState({ loading: false });
            });
    };

    columns = [
        { title: 'Title', 
            render: (text, record) => (
                <Row gutter={8} className='d-flex flex-column'>
                    <Col span={24} style={{flex:'unset'}}>
                        <p style={titleStyle} className='font-bold'>{record.title}</p>
                    </Col>
                    <Col span={24} className='gap-4 d-flex flex-row align-items-center' style={{flex:'unset'}}>
                        <a href={'https://www.luxuryproperty.com/blog/'+record.slug} target="_blank" style={slugStyle}><LinkOutlined className='mr-1'/> {'https://www.luxuryproperty.com/blog/'+record.slug}</a>
                    </Col>
                </Row>
                
            )
        }, {title: 'Preview', className: "actionwrap" ,
            render: (text, record) => (
                <Row className="d-flex flex-column" gutter={8}>
                    <Col>
                        <StyledSelect placeholder="Edit" allowClear onChange={(e) => this.handleEdit(e, record.id)}
                        options={[
                            { value: 'edit', label: <span><EditOutlined className='mr-1' /> Update</span> },
                            { value: 'delete', label: <span style={{color:'red'}}><DeleteOutlined className='mr-1' /> Delete</span>  } 
                        ]}
                        />
                    </Col>
                </Row>
            ),
        }
    ]

    handlePageChange = (page) => {

    }
    handleEdit = (e, id) => {
        console.log(e, id);
        if (e === 'edit') {  this.handleOpenEdit(id); 
        } else if (e === 'delete') { this.handleDelete(id); }
    }

    handleOpenEdit = (id) => { this.setState({ openEditCategory: true, editID:id }) }
    handleCloseEdit = () => { this.setState({ openEditCategory: false }) }
    handleDelete = () => { 
        Swal.fire({
            title: "Delete this category?",
            text: "You can't undo this action",
            showCancelButton: true,
            confirmButtonColor: "#ff0000",
            cancelButtonColor: "#FFF",
            confirmButtonText: "Delete Category",
            width:'400px',
            icon: <WarningOutlined />,
          }).then((result) => {
            if (result.isConfirmed) {
                toast.success("Successfully deleted")
            }
          })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    // SAVE ALL

    handleSaveNewCategory = () => {
        this.setState({ saving: true });
        const fieldsToSave = [  "title", "slug", "parent_category", "description", "seo_title", "seo_description", "order", "status"            
        ];
    
        const data = new FormData();
    
        fieldsToSave.forEach((field) => {
            const value = this.state[field] !== undefined ? this.state[field] : null;
            data.append(field, value);
        });
        // Log all FormData entries
        for (let [key, value] of data.entries()) {
            console.log(`${key}:`, value);
        }
        // API.post("/add_new_category", data, { headers: { 'Content-Type': 'multipart/form-data'} })
        // .then((response) => {
        //     if (response.data.success) {
        //         toast.success("Successfully added new category");
        //         window.location.reload();
        //     } else {
        //         toast.warning("Something went wrong");
        //     }
        // })
        // .catch((error) => {
        //     toast.error("Error adding new category");
        //     console.error('Save homepage error:', error);
        // })
        // .finally(() => {
        //     this.setState({ saving: false }); // End loading
        // });
    }

    render() {
        return (
            <Layout className="d-flex flex-row justify-content-between relative" style={{background:'transparent'}}>
                <ToastContainer />
                <Content style={{background:'none'}}>
                    <Row gutter={[16, 24]} className="justify-content-between">
                        <Col span={9}>
                            <Row>
                                <Col span={24}><label className="font-bold mb-3 uppercase">Add New Category</label></Col>
                                <Col span={24}>
                                    <div className='form-group'>
                                        <label className='font-bold'>Name:</label>
                                        <StyledInput placeholder="" name='blog_new_title' />
                                        <label>The name is how it appears on your site.</label>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className='form-group'>
                                        <label className='font-bold'>Slug:</label>
                                        <StyledInput placeholder="" name='blog_new_slug' />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className='form-group'>
                                        <label className='font-bold'>Parent Category:</label>
                                        <StyledSelect placeholder="" name='blog_new_parent_category' />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className='form-group'>
                                        <label className='font-bold'>Description:</label>
                                        <StyledInput placeholder="" name='blog_new_description' />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className='form-group'>
                                        <label className='font-bold'>SEO Title:</label>
                                        <StyledInput placeholder="" name='blog_new_seo_title' />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className='form-group'>
                                        <label className='font-bold'>SEO Description:</label>
                                        <StyledTextArea placeholder="" name='blog_new_seo_title' />
                                    </div>
                                </Col>
                                <Col span={24}><StyledButton onClick={this.handleSaveNewCategory}>Add Category</StyledButton></Col>
                            </Row>
                        </Col>
                        <Col span={14}>
                            <StyledTableWrapper>
                                <StyledTable columns={this.columns} dataSource={this.state?.categories?.data}
                                    showHeader={false}
                                    rowKey="id"
                                    rowSelection={{ type: this.state.selectionType, ...this.rowSelection, }}
                                    pagination={{
                                        total: this.state?.categories?.total || 0,
                                        pageSize: this.state?.categories?.per_page || 10,
                                        current: this.state.currentPage,    
                                        showTotal: (total, range) =>
                                        window.isTabView ? `` : `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                        showSizeChanger: true,
                                        onChange: this.handlePageChange,
                                    }}
                                />
                            </StyledTableWrapper>
                        </Col>
                    </Row>
                    <Modal open={this.state.openEditCategory} onCancel={this.handleCloseEdit} onOk={this.handleCloseEdit}
                    width={700} centered={true}
                        title={
                            <Space>
                                <FormOutlined /><span className='font-bold ml-1'>Edit Category ( {this.state.editID} )</span>
                            </Space>
                        }
                    >
                        <Row fullwidth className='p-4' gutter={12}>
                            <Col span={24} className='d-flex flex-row justify-content-between'>
                                <div className='form-group'>
                                    <label className='font-bold'>Status:</label>
                                    <Switch
                                        checkedChildren={<><CheckOutlined /> <span>Active</span></>}
                                        unCheckedChildren={<><CloseOutlined /> <span>Inactive</span></>}
                                        defaultChecked
                                    />
                                </div>
                                <div className='form-group'>
                                    <label className='font-bold'>Order:</label>
                                    <StyledInput placeholder="" name='blog_title' />
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className='form-group'>
                                    <label className='font-bold'>Title:</label>
                                    <StyledInput placeholder="" name='blog_title' />
                                    <label style={infolabelStyle}>The name is how it appears on your site.</label>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className='form-group'>
                                    <label className='font-bold'>Slug:</label>
                                    <StyledInput placeholder="" name='blog_title' />
                                    <label style={infolabelStyle}>The "slug" is the URL-friendly version of the name. It is all lowercase and contains only letters, numbers, and hyphens.</label>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className='form-group'>
                                    <label className='font-bold'>Parent Category:</label>
                                    <StyledSelect placeholder="" name='blog_title' />
                                </div>
                            </Col>                                
                            <Col span={24}>
                                <div className='form-group'>
                                    <label className='font-bold'>Description:</label>
                                    <StyledTextArea placeholder="" name='blog_title' />
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className='form-group'>
                                    <label className='font-bold'>SEO Title:</label>
                                    <StyledInput placeholder="" name='blog_title' />
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className='form-group'>
                                    <label className='font-bold'>SEO Description:</label>
                                    <StyledTextArea placeholder="" name='blog_title' />
                                </div>
                            </Col>
                            
                        </Row>
                    </Modal>

                </Content>
            </Layout>
        )
    }
}

export default AddCategory;


const StyledButton = styled(Button)`
    height:36px; width:fit-content;
`;
const StyledSelect = styled(Select)`
    height:36px; min-width:180px;
    .ant-select-selection-placeholder {color:#151515}
`;
const StyledInput = styled(Input)`
    height:36px;
`;
const StyledTextArea = styled(TextArea)`
    height:7rem;
`;
const slugStyle = { fontSize:12,  marginBottom:0, textTransform:'lowercase'}
const titleStyle = { marginBottom:0  }
const StyledTable = styled(Table)`
  width: 100%; 
  .ant-table-content {border-radius: 8px; overflow: hidden;}
  .ant-table-cell {border-top:1px solid rgba(21,21,21,0.16) !important; border-bottom:1px solid rgba(21,21,21,0.16) !important; }
`;
const StyledTableWrapper = styled.div`
    width:100%;
  .ant-table-content table {border: none !important;border-spacing: 0 0.6rem !important;}
  .ant-table-tbody > tr > td {border-bottom: 1px solid rgba(21, 21, 21, 0.16) !important;border-top: 1px solid rgba(21, 21, 21, 0.16) !important;}
  .ant-table-tbody > tr > td:first-child {border-left: 1px solid rgba(21, 21, 21, 0.16) !important; border-top-left-radius:5px;border-bottom-left-radius:5px;}
  .ant-table-tbody > tr > td:last-child { border-top-right-radius:5px;border-bottom-right-radius:5px; padding:0 1rem !important; border-right:1px solid rgba(21,21,21,0.16) !important; vertical-align:middle; }
  .ant-table-cell > div { height: 100%;flex:1}
`;
const infolabelStyle = { fontSize:12, fontStyle:'italic'}