import React, {Component} from 'react'
import 'antd/dist/reset.css';
import {Table, Space, Card, Pagination, Row, Col, Button, Input, Skeleton} from 'antd'
import {itemRender, onShowSizeChange} from "../../Pages/paginationfunction"
import "../../Pages/antdstyle.css"
import API from '../../utils/API';
import ReactDragListView from "react-drag-listview"
import Moment from 'react-moment';
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {oldBaseurl} from "../../components/BaseUrl";
import './document.css'
import {EyeOutlined, FileTextOutlined, FileWordOutlined, YoutubeOutlined} from '@ant-design/icons';
import styled from 'styled-components';

let columns = []
const { Meta } = Card;

const CardStyleSkeleton = styled(Card)`
    .ant-card-body {padding:0}
    .ant-skeleton {margin-top:0; width:100%; margin-bottom:0}
    .ant-skeleton-paragraph {margin:5px 0 0}
    .ant-card-body .ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph >li {height:47px; width:100% !important; border-top-left-radius:0; border-top-right-radius:0}
    .ant-skeleton-title {margin: 24px 1.5rem; height:24px !important}
    
`

class DocumentsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            documents: [],
            files: [],
            currentPage: 1,
            perPage: 16,
            totalDocuments: 0,
            searchQuery: '',
            allDocuments: [],
            category: [
                { value: 1, label: "Onboarding" },
                { value: 2, label: "Digital Assets" },
                { value: 3, label: "HR" },
                // { value: 4, label: "RERA" },
                { value: 5, label: "Conveyance" },
                { value: 6, label: "Finance" },
                { value: 7, label: "Magazines" },
                { value: 8, label: "Company Legal" },
                { value: 9, label: "Marketing" },
                { value: 10, label: "Presentations" },
                { value: 11, label: "Forms" },
                { value: 12, label: "Careers" },
            ],
            activeCategory: null,
        }
    }
    componentDidMount() {
        this.fetchAllDocuments(); // Fetch everything once
    }
    
    fetchAllDocuments = async () => {
        this.setState({ isLoading: true });
    
        let allDocs = [];
        let page = 1;
        let hasNextPage = true;
    
        try {
            while (hasNextPage) {
                const response = await API.get(`/documents?page=${page}&perPage=${this.state.perPage}`);
                const data = response.data.documents;
    
                if (data?.data?.length > 0) {
                    allDocs = [...allDocs, ...data.data]; // Merge results
                }
    
                hasNextPage = Boolean(data.next_page_url); // Check for next page
                page++; // Move to next page
            }
    
            // Sort all documents by date in descending order
            const sortedDocs = allDocs.sort((a, b) => new Date(b.date) - new Date(a.date));
    
            this.setState(
                {
                    allDocuments: sortedDocs,  // Store all documents sorted
                    documents: { data: sortedDocs }, // Show all documents by default
                    totalDocuments: sortedDocs.length,
                    isLoading: false,
                    currentPage: 1,
                }
            );
    
        } catch (error) {
            console.error('Error fetching all documents:', error);
            this.setState({ isLoading: false });
        }
    };
    
    
    handleSearch = (searchQuery) => {
        this.setState({ searchQuery });
    
        if (!searchQuery) {
            // Show all documents if search is empty
            this.setState({ documents: { data: this.state.allDocuments } });
            return;
        }
    
        const filteredDocuments = this.state.allDocuments.filter(doc =>
            doc.name?.toLowerCase().includes(searchQuery.toLowerCase())
        );
    
        this.setState({ documents: { data: filteredDocuments } });
    };
    

    handlePreviewClick = (url) => {
        if (url.startsWith("https://")) {
          window.open(url, '_blank');  // Open the link in a new tab
        } else {
          window.open(`https://crm2.luxuryproperty.com/${url}`, '_blank');
        }
      };

    renderImage = (document) => {
        const { url, type, preview_img } = document;
    
        if (preview_img){
            return (<>
                <div style={{borderBottom: '1px solid rgba(21, 21, 21, 0.16)', display:'flex', alignItems:'flex-start'}}><img src={'https://crm2.luxuryproperty.com/uploads/documents/preview/'+preview_img} style={{width:'100%', height:'100%', objectFit:'cover',}} /></div>
                <div className="preview-overlay"><a className="py-0" style={{textDecoration:'none', gap:'0.3rem', display:'flex', justifyContent:'center'}} href={(document.type === "file") ? "https://crm2.luxuryproperty.com/" + document.url : document.url} target="_blank"><EyeOutlined key="preview" />Preview</a></div>
              </>);
        } else {
        if (url.includes('.pdf')) {
            return (<>
                <iframe
                    loading="lazy"
                    title={document.name}
                    src={`https://docs.google.com/gview?url=${encodeURIComponent(
                    url.startsWith("https://") ? url : `https://crm2.luxuryproperty.com/${url}`
                    )}&embedded=true`}
                    style={{ width: "100%", height: "15rem", border: "none", overflow: "hidden", cursor: "pointer" }}
                /> 
              <div className="preview-overlay"><a className="py-0" style={{textDecoration:'none', gap:'0.3rem', display:'flex', justifyContent:'center'}} href={(document.type === "file") ? "https://crm2.luxuryproperty.com/" + document.url : document.url} target="_blank"><EyeOutlined key="preview" />Preview</a></div>
            </>);
        } else if (url.includes('.docx') || url.includes('docs.google.com')) {
            return (<div className="docxwrap" style={{ width: "100%", height: "15rem", overflow:'hidden', cursor:'pointer',display:'flex', alignItems:'center', justifyContent:'center', background:'rgba(21,21,21,0.06)' }}>
                <FileWordOutlined style={{fontSize:'6rem', color:'rgba(21,21,21,0.1)'}}/>
                <div className="preview-overlay"><a className="py-0" style={{textDecoration:'none', gap:'0.3rem', display:'flex', justifyContent:'center'}} href={(document.type === "file") ? "https://crm2.luxuryproperty.com/" + document.url : document.url} target="_blank"><EyeOutlined key="preview" />Preview</a></div>
                </div>
              );
        } else if (url.includes('issuu.com')) {
            return (<div className="docxwrap" style={{ width: "100%", height: "15rem", overflow:'hidden', cursor:'pointer',display:'flex', alignItems:'center', justifyContent:'center', background:'rgba(21,21,21,0.06)' }}>
                <FileTextOutlined style={{fontSize:'6rem', color:'rgba(21,21,21,0.1)'}}/>
                <div className="preview-overlay"><a className="py-0" style={{textDecoration:'none', gap:'0.3rem', display:'flex', justifyContent:'center'}} href={(document.type === "file") ? "https://crm2.luxuryproperty.com/" + document.url : document.url} target="_blank"><EyeOutlined key="preview" />Preview</a></div>
                </div>
              );
        } else if (url.includes('.mp4')) {
            return (<div className="docxwrap" style={{ width: "100%", height: "15rem", overflow:'hidden', cursor:'pointer',display:'flex', alignItems:'center', justifyContent:'center', background:'rgba(21,21,21,0.06)' }}>
                <YoutubeOutlined style={{fontSize:'6rem', color:'rgba(21,21,21,0.1)'}}/>
                <div className="preview-overlay"><a className="py-0" style={{textDecoration:'none', gap:'0.3rem', display:'flex', justifyContent:'center'}} href={(document.type === "file") ? "https://crm2.luxuryproperty.com/" + document.url : document.url} target="_blank"><EyeOutlined key="preview" />Preview</a></div>
                </div>
              );
        } else {
            return (<>
              <img
                alt={document.name}
                src={url.startsWith("https://") ? `${url}` : `https://crm2.luxuryproperty.com/${url}`}
                style={{ height: "15rem", objectFit: "cover", objectPosition:'top' }}
              />
              <div className="preview-overlay"><a className="py-0" style={{textDecoration:'none', gap:'0.3rem', display:'flex', justifyContent:'center'}} href={(document.type === "file") ? "https://crm2.luxuryproperty.com/" + document.url : document.url} target="_blank"><EyeOutlined key="preview" />Preview</a></div>
              </>
            );
        }}
    };

    handleCategoryClick = (selectedCategory) => {
        const { activeCategory, allDocuments } = this.state;
    
        // Toggle category selection (deselect if clicked again)
        const newActiveCategory = activeCategory === selectedCategory ? null : selectedCategory;
    
        // Filter documents by selected category
        const filteredDocuments = newActiveCategory
            ? allDocuments.filter((doc) => doc.category.split(',').includes(String(newActiveCategory)))
            : allDocuments;
    
        this.setState({
            activeCategory: newActiveCategory,
            documents: { data: filteredDocuments },
            currentPage: 1,
        });
    };
    
    
    
    

    SkeletonCard = () => (
        <Row gutter={[21, '1rem']} style={{ width: '100%', margin:0 }}>
          {[...Array(12)].map((_, index) => (  // 4 columns * 3 rows = 12 skeleton cards
            <Col xs={24} sm={12} md={6} key={index}>
              <CardStyleSkeleton>
                <Skeleton.Button active style={{ width: '100%', height: '10rem', marginTop: 0 }} />
                <Skeleton active paragraph={{ rows: 1 }} />
              </CardStyleSkeleton>
            </Col>
          ))}
        </Row>
      );
      

    render() {

        const ButtonCatStyle = styled('Button')` width:fit-content; padding:3px 12px; border-radius:36px;border: 1px solid rgba(21, 21, 21, 0.16); position:relative;
            ${({ type }) => type === 'primary' && `border:1px solid rgb(37, 211, 102,1); background:rgba(37, 211, 102,0.16); color:rgb(37, 211, 102);
            &::before {content: 'X'; position: absolute;top: -8px; right: -7px;font-size: 10px;  color: #FFF; border-radius: 50%; background: rgb(37, 211, 102);border: 1px solid rgb(37, 211, 102); height: 18px; width: 18px; }
            `}
            @media screen and (max-width:768px) {
                height:28px; white-space:nowrap;
            }

        `
        const StyledDiv = styled.div` display:flex; gap:0.6rem;margin-bottom:5px; 
            @media screen and (max-width:768px) { overflow-x:scroll;}
        `
        const CardStyle = styled(Card)` box-shadow: 0 0 10px rgba(21, 21, 21, 0.16); margin: 0 1rem 2rem 1rem; display: flex; flex-direction:column; height:100%; width:100%;border-radius:5px; overflow:hidden;
        @media screen and (max-width:768px) {
            margin:0;
            .ant-card-body { padding:16px 16px 3rem }
        }
        `

        return (
            <div className='pb-5'>
                <ToastContainer/>
                <Row gutter={16} className={`${window.isMobileView ? 'flex-column':'flex-row'}  'justify-content-between '`} style={{padding:window.isMobileView?'1.5rem': '2rem 3.7rem 1rem'}}>
                    <Col span={12}>
                        <h1>Documents</h1>                  
                    </Col>
                    <Col xs={24} md={12} style={{display:'flex', justifyContent:'flex-end', paddingRight:0}}>
                        <Input className="ml-auto"
                            placeholder="Search documents"
                            value={this.state.searchQuery}
                            onChange={(e) => this.handleSearch(e.target.value)} // Call handleSearch directly
                            style={{ width: '100%', marginRight: '0', height:46 }}
                            prefix={<i className="bi bi-search"></i>}
                        />
                    </Col>
                    <Col span={24} style={{marginTop:'1rem', borderBottom:'1px solid rgba(21,21,21,0.16)'}}>
                        <span className="font-bold">Filter By:</span>
                        <StyledDiv>{this.state.category.map((cat) => (
                            <ButtonCatStyle block key={cat.value} type={this.state.activeCategory === cat.value ? 'primary' : 'default'} onClick={() => this.handleCategoryClick(cat.value)} > {cat.label} </ButtonCatStyle>
                        ))}</StyledDiv>
                    </Col>
                </Row>

                <div className="documentlist-wrap" style={{padding:window.isMobileView?'1rem':'1rem 3rem'}}>
                    <Row gutter={[21,'1rem']}>
                        {this.state?.documents?.data?.length > 0 ? (
                        this.state.documents.data.map((document) => (
                            <Col  xs={24} sm={12} md={12} lg={6}>
                            <CardStyle
                            key={document.id}
                            bordered
                            style={{  }} 
                            actions={[
                                <a className="py-0" style={{textDecoration:'none', gap:'0.3rem', display:'flex', justifyContent:'center'}} href={(document.type === "file") ? "https://crm2.luxuryproperty.com/" + document.url : document.url} target="_blank"><i className="bi bi-cloud-arrow-down"></i>Download</a>,
                                // <Button className="py-0 mx-auto" type='link' style={{textDecoration:'none', gap:'0.3rem', margin:0, display:'flex', alignItems:'center', color:'#151515'}}><EditOutlined key="edit"  />Edit</Button>,
                            ]}
                            cover={this.renderImage(document)}
                            >
                            <Meta title={<p className={"font-bold"} style={{ display:'flex', whiteSpace:'wrap', overflow:'visible', flexGrow: 1 }}>{document.name}</p>} />
                            </CardStyle>
                            </Col>
                        ))
                        ) : (this.SkeletonCard())}
                    </Row>
                    {/* <Row className='pt-5'>
                        <Col span={24} style={{display:'flex', justifyContent:'center'}}>
                            <Pagination style={{gap:'0.6rem'}}
                                current={this.state.currentPage}
                                pageSize={this.state.perPage}
                                total={this.state.totalDocuments}
                                onChange={(page) => this.fetchData(page, this.state.perPage)}
                                showSizeChanger={false} // You can enable this if you want to allow page size changes
                            />
                        </Col>
                    </Row> */}

                    
                </div>
            </div>
        )
    }
}


export default DocumentsList