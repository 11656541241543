import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  Table,
  Modal,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import API from "../../utils/API";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditOutlined } from "@ant-design/icons";
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction";
import Swal from "sweetalert2";


const serviceTypeMapping = {
  1: "Banks",
  2: "Mortgages",
  3: "Insurance",
  4: "Interior Design",
  5: "Construction",
  6: "Project Management",
  7: "Cleaning",
  8: "Moving/Shipping Companies",
  9: "Residency Visa / Golden Visa",
  10: "Defect Inspection Snag and Inspect",
  11: "Valuations",
};


class ListTelephone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name   : "",
      number : "",
      type   : "",
      id: "",
      service_provider_popUp: false,
    };
  }

  componentDidMount() {
    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage);
  }
  fetchData = (page, perPage) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      API.get(`/show_service_provider?page=${page}&perPage=${perPage}`).then(
        async (response) => {
          const serviceprovider = response.data.serviceprovider;
          var data = await response.data.serviceprovider;
          await response.data.serviceprovider;
          this.setState({
            serviceprovider: response.data.serviceprovider,
            isLoading: false,
          });
        }
      );
    }, 300);
  };

  OpenAddCommunityModal = () => {
    this.setState({ name: "" });
    this.setState({ service_provider_popUp: true });
  };

  handleOKModal = () => {
    this.setState({ service_provider_popUp: false });
  };
  handleCancel = () => {
    this.setState({ service_provider_popUp: false });
  };

  HandelCommunityName = (e) => {
    this.setState({ name: e.target.value });
  };

  HandleServiceTypeChange = (e) => {
     this.setState({ type: e.target.value });
  }

  HandelNumber = (e) => {
        this.setState({ number: e.target.value });
  }


  Save = () => {
    const { name, id, number, type } = this.state;
    const data = {
      name   : name,
      number : number,
      type   : type
    };
    if (id) {
      API.put(`/update_service_provider/${id}`, data).then((response) => {
          if (response.data.status === "success") {
            toast.success("Community Updated Successfully");
            this.setState({
              name    :  "",
              id : "",
              number  : "",
              type    : "",
              service_provider_popUp: false,
            });
            this.fetchData(1, 10);
          }
        }, (error) => {
            if(error.response && error.response.data.status === "error"){
                const errors = error.response.data.errors;
                if (errors) {
                  Object.keys(errors).forEach((key) => {
                    toast.error(`${errors[key].join(", ")}`);
                  });
                }else{
                  toast.success("An unexpected error occurred");
                }
            }
        }
      );
    } else {
      API.post("/create_service_provider", data).then((response) => {
        if (response.data.status === "success") {
          this.setState({ name: "" , number : "", type : "",  });
          toast.success("Create Service SuccessFully");
          this.setState({ service_provider_popUp: false });
          const page = 1;
          const perPage = 10;
          this.fetchData(page, perPage);
        }
      }, (error) => {
        if (error.response && error.response.data.status === "error") {
          const errors = error.response.data.errors;
          if (errors) {
            Object.keys(errors).forEach((key) => {
              toast.error(`${errors[key].join(", ")}`);
            });
          }else{
            toast.success("An unexpected error occurred");
          }
        }
      });
    }
  };

  handleDelete = (id, e) => {
    const page = 1;
    const perPage = 10;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete("/delete_service_provider/" + id).then((response) => {
          if (response.data.status === "delete") {
            toast.success("Successfully deleted");
            this.fetchData(page, perPage);
          }
        }, (error) =>{
          if(error.response && error.response.data.status === "error"){
              toast.error(`Recod Can't delete something is wrong`);
          }
        });
      }
    });
  };
  handleEdit = (id, e) => {
    const page = 1;
    const perPage = 10;
    API.get("/edit_service_provider/" + id).then((response) => {
      if (response.data) {
        this.setState(
          {
            name   : response.data.SingleServiceprovider.name,
            number : response.data.SingleServiceprovider.number,
            type   : response.data.SingleServiceprovider.type,
            service_provider_popUp: true,
            id: id,
          },
          () => {
            const data = {
              // id: this.state.id,
              // name: this.state.name,
            };
          }
        );
      }
    });
  };

  // Render Html & Functions
  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.label.localeCompare(b.label),
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        render: (type) => serviceTypeMapping[type] || "Unknown",
        sorter: (a, b) => a.label.localeCompare(b.label),
      },
      {
        title: "Number",
        dataIndex: "number",
        key: "number",
        sorter: (a, b) => a.label.localeCompare(b.label),
      },
      {
        title: "Action",
        render: (text, record) => (
          <div className="ms-auto">
            {/* Value means Id */}
            <a
              href="#"
              className="btn btn-success btn-sm m-2"
              onClick={this.handleEdit.bind(this, record.id)}
              style={{ color: "white" }}
            >
              {" "}
              <i className="fa fa-edit"></i>{" "}
            </a>
            <a
              className="btn btn-danger btn-sm m-2"
              onClick={this.handleDelete.bind(this, record.id)}
              style={{ color: "white" }}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </a>
          </div>
        ),
      },
    ];
    const handleTableChange = (pagination) => {
      this.fetchData(pagination.current, pagination.pageSize);
    };
    return (
      <div>
        <ToastContainer />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              {" "}
              <Link to="/dashboard">Dashboard</Link>{" "}
            </Breadcrumb.Item>
            <Breadcrumb.Item>Locations</Breadcrumb.Item>
            <Breadcrumb.Item>Service Provider list</Breadcrumb.Item>
          </Breadcrumb>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={this.OpenAddCommunityModal}
          >
            {" "}
            <PlusCircleOutlined />
            Add Provider
          </Button>
        </div>
        <Modal
          width={600}
          title="Create Service Provider"
          open={this.state.service_provider_popUp}
          onOk={this.handleOKModal}
          onCancel={this.handleCancel}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-2">
                  <select
                    name="serviceType"
                    className="form-control input-sm"
                    onChange={this.HandleServiceTypeChange}
                    value={this.state.type}
                  >
                    <option value="">Select Service</option>
                    <option value="1">Banks</option>
                    <option value="2">Mortgages</option>
                    <option value="3">Insurance</option>
                    <option value="4">Interior Design</option>
                    <option value="5">Construction</option>
                    <option value="6">Project Management</option>
                    <option value="7">Cleaning</option>
                    <option value="8">Moving/Shipping Companies</option>
                    <option value="9">Residency Visa / Golden Visa</option>
                    <option value="10">
                      {" "}
                      Defect Inspection Snag and Inspect{" "}
                    </option>
                    <option value="11">Valuations</option>
                    <option value="11">Conveyancing</option>
                    <option value="12">Developer/Offplan</option>
                  </select>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-2">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    className="form-control input-sm"
                    id="name"
                    onChange={this.HandelCommunityName}
                    value={this.state.name}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-2">
                  <input
                    type="number"
                    name="number"
                    placeholder="Number"
                    className="form-control input-sm"
                    id="number"
                    onChange={this.HandelNumber}
                    value={this.state.number}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <button
                  onClick={this.Save}
                  className="btn btn-success"
                  type="submit"
                  href="javascript:void(0)"
                  data-href
                >
                  {" "}
                  <i className="bi bi-check" />{" "}
                </button>
              </div>
            </div>
          </div>
        </Modal>
        ;
        <div style={{ padding: 24, minHeight: 380, background: "#fff" }}>
          <div class="table-responsive">
            <Table
              onChange={handleTableChange}
              loading={this.state.isLoading}
              className="table-striped"
              pagination={{
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                total: this.state.serviceprovider
                  ? this.state.serviceprovider.length
                  : 0,

                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                onShowSizeChange: onShowSizeChange,
                itemRender: itemRender,
              }}
              style={{ overflowX: "auto" }}
              columns={columns}
              // bordered
              dataSource={this.state.serviceprovider ? this.state.serviceprovider            : []}
              rowKey={(value) => value}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ListTelephone;
