import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  Table,
  Modal,
} from "antd";
import { ConsoleSqlOutlined, SaveOutlined } from "@ant-design/icons";
import API from "../../utils/API";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditOutlined } from "@ant-design/icons";
import { itemRender, onShowSizeChange } from "../../Pages/paginationfunction";
import Swal from "sweetalert2";

class Property extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property_name: "",
      property_modal: false,
      edit_property_id: "",
      communities: [],
      relatedsubcommunities: [],
      filter_community_id: "",
      filter_subcommunity_id: "",
    };
  }

  componentDidMount() {
    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage);
    this.fetchCommunity();
  }

  fetchData = (page, perPage) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      API.get(`/properties?page=${page}&perPage=${perPage}`).then(
        async (response) => {
          var data = await response.data.data;
          await response.data.data;
          this.setState({
            subcommunityproperty: response.data.data,
            isLoading: false,
          });
        }
      );
    }, 300);
  };

  fetchCommunity = () => {
    API.get("/community").then((response) => {
      if (response.data.success) {
        this.setState({ communities: response.data.communities });
      }
    });
  };

  SaveProperty = () => {
    const { filter_community_id, filter_subcommunity_id, property_name, edit_property_id } = this.state;

     if (!filter_community_id || !filter_subcommunity_id || !property_name.trim()) {
           toast.error("Please complete all required fields.");
          return;
    }
    const data = {
      filter_community_id: filter_community_id,
      filter_subcommunity_id: filter_subcommunity_id,
      property_name: property_name,
    };

    if (edit_property_id){
         API.put(`/update_property/${edit_property_id}`, data).then((response) => {
          if (response.data.status === "success") {
            toast.success("Property Updated Successfully");
            this.setState({ property_name: "",  filter_subcommunity_id: "",  filter_community_id:"", property_modal: false });
            this.fetchData(1, 10);
          }
        });
    }else{
      API.post("/add_property", data).then((response) => {
        if (response.data.status === "success") {
          this.setState({
            property_name: "",
            filter_community_id: "",
            filter_subcommunity_id: "",
          });
          toast.success("Porperty Add SuccessFully");
          this.setState({ property_modal: false });
          const page = 1;
          const perPage = 10;
          this.fetchData(page, perPage);
        }
      });
    }
      
  };

  OpenPropertyModal = () => {
    this.setState({ 
      property_modal: true,
      filter_community_id : "",
      filter_subcommunity_id : "",
      property_name : "",
     });
  };

  HandelProperyName = (e) => {
    this.setState({ property_name: e.target.value });
  };

  handleCommunityFilter = (community_id) => {
    if (community_id != undefined) {
      this.setState({ filter_community_id: community_id });
      API.get("/get_relared_subcommunity/" + community_id).then((response) => {
        if (response.data.relatedsubcommunity.length > 0) {
          this.setState({
            relatedsubcommunities: response.data.relatedsubcommunity,
          });
        } else {
          this.setState({
            relatedsubcommunities: [],
            filter_community_id: "",
          });
          toast.info(
            "No subcommunities found related to this community. Please add at least one subcommunity."
          );
        }
      });
    } else {
      alert("please select atleast commmunity");
    }
  };
  handleSubcommunityFilter = (e) => {

    this.setState({ filter_subcommunity_id: e });
  };

  handleOKModal = () => {
    this.setState({ property_modal: false });
  };

  handleCancel = () => {
    this.setState({ property_modal: false });
  };

  handleDelete = (id, e) => {
    const page = 1;
    const perPage = 10;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete("/delete_property/" + id).then((response) => {
          if (response.data.status === "success") {
            toast.success("Successfully deleted");
            this.fetchData(page, perPage);
          }
        });
      }
    });
  };

  handleEdit = (id, e) => {
    const page = 1;
    const perPage = 10;
    API.get("/edit_property/" + id).then((response) => {
      console.log(response);
      if (response.data.status === "success") {
        const filter_community_id = response.data.properties?.developments_id;
        const filter_subcommunity_id = response.data.properties?.project_id;
        const property_name = response.data.properties?.name;
        const edit_property_id = response.data.properties?.id;
        this.setState(
          {
            // relatedsubcommunities: response.data.relatedsubcommunity,
            
            filter_community_id: filter_community_id,
            filter_subcommunity_id: filter_subcommunity_id,
            property_name: property_name,
            property_modal: true,
            edit_property_id: edit_property_id,
          },
          () => {
            const data = {
              edit_property_id: this.state.id,
              property_name: this.state.property_name,
              filter_community_id: this.state.filter_community_id,
              filter_subcommunity_id: this.state.filter_subcommunity_id,
            };
          }
        );
      }
    });
  };

  render() {
    const columns = [
      {
        title: "Community",
        dataIndex: "getcommunityforproperty",
        key: "title",
        sorter: (a, b) =>
          a.getcommunityforproperty?.label?.localeCompare(
            b.getcommunityforproperty?.label || ""
          ),
        render: (getcommunityforproperty) =>
          getcommunityforproperty && getcommunityforproperty.label
            ? getcommunityforproperty.label
            : "No Sub-Community",
      },

      {
        title: "Sub-Community",
        dataIndex: "getsubcommunity",
        key: "title",
        sorter: (a, b) =>
          a.getsubcommunity?.title?.localeCompare(
            b.getsubcommunity?.title || ""
          ),
        render: (getsubcommunity) =>
          getsubcommunity && getsubcommunity.title
            ? getsubcommunity.title
            : "No Sub-Community",
      },
      {
        title: "Property",
        dataIndex: "title",
        key: "title",
        sorter: (a, b) => a.label.localeCompare(b.label),
      },
      {
        title: "Action",
        render: (text, record) => (
          <div className="ms-auto">
            <a
              href="#"
              className="btn btn-success btn-sm m-2"
              onClick={this.handleEdit.bind(this, record.id)}
              style={{ color: "white" }}
            >
              {" "}
              <i className="fa fa-edit"></i>{" "}
            </a>
            <a
              className="btn btn-danger btn-sm m-2"
              onClick={this.handleDelete.bind(this, record.id)}
              style={{ color: "white" }}
            >
              {" "}
              <i className="fa fa-trash"></i>{" "}
            </a>
          </div>
        ),
      },
    ];
    
    const handleTableChange = (pagination) => {
      this.fetchData(pagination.current, pagination.pageSize);
    };

    return (
      <div>
        <ToastContainer />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              {" "}
              <Link to="/dashboard">Dashboard</Link>{" "}
            </Breadcrumb.Item>
            <Breadcrumb.Item>Locations</Breadcrumb.Item>
            <Breadcrumb.Item>Communities</Breadcrumb.Item>
            <Breadcrumb.Item>Sub-Community</Breadcrumb.Item>
            <Breadcrumb.Item>Properties</Breadcrumb.Item>
          </Breadcrumb>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={this.OpenPropertyModal}
          >
            {" "}
            <PlusCircleOutlined /> Add Properties
          </Button>
        </div>
        <Modal
          width={600}
          title="Offers"
          open={this.state.property_modal}
          onOk={this.handleOKModal}
          onCancel={this.handleCancel}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="form-group mt-2">
                  <label className="control-labels">Communities</label>
                  <div className="form-group w-100 px-0 mb-0">
                    <Select
                      allowClear
                      showSearch
                      style={{ width: "100%" }}
                      value={this.state.filter_community_id}
                      placeholder="Search"
                      onChange={this.handleCommunityFilter}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={this.state.communities.map((community) => ({
                        label: community.label, // Assuming 'title' is the display name
                        value: community.value, // Assuming 'id' is the unique identifier
                      }))}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="form-group mt-2">
                  <label className="control-labels">Sub-Communities</label>
                  <div className="form-group w-100 px-0 mb-0">
                    <Select
                      allowClear
                      showSearch
                      style={{
                        width: "100%",
                        cursor: !this.state.filter_community_id
                          ? "not-allowed"
                          : "auto",
                      }}
                      value={this.state.filter_subcommunity_id}
                      placeholder="Select Subcommunity"
                      onChange={this.handleSubcommunityFilter}
                      filterOption={(input, option) =>
                        (option?.id ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={this.state.relatedsubcommunities.map(
                        (subcommunity) => ({
                          label: subcommunity.title, // Assuming 'title' is the display name
                          value: subcommunity.id, // Assuming 'id' is the unique identifier
                        })
                      )}
                      disabled={!this.state.filter_community_id}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-group mt-2">
                  <input
                    type="text"
                    name="property"
                    placeholder="Property Name"
                    className="form-control input-sm"
                    id="property"
                    value={this.state.property_name}
                    onChange={this.HandelProperyName}
                    disabled={!this.state.filter_community_id}
                    style={{
                      cursor: !this.state.filter_community_id
                        ? "not-allowed"
                        : "auto",
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <button
                  onClick={this.SaveProperty}
                  className="btn btn-success"
                  type="submit"
                  href="javascript:void(0)"
                  data-href
                >
                  {" "}
                  <i className="bi bi-check" />{" "}
                </button>
              </div>
            </div>
          </div>
        </Modal>
        ;
        <div style={{ padding: 24, minHeight: 380, background: "#fff" }}>
          <div class="table-responsive">
            <Table
              onChange={handleTableChange}
              loading={this.state.isLoading}
              className="table-striped"
              pagination={{
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                total: this.state.communities
                  ? this.state.communities.length
                  : 0,
                // total: this.state?.title?.total > 0 && this.state.title.total,
                // pageSize : this.state.communities.per_page,
                // current  : this.state.communities.current_page,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                onShowSizeChange: onShowSizeChange,
                itemRender: itemRender,
              }}
              style={{ overflowX: "auto" }}
              columns={columns}
              // bordered
              dataSource={
                this.state.subcommunityproperty
                  ? this.state.subcommunityproperty
                  : []
              }
              rowKey={(value) => value}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Property;
