import React, { Component } from 'react'
import { Col, Row, Typography, Card, Layout, Space, Button } from 'antd';
import {SnippetsOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';


const { Title } = Typography;

class AllPages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            
        }
    }

    render() {

        return (
            <Card className='websettingwrap' title={
                <Space className='d-flex flex-row justify-content-between align-items-center'><Space className='d-flex align-items-center'><SnippetsOutlined style={{fontSize:21}} /><Title style={{fontSize:21}} className='font-bold mb-0'>All Pages</Title></Space>
                <Link to="/addpage" className="mt-0 savebtn"> Add new page</Link>
                </Space>} >
                <Layout className="d-flex flex-row gap-3 justify-content-between relative" style={{background:'transparent'}}>
                    
                </Layout>
            </Card>
        )

    }
}

export default AllPages