import React, { useState, useEffect } from 'react'; 

const BrochurePdfOne = (data) => {
    const [agentPicBase64, setAgentPicBase64] = useState('');
    const listing = data? data?.data : []               
    const listingimage = listing.property_images.split(',');
    const SuperAdmin = ['Christian', 'Kamela'];
    const [agentName, setAgentName] = useState('');
  const [agentMobile, setAgentMobile] = useState('');
  const [agentEmail, setAgentEmail] = useState('');

    const convertToBase64 = async (url) => {
        try {
          const response = await fetch(url);
          if (!response.ok) throw new Error('Failed to fetch image');
          const blob = await response.blob();
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = (err) => reject('Error reading blob as Base64: ' + err);
            reader.readAsDataURL(blob);
          });
        } catch (err) {
          console.error(err);
          return '';
        }
      };
      
    
      useEffect(() => {
        const firstName = listing.agent_name?.split(' ')[0];
        const name = SuperAdmin.includes(firstName) ?  listing.listing_agent_name : listing.agent_name;
        const mobile = SuperAdmin.includes(firstName) ? listing.listing_agent_mobile : listing.mobile ;
        const email = SuperAdmin.includes(firstName) ? listing.listing_agent_email : listing.email;

        const getAgentPic = async () => {       console.log(SuperAdmin.includes(firstName))
            if (SuperAdmin.includes(firstName) && listing.profile_photo) {
                setAgentPicBase64(data.picBase);
            } else {
                const base64 = await convertToBase64(listing.profile_photo);
                setAgentPicBase64(base64);
            }
        };

        setAgentName(name);
        setAgentMobile(mobile);
        setAgentEmail(email);
        getAgentPic()
      }, [listing, SuperAdmin, data.picBase]);


    return (
        <>
        <style>{`
            @page { size: a4 ;margin: 0in;padding:0}
            @media print{@page {size: portrait}; div {print-color-adjust: exact;}}
            @font-face { font-family: Poppins; src: url(/fonts/Poppins-Regular.ttf); font-weight:200 }
            @font-face { font-family: Cormorant Garamond; src: url(/fonts/cormorantgaramond-regular-webfont.woff); }

            .flyerbrochure2 { width: 210mm; height: 297mm;}
            .flyerbrochure2 .pagecontainer { background:#FFF; width:210mm; z-index:2; position:relative; overflow:hidden; margin-bottom:2rem; flex-direction:column }
            .flyerbrochure2 img {object-fit:cover; height:100%; width:100%}
            .flyerbrochure2 * {font-family: 'Poppins', sans-serif; font-size: 16px; margin:0; line-height:1.8}            
            .flyerbrochure2 .bgimage { position: absolute; z-index: 1; height:100%; width:100%; overflow:hidden; max-height: 297mm;min-height:38rem}
            .flyerbrochure2 .bcontainer {  z-index:2; position:relative; overflow:hidden; display:flex; flex-direction:row; height:100%; width:100%}
            .flyerbrochure2 .poppins-semibold {font-family: "Poppins", sans-serif; font-weight: 600;font-style: normal; font-size:16px;}
            .flyerbrochure2 .poppins-bold {font-family: "Poppins", sans-serif;font-weight: 700;font-style: normal; font-size:18px}
            .flyerbrochure2 .cormorant-semibold { font-family: "Cormorant Garamond", serif; font-optical-sizing: auto; font-weight: 600; font-style: normal;}
            .flyerbrochure2 .cormorant-bold { font-family: "Cormorant Garamond", serif; font-optical-sizing: auto; font-weight: 700; font-style: normal; font-size:21px}
            .flyerbrochure2 .header {display:flex; width: 100%; justify-content:space-between; align-items: center; margin:0.6rem 0}
            .flyerbrochure2 .brochuredesc p{ font-size:8pt}

            .flyerbrochure2 .col-2 {display:flex; max-width: 20%; flex: 0 0 20%;}
            .flyerbrochure2 .col-3 {display:flex; max-width:25%; flex: 0 0 25%}
            .flyerbrochure2 .col-4 {display:flex; max-width: 32.33%; flex: 0 0 32.33%;}        
            .flyerbrochure2 .col-5 {display:flex; max-width: 41.66%; flex: 0 0 41.66%;}     
            .flyerbrochure2 .col-6 {display: flex; max-width: 50%; flex: 0 0 50%;}
            .flyerbrochure2 .col-7 {display: flex; max-width: 58.33%; flex: 0 0 58.33%;}
            .flyerbrochure2 .col-8 {max-width: 66.66%; flex:66.66%}
            .flyerbrochure2 .col-9 {max-width: 75%; flex:75%}
            .flyerbrochure2 .col-10 {max-width:83.33%; flex:83.33%}
            .flyerbrochure2 .col-12 {max-width:100%; flex:100%}
            .flyerbrochure2 .flex-column {flex-direction: column;}
            .flyerbrochure2 .flex-row {flex-direction: row;}

            .flyerbrochure2 img {overflow:hidden; width:100%; height:100%}
            .flyerbrochure2 .imageStyle { object-fit: cover; width: auto; height: 100%; transform: translateX(-50%); left: 50%;    position: relative;}
            .flyerbrochure2 .imageWrap {overflow:hidden; max-height: 297mm; z-index:999; height: 40.2rem}
        `}</style>

        <div className="flyerbrochure2">
            {/* First Page */}
            <div className="pagecontainer">
                <div className="bgimage"><img src="brochure_assets/backgroundDigital.png" style={{height:'100%', zIndex:2}} /></div>
                <div className="col-12 px-0" className='imageWrap'>
                    <img src={listingimage[0] ? `https://www1.luxuryproperty.com/${listingimage[0]}?nowm`: '/comingtomarket.jpg'} className="imageStyle" alt="Main image" />
                </div>
                <div className="col-12 d-flex flex-row px-0" style={{zIndex:999, position:'relative'}}>
                    { listingimage[1] && listingimage[2] && <div className="col-5 d-flex flex-column" style={{background:'#DBDBDB', padding:'1rem', marginTop:'-8rem'}}>
                        <img src={'https://www1.luxuryproperty.com/' + listingimage[1] + '?nowm'} width="200pt" height="792.5pt" style={{objectFit: 'cover', marginBottom:'1rem'}}/>
                        <img src={'https://www1.luxuryproperty.com/' + listingimage[2] + '?nowm'} width="200pt" height="792.5pt" style={{objectFit: 'cover', }}/>
                    </div> }
                    <div className="col-7 d-flex flex-column" style={{padding:'1.5rem', zIndex:999, position:'relative'}}>
                        <p style={{fontSize:18, fontWeight:300, textTransform:'uppercase', letterSpacing:'9px'}}>{'For ' + listing.property_for}</p>
                        <p style={{fontSize: 27, fontWeight: 800, lineHeight: 1.2, marginTop: '5px'}}>{listing.subcommunity}</p>
                        <p style={{fontSize:21}}>{listing.community}</p>
                        <div className="col-12 d-flex flex-row justify-content-between" style={{padding:0}}>
                            <div className="col-9 d-flex flex-column" style={{padding:0}}>
                                {listing.price && <div className="d-flex flex-column" style={{width:'100%'}}>
                                    <p className="d-flex flex-column">
                                        <span style={{fontWeight:700, fontSize:16}}>
                                            {listing.poa == "N" ?
                                                "AED "+ listing.price?listing.price.replace(/\B(?=(\d{3})+(?!\d))/g, ','):''
                                            : "Price on Application" }
                                        </span>
                                        {listing.poa == "N" && <span style={{fontSize:12, textTransform:'uppercase',fontWeight:400}}>Price</span> }
                                    </p>
                                </div>}
                                <div className="d-flex flex-row flex-wrap justify-content-between" style={{width:'100%', marginTop:'1.8rem'}}>
                                    {listing.bedrooms && ( <div className="d-flex flex-column" style={{width:'50%', marginBottom:'0.8rem'}}>
                                        <p className="d-flex flex-column">
                                            <span style={{fontWeight:700, fontSize:16}}>{listing.bedrooms?listing.bedrooms:''}</span>
                                            <span style={{fontSize:12, textTransform:'uppercase',fontWeight:400}}>Bedrooms</span>
                                        </p>
                                    </div>)}
                                    {listing.refno && <div className="d-flex flex-column" style={{width:'50%'}}>
                                        <p className="d-flex flex-column">
                                            <span style={{fontWeight:700, fontSize:16}}>{listing.refno?listing.refno:''}</span>
                                            <span style={{fontSize:12, textTransform:'uppercase',fontWeight:400}}>Ref No</span>
                                        </p>
                                    </div>}
                                    {listing.bathrooms && ( <div className="d-flex flex-column" style={{width:'50%'}}>
                                        <p className="d-flex flex-column">
                                            <span style={{fontWeight:700, fontSize:16}}>{listing.bathrooms?listing.bathrooms:''}</span>
                                            <span style={{fontSize:12, textTransform:'uppercase',fontWeight:400}}>Bathroom</span>
                                        </p>
                                    </div>)}
                                    {listing.bua && ( <div className="d-flex flex-column" style={{width:'50%'}}>
                                        <p className="d-flex flex-column">
                                            <span style={{fontWeight:700, fontSize:16}}>{listing.bua ? listing.bua.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' SQ. FT.' : ''}</span>
                                            <span style={{fontSize:12, textTransform:'uppercase',fontWeight:400}}>Built Up Area</span>
                                        </p>
                                    </div>)}
                                </div>
                                
                            </div>
                            {listing.qr_code && <div className="d-flex align-items-end">
                                <img src={'https://www1.luxuryproperty.com/' + listing.qr_code} width="130px" height="130px" style={{objectFit:'unset !important', height:'130px', width:'130px'}}/>
                            </div> }
                        </div>
                    </div>
                </div>
                <div style={{background:'#151515', display:'flex', alignItems:'center', width:'100%', justifyContent:'center', padding:'0.8rem 0', zIndex:999}}>
                    <img src={agentPicBase64} height={'100px'} width={'100px'} style={{height:'100px', width:'100px', borderRadius:'50%'}} crossorigin="anonymous"/>
                    <div className="col-5 d-flex flex-column" >
                        <p style={{color:'#FFF', fontSize:14}}>For details, please contact:</p>
                        <p style={{color:'#FFF', fontSize:18, fontWeight:'700'}}>{agentName}</p>
                        <p style={{color:'#FFF', fontSize:14}}>{agentMobile}</p>
                        <p style={{color:'#FFF', fontSize:14}}>{agentEmail}</p>
                    </div>
                    <img src="brochure_assets/Lp-Logo-2021-White-Web.png" style={{height:'30px', objectFit:'cover', width:'300px'}}  />


                </div>
            </div>

        </div>
        </> 
    )
}

export default BrochurePdfOne