import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Breadcrumb,Form, Col, Row,Card, Upload, Space, Typography, message, Radio, Switch, Input, Button, Select } from 'antd';
import { LoadingOutlined, PlusOutlined,PictureOutlined, UploadOutlined, UserOutlined,DesktopOutlined, MobileOutlined, CheckOutlined, CloseOutlined, ExportOutlined, SaveOutlined} from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import ImgCrop from 'antd-img-crop';
import API from '../../utils/API';
import Moment from 'react-moment';
import cmsstyle from "../Cms/Website/cms.css"

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const { Title } = Typography;
  const { TextArea } = Input;
  const modules = {
    toolbar: [
      [{ 'font': ['sans-serif', 'serif', 'monospace'] }],
      [{ 'header': [1, 2, 3,4,5,6, false] }], // Header levels
      ['bold', 'italic', 'underline', 'strike'], // Text formatting
      [{ 'color': [] }, { 'background': [] }], // Text color and background color
      [{ 'script': 'sub' }, { 'script': 'super' }], // Subscript/Superscript
      [{ 'list': 'ordered'}, { 'list': 'bullet' }], // Lists
      [{ 'indent': '-1'}, { 'indent': '+1' }], // Indent
      [{ 'align': [] }], // Text alignment
      ['clean'], // Clear formatting
      
    ],
  };

 class AddUser extends Component {

    constructor(props){
        super(props);
        this.state ={
            modules:[],
            permissions:[],
            user_status:false,
            teams:[],
            user:"",
            name:"",
            first_name:'',
            last_name:'',
            email:"",
            email1:"",
            mobile:"",
            mobile1:"",
            gender:"",
            language:"",
            display_name:"",
            role:"",
            username:"",
            jobtitle:"",
            office_phone:"",
            rera_no:"",
            telephone_directory:"",
            add_to_website:0,
            position:"",
            description:"",
            slug:"",
            category:"",
            sort_order:"",
            meta_title:"",
            meta_description:"",
            meta_keywords:"",
            instagram:"",
            linkedin:"",
            facebook:"",
            whatsapp:"",
            team_id:0,
            active:false,
            status:0,
            loading: false,
            profile_photo:'',
            profile_photo_temp:'',
            profile_photo_change:false,
            previewOpen:'false',
            previewImage:'',
            sort_order:0,
            saving:false,
            roles:[], role:''
        }
    }

    componentDidMount(){
        API.get("/permissions")
        .then((response) =>{
            var data = response.data.permissions
            for (let i = 0; i < data.length; i++) {
                data[i].ischecked = false;
            }
            this.setState({permissions:data})


        })
        
        this.fetchRoles()
    }

    fetchRoles =()=> {
        API.get("/get_roles").then((response) => {
            const roles = response.data.roles.map((role) => ({
              label: role.role, // setting label to be displayed
              value: role.roles_id, // setting value
            }));
            this.setState({ roles });
          });
    }

     validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
      };

      handleUserStatus = (value) =>{
       this.setState({user_status:value})
      }

      onChangePermission = (permission_id,e)=>{
       
        let index = this.state.permissions.findIndex(obj => obj.id ===permission_id);
        var mydata = this.state.permissions;

      

        var i;
        for (i = 0; i < mydata.length; i++) {
           
          if (i == index) {

           
           
            mydata[i].ischecked = !mydata[i].ischecked;
            
          }
        }

        this.setState({permissions:mydata})


      }



       onFinish = (values) => {
      
        const data = {
            form_values:values,
            permissions:this.state.permissions,
        }

       

        API.post("/createUser",data)
        .then((response) =>{
           if(response.data.success){
                 this.props.history.push("/users");
           }
        })
      };


getBase64(file) {
        return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        });
    }

// Method to handle file preview
async handlePreview(file) { 
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewOpen: true,
    });
}
handleProfilePic = ({ file, fileList }) => {console.log("test")
    if (file.status === 'done' || file.status === 'uploading') {
        const fileObj = fileList[0].originFileObj;
        const previewUrl = URL.createObjectURL(fileObj);
        this.setState({ profile_photo: fileObj, profile_photo_change:true });
        this.setState({profile_photo_temp: previewUrl})
    }
};

handleStatusChange = (checked) => {
    const newStatus = checked ? 1 : 0;
    this.setState({ status: newStatus });
};

handleImageSrc = (image) => {
    let imgSrc=''
    if(image.includes("https:")) { imgSrc = image
    } else { imgSrc = 'https://www1.luxuryproperty.com/uploads/team/' + imgSrc}
    return imgSrc
}

handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
};

handleNameInputChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
        // Determine the updated value for each field
        const firstName = name === "first_name" ? value : prevState.first_name;
        const lastName = name === "last_name" ? value : prevState.last_name;
        const jobTitle = name === "jobtitle" ? value : prevState.jobtitle;

        // Concatenate and remove special characters for meta_title
        const sanitizedMetaTitle = `${firstName} ${lastName} - ${jobTitle}`
            .trim();

        return {
            [name]: value,
            meta_title: sanitizedMetaTitle
        };
    });
};


handleGenderChange = (gender) => {
    this.setState({gender: gender.target.value });
  };

handleRoleChange = (value) => {
    this.setState({ role: value });
};
handleDescriptionChange = (value) => {
    this.setState({ description: value });
};
  

handleSubmit = () => {
    this.setState({ saving: true });
    const fieldsToSave = [ "first_name", "last_name", "gender", "language", "email", "email1", "role", "mobile", "mobile1", "jobtitle", "office_phone", "rera_no", "status", "telephone_directory", "add_to_website", "description", "category", "meta_title","meta_description","meta_keywords","instagram","linkedin","facebook",];

    const user = {};

    // Populate user object with fields to save
    fieldsToSave.forEach((field) => {
        user[field] = this.state[field] !== undefined ? this.state[field] : null;
    });
    
    // Add additional computed fields to the user object
    user.display_name = `${this.state.first_name.toLowerCase().replace(/\s+/g, "")}_${this.state.last_name.toLowerCase().replace(/\s+/g, "")}`;
    user.username = this.state.first_name.toLowerCase();
    user.position = this.state.jobtitle;
    user.slug = `${this.state.first_name.toLowerCase()}_${this.state.last_name.toLowerCase()}`;
    user.sort_order = this.state.sort_order;
    
    const data = new FormData();
    data.append("user", JSON.stringify(user));
    
    // Add profile photo if available
    if (this.state.profile_photo !== undefined) {
        data.append("profile_photo", this.state.profile_photo);
    } else {
        data.append("profile_photo", null);
    }
    
    // Log all FormData entries
    for (let [key, value] of data.entries()) {
        console.log(`${key}:`, value);
    }

    API.post("/createUser", data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) => {
        if (response.data.success) {
            toast.success("User " + this.state.firstname + 'has been added' )
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } else {
            toast.warning("Something went wrong");
        }
    })
    .catch((error) => {
        toast.error("Error saving homepage data");
        console.error('Save homepage error:', error);
    })
    .finally(() => {
        this.setState({ saving: false }); // End loading
    });


}


  render() {

    const uploadButton = ( <div> {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />} <div style={{ marginTop: 8, }} > Upload </div> </div>);

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 828 / 682 < 2;
        if (!isLt2M) {
          message.error('Image must be smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };    

    return (<>
        <div style={{display:'none'}}>
        <Breadcrumb
         style={{
           margin: '16px 0',
         }}
       >
        <Breadcrumb.Item><Link to="/dashboard">Dashboard</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/users">All Uses</Link></Breadcrumb.Item>
         <Breadcrumb.Item>Add User</Breadcrumb.Item>
         
       </Breadcrumb>
       <div style={{
           padding: 24,
           minHeight: 380,
           background: "#fff",
         }}>
      <Form
    {...layout}
    name="nest-messages"
    onFinish={this.onFinish}
   
    validateMessages={this.validateMessages}
  >
    <Form.Item
      name="name"
      label="Name"
      style={{
        maxWidth: 600,
      }}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="email"
      label="Email"
      style={{
        maxWidth: 600,
      }}
      rules={[
        {
          type: 'email',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Password"
      name="password"
      style={{
        maxWidth: 600,
      }}
      rules={[
        {
          required: true,
          message: 'Please input your password!',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>

    <Form.Item
      label="Confirm Password"
      name="c_password"
      style={{
        maxWidth: 600,
      }}
      rules={[
        {
          required: true,
          message: 'Please input your confirm password!',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>

   
    <Form.Item label="Team" name="team_id"  style={{
      maxWidth: 600,
    }}>
        <Select  onChange={this.handleTeam}>
          <Select.Option value="1">Sales</Select.Option>
          <Select.Option value="2">Rent</Select.Option>
          <Select.Option value="3">other</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="user_status" label="Active / Inactive" valuePropName="checked"   style={{
        maxWidth: 600,
      }}>
        <Switch  defaultChecked={this.state.user_status} onChange={this.handleUserStatus} />
      </Form.Item>
      {/* <h4>Permissions:</h4>

        <hr/>

      <div className='row'>

        

   


   {this.state.permissions.map((permission,index) =>{
     
   return  (<div className='col-md-3'>
        <Checkbox checked={permission.ischecked} onChange={this.onChangePermission.bind(this,permission.id)}  >
        {permission.name}
       </Checkbox>
      
       
       </div>)
      
       
     })}

 
</div> */}

    <Form.Item
      wrapperCol={{
        ...layout.wrapperCol,
       // offset: 8,
      }}
      style={{
        maxWidth: 600,
        marginTop:'50px'
      }}
    >
      <Button type="primary" htmlType="submit">
      <SaveOutlined /> Save
      </Button>
    </Form.Item>
  </Form>



       </div>
       </div>

       <ToastContainer />
        <Row gutter={12} className='mb-3'>
            <Col span={12}><Title level={3} className='font-bold mb-0'>{this.state.display_name}</Title></Col>
            <Col span={12}>
                <Button type="primary" className='ml-auto mt-0 savebtn' onClick={this.handleSubmit} >{this.state.saving? <><LoadingOutlined style={{marginRight:'5px'}}/>Saving...</> : <>Save New User</>}</Button>
            </Col>
        </Row>
        <Row gutter={12}>
            <Col span={5}>
                <Card title={<Space className='d-flex align-items-center'><PictureOutlined /><Title level={5} className='font-bold mb-0'>Profile Photo</Title></Space>} style={{height:'100%'}}>
                    <ImgCrop aspect={828 / 682} quality={12}  modalTitle="Crop your image" modalOk="Crop" modalCancel="Cancel" style={{minHeight:'17.5rem'}}>
                    <div className="custom-upload-wrapper">
                        <Upload name="profile_photo" listType="picture-card" className="profile_photo" showUploadList={false}  onPreview={this.handlePreview} beforeUpload={beforeUpload} onChange={this.handleProfilePic} onRemove={this.handleRemove}>
                        {this.state.profile_photo_temp ? (<img src={this.state.profile_photo_temp} alt="Change Profile Photo" style={{ width: '100%',}} />)
                        :this.state.profile_photo ? (<img src={this.handleImageSrc(this.state.profile_photo)} alt="Profile Photo" style={{ width: '100%',}} />) 
                        : (uploadButton)
                        }
                        </Upload>
                    </div>
                    </ImgCrop>
                </Card>    
            </Col>
            <Col span={14}>
                <Card title={<Space className='d-flex align-items-center'><UserOutlined /><Title level={5} className='font-bold mb-0'>Personal Info</Title></Space>} style={{height:'100%'}}>
                    <Row gutter={12} direction="row">
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>First Name:</label>
                                <input type="text" className="form-control" name="first_name" defaultValue={this.state.first_name} onChange={this.handleNameInputChange} placeholder='First Name' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Last Name:</label>
                                <input type="text" className="form-control" name="last_name" defaultValue={this.state.last_name} onChange={this.handleNameInputChange} placeholder='Last Name' />
                            </div>
                        </Col>
                    </Row>
                    
                    <Row gutter={12} direction="row">
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Gender:</label>
                                <Radio.Group className='d-flex' buttonStyle="solid" value={this.state.gender} onChange={this.handleGenderChange}>
                                    <Radio.Button value="male">Male</Radio.Button>
                                    <Radio.Button value="female">Female</Radio.Button>
                                </Radio.Group>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Language:</label>
                                <input type="text" className="form-control" name="language" defaultValue={this.state.language}  placeholder='Enter language' onChange={this.handleInputChange} />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={5}>
                <Card className={'loginStyle'} title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>Status</Title></Space>}>
                    <Row gutter={12} direction="row" style={{ display:'flex', justifyContent:'center', height:'80%' }}>
                        <Col span={24} className="d-flex flex-column align-items-start pt-3">
                            <span className='font-bold font-12 mb-1'>Account:</span>
                            <Switch size='large' className='switchStyle' handleSize={'large'}
                            checked={this.state.status === 'Y'} 
                            onChange={checked => this.handleStatusChange(checked)} 
                            checkedChildren={<><CheckOutlined style={{marginRight:5}} />Active</>} 
                            unCheckedChildren={<><CloseOutlined style={{marginRight:5}} />Inactive</>}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
        <Row className='mt-4' gutter={12}>
            <Col span={12}>
                <Card title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>Contact Details</Title></Space>} style={{height:'100%'}}>
                <Row gutter={12} direction="row">
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Email:</label>
                                <input type="text" name="email" className="form-control" defaultValue={this.state.email} onChange={this.handleInputChange} placeholder='Enter email address' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Email 2:</label>
                                <input type="text" name="email1" className="form-control" defaultValue={this.state.email1} onChange={this.handleInputChange} placeholder='Enter secondary email address' />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={12} direction="row">
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Mobile:</label>
                                <input type="text" name="mobile" className="form-control" defaultValue={this.state.mobile} onChange={this.handleInputChange} placeholder='enter mobile' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Mobile 2:</label>
                                <input type="text" name="mobile1" className="form-control" defaultValue={this.state.mobile1} onChange={this.handleInputChange} placeholder='Enter secondary mobile' />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={12}>
                <Card title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>Office Details</Title></Space>} style={{height:'100%'}}>
                <Row gutter={12} direction="row">
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Designation:</label>
                                <input type="text" name="jobtitle" className="form-control" defaultValue={this.state.jobtitle} onChange={this.handleNameInputChange} placeholder='Enter Designation' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Role:</label>
                                <Select options={this.state.roles} style={{ width: "100%",}} name="role" value={this.state.role} showSearch allowClear placeholder="Choose a role" optionFilterProp="children" onChange={this.handleRoleChange} />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={12} direction="row">
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Telephone Directory:</label>
                                <input type="text" name="telephone_directory" className="form-control" defaultValue={this.state.telephone_directory} onChange={this.handleInputChange} placeholder='Choose a telephone directory' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>BRN / RERA no:</label>
                                <input type="text" name="rera_no" className="form-control" defaultValue={this.state.rera_no} onChange={this.handleInputChange} placeholder='Enter BRN number' />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
        <Row className='mt-4'>
            <Col span={24}>
                <Card title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>Bio</Title></Space>} style={{ paddingBottom:'1rem'}}>
                    <TextArea name="description" placeholder={'Write something...'} className='reactquillwrap' theme="snow" modules={modules} value={this.state.description} onChange={this.handleDescriptionChange}/>
                </Card>  
            </Col>
        </Row>
        <Row className='mt-4' gutter={12}>
            <Col span={16}>
                <Card title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>SEO</Title></Space>} style={{ paddingBottom:'1rem'}}>
                    <Row gutter={12}>
                        <Col span={24}>
                            <div className='form-group'>
                                <label className='font-bold'>Meta Title:</label>
                                <input type="text" name="meta_title" className="form-control" defaultValue={this.state.meta_title} onChange={this.handleInputChange} placeholder='Enter meta title' />
                            </div>
                            <div className='form-group'>
                                <label className='font-bold'>Meta Keywords:</label>
                                <input type="text" name="meta_keywords" className="form-control" defaultValue={this.state.meta_keywords} onChange={this.handleInputChange} placeholder='Enter keywords' />
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className='form-group mb-0'>
                                <label className='font-bold'>Meta Description:</label>
                                <TextArea name="meta_description" value={this.state.meta_description} style={{height:'3.3rem'}} palceholder="Enter meta description" onChange={this.handleInputChange}/>
                            </div>
                        </Col>
                    </Row>
                </Card>  
            </Col>
            <Col span={8}>
                <Card title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>Social Media</Title></Space>} style={{ paddingBottom:'1rem'}}>
                    <Row gutter={12}>
                        <Col span={24}>
                            <div className='form-group'>
                                <label className='font-bold'>Instagram:</label>
                                <input type="text" name="instagram" className="form-control" defaultValue={this.state.instagram} onChange={this.handleInputChange} placeholder='Enter the url of instagram profile' />
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className='form-group'>
                                <label className='font-bold'>Linkedin:</label>
                                <input type="text" name="linkedin" className="form-control" defaultValue={this.state.linkedin} onChange={this.handleInputChange} placeholder='Enter the linkedin url' />
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className='form-group'>
                                <label className='font-bold'>Facebook:</label>
                                <input type="text" name="facebook" className="form-control" defaultValue={this.state.facebook} onChange={this.handleInputChange} placeholder='Enter the facebook profile url' />
                            </div>
                        </Col>
                    </Row>
                </Card>  
            </Col>
        </Row>
        <Row style={{borderTop:'1px solid rgba(21,21,21,0.06)', marginTop:'2rem', paddingTop:'1rem'}}>
            <Col span={12} offset={12}> 
                <Button type="primary" className='ml-auto mt-0 savebtn' onClick={this.handleSubmit} >{this.state.saving? <><LoadingOutlined style={{marginRight:'5px'}}/>Saving...</> : <>Save New User</>}</Button>
            </Col>
        </Row>



    </>)
  }
}

export default AddUser;
