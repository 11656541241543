import React, { Component } from 'react'
import { Col, Row,Card, Upload, Space, Typography, message, Radio, Switch, Input, Button, Select } from 'antd';
import { LoadingOutlined, PlusOutlined,PictureOutlined, UploadOutlined, UserOutlined,DesktopOutlined, MobileOutlined, CheckOutlined, CloseOutlined, ExportOutlined, SaveOutlined, DeleteOutlined} from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImgCrop from 'antd-img-crop';
import API from '../../utils/API';
import Moment from 'react-moment';
import cmsstyle from "../Cms/Website/cms.css"

import { Link } from 'react-router-dom';

const { Title } = Typography;
const imageUrl="https://www1.luxuryproperty.com/"

const { TextArea } = Input;
const modules = {
    toolbar: [
      [{ 'font': ['sans-serif', 'serif', 'monospace'] }],
      [{ 'header': [1, 2, 3,4,5,6, false] }], // Header levels
      ['bold', 'italic', 'underline', 'strike'], // Text formatting
      [{ 'color': [] }, { 'background': [] }], // Text color and background color
      [{ 'script': 'sub' }, { 'script': 'super' }], // Subscript/Superscript
      [{ 'list': 'ordered'}, { 'list': 'bullet' }], // Lists
      [{ 'indent': '-1'}, { 'indent': '+1' }], // Indent
      [{ 'align': [] }], // Text alignment
      ['link', 'image', 'video'], // Link, image, video
      ['blockquote', 'code-block'], // Blockquote and code block
      ['clean'], // Clear formatting
      
    ],
  };

 class EditUser extends Component {

    constructor(props){
        super(props);
        this.state ={
            modules:[],
            permissions:[],
            teams:[],
            user:{add_to_website: '0'},
            name:"",
            firstname:'',
            lastname:'',
            email:"",
            team_id:0,
            active:false,

            loading: false,
            profile_photo:'',
            profile_photo_temp:'',
            previewOpen:'false',
            previewImage:'',

            saving:false,
            roles:[]
        }
    }

    componentDidMount() {
        this.fetchUserData(this.props.record); // Initial fetch when component mounts
    }

    componentDidUpdate(prevProps) {
        // Check if the record prop has changed
        if (prevProps.record !== this.props.record) {
            this.fetchUserData(this.props.record); // Fetch new data if the record has changed
        }
    }

    fetchUserData = (id) => { 
        this.setState({ loading: true });
        API.get("/get_roles").then((response) => {
            const roles = response.data.roles.map((role) => ({
              label: role.role, // setting label to be displayed
              value: role.roles_id, // setting value
            }));
            this.setState({ roles });
          });
          

        API.get(`/user/${id}`).then((response) =>{
            const mydata = response.data.permissions
            const userpermissions = response.data.userpermissions
            const userdata = response.data.user ;    console.log(userdata)
            for (let i = 0; i < mydata.length; i++) {
              
                for (let j = 0; j < userpermissions.length; j++) {
               
                   if(mydata[i].id == userpermissions[j].permission_id){

                    mydata[i].ischecked = true;

                   }else{
                    //mydata[i].ischecked = false;
                   }
                    
                }
                
            }
            
            this.setState({
              permissions:mydata,
              user:userdata,
            })
           
        })
    }

     validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
      };

      getBase64(file) {
        return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        });
    }
    getCurrentTime = (date) => {
        if (date != null && date !== "" && date !== undefined) {
            let newDate = new Date(date);
            if (isNaN(newDate.getTime())) {
                console.error("Invalid date format:", date);
                return null;
            }
            newDate.setTime(newDate.getTime() - (60 * 60 * 1000));
            // console.log("New date:", newDate);

            return newDate.toISOString();
        } else {
            return null;
        }
    }
  // Method to handle file preview
   async handlePreview(file) {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewOpen: true,
    });
  }


      onChangePermission = (permission_id,e)=>{
       
        let index = this.state.permissions.findIndex(obj => obj.id ===permission_id);
        var mydata = this.state.permissions;

        var i;
        for (i = 0; i < mydata.length; i++) {
           
          if (i == index) {

           
           
            mydata[i].ischecked = mydata[i].ischecked? !mydata[i].ischecked:false;
            
          }
        }

        this.setState({permissions:mydata})


      }

     handleSubmit = () =>{
        
        const data = {
            user: this.state.user, // user data
        };


        console.log(data)

        API.put("EditUser/"+this.state.user.id,data)
        .then((response) =>{
            if(response.data.success){
                console.log(response)
            }
        })

    }



       onFinish = (values) => {
      
        const data = {
            form_values:values,
            permissions:this.state.permissions,
        }

       

        API.post("/createUser",data)
        .then((response) =>{
           if(response.data.success){
                 this.props.history.push("/users");
           }
        })
      };

      handleStatusChange = (checked) => {
        const newStatus = checked ? 'Y' : 'N';
        this.setState(prevState => ({
          user: {
            ...prevState.user,
            status: newStatus
          }
        }));
      };

      handleAddToWebsiteChange = (checked) => {
        if (this.state.user.rera_no) {
            const newStatus = checked ? 1 : 0;  // Set as a number 1 or 0
            this.setState(prevState => ({
            user: {
                ...prevState.user,
                add_to_website: newStatus
            }
            }));
            console.log(newStatus)
        } else {
            toast.error("Please enter the BRN/Rera No first before pushing to the website")
        }
      };

      handleGenderChange = (gender) => {
        // Update the user's gender in the state
        this.setState(prevState => ({
          user: {
            ...prevState.user,
            gender: gender.target.value
          }
        }));
      
        // Optionally, you can add an API call here to save the gender change
      };
      
    handleImageSrc = (image) => {
        let imgSrc=''
        if(image.includes("https:")) { imgSrc = image
        } else { imgSrc = 'https://www1.luxuryproperty.com/uploads/team/' + imgSrc}
        return imgSrc
    }

    handleInputChange = (event) => {
        const { name, value } = event.target; // Get the name and value from the input
        this.setState((prevState) => ({
          user: {
            ...prevState.user,
            [name]: value, // Dynamically update the field in the state
          },
        }));
    };

    handleDescriptionChange = (value) => {
        this.setState((prevState) => ({
            user: {
            ...prevState.user,
            description: value, // Update the description field
            },
        }));
    };




  render() {
    const uploadButton = ( <div> {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />} <div style={{ marginTop: 8, }} > Upload </div> </div>);

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must be smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };    

    return (<div className='editUserWrap'> <ToastContainer />
        <Row gutter={12} className='mb-3'>
            <Col span={12}><Title level={3} className='font-bold mb-0'>{this.state.user.display_name}</Title></Col>
            <Col span={12}>
                <Button type="primary" className='ml-auto mt-0 savebtn' onClick={this.handleSubmit} >{this.state.saving? <><LoadingOutlined style={{marginRight:'5px'}}/>Saving...</> : <>Save changes</>}</Button>
            </Col>
            <Col span={24}>
                <span color='rgba(21,21,21,0.8)'>ID: {this.state.user.id}</span>
            </Col>
        </Row>
        <Row gutter={12}>
            <Col span={5}>
                <Card title={<Space className='d-flex align-items-center'><PictureOutlined /><Title level={5} className='font-bold mb-0'>Profile Photo</Title></Space>} style={{height:'100%'}}>
                    <ImgCrop aspect={828 / 682}  quality={12}  modalTitle="Crop your image" modalOk="Crop" modalCancel="Cancel" style={{minHeight:'17.5rem'}}>
                    <div className="custom-upload-wrapper">
                        <Upload name="profile_photo" listType="picture-card" className="profile_photo" showUploadList={false}  onPreview={this.handlePreview} beforeUpload={beforeUpload} onChange={this.handleDesktopChange} onRemove={this.handleRemove}>
                        {this.state.profile_photo_temp ? (<img src={this.state.profile_photo_temp} alt="Change Profile Photo" style={{ width: '100%',}} />)
                        :this.state.user.profile_photo ? (<img src={this.handleImageSrc(this.state.user.profile_photo)} alt="Profile Photo" style={{ width: '100%',}} />) 
                        : (uploadButton)
                        }
                        </Upload>
                        <div className="upload-content">
                            <UploadOutlined className="upload-icon" />
                        </div>
                    </div>
                    </ImgCrop>
                </Card>    
            </Col>
            <Col span={14}>
                <Card title={<Space className='d-flex align-items-center'><UserOutlined /><Title level={5} className='font-bold mb-0'>Personal Info</Title></Space>} style={{height:'100%'}}>
                    <Row gutter={12} direction="row">
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>First Name:</label>
                                <input type="text" className="form-control" name="first_name" defaultValue={this.state.user.first_name} onChange={this.handleInputChange} placeholder='First Name' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Last Name:</label>
                                <input type="text" className="form-control" name="last_name" defaultValue={this.state.user.last_name} onChange={this.handleInputChange} placeholder='Last Name' />
                            </div>
                        </Col>
                    </Row>
                    
                    <Row gutter={12} direction="row">
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Gender:</label>
                                <Radio.Group className='d-flex' buttonStyle="solid" value={this.state.user.gender} onChange={this.handleGenderChange}>
                                    <Radio.Button value="male">Male</Radio.Button>
                                    <Radio.Button value="female">Female</Radio.Button>
                                </Radio.Group>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Language:</label>
                                <input type="text" className="form-control" name="language" defaultValue={this.state.user.language}  placeholder='Enter language' onChange={this.handleInputChange} />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={5}>
                <Card className={'loginStyle'} title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>Status</Title></Space>}>
                    <Row gutter={12} direction="row" style={{ display:'flex', justifyContent:'center', height:'80%' }}>
                        <Col span={24} className="d-flex flex-column align-items-start pt-3">
                            <span className='font-bold font-12 mb-1'>Account:</span>
                            <Switch size='large' className='switchStyle' handleSize={'large'}
                            checked={this.state.user.status === 'Y'} 
                            onChange={checked => this.handleStatusChange(checked)} 
                            checkedChildren={<><CheckOutlined style={{marginRight:5}} />Active</>} 
                            unCheckedChildren={<><CloseOutlined style={{marginRight:5}} />Inactive</>}
                            />
                        </Col>
                        <Col span={24}><Space className='d-flex flex-column gap-2' align='left'>
                            <span className='font-bold font-12 gap-1'>  
                            Last Seen: 
                            {this.state.user.web_login != null && this.state.user.web_login != "" && this.state.user.web_login != undefined ?<Moment fromNow className='font-bold' >{this.getCurrentTime(this.state.user.web_login)}</Moment>:' Never'} </span>
                            <div style={{marginTop:5, display:'flex', flexDirection:'row'}}>
                                <span className='d-flex flex-row font-12 align-items-start' style={{ gap: '0.3rem', marginRight:3 }}><DesktopOutlined style={{marginTop:'3px'}}/>Web: </span>
                                <span className='d-flex flex-row font-12 align-items-start' style={{ gap: '0.3rem' }}>
                                {this.state.user.web_login != null && this.state.user.web_login != "" && this.state.user.web_login != undefined ? <div className='d-flex flex-column font-12 align-items-start gap-1'>
                                    <Moment format=' hh:mm:ss | DD MMM YYYY'>{(this.state.user.web_login) ? this.getCurrentTime(this.state.user.web_login) : ""}</Moment> 
                                </div>: " Never"
                                    }</span>
                            </div>
                            <div style={{marginTop:5,display:'flex', flexDirection:'row'}}>
                                <span className='d-flex flex-row font-12 align-items-start' style={{ gap: '0.3rem', marginRight:3 }}><MobileOutlined style={{marginTop:'3px'}}/>Mobile: </span>
                                <span className='d-flex flex-column font-12 align-items-start' style={{ gap: '0.3rem' }}>
                                {this.state.user.mobile_login != null && this.state.user.mobile_login != "" && this.state.user.mobile_login != undefined ? 
                                <div className='d-flex flex-column font-12 align-items-start'>
                                    <Moment format=' hh:mm:ss | DD MMM YYYY'>{(this.state.user.mobile_login) ? this.getCurrentTime(this.state.user.mobile_login) : ""}</Moment>
                                </div>: " Never"
                            }</span>
                            </div>
                        </Space></Col>    
                    </Row>
                </Card>
            </Col>
        </Row>
        <Row className='mt-4' gutter={12}>
            <Col span={12}>
                <Card title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>Contact Details</Title></Space>} style={{height:'100%'}}>
                <Row gutter={12} direction="row">
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Email:</label>
                                <input type="text" name="email" className="form-control" defaultValue={this.state.user.email} onChange={this.handleInputChange} placeholder='Enter email address' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Email 2:</label>
                                <input type="text" name="email1" className="form-control" defaultValue={this.state.user.email1} onChange={this.handleInputChange} placeholder='Enter secondary email address' />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={12} direction="row">
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Mobile:</label>
                                <input type="text" name="mobile" className="form-control" defaultValue={this.state.user.mobile} onChange={this.handleInputChange} placeholder='enter mobile' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Mobile 2:</label>
                                <input type="text" name="mobile1" className="form-control" defaultValue={this.state.user.mobile1} onChange={this.handleInputChange} placeholder='Enter secondary mobile' />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={12}>
                <Card title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>Office Details</Title></Space>} style={{height:'100%'}}>
                <Row gutter={12} direction="row">
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Designation:</label>
                                <input type="text" name="jobtitle" className="form-control" defaultValue={this.state.user.jobtitle} onChange={this.handleInputChange} placeholder='Enter Designation' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Role:</label>
                                <Select options={this.state.roles} style={{ width: "100%",}} name="role" value={this.state.user.role} showSearch allowClear placeholder="Choose a role" optionFilterProp="children" />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={12} direction="row">
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Telephone Directory:</label>
                                <input type="text" name="telephone_directory" className="form-control" defaultValue={this.state.user.telephone_directory} onChange={this.handleInputChange} placeholder='Choose a telephone directory' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>BRN / RERA no:</label>
                                <input type="text" name="rera_no" className="form-control" defaultValue={this.state.user.rera_no} onChange={this.handleInputChange} placeholder='Enter BRN number' />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
        <Row className='mt-4'>
            <Col span={24}>
                <Card title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>Bio</Title></Space>} style={{ paddingBottom:'1rem'}}>
                    <TextArea theme="snow" modules={modules} value={this.state.user.description} onChange={this.handleDescriptionChange}/>
                </Card>  
            </Col>
        </Row>
        <Row className='mt-4' gutter={12}>
            <Col span={8}>
                <Card title={<Space className='d-flex align-items-center '><Title level={5} className='font-bold mb-0'>Website</Title></Space>} style={{ paddingBottom:'1rem'}} className='addtowebwrap'>
                    <Row gutter={12}>
                        <Col span={24} className='d-flex flex-column gap-3 mb-3 '>
                            <span className='font-bold font-12 mb-0' style={{whiteSpace:'nowrap'}}>Show on the website:</span>
                            <Switch size='large' className='switchStyle' handleSize={'large'}
                            checked={this.state.user.add_to_website === 1} 
                            onChange={this.handleAddToWebsiteChange} 
                            checkedChildren={<><CheckOutlined style={{marginRight:5}} />Added</>} 
                            unCheckedChildren={<><CloseOutlined style={{marginRight:5}} />Not on the website</>}
                            />
                        </Col>
                        {this.state.user.add_to_website === 1 && <Col span={24} className='mb-3'>
                            <div className='form-group d-flex flex-row align-items-center gap-1 mb-0'>
                                <label className='font-bold mb-0'>Sort:</label>
                                <input type="text" name="sort_order" className="form-control" defaultValue={this.state.user.sort_order} onChange={this.handleInputChange} placeholder='Sort' />
                            </div>
                        </Col>   }    
                        {this.state.user.add_to_website === 1 && <Col span={24}>
                            <div className='form-group d-flex flex-row align-items-center gap-1 mb-0'>
                                <label className='font-bold mb-0'>Slug:</label>
                                <input type="text" name="slug" className="form-control" defaultValue={this.state.user.slug} onChange={this.handleInputChange} placeholder='Enter profile url' />
                            </div>
                            <Link className="d-flex flex-row gap-1 mt-4 ml-auto" target="_blank"  href={this.state.user && this.state.user.slug ? `https://www.luxuryproperty.com/team/${this.state.user.slug}` : '#'} style={{color:'rgba(21,21,21,0.6)', textDecoration:'none', marginLeft:'auto', fontSize:14}}><ExportOutlined fontSize={14} color="#151515" />Open profile</Link>
                        </Col>   }    
                    </Row>
                </Card>
            </Col>
            <Col span={16}>
                <Card title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>SEO</Title></Space>} style={{ paddingBottom:'1rem'}}>
                    <Row gutter={12}>
                        <Col span={24}>
                            <div className='form-group'>
                                <label className='font-bold'>Meta Title:</label>
                                <input type="text" name="meta_title" className="form-control" defaultValue={this.state.user.meta_title} onChange={this.handleInputChange} placeholder='Enter meta title' />
                            </div>
                            <div className='form-group'>
                                <label className='font-bold'>Meta Keywords:</label>
                                <input type="text" name="meta_keywords" className="form-control" defaultValue={this.state.user.meta_keywords} onChange={this.handleInputChange} placeholder='Enter keywords' />
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className='form-group mb-0'>
                                <label className='font-bold'>Meta Description:</label>
                                <TextArea name="meta_description" value={this.state.user.meta_description} style={{height:'7rem'}} palceholder="Enter meta description" onChange={this.handleInputChange}/>
                            </div>
                        </Col>
                    </Row>
                </Card>  
            </Col>
        </Row>
        <Row className='mt-4'>
            <Col span={24}>
                <Card title={<Space className='d-flex align-items-center'><Title level={5} className='font-bold mb-0'>Social Media</Title></Space>} style={{ paddingBottom:'1rem'}}>
                    <Row gutter={12}>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Instagram:</label>
                                <input type="text" name="instagram" className="form-control" defaultValue={this.state.user.instagram} onChange={this.handleInputChange} placeholder='Enter the url of instagram profile' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Linkedin:</label>
                                <input type="text" name="linkedin" className="form-control" defaultValue={this.state.user.linkedin} onChange={this.handleInputChange} placeholder='Enter the linkedin url' />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className='form-group'>
                                <label className='font-bold'>Facebook:</label>
                                <input type="text" name="facebook" className="form-control" defaultValue={this.state.user.facebook} onChange={this.handleInputChange} placeholder='Enter the facebook profile url' />
                            </div>
                        </Col>
                    </Row>
                </Card>  
            </Col>
        </Row>
        <Row style={{borderTop:'1px solid rgba(21,21,21,0.06)', marginTop:'2rem', paddingTop:'1rem'}}>
            <Col span={12}>
                <Button type="secondary" className='gap-1 removeuser mt-0'><DeleteOutlined />Remove user</Button>
            </Col>
            <Col span={12}> 
                <Button type="primary" className='ml-auto mt-0 savebtn' onClick={this.handleSubmit} >{this.state.saving? <><LoadingOutlined style={{marginRight:'5px'}}/>Saving...</> : <>Save changes</>}</Button>
            </Col>
        </Row>
        </div>
    )
  }
}

export default EditUser;

const tagRole = { fontSize:14, display:'flex', alignItems:'center', width:'fit-content',textTransform:'capitalize',
    '.stat-Y': {background:'green'},
}